import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getInsuranceDetail } from "../../../api/insurance";
import { formatCurrency } from "../../../helper/formatCurrency"

const InsuranceDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const insuranceList = async () => {
        setLoading(true);
        try {
            const res = await getInsuranceDetail(id)
            setDetail(res?.data?.data);
        } catch (err) {
            message.error({
                content: "Request Not Found",
            });
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else {
            insuranceList();
        }
    }, []);

    const dataBreadcrumb = [
        {
            title: "Payroll Management",
            url: "/insurance"
        },
        {
            title: "Insurance",
            url: "/insurance"
        },
        {
            title: "Insurance Detail"
        },
    ];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{ marginRight: "14px" }} onClick={() => navigate('/insurance')} icon={<ArrowLeftOutlined />} size={"large"} />
                Insurance Detail
            </div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
                <Col span={24}>
                    <Breadcrumb items={dataBreadcrumb} />
                </Col>
            </div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Code</div>
                            <div className="subtitle">{detail?.code}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Insurance Name</div>
                            <div className="subtitle">{detail?.name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Premium Value</div>
                            <div className="subtitle">{formatCurrency(detail?.premium_value) || "-"}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default InsuranceDetail