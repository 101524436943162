import React, { useState } from "react"
import "../Health/styles.scss"
export function HealthPage(){

    const response = {
        "success" : true,
        "code" : 200,
        "data" : null,
        "message" : "Everything under controll.",
        "error" : null
        };
return (
  
    <div id="health-page">
         <h3>Hey Dude!!! The App is Healthy</h3>
         <textarea>
       
        {JSON.stringify(response,null,4)}
      
        </textarea>
         
    </div>
   
  
    )
}