import { decryptData } from "../helper/cryptojs";
import { sso } from "./hris";

export const api_key = "SSOQLeCuJFw3NkzO0MugSJWEb2hV4SZzPOlTHDK4OpcACzYyN3ukWDaT96b6TNOCGc2JmKPVHEFZA2XwoZl89lXjlOtkWDprFfouNq0R6tAeU9hlKW84GcbmjmA0F81D"

// export const login = async (data) => {
//   let payload = {
//     api_key : api_key,
//     application : ["HRIS"],
//     email: data?.email,
//     employee_number :data.employee_number,
//     password: data?.password,
//   };
//   return await sso.post("/login", payload);
// };

export const login = async (data) => {
  let payload = {
    api_key: api_key,
    application: ["HRIS"],
    password: data?.password,
  };

  if (data?.employee_number) {
    payload.employee_number = data.employee_number.toUpperCase();
  } else {
    payload.email = data?.email;
  }

  return await sso.post("/login", payload);
};

export const register = async (data) => {
  let payload = {
    api_key : api_key,
    employee_number: data.employee_number,
    employee_name:data.employee_name,
    // name:data.name,
    email : data.email,
    // password : data.password,
    application : ["HRIS"],
    role : data.role,
    is_active : data.status,
    // phone_number : '62' + data.phoneNumber,
    company_code : data.company_code,
    branch_code : data.branch_code,
  };
  return await sso.post("/register", payload);
};

export const getUser = async (search,limit, offset,searchby,company, is_active) => {
  let payload = {
    api_key : api_key,
    search_by: searchby.length > 0 ? searchby : "username",
    search_value : search.length > 0 ? search : undefined,
    company_code: company.length > 0 ? company : undefined,
    branchCode: "",
    limit : limit,
    offset : offset,
    is_active: is_active
  };
  return await sso.post("/getUserList", payload);
};

export const getUserDetail = async (id) => {
  let payload = {
    api_key : api_key,
    user_id : id
  };
  //console.log(payload)
  return await sso.post("/getUsers", payload);
};

export const sendEmail = async (data) => {
  let payload = {
    api_key : api_key,
    email: data.email,
    // hosturl: "https://hris.ethos.co.id"
    hosturl: process.env.REACT_APP_HRIS_URL_MAINTANCE,
  };
  return await sso.post("/forgot", payload);
}

export const resetPassword = async (data, email,expired,token) => {
  let payload = {
    api_key : api_key,
    email: email,
    expired:expired,
    token:token,
    // url:"https://hris.ethos.co.id",
    url:process.env.REACT_APP_HRIS_URL_MAINTANCE,
    password: data.password,
  };
  return await sso.post("/updatePassword", payload);
}

export const updateUser = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    api_key : api_key,
    user_id : id,
    employee_number:data.employee_number,
    employee_name:data.employee_name,
    // name:data.name,
    is_active : data.status,
    email : data.email,
    // password : data.password,
    role : data.role,
    // phone_number : '62' + data.phoneNumber,
    updated_by : user,
    company_code : data.company_code,
    branch_code : data.branch_code,
  };
  return await sso.post("/updateUser", payload);
}


export const checkMaintance = async () => {
  let payload = {
    url : process.env.REACT_APP_HRIS_URL_MAINTANCE,
  };
  return await sso.post("/check-maintenance", payload);
};

