import React from 'react';
import { Link } from 'react-router-dom';
import IconWelcome from '../component/IconWelcome';

const Welcome = () => (
  <div className='mt-32'>
    <div className='flex justify-center'>
      <div className='hidden md:block'>
        <IconWelcome className='' />
      </div>
      <div className='md:hidden'>
        <IconWelcome />
      </div>
    </div>
    <div className='text-center mt-8 md:mt-12'>
      <h1 className='text-2xl md:text-4xl'>Welcome to HRIS</h1>
      <p className='text-sm md:text-base'>
        We're thrilled to have you on board. Get ready to explore and experience all the amazing features we have to offer.
      </p>
      <p className='text-sm md:text-base'>
        If you have any questions or need assistance, feel free to reach out to our support team.
      </p>
      <Link className='text-sm md:text-base underline underline-offset-8'>Happy exploring!</Link>
    </div>
  </div>
);

export default Welcome;
