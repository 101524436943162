/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, message, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined,CloseCircleOutlined ,CheckCircleOutlined,CaretRightOutlined} from "@ant-design/icons";
import { getApprovalListDetail,Singlecreate} from "../../../api/approvallist";
import { decryptData } from "../../../helper/cryptojs";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

const { TextArea } = Input;
const ApprovalListDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    let n = location.lastIndexOf('/');
    let result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    let parameters = decode.split('?');
    let id = parameters[0].split('=')[1];
    let row_type = parameters[1].split('=')[1];
    const [detail, setDetail] = useState([]);
    const [loading, setLoading] = useState(false);
    const BestpraticeUseEffect = useRef(false)
    const [open, setOpen] = useState(false);
    const [openReasonReject, setOpenopenReasonReject] = useState(false);
    const userNIK = decryptData(localStorage.getItem("EmployeeNumber"));
    const userCompany = decryptData(localStorage.getItem("DefaultCompanyCode"));

    const ApprovalDetail = async () => {
        try {
            setLoading(true);
            const res = await getApprovalListDetail(id,row_type)
            const data = res?.data?.data;
            setDetail(data[0])
          } catch (err) {
            console.log(err);
          }finally {
            setLoading(false);
            
          };
	  };


    const Approve = async () => {
        try {
            Modal.confirm({
                title: "Confirmation",
                content: "Are you sure want to approve this request?",
                onOk: async () => {
                  try {
                    detail.companyCode = userCompany;
                    detail.employeeNumber = userNIK;
                    detail.submit_type = 'APPROVED';
                    detail.row_id = id;
                    const res = await Singlecreate(detail);
                    if (!res?.data?.success) {
                      message.error({
                        content: res?.data?.error,
                      });
                    } else {
                      message.success({
                        content: "Request successfully approved",
                      });
                      navigate("/approval-list");
                    }
                  } catch (err) {
                    console.log(err);
                  } finally {
                    setLoading(false);
                  }
                },
              });
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
        }
    };


      const validationSchema = yup.object().shape({
        remark: yup.string().required("Field is required"),
      });

      const formik = useFormik({
        initialValues: {
          remark: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
          setLoading(true);
          await Reject(values.remark);
          setLoading(false);
        },
      });
      
    const Reject = async (remark) => {
        try {
            detail.companyCode = userCompany;
            detail.employeeNumber = userNIK;
            detail.submit_type = 'REJECTED';
            detail.remark = remark;
            detail.row_id = id;
            const res = await Singlecreate(detail)
            if(!res?.data?.success){
                message.error({
                    content: res?.data?.error,
                });
            } else {
                message.success({
                    content: "Request successfully rejected",
                });
                navigate("/approval-list")
            }
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
        }
    };


      const handleApprove = () => {
        setLoading(true);
        Approve();
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
        }, 3000);
      };

      const handleCancelReason = () => {
        setOpenopenReasonReject(false);
        setOpen(false);
      };

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          }else{
            ApprovalDetail();
          }
	}, []);

     const handlerejects = () => {
        Modal.confirm({
            title: "Confirmation",
            content: "Are you sure want to reject this request?",
            onOk: async () => {setOpenopenReasonReject(true)},
          });
     }

    const dataBreadcrumb = [
		{
			title: "Approval List",
			url: "/approval-list"
		},
        {
			title: "Approval List Detail",
			// url: "/approval-list/approval-list-detail"
		},
	];

    const RoleAccessApprovalListApprove = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalListApprove'));
    const RoleAccessApprovalListReject = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalListReject'));

    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/approval-list')} icon={<ArrowLeftOutlined />} size={"large"} />
				Approval List Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">

            <div className="flex flex-row gap-3 items-center justify-center">
                <h3>{detail?.full_name}</h3>
                <p>would like to request </p>
                <h3>{detail?.request_type} :</h3>
            </div>
            <div className="text-center">
                <p>Position :  {detail?.position_name} </p>
            </div>
            <div className="text-center text-blue-600">
                {detail?.request_type === 'Attendance' && (
                    <h3>Schedule Detail :</h3>
                )}
                {detail?.request_type === 'Overtime' && (
                    <h3>Request Detail :</h3>
                )}
                
            </div>
                
                {/*  START UNTUK OVERTIME */}
                { detail?.request_type  === 'Overtime' &&
                <>
                <div className="flex justify-center">
                    <div className="border-dashed overflow-x-auto border-2  border-sky-500 bg-white w-72 md:w-[310px] h-auto rounded-lg shadow-lg mt-3">
                        <div className="p-2">
                            <h3 className="text-blue-600">Date : <span className="text-sm text-black">{detail?.request_date}</span></h3>
                            <h3 className="text-blue-600 flex">
                            Notes
                            <span className="ml-1">:</span>
                            <span className="text-sm ml-1 text-black overflow-x-auto">
                                {detail?.description}
                            </span>
                            </h3>
                            <h3 className="text-blue-600">Break Duration : <span className="text-sm text-black">{moment(detail?.break_duration,"HH:mm").format("HH:mm") || '-'}</span></h3>
                            <h3 className="text-blue-600">Overtime Duration : <span className="text-sm text-black"> {moment(detail?.duration,"HH:mm").format("HH:mm") || '-'}</span></h3>
                            <h3 className="text-base md:text-sm flex">
                            Attachment
                            <span className="text-base md:text-sm text-zinc-400 ml-3 flex">
                                :
                                <div className="flex">
                                <svg
                                    className="w-4 h-4 mt-[-5px] md:w-6 md:h-6 mr-2 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                </svg>
                            <span className="">
                            {detail?.attachment_url ? (
                                <Link
                                to={detail?.attachment_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="md:text-sm sm:text-xs overflow-x-auto text-gray-500 relative bottom-0 top-[-25px]"
                                >
                                {detail?.attachment_url.replace(`${process.env.REACT_APP_HRIS_URL}overtime/`, "").match(/.{1,18}/g)?.map((line, index) => <span key={index}><br />{line}</span>)}
                                </Link>
                            ) : (
                                <span key={detail?.id} className="text-gray-500">
                                No File
                                </span>
                            )}
                            </span>
                                </div>
                            </span>
                        </h3>
                        </div>
                 </div>
                 </div>
                <div className="flex flex-row justify-around text-center">
                    <div>
                        <h3 className="text-blue-600">Scheduled</h3>
                        <p>{detail?.scheduled_time }</p>
                    </div>
                    <div>
                        <h3 className="text-blue-600">Compensasion Type</h3>
                        <p>{detail?.compensation_type}</p>
                    </div>
                </div>
                <div className="flex flex-row justify-around text-center ">
                    <div className="px-3 py-3">
                        <h3 className="text-blue-600">Scheduled In</h3>
                        <p>{moment(detail?.start_time,"HH:mm").format("HH:mm") || '-'}</p>
                    </div>
                    <div className="px-4 py-4">
                        <h3 className="text-blue-600">Scheduled Out</h3>
                        <p>{moment(detail?.end_time,"HH:mm").format("HH:mm") || '-'}</p>
                    </div>
                </div> 
                </>
                }
            
               {/*  END UNTUK OVERTIME */}

                   {/*  START UNTUK LEAVE */}
                   {detail?.request_type === "Leave" && (
                    <div>
                      <h3 className="text-center text-blue-600">Request Detail :</h3>
                    <div className="flex justify-center">
                      <div className="border-dashed border-2 border-sky-500 bg-white h-auto w-[350px] p-3 rounded-lg shadow-lg mt-3">
                        <div className="p-2">
                          <h3 className="text-black">
                            Start Date <span className="relative left-10 mr-1">  
                              :
                              </span> 
                            <span className="text-sm text-black relative left-10">{detail?.date_from}</span>
                          </h3>
                           <h3 className="text-black">
                            End Date 
                            <span className="relative left-[54px] mr-1">  
                              :
                              </span> 
                              {" "}
                            <span className="text-sm text-black relative left-[50px]">{detail?.date_to}</span>
                          </h3>
                           <h3 className="text-black">
                            Duration 
                            <span className="relative left-[56px] mr-1">  
                              :
                              </span> 
                              {" "}
                            <span className="text-sm text-black relative left-[52px]">{`${detail?.duration} Days` || "-"}</span>
                          </h3>
                           <h3 className="text-black flex gap-5">
                            <div className="w-[200px]">
                            Leave Type <span className=" relative left-[32px]">  
                              :
                              </span>
                              </div> 
                            <span className="block text-sm text-black" style={{ wordWrap: 'break-word', width:'100%' }}>
                            {detail?.leave_type || "-"}
                            </span>
                          </h3>
                           <h3 className="text-black flex gap-2">
                            <div className="w-[200px]">
                            Request Type <span className=" relative left-[12px]">  
                              :
                              </span>
                              </div> 
                            <span className="block text-sm text-black relative left-2" style={{ wordWrap: 'break-word', width:'100%' }}>
                            {detail?.request_type || "-"}
                            </span>
                          </h3>
                          <h3 className="text-black flex gap-2">
                            <div className="w-[200px]">
                            Description <span className=" relative left-[29px]">  
                              :
                              </span>
                              </div> 
                            <span className="block text-sm text-black relative left-2" style={{ wordWrap: 'break-word', width:'100%' }}>
                            {detail?.reason || "-"}
                            </span>
                          </h3>
                          <h3 className="text-black flex gap-2">
                            <div className="w-[200px]">
                            Attachment <span className=" relative left-[27px]">  
                              :
                              </span>
                              </div> 
                            <span className="block overflow-x-scroll text-sm text-black relative left-2" style={{ wordWrap: 'break-word', width:'100%' }}>
                            <div className="flex">
                                <svg
                                    className="w-12 h-4 md:w-8 mt-[-5px] md:h-6 mr-2 fill-current text-zinc-400 "
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                </svg>
                            {detail?.attachment_url ? (
                                <Link
                                to={detail?.attachment_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=" md:text-sm sm:text-xs overflow-x-auto text-gray-500 relative bottom-0 top-[-25px]"
                                >
                                {detail?.attachment_url.replace(`${process.env.REACT_APP_HRIS_URL}backend/company/image/`, "").match(/.{1,22}/g)?.map((line, index) => <span key={index}><br />{line}</span>)}
                                </Link>
                            ) : (
                                <span key={detail?.id} className="text-gray-500">
                                No File
                                </span>
                            )}
                                </div>
                            </span>
                          </h3>
                          {/* <h3 className="text-black relative left-10 flex">
                            Attachment
                            <span className="text-base  text-zinc-400 relative left-10 flex">
                              <span className="text-black relative left-10 mr-1">
                                :

                              </span>
                                <div className="flex">
                                <svg
                                    className="w-4 h-4 md:w-6 mt-[-5px] md:h-6 mr-2 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                </svg>
                            {detail?.attachment_url ? (
                                <Link
                                to={detail?.attachment_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=" md:text-sm sm:text-xs overflow-x-auto text-gray-500 relative bottom-0 top-[-25px]"
                                >
                                {detail?.attachment_url.replace(`${process.env.REACT_APP_HRIS_URL}backend/company/image/`, "").match(/.{1,22}/g)?.map((line, index) => <span key={index}><br />{line}</span>)}
                                </Link>
                            ) : (
                                <span key={detail?.id} className="text-gray-500">
                                No File
                                </span>
                            )}
                                </div>
                            </span>
                        </h3> */}
                        </div>
                      </div>
                    </div>
                    </div>
                  )}
                  {/*  END UNTUK LEAVE */}
                  
                 {/*  START UNTUK SHIFT */}
                 {detail?.request_type === "Shifting" && (
                  <div className="flex flex-col items-center gap-4 justify-center">
                  <h3 className="text-center text-blue-600">Request Detail :</h3>
                  <div className="border-dashed border-2 border-sky-500 bg-white w-72 md:w-[400px] h-auto rounded-lg shadow-lg mt-3 overflow-hidden">
                    <div className="flex justify-center">
                      <div className="p-2">
                        <h3 className="text-black mb-[-4px] text-center">{detail?.prev_shift_date}</h3>
                        <div className="text-gray-700 flex flex-col md:flex-row gap-2 items-center">
                          <p>{detail?.prev_shift_name}</p>
                          <CaretRightOutlined size={'small'} />
                          <p>
                            In {moment(detail?.prev_schedule_in, 'HH:mm:ss').format('HH:mm')} - Out{' '}
                            {moment(detail?.prev_schedule_out, 'HH:mm:ss').format('HH:mm')}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-[-20px] mt-[-20px]">
                    <h3 className="text-center text-blue-600">To</h3>
                    </div>
                    <div className="flex justify-center">
                      <div className="p-2">
                        <h3 className="text-black mb-[-4px] text-center">{detail?.prev_shift_date}</h3>
                        <div className="text-gray-700 flex flex-col md:flex-row gap-2 items-center">
                          <p>{detail?.shift_name}</p>
                          <CaretRightOutlined size={'small'} />
                          <p>
                            In {moment(detail?.schedule_in, 'HH:mm:ss').format('HH:mm')} - Out{' '}
                            {moment(detail?.schedule_out, 'HH:mm:ss').format('HH:mm')}
                          </p>
                        </div>
                      </div>
                      </div>
                          <div className="pl-4 pr-4">
                            <h3 className="text-base md:text-sm flex">
                              Description
                              <span className="text-base md:text-sm text-zinc-400 ml-3 flex">
                                :
                              </span>
                            </h3>
                                <div className="flex">
                                <div className="text-gray-700">
                                  {detail?.description || "-"}
                                  </div>
                                </div>
                          </div>
                          <div className="pl-4 pr-4">
                            <h3 className="text-base md:text-sm flex">
                              Attachment
                              <span className="text-base md:text-sm text-zinc-400 ml-3 flex">
                                :
                                </span>
                                </h3>
                                <div className="flex">
                                  <svg
                                    className="w-4 text-zinc-400 h-4 md:w-6 mt-[-5px] md:h-6 mr-2 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"
                                    ></path>
                                  </svg>
                                  {detail?.attachment_url ? (
                                    <Link
                                      to={detail?.attachment_url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="md:text-sm sm:text-xs overflow-x-auto text-gray-500 relative bottom-0 top-[-25px]"
                                    >
                                      {detail?.attachment_url
                                        ?.replace(`${process.env.REACT_APP_HRIS_URL}/backend/company/image/change-shift/`, "")
                                        ?.match(/.{1,45}/g)
                                        ?.map((line, index) => (
                                          <span key={index}>
                                            <br />
                                            {line}
                                          </span>
                                        ))}
                                    </Link>
                                  ) : (
                                    <span key={detail?.id} className="text-gray-500">
                                      No File
                                    </span>
                                  )}
                                </div>
                          </div>
                    </div>
                  </div>
              
                    )}
                  {/*  END UNTUK SHIFT */}


                  {/*  START UNTUK ATTENDANCE */}
                  { detail?.request_type === 'Attendance' &&
                  <div>
                    <div className="flex justify-center">
                        <div className="border-dashed border-2 border-sky-500 bg-white h-auto w-64 rounded-lg shadow-lg mt-3">
                            <div className="p-2">
                                <h3 className="text-black text-center">{detail?.request_date}</h3>
                                <h3 className="text-center">Schedule : {detail?.schedule_name || 'Schedule : -'}</h3>
                                <div className="flex flex-row gap-4 justify-center">
                                <div className="flex justify-center flex-col ">
                                    <div className="text-center">
                                        <p className="text-sm">Check-In</p>
                                        <p>{detail?.prev_check_in ? moment(detail?.prev_check_in, "HH:mm").format("HH:mm") : "--:--"}</p>
                                    </div>
                                </div>
                                <div className="flex justify-center flex-col">
                                    <div className="text-center">
                                        <p className="text-sm">Check-Out</p>
                                        <p>{detail?.prev_check_out ? moment(detail?.prev_check_out, "HH:mm").format("HH:mm") : "--:--"}</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <h3 className="text-center text-blue-600">Request Detail :</h3>
                    <div className="flex flex-row gap-4 justify-center">
                    <div className="border-dashed overflow-x-auto border-2  border-sky-500 bg-white w-72 md:w-[310px] h-auto rounded-lg shadow-lg mt-3">
                    <div className="p-2">
                        <h3 className="text-sm">Check-In  <span className="text-zinc-400  ml-7">: {moment(detail?.req_check_in,"HH:mm").format("HH:mm")  || '-'}</span> </h3>
                        <h3 className="text-sm">Check-Out  <span className="text-zinc-400  ml-4">: {moment(detail?.req_check_out,"HH:mm").format("HH:mm")  || '-'}</span></h3>
                        <h3 className="text-sm">Note  <span className="text-zinc-400 ml-14 overflow-x-auto">: {detail?.description || '-'}</span></h3>
                        <h3 className="text-base md:text-sm flex">
                            Attachment
                            <span className="text-base md:text-sm text-zinc-400 ml-3 flex">
                                :
                                <div className="flex">
                                <svg
                                    className="w-4 h-4 md:w-6 mt-[-5px] md:h-6 mr-2 fill-current"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                >
                                    <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                </svg>
                            {detail?.attachment_url ? (
                                <Link
                                to={detail?.attachment_url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className=" md:text-sm sm:text-xs overflow-x-auto text-gray-500 relative bottom-0 top-[-25px]"
                                >
                                {detail?.attachment_url.replace(`${process.env.REACT_APP_HRIS_URL}attendancerequest/Phase02/`, "").match(/.{1,19}/g)?.map((line, index) => <span key={index}><br />{line}</span>)}
                                </Link>
                            ) : (
                                <span key={detail?.id} className="text-gray-500">
                                No File
                                </span>
                            )}
                                </div>
                            </span>
                        </h3>
                        </div>
                      </div>
                    </div>
                </div>
                }
              {/*  END UNTUK ATTENDANCE */}
              <div className="flex justify-end mt-8">
                {RoleAccessApprovalListReject && (
                    <Button key="back"   size="large" icon={<CloseCircleOutlined/>} type="primary" danger style={{marginRight: "14px"}} onClick={handlerejects} >
                        Reject
                    </Button>
                )}
                {RoleAccessApprovalListApprove && (
                    <Button size="large" icon={<CheckCircleOutlined />}  style={{ backgroundColor: "#34D399", color: "white",borderColor:"#34D399", marginRight: "14px" }} onClick={handleApprove}>
                        Approve
                    </Button>
                )}
              </div>
                <form onSubmit={formik.handleSubmit}>
                    <Modal
                        open={openReasonReject}
                        title="Reason"
                        onOk={formik.handleSubmit}
                        onCancel={handleCancelReason}
                        footer={[
                        <Button key="back" className="mt-6" onClick={handleCancelReason}>
                            Cancel
                        </Button>,
                        <Button key="submit" className="mt-6" type="primary" loading={loading} onClick={formik.handleSubmit}>
                            Ok
                        </Button>,
                        ]}
                    >
                        <TextArea
                        showCount
                        maxLength={100}
                        name="remark"
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        />
                        {formik.touched.remark && formik.errors.remark ? (
                        <div className="text-red-500">{formik.errors.remark}</div>
                        ) : null}
                    </Modal>
                </form>
            </div>
        </>
        
    )
}

export default ApprovalListDetail