/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, Modal, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";
import { decryptData } from "../../helper/cryptojs";
import { DeleteTaxMethod, getTaxMethod } from "../../api/taxMethod";

export default function TaxMethod() {
  const navigate = useNavigate();
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const RoleAccessTaxMethodDelete = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeTaxMethodDelete")
  );
  const RoleAccessTaxMethodUpdate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeTaxMethodUpdate")
  );
  const RoleAccessTaxMethodCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeTaxMethodCreate")
  );

  const limitSession = sessionStorage.getItem("sessionPageTaxMethod");
  const offsetSession = sessionStorage.getItem("sessionOffsetTaxMethod");
  const sessionSearch = sessionStorage.getItem("sessionSearchTaxMethod");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch ?? "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const TaxMethodMaster = async () => {
    setLoading(true);
    try {
      const res = await getTaxMethod(company, page, offset, limit, search);
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageTaxMethod", page);
  }, [page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageTaxMethod");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      TaxMethodMaster();

      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageTaxMethod");
        sessionStorage.removeItem("sessionOffsetTaxMethod");
        sessionStorage.removeItem("sessionSearchTaxMethod");
        setSearch("");
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, page, offset, limit, search]);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - 10);
    }
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/tax-methods/detail/${encodeURIComponent(
                  "id?" + record.m_tax_method_id
                )}`
              )
            }>
            {index + 1 + (page - 1) * limit}
          </div>
        );
      },
    },
    {
      title: "Tax Method",
      dataIndex: "tax_method",
      key: "tax_method",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/tax-methods/detail/${encodeURIComponent(
                  "id?" + record.m_tax_method_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      render: (_, record, index) => {
        return RoleAccessTaxMethodUpdate && RoleAccessTaxMethodDelete ? (
          <Row justify={"space-between"}>
            <div className="icon-action">
              <DeleteFilled
                onClick={() => showModal(record?.m_tax_method_id)}
              />
            </div>
            <div
              className="icon-action"
              onClick={() =>
                navigate(
                  `/tax-methods/edit-tax-methods/${encodeURIComponent(
                    "id?" + record?.m_tax_method_id
                  )}`
                )
              }>
              <EditFilled />
            </div>
          </Row>
        ) : null;
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/tax-methods",
    },
    {
      title: "PPh21",
    },
    {
      title: "Tax Method",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageTaxMethod", pageNumber);
    sessionStorage.setItem("sessionOffsetTaxMethod", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchTaxMethod", e.target.value);
  };

  const handleDelete = async (id, company) => {
    setLoading(true);
    try {
      const res = await DeleteTaxMethod(id, company);
      message.success({
        content: "Tax method successfully deleted",
      });
      setLoading(false);
      TaxMethodMaster();
      console.log(res);
    } catch (error) {
      message.error({
        content: "Tax method failed to delete",
      });
      setLoading(false);
    }
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    handleDelete(deleteId, company);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure to delete this data?</p>
      </Modal>
      <div className="title-section">Tax Method</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              <InputWithIcon
                type="text"
                placeholder="Search Tax Method"
                img={iconSearch}
                className="search-input w-[250px] btn-sh-sec"
                classImg="op-icon"
                onChange={onChangeSearch}
                value={search}
              />
              {RoleAccessTaxMethodCreate && (
                <Button
                  className="btn-sh-p"
                  type="primary"
                  shape="circle"
                  onClick={() => navigate("/tax-methods/add-tax-methods")}
                  icon={<PlusOutlined />}
                  size={"large"}
                />
              )}
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
