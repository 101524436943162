import { Button, Col, DatePicker, Pagination, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import {ClearOutlined} from "@ant-design/icons";
const { RangePicker } = DatePicker;
export default function LogUser() {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(0)
	const previousPage = useRef(1);
	const [search, setSearch] = useState("")
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)


	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/log-user/detail/${encodeURIComponent("id?" + record.overtimetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
	
		{
			title: 'Date and Time',
			dataIndex: 'Request_date',
			key: 'Request_date',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/log-user/detail/${encodeURIComponent("id?" + record.overtimetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'NIK',
			dataIndex: 'nik',
			key: 'nik',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/log-user-detail/${encodeURIComponent("id?" + record.overtimetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Employee Name',
			dataIndex: 'employee_name',
			key: 'employee_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/log-user-detail/${encodeURIComponent("id?" + record.overtimetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Menu',
			dataIndex: 'menu',
			key: 'menu',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/log-user-detail/${encodeURIComponent("id?" + record.overtimetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
	
		
		{
			title: 'Activity',
			key: 'action',
			width: "25px",
			render: (_,record,index) => {
				return (
					<Row justify={"space-between"}>
						{/* <div className="icon-action" onClick={() => handleDelete(record.company_id)}>
							<DeleteFilled />
						</div> */}
					</Row>
				)
			}
		},
	];
	const dataBreadcrumb = [
		{
			title: "Log User",
			url: "/log-user"
		},
	];


	useEffect(() => {
		previousPage.current = page;
	}, [page]);

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
	}

	const Reset = () => {
		const params = new URLSearchParams(location.search);
		params.delete('start_date');
		params.delete('end_date');
		params.delete('picker_selected');
		sessionStorage.removeItem('start_date');
		sessionStorage.removeItem('end_date');
		sessionStorage.removeItem('picker_selected');
		navigate(`${location.pathname}?${params.toString()}`);
		setStartDate(null);
		setEndDate(null);
		// setPickerSelected(false);
		window.location.reload('/log-user')
	}

    return (
        <>
            <div className="title-section">
				Log User
			</div>
			<Row style={{marginBottom: "24px", justifyContent:'space-between'}} align={"middle"} className="head-action">
				<Col span={4}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
				<Col md={{top:20}}>
					<Row justify={"end"} style={{gap:15}} md={14}>
							<RangePicker  size={"large"} 
								defaultValue={[startDate,endDate]}
								format="YYYY-MM-DD"
							/>
							<Button size="large" onClick={Reset}><ClearOutlined /></Button>
						
							<InputWithIcon
								type="text"
								placeholder="Search Log User"
								img={iconSearch}
								className="search-input btn-sh-sec"
								classImg="op-icon"
								onChange={onChangeSearch}
							/>
					</Row>
				</Col>
			
			</Row>
			<div className="table-wrapper">
            	<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading}/>
				<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
				<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
				</div>
			</div>
        </>
    )
}