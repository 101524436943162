/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, message, Pagination, Row, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { decryptData, encryptData } from "../../helper/cryptojs";
import dayjs from "dayjs";
import { getListChangeRequest, getListTimeRequest } from "../../api/timeRequest";
const { RangePicker } = DatePicker;
export default function OvertimeTransaction() {
	const navigate = useNavigate();
	const previousPage = useRef(1);
	const BestpraticeUseEffect = useRef(false)
	const filterSession = decryptData(sessionStorage.getItem("sessionFilterTimeRequest"));
	const limitSession = sessionStorage.getItem("sessionPageTimerequest");
	const limitSessionChange = sessionStorage.getItem("sessionPageChangeShift");
	const offsetSession = sessionStorage.getItem("sessionOffsetTimerequest");
	const offsetSessionShift = sessionStorage.getItem("sessionOffsetChangeShift");
	const startDateSession = decryptData(sessionStorage.getItem("sessionStarDateTimerequest"));
	const endDateSession = decryptData(sessionStorage.getItem("sessionEndDateTimerequest"));
	const sessionSearch = sessionStorage.getItem("sessionSearchTimeRequest");
	
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [listTableShift, setListTableShift] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [totalDataShift, setTotalDataShift] = useState(0)
	const [page, setPage] = useState(limitSession ? parseInt(limitSession) :  1);
	const [pageShift, setPageShift] = useState(limitSessionChange ? parseInt(limitSessionChange) :  1);
	const [limit, setLimit] = useState(10)
	const [search, setSearch] = useState(sessionSearch ?? "")
	const [startDate, setStartDate] = useState(startDateSession ? startDateSession : null)
	const [endDate, setEndDate] = useState(endDateSession ? endDateSession : null)
	const [selectedDateRange, setSelectedDateRange] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [filter, setFilter] = useState(filterSession ? filterSession : "attendance");
	const [offset, setOffset] = useState( offsetSession ? parseInt(offsetSession) : 0)
	const [offsetShift, setOffsetShift] = useState(offsetSessionShift ? parseInt(offsetSessionShift) : 0)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const branch = decryptData(sessionStorage.getItem("selectBranch"));
	decryptData(sessionStorage.setItem("FilterRequestDetail", encryptData(filter)))
	
	const dataFilter = [
		{
			value: "attendance",
			title: "Attendance Request"
		},
		{
			value: "shift",
			title: "Change Shift"
		}
	]

	
	const TimeRequestList = async () => {
		setLoading(true);
		try {
			const res = await getListTimeRequest(search, startDate, endDate, limit, offset, page, company,branch,filter)
			setListTable(res?.data?.data);
			setTotalData(res?.data?.recordsTotal)
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
			console.log(err);
		} finally {
			setLoading(false);
		}
	};
	const ChangeRequestShift = async () => {
		setLoading(true);
		try {
			const res = await getListChangeRequest(startDate, endDate, limit, offsetShift, pageShift, company,branch,filter,search)
			setListTableShift(res?.data?.data?.data);
			setTotalDataShift(res?.data?.data?.recordsTotal)
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		} else {
			
				TimeRequestList();
			
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageTimerequest");
				sessionStorage.removeItem("sessionPageChangeShift");
				sessionStorage.removeItem("sessionOffsetTimerequest");
				sessionStorage.removeItem("sessionOffsetChangeShift");
				sessionStorage.removeItem('sessionStarDateTimerequest');
				sessionStorage.removeItem('sessionEndDateTimerequest');
				sessionStorage.removeItem('sessionFilterTimeRequest');
				sessionStorage.removeItem('picker_selected_time_request');
				sessionStorage.removeItem("sessionSearchTimeRequest");
				setSearch("")
			  };
			  window.addEventListener("beforeunload", beforeUnloadHandler);
			  return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			  };
		}
	}, [startDate, endDate, search, limit, offset, page, company,branch,filter]);
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		} else {
				ChangeRequestShift();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageTimerequest");
				sessionStorage.removeItem("sessionPageChangeShift");
				sessionStorage.removeItem("sessionOffsetTimerequest");
				sessionStorage.removeItem("sessionOffsetChangeShift");
				sessionStorage.removeItem('sessionStarDateTimerequest');
				sessionStorage.removeItem('sessionEndDateTimerequest');
				sessionStorage.removeItem('sessionFilterTimeRequest');
				sessionStorage.removeItem('picker_selected_time_request');
				sessionStorage.removeItem("sessionSearchTimeRequest");
				setSearch("")
			  };
			  window.addEventListener("beforeunload", beforeUnloadHandler);
			  return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			  };
		}
	}, [startDate, endDate, search, limit, offsetShift, pageShift, company,branch,filter]);

	useEffect(() => {
		let offset = 0
		if(page > 1){
			offset = page*limit - 10
		}
		
		setOffset(offset)
		sessionStorage.setItem("sessionPageTimerequest", page);	
		sessionStorage.setItem("sessionOffsetTimerequest", offset);	
	}, [page]);

	useEffect(() => {
		let offset = 0
		if(pageShift > 1){
			offset = pageShift*limit - 10
		}
		
		setOffsetShift(offset)
		sessionStorage.setItem("sessionPageChangeShift", pageShift);	
		sessionStorage.setItem("sessionOffsetChangeShift", offset);	
	}, [pageShift]);

	const columns = [
		{
			title: 'No',
			dataIndex: 'index',
			key: 'index',
			render: (text, record,index) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${filter === "attendance" ? encodeURIComponent("id?" + record.attendance_request_id) : encodeURIComponent("id?" + record.change_shift_id)}`)}>
					  {filter === "attendance" ? (index + 1 + (page - 1) * limit) : (index + 1 + (pageShift - 1) * limit)}
					</div>
				)
			}
		},
		{
			title: 'Branch',
			dataIndex: 'branch_name',
			key: 'branch_name',
			render: (text, record) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${filter === "attendance" ? encodeURIComponent("id?" + record.attendance_request_id) : encodeURIComponent("id?" + record.change_shift_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'NIK',
			dataIndex: 'employee_number',
			key: 'employee_number',
			render: (text, record) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${filter === "attendance" ? encodeURIComponent("id?" + record.attendance_request_id) : encodeURIComponent("id?" + record.change_shift_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Employee Name',
			dataIndex: (filter === "attendance" ? 'employee_name' : 'full_name'),
			key: (filter === "attendance" ?  'employee_name' : 'full_name'),
			render: (text, record) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${filter === "attendance" ? encodeURIComponent("id?" + record.attendance_request_id) : encodeURIComponent("id?" + record.change_shift_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},

		{
			title: 'Request Date',
			// dataIndex: 'request_date',
			dataIndex: (filter === "attendance" ? 'request_date' : 'created_at'),
			key: 'request_date',
			sorter: (filter === "attendance" ? (a, b) => new Date(a.request_date) - new Date(b.request_date) : (a, b) => new Date(a.created_at) - new Date(b.created_at)) ,
			render: (text, record) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${filter === "attendance" ? encodeURIComponent("id?" + record.attendance_request_id) : encodeURIComponent("id?" + record.change_shift_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Request Type',
			dataIndex: 'request_type',
			key: 'request_type',

			render: (text, record) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${filter === "attendance" ? encodeURIComponent("id?" + record.attendance_request_id) : encodeURIComponent("id?" + record.change_shift_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Status',
			dataIndex: (filter === "attendance" ? 'request_status' : 'status'),
			key: (filter === "attendance" ? 'request_status' : 'status'),
			render: (text, record) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${filter === "attendance" ? encodeURIComponent("id?" + record.attendance_request_id) : encodeURIComponent("id?" + record.change_shift_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},

	];
	const dataBreadcrumb = [
		{
			title: "Attendance Manegement",
			url: "/time-request"
		},
		{
			title: "Time Request",
			url: "/time-request"
		},
	];


	useEffect(() => {
		previousPage.current = page;
	}, [page]);
	useEffect(() => {
		previousPage.current = pageShift;
	}, [pageShift]);

	const onChangeTable = (pageNumber, type) => {
		const offsetSession = pageNumber*limit- 10
		setPage(pageNumber);
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageTimerequest", pageNumber);
		sessionStorage.setItem("sessionOffsetTimerequest", offsetSession);
	};
	const onChangeTableShift = (pageNumber, type) => {
			const offsetSessionShift = pageNumber*limit- 10
			setPageShift(pageNumber);
			setOffsetShift(offsetSessionShift)
			sessionStorage.setItem("sessionPageChangeShift", pageNumber);
			sessionStorage.setItem("sessionOffsetChangeShift", offsetSessionShift);
	};
	

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		
			return listTable?.map((data) => {
				return {
					...data,
				};
			});
	}
	function dataTableWithNumberShift() {
		
			return listTableShift?.map((data) => {
				return {
					...data,
				};
			});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		setPageShift(1)
		sessionStorage.setItem("sessionSearchTimeRequest", e.target.value);
	}


	useEffect(() => {
		const startDate = decryptData(sessionStorage.getItem("sessionStarDateTimerequest"));
		const endDate = decryptData(sessionStorage.getItem("sessionEndDateTimerequest"));
		if (startDate && endDate) {
			setStartDate(startDate);
			setEndDate(endDate);
			setSelectedDateRange([dayjs(startDate), dayjs(endDate)])
		}
	}, []);


	const handleDateChange = (dates) => {
		if (dates && dates.length === 2) {
			const start = dates[0].startOf('day').format('YYYY-MM-DD');
			const end = dates[1].endOf('day').format('YYYY-MM-DD');
			sessionStorage.setItem('sessionStarDateTimerequest', encryptData(start));
			sessionStorage.setItem('sessionEndDateTimerequest', encryptData(end));
			sessionStorage.setItem('picker_selected_time_request', 'true');
			setStartDate(start);
			setEndDate(end);
			setPage(1)
			setOffsetShift(1)

		} else {
			sessionStorage.removeItem('sessionStarDateTimerequest');
			sessionStorage.removeItem('sessionEndDateTimerequest');
			sessionStorage.removeItem('picker_selected_time_request');
			setStartDate(null);
			setEndDate(null);
			setPage(1)
			setPageShift(1)

		}
		setSelectedDateRange(dates);
	};
	const RoleAccessTimeRequestCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeTimeRequestCreate'));
	return (
		<>
			<div className="title-section">
				Time Request
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb} />
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{ marginBottom: "24px", justifyContent: 'end' }} align={"middle"} >
					<Col md={{ top: 20 }} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{ gap: 15 }} md={14}>
							<RangePicker
								size={"large"}
								onChange={handleDateChange}
								value={selectedDateRange}
								allowClear={true}
								format="YYYY-MM-DD"
								
							/>
							<Select
								placeholder="Filter Data"
								value={filter}
								onChange={(val) => {
									setFilter(val)
									sessionStorage.setItem('sessionFilterTimeRequest', encryptData(val));
									sessionStorage.setItem('picker_selected_time_request', 'true');
									setPage(1)
									setPageShift(1)
								}}
								allowClear={false}
								className="select-company w-48"
							>
								{dataFilter.map((data, index) => {
									return (
										<Select.Option style={{ height: "42px" }} key={index} value={data.value}>{data.title}</Select.Option>
									)
								})}
							</Select>
							<InputWithIcon
								type="text"
								placeholder="Search Time Request"
								img={iconSearch}
								className="search-input btn-sh-sec"
								classImg="op-icon"
								value={search}
								onChange={onChangeSearch}
							/>

							{RoleAccessTimeRequestCreate && (
								<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => setIsOpen(!isOpen)} icon={<PlusOutlined />} size={"large"} />
							)}

							{isOpen && (
								<div className="absolute  z-10 right-3 mt-12 w-56 bg-white rounded shadow ">
									<div className="px-4 py-2" role="none">
										<p className="text-lg text-gray-900" role="none">
										  <Link to={'/time-request/add-attendance-request'}>+ Attendance Request</Link>
										</p>
										<p className="text-lg text-gray-900" role="none">
											<Link to={'/time-request/add-change-shift-request'}>+ Change Shift Request</Link>
										</p>
								</div>
								</div>
							)}
						</Row>
					</Col>

				</Row>
				<div className="table-wrapper">
				{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={filter === "attendance" ? page : pageShift}  current={filter === "attendance" ? page : pageShift} total={filter === "attendance" ?  totalData : totalDataShift} onChange={filter === "attendance" ? onChangeTable : onChangeTableShift} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={filter === "attendance" ? dataTableWithNumber() : dataTableWithNumberShift()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={filter === "attendance" ? page : pageShift} current={filter === "attendance" ? page : pageShift} total={filter === "attendance" ?  totalData : totalDataShift} onChange={filter === "attendance" ? onChangeTable : onChangeTableShift} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}
				</div>
			</div>
		</>
	)
}