import { Button, Col, Form as FormAntd, Row, Select, message,DatePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { CheckboxOptionWithLabel,  InputWithLabel, SelectOptionAttendanceNoLabel, SelectOptionAttendanceWithLabel, SelectSearchWithLabel, TimeWithLabel } from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { DeleteOutlined,CloudUploadOutlined } from "@ant-design/icons";
import './styles.scss'
import { getEmployeeNumber } from "../../api/employee";
import { getLeaveDropdown } from "../../api/leave";
import { AddAttendance, EditAttendance, getAttendanceDetail } from "../../api/attendance";
import moment from "moment";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import dayjs from "dayjs";
const FormAddAttendance = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    let n = location.split("/");
    let result =  n[5];
    const inputRef = useRef(null);
    const decode = decodeURIComponent(result)
    let dec = decode?.lastIndexOf('?');
    let id = decode?.substring(dec + 1);
    const [flag, setFlag] = useState(0);
    const companyStore = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const { Option } = Select;
    const [fileName, setFileName] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const [optionsMLeave, setOptionsMLeave] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const attendanceList = async () => {
		setLoading(true);
		try {
		  const res = await getAttendanceDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	

     const employeeNumberActive = async () => {
        try {
            const res = await getEmployeeNumber(companyStore)
            const data = res?.data?.data;
            console.log('testing data',data)
            setOptions(data)
          } catch (err) {
            console.log(err);
          }
	  };

    const dropdownMLeave = async () => {
        try {
            const res = await getLeaveDropdown(companyStore);
            const data = res?.data?.data;
            setOptionsMLeave(data);
        } catch (err) {
            console.log(err);
        }
    };
    const handleSelectChange = (value) => {
        setSelectedOption(value);
        form.setValues({
            ...form.values,
            EmployeeNumber: value,
        })
        // Mendapatkan nilai full_name terkait dari data API
        const selectedData = options.find(option => option.employee_number === value);
        if (selectedData) {
        form.setFieldValue('EmployeeName',selectedData ? selectedData.full_name : '');
        }
        if(selectedData){
            form.setFieldValue('Branch',selectedData ? selectedData.branch_name : '');
        } 
        else {
        form.setFieldValue('EmployeeName');
        form.setFieldValue('Branch', '');
        }
    };

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if(result) {
                attendanceList();
            }
            employeeNumberActive();
            form.resetForm();
          }
        dropdownMLeave();
	},[]);


   
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 2 * 1024 * 1024) {
          const newFileName = file.name.replace(/['"]+/g, '')
          const renamedFile = new File([file], newFileName, { type: file.type });
          setFileName(renamedFile.name);
          form.setValues({
              ...form.values,
              document_url: renamedFile,
          })
        }
        else{
          setFileName(null);
          message.error('Please upload a PDF,JPEG,JPG,PNG file with a maximum size of 2 MB');        
        }
      };

      const handleFileDelete = () => {
        setFileName(null);
        form.setValues({
            ...form.values,
            document_url: null,
        })
        document.getElementById('document_url').value = null
      };
    
    const validationUser = yup.object().shape({
        EmployeeNumber: yup.string().required("Field is required"),
        DateFrom: yup.string().required("Field is required"),
    });
   
     const form = useFormik({
        initialValues: {
            EmployeeNumber: result ? detail?.employee_number : "",
            EmployeeName: result ? detail?.full_name: "",
            Branch: result ? detail?.branch_name: "",
            DateFrom: result ? detail?.attendance_date :"",
            TimeOff : result ? detail?.time_off: "",
            CheckIn : result ? detail?.attendance_time_in: "",
            CheckOut : result ? detail?.attendance_time_out: "",
            LeaveType : result ? detail?.leave_type: "",
            LeaveTime : result ? detail?.leave_time: "",
            document_url :  result ? null : null
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
            try {
                if(values.TimeOff === false) {
                    values.TimeOff = ""
                }
                const res = result ? await EditAttendance(values, id) : await AddAttendance(values,companyStore);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Attendance successfully updated" : "Attendance successfully saved",
                    });
                    navigate("/attendance")
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                    // window.location.reload()

                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });

    const dataBreadcrumb = [
		{
			title: "Attendance Management",
			url: "/attendance"
		},
		{
			title: "Attendance",
			url: "/attendance"
		},
		{
			title: `${result ?  'Edit Attendance' : 'Add Attendance'}` ,
			// url: `${ result ?  '/attendance/edit-attendance' : '/attendance/add-attendance'}`
		},
	]
    return (
       <>
        <div className="title-section">
                {result ? "Edit Attendance" : "Add Attendance"}
			</div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
		</div>
        <div className="add-wrapper">
           
            <FormAntd
                onFinish={form.handleSubmit}
            >  
            <Col>
                <Row justify={"space-between"}>
                    <Col span={24}>
                        <FormAntd.Item>
                            <SelectSearchWithLabel
                                labelname={<div>Employee<span style={{color:"#FF0000"}}> *</span></div>}
                                showSearch
                                allowClear
                                placeholder="Select Employee"
                                optionFilterProp="children"
                                onChange={handleSelectChange}
                                value={selectedOption ? selectedOption : detail?.employee_number}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={result ? true : false}
                            >
                                {options.map(option => (
                                <Option key={option.employee_number} value={option.employee_number}>{option.employee_option}</Option>
                                ))}
                            </SelectSearchWithLabel>
                            {form.touched.EmployeeNumber && form.errors.EmployeeNumber ? (
                                <span className="text-error">{form.errors.EmployeeNumber}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>

                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    name="Branch"
                                    label={"Branch"} 
                                    placeholder="Branch"
                                    value={form.values.Branch ? form.values.Branch : ''  }
                                    onChange={form.handleChange}
                                    disabled={true}
                                />
                                {form.touched.Branch && form.errors.Branch ? (
                                    <span className="text-error">{form.errors.Branch}</span>
                                ) : null}
                            </FormAntd.Item>
                    </Col>
                <Col span={11}>
                        <FormAntd.Item>
                        <div className="flex flex-col">
                          <div className="text-slate-500">{"Date"}<span className="text-red-500">*</span></div>
                        <DatePicker
                            name="DateFrom"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            allowClear={true}
                            value={form.values.DateFrom ? dayjs(form.values.DateFrom, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('DateFrom', formattedDate);
                      
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          {form.touched.DateFrom && form.errors.DateFrom ? (
                                    <span className="text-error">{form.errors.DateFrom}</span>
                                ) : null}
                          </div>
                      </FormAntd.Item>
                    </Col>
                </Row>

                <Row justify={"space-between"}>
                        <Col span={11}>
                            <FormAntd.Item>
                                <TimeWithLabel 
                                name="CheckIn"
                                label={"Check In"}
                                value={form?.values?.CheckIn ? moment(form?.values?.CheckIn,"HH:mm").format('HH:mm') : null} 
                                onChange={form.handleChange} 
                                disabled={form.values.TimeOff}
                                />

                                {form.touched.CheckIn && form.errors.CheckIn ? (
                                    <span className="text-error">{form.errors.CheckIn}</span>
                                ) : null}
                            </FormAntd.Item>
                        </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                                <TimeWithLabel 
                                name="CheckOut"
                                label={"Check Out"}
                                value={form?.values?.CheckOut ? moment(form?.values?.CheckOut,"HH:mm").format("HH:mm"): null} 
                                onChange={form.handleChange}  
                                disabled={form.values.TimeOff} 
                                />
                                {form.touched.CheckOut && form.errors.CheckOut ? (
                                <span className="text-error">{form.errors.CheckOut}</span>
                                ) : null}
                             </FormAntd.Item>
                        </Col>
                </Row>
                
                <Row justify={"space-between"}>
                    {form.values?.TimeOff !== 0 && (  // Add this conditional check
                        <Col span={11}>
                        <FormAntd.Item>
                            <CheckboxOptionWithLabel 
                            name="TimeOff"
                            onChange={e => {
                                form.handleChange(e);
                            }}
                            label={'Time OFF'}
                            checked={form.values?.TimeOff}  
                            value={form.values?.TimeOff ? form.values?.TimeOff !== 1 : null }
                            />
                        </FormAntd.Item>
                        </Col>
                    )}
                    </Row>



                <Row> {form.values.TimeOff  && form.values.TimeOff  ?  (
                    <>
                        <Col span={13}>
                                    <SelectOptionAttendanceWithLabel
                                        label={"Select Time OFF"}
                                        placeholder={'Leave Type'}
                                        items={optionsMLeave.map((option) => ({
                                            value: option.leave_name,
                                            title: option.leave_name,
                                        }))}
                                        value={ form?.values?.LeaveType ? form?.values?.LeaveType : null }
                                        onChange={(val) =>
                                            form.setValues({
                                            ...form.values,
                                            LeaveType: val,
                                            })
                                        }
                                        />
                                        <br/>
                                        <SelectOptionAttendanceNoLabel 
                                        placeholder={"Leave Time"}
                                        items={[
                                            {
                                                value: "Full Day",
                                                title: "Full Day "
                                            },
                                            {
                                                value: "Half Day",
                                                title: "Half Day"
                                            },
                                        
                                        ]}
                                        value={ form?.values?.LeaveTime ? form?.values?.LeaveTime : null}
                                        onChange={(val) =>
                                            form.setValues({
                                            ...form.values,
                                            LeaveTime: val,
                                            })
                                        }
                                        />
                        </Col>
                        
                        <Col>
                            <FormAntd.Item>
                                <div className="">
                                        <div>
                                            <label className="file-upload">
                                                <input type="file" name="document_url" id="document_url" onChange={handleFileUpload} accept=".jpeg, .jpg, .png, .pdf"  ref={inputRef}/>
                                                <CloudUploadOutlined /> Supporting Document
                                            </label>
                                        </div>
                                        <div>
                                            {fileName && (
                                                <div>
                                                    <p>{fileName}   <Button  shape="circle" onClick={handleFileDelete} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                                                </div>
                                            )}
                                        </div>
                                </div>
                            </FormAntd.Item>
                        </Col>
                            
                    </>
                        ): ''}
                </Row>
            </Col>
            </FormAntd>

            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/attendance')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
       </>
    )
}

export default FormAddAttendance;