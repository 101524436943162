import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getListTimeRequest = async (
  search,
  startDate,
  endDate,
  limit,
  offset,
  page,
  company,
  branch,
  fillter
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch : branchDefault,
    startDate: startDate,
    endDate: endDate,
    search_by: "full_name",
    search_value: search?.length > 0 ? search : undefined,
    filter: fillter,
    start: offset,
    length: limit,
    draw: page,
  };
  return master.post("/attendance-request", payload);
};
export const getListChangeRequest = async (
  startDate,
  endDate,
  limit,
  offsetShift,
  pageShift,
  company,
  branch,
  filter,
  search
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch : branchDefault,
    startDate: startDate,
    endDate: endDate,
    start: offsetShift,
    filter: filter,
    length: limit,
    draw: pageShift,
    search: search?.length > 0 ? search : undefined,
  };
  return master.post("/change-shift-request", payload);
};

export const getTimeRequestDetail = async (id) => {
  let payload = {
    requestId: id,
  };
  return master.post("/attendance-request-detail", payload);
};
export const getChangeRequestDetail = async (id) => {
  return master.get(`/change-shift-request/${id}`);
};

export const getListTimeRequestEmployeeNumber = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/list-employee-attendance-request", payload);
};
export const getListEmployeeActive = async (company, search = null) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    search: search
  };
  return master.post("/employee-active-company", payload);
};
export const getListScheduleNikDate = async (
  employeeNumberSchedule,
  DateSchedule
) => {
  let payload = {
    employeeNumber: employeeNumberSchedule,
    date: DateSchedule,
  };
  return master.post("/schedule-by-nik-date", payload);
};

export const addAttendanceRequest = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    employeeNumber: data.EmployeeNumber,
    date: data.date,
    checkIn: data.checkIn,
    checkOut: data.checkOut,
    description: data.description,
    attachment: data.AttechmentUpload,
    createdBy: user,
  };

  return master.post("/attendance-request-create", payload);
};
export const addChangeShiftRequest = async (data, company, ShiftId) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const originalShift = decryptData(sessionStorage.getItem("selectedIdShift"));
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    employeeId: data.employeeId,
    originalShiftId: originalShift || ShiftId !== null ? ShiftId : "",
    requestShiftId: data.requestShiftId,
    requestDate: data.requestDate,
    description: data.description,
    attachment: data.AttechmentUpload,
    status: "APPROVED",
    createdBy: user,
  };

  return master.post("/change-shift-request/create", payload);
};
export const getDropdownOriginalShift = async (company, employeeId, date) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const dateStore = decryptData(sessionStorage.getItem("DateSchedule"));
  const dedeEmployee = sessionStorage.getItem("EmployeeNumScheedule");
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    employeeNumber: dedeEmployee || employeeId !== null ? employeeId : "",
    date: dateStore || date !== null ? date : "",
  };

  return master.post("/shift-request-option", payload);
};
