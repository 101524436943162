import { decryptData } from "../helper/cryptojs";
import {  sso } from "./hris";

const getListHistoryFakeGpsReport = async (
  company,
  startDate,
  endDate,
  offset,
  limit,
  page,
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const api_key = "SSOQLeCuJFw3NkzO0MugSJWEb2hV4SZzPOlTHDK4OpcACzYyN3ukWDaT96b6TNOCGc2JmKPVHEFZA2XwoZl89lXjlOtkWDprFfouNq0R6tAeU9hlKW84GcbmjmA0F81D"
  let payload = {
    api_key : api_key,
    companyCode: companyStore || company !== null ? company : "",
    startDate: startDate,
    endDate: endDate,
    offset: offset,
    limit: limit,
    page: page,
  
  };

  return sso.post("/list-fake-gps", payload);
};

export { getListHistoryFakeGpsReport };