import { Button, Col, Form as FormAntd, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { InputWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addDepartment, editDepartment, getDepartmentDetail } from "../../api/department";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";

const FormAddDepartment = () => {
    const navigate = useNavigate();
    const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0)
    const BestpraticeUseEffect = useRef(false)

    const departmentList = async () => {
		setFetching(true);
		try {
		  const res = await getDepartmentDetail(id)
		  setDetail(res?.data?.data[0]);
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            departmentList();
          }
	}, [flag]);

    const validationUser = yup.object().shape({
        name: yup.string().required("Field is required"),
        companyCode: yup.string().required("Field is required"),
    });
    const form = useFormik({
        initialValues: {
            name: result ? detail?.name : "",
            companyCode: result ? detail?.company_code : companyStore ? companyStore : deCompanyCode,
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
            try {
                const res = result ? await editDepartment(values, id) : await addDepartment(values);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Department successfully updated" : "Department successfully saved",
                    });
                    navigate('/department')
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });
   const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/department"
		},
		{
			title: "Department",
			url: "/department"
		},
		{
			title: `${result ?  'Edit Department' : 'Add Department'}` ,
			// url: `${ result ?  '/department/edit-department' : '/department/add-department'}`
		},
    ]
    return (
      <>
            <div className="title-section">
				{result ? "Edit Department" : "Add Department"}
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="add-wrapper">
            
                <FormAntd
                    onFinish={form.handleSubmit}
                >
                    <FormAntd.Item>
                        <InputWithLabel
                            name="name"
                            label={<div>Name<span style={{color:"#FF0000"}}> *</span></div>}
                            placeholder="Name"
                            value={form?.values?.name}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        {form.touched.name && form.errors.name ? (
                            <span className="text-error">{form.errors.name}</span>
                        ) : null}
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="companyCode"
                            type="hidden"
                            placeholder="Name"
                            value={form?.values?.companyCode}
                            onChange={(e) => {
                                const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                                if (!regex.test(e.target.value)) {
                                    form.handleChange(e);
                                }
                            }}
                            onBlur={form.handleBlur}
                        />
                        {form.touched.companyCode && form.errors.companyCode ? (
                            <span className="text-error">{form.errors.companyCode}</span>
                        ) : null}
                    </FormAntd.Item>
                </FormAntd>
                <div className="footer-add">
                    <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/department')}>
                        Cancel
                    </Button>
                    <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                        Submit
                    </Button>
                </div>
            </div>
        </>
    )
}

export default FormAddDepartment;