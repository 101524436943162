import React, { useState, useEffect } from "react";
import { Menu, Layout } from "antd";
import logo from "../../assets/img/content/logo-ethos.png";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { decryptData } from "../../helper/cryptojs";
import {
  DashboardOutlined,
  NotificationOutlined,
  ContainerOutlined,
  AppstoreAddOutlined,
  SolutionOutlined,
  AccountBookOutlined,
  BoxPlotOutlined,
  SnippetsOutlined
} from "@ant-design/icons";

const { SubMenu } = Menu;

const Sidebar = ({ collapsed, showMobile }) => {
  const menuList = decryptData(JSON.parse(localStorage.getItem("Menusidbar")));
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedOpenKeys = JSON.parse(localStorage.getItem("OpenKeys")) || [];
    const storedSelectedKeys = JSON.parse(localStorage.getItem("SelectedKeys")) || [];
    setOpenKeys(storedOpenKeys);
    setSelectedKeys(storedSelectedKeys);
  }, []);

  const handleMenuClick = (link) => {
    navigate(link);

    //proses hapus session yang sudah di set pada page(filter, pagination, search)
    for(let key in sessionStorage) {
      if(key.indexOf("session") > -1){
        sessionStorage.removeItem(key)
      } 
    }
    // window.location.reload();
  };

  const handleMenuOpenChange = (keys) => {
    setOpenKeys(keys);
    localStorage.setItem("OpenKeys", JSON.stringify(keys));
  };

  const handleMenuSelect = ({ selectedKeys }) => {
    setSelectedKeys(selectedKeys);
    localStorage.setItem("SelectedKeys", JSON.stringify(selectedKeys));
  };

  const menuIcon = [
    { menu: 'Dashboard', icon: DashboardOutlined },
    { menu: 'Master Data', icon: AppstoreAddOutlined },
    { menu: 'Attendance Management', icon: SolutionOutlined },
    { menu: 'Payroll Management', icon: AccountBookOutlined },
    { menu: 'Loan Management', icon: BoxPlotOutlined },
    { menu: 'Approval List', icon: ContainerOutlined },
    { menu: 'Report', icon: SnippetsOutlined },
    { menu: 'Announcement', icon: NotificationOutlined }
  ];

  const renderSubmenu = (v) => {
    if (v.submenu.length === 0) {
      return (
        <Menu.Item
          key={v.key}
          className="menu-sidebar"
          onClick={() => handleMenuClick(v.link)}>
          <div style={{ paddingLeft: "14px" }}>{v.menu}</div>
        </Menu.Item>
      );
    } else {
      return (
        <SubMenu
          key={v.key}
          className="submenu-head1"
          title={
            <span>{!collapsed && <span key={v.key}>{v.menu}</span>}</span>
          }>
          {v.submenu && v.submenu.map((v2) => renderSubmenu(v2))}
        </SubMenu>
      );
    }
  };

  return (
    <div className="overflow-auto">
      <Layout
        style={{
          minHeight: "100vh",
          width: "100%",
          backgroundColor: "#fff",
          borderRight: "1px solid #eeeeee",
        }}
        className={`sidebar-wrapper overflow-auto ${
          showMobile ? "active" : ""
        }`}>
        <div className="logo-wrapper">
          <img
            src={logo}
            className="logo"
            style={!collapsed ? { marginLeft: "60px" } : { marginLeft: "8px" }}
            alt="logo"
          />
        </div>
        <Menu
          defaultOpenKeys={['sub1']}
          mode="inline"
          inlineCollapsed={collapsed}
          className="menu-wrapper"
          openKeys={openKeys}
          selectedKeys={selectedKeys}
          onOpenChange={handleMenuOpenChange}
          onSelect={handleMenuSelect}
        >
          {menuList && menuList.map(mainMenu => {
            const iconMenu = menuIcon.find(menu => menu.menu === mainMenu.menu) ?? menuIcon.find(menu => menu.menu === 'Payroll Management')
            if (mainMenu.submenu.length > 0) {
              return (
                <SubMenu
                  key={mainMenu.key}
                  className="submenu-head"
                  title={
                    <span>
                      <iconMenu.icon />
                      {!collapsed && <span key={mainMenu.key}>{mainMenu.menu}</span>}
                    </span>
                  }
                >
                  {mainMenu.submenu && mainMenu.submenu.map(v => renderSubmenu(v))}
                </SubMenu>
              )
            } else {
              return (
                <Menu.Item key={mainMenu.key} className="menu-sidebar" onClick={() => handleMenuClick(mainMenu.link)}>
                  <iconMenu.icon />
                  <div >{mainMenu.menu}</div>
                </Menu.Item>
              )
            }
          })}
        </Menu>
      </Layout>
    </div>
  );
};

export default Sidebar;
