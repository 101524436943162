import { Col, message, Tabs } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonalForm from "./PersonalForm";
import "../Detail/styles.scss";
import { validationPayroll, validationPersonal } from "../validationSchema";
import { addEmployeeData, addOnBoadingData, addPayrollData, addPersonalDataEmployee, EditEmployeeData, EditOnBoadingData, EditPayrollData, EditPersonalDataEmployee, getEmployeeDetail } from "../../../api/employee";
import EmployeeForm from "./EmployeeForm";
import PayrollForm from "./PayrollForm";
import OnBoardingForm from "./Onboarding";
import * as yup from "yup";
import Breadcrumb from "../../../component/Breadcrumb";
import { decryptData, encryptData } from "../../../helper/cryptojs";


const FormAddEmployee = () => {
    const navigate = useNavigate();
    const onChange = (key) => {
        setTabValue(key)
    };

    var level = 0;
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false)
    const [flag, setFlag] = useState("1");
    const [detail, setDetail] = useState([]);
    const [tabValue, setTabValue] = useState("1");
    const [employeeId, setEmployeeId] = useState("");
    const [switchStat, setSwitchStat] = useState(false);
    const [codeLevel, setCodeLevel] = useState("");
    const BestpraticeUseEffect = useRef(false)
    const employeeList = async () => {
		setLoading(true);
		try {
		  const res = await getEmployeeDetail(id)
          sessionStorage.setItem('employeeNumber',encryptData(id));
          setSwitchStat(res?.data?.data?.employee_data?.is_multi_company)
		  setDetail(res?.data?.data);
          setCodeLevel(res?.data?.data?.employee_data?.level_code);
          if(result){
            setFlag("4")
          }
         

		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	};

    
    useEffect(() => {
        employeeList();
	}, []);
    
    const companyStore = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const deCompanyId = decryptData(localStorage.getItem("DefaultCompanyId"));
    const companyStoreId = decryptData(sessionStorage.getItem("selectCompanyId"));
    const company = companyStoreId ? companyStoreId : deCompanyId;
    const role = decryptData(localStorage.getItem("Role"));
    const AllowedPayroll = decryptData(localStorage.getItem("AllowedPayroll"));
    const UserSequence = decryptData(localStorage.getItem("LevelSequence"));
 
    const personalForm = useFormik({
        initialValues: {
            name: result ? detail?.personal_data?.full_name : "",
            emailOffice: result ? detail?.personal_data?.official_email : "",
            emailPersonal: result ? detail?.personal_data?.email : "",
            placeOfBirth: result ? detail?.personal_data?.place_of_birth : "",
            dateOfBirth:   result ? detail?.personal_data?.date_of_birth  : "",
            nationaly: result ? detail?.personal_data?.nationality : "",
            religion: result ? detail?.personal_data?.religion : "",
            gender: result ? detail?.personal_data?.gender : "",
            maritalStatus: result ? detail?.personal_data?.marital_status : "",
            phoneNumber : result ? detail?.personal_data?.phone_number : "",
            education: result ? detail?.personal_data?.education : "",
            identityNumber: result ? detail?.personal_data?.identity_card_number : "",
            familyCertificateNumber: result ? detail?.personal_data?.family_certificate_number : "",
            driverLicenseNumber: result ? detail?.personal_data?.driver_license_number : "",
            driverLicenseExpired: result ? detail?.personal_data?.driver_license_expiry : "",
            addressPermanent: result ? detail?.personal_data?.permanent_address : "",
            postalCodePermanent: result ? detail?.personal_data?.permanent_postal_code  :"",
            cityPermanent: result ? detail?.personal_data?.permanent_city : "",
            provincePermanent: result ? detail?.personal_data?.permanent_province : "",
            addressCurrent: result ? detail?.personal_data?.current_address : "",
            postalCodeCurrent: result ?detail?.personal_data?.current_postal_code : "",
            cityCurrent: result ?detail?.personal_data?.current_city : "",
            provinceCurrent: result ? detail?.personal_data?.current_province : "",
            Status : result ? detail?.personal_data?.is_active : "",
            Age : result ? detail?.personal_data?.age : "",
            HusbandWifeName : result ? detail?.personal_data?.husband_wife_name : "",
            HusbandWifeMobile : result ? detail?.personal_data?.husband_wife_mobile : "",
            HusbandWifeEmail : result ? detail?.personal_data?.husband_wife_email : "",
            HusbandWifeOccupation : result ? detail?.personal_data?.husband_wife_occupation : "",
            Child : result ? detail?.personal_data?.child : "",
            Child1 : result ? detail?.personal_data?.child_1 : "",
            Child2 : result ? detail?.personal_data?.child_2 : "",
            Child3: result ? detail?.personal_data?.child_3 : "",
            EducationInstitution: result ? detail?.personal_data?.education_institution : "",
            EducationField: result ? detail?.personal_data?.education_field : "",
            Gpa: result ? detail?.personal_data?.gpa : "",
            in_active_reason : result ? detail?.personal_data?.in_active_reason : "",
            in_active_description : result ? detail?.personal_data?.in_active_description : "",
            in_active_endate : result ? detail?.personal_data?.in_active_endate : "",
            in_active_backlist: result ? (detail?.personal_data?.in_active_backlist  ? 1 : 0) : null,

        },
        enableReinitialize: true,
        validationSchema:validationPersonal,
        onSubmit: async (values) => {
            if(companyStore === "" && role === "HR"){
                message.error({
                    content: "Please select company",
                });
            }  else {
                setLoading(true)
                try {
                    const res = result ? await EditPersonalDataEmployee(values,id,companyStore) : await addPersonalDataEmployee(values,companyStore);
                    if(!res?.data?.success){
                        message.error({
                            content: res?.data?.error,
                        });
                        setLoading(false)
                    } else {
                        message.success({
                            content: result ? "Employee successfully updated" : "Employee successfully saved",
                        });
                        setLoading(false)
                        // personalForm.resetForm();
                        setEmployeeId(res?.data?.data?.employee_id)
                        localStorage.setItem("Employee_Id", res?.data?.data?.employee_id);
                        setTabValue("2")
                        if(result){
                            setFlag("4")
                        }else{
                            setFlag("2")
                        }
                       
                    }
                    
                    console.log(res);
                } catch (error) {
                    message.error({
                        content: "Request Not Found",
                    });
                    setLoading(false)
                }
            }
            
        },
    });

    const validationEmployee = yup.object().shape({
        branch: yup.string().required("Field is required"),
        department: yup.string().required("Field is required"),
        CodeDivision: yup.string().required("Field is required"),
        LevelCode: yup.string().required("Field is required"),
        CodePosition: yup.string().required("Field is required"),
        employeeStatus: yup.string().required("Field is required"),
        joinDate: yup.string().required("Field is required"),
        startDate: yup.string().required("Field is required"),
        endDate: yup.string().required("Field is required"),
        signDate: yup.string().when(['employeeStatus'], (employeeStatus, schema) => {
            return employeeStatus[0] === 'Permanent' ? schema.required("Field is required") : schema.nullable()
        }),
        SupervisorCode: yup.string().required("Field is required"),
        employee_number: companyStoreId === 1 
        ? yup.string().required("Field is required") 
        : yup.string(), 
    });
	const employeeForm = useFormik({
        initialValues: {
            // nip: result ? detail?.employee_data?.employee_number : "",
            employee_number: result ? detail?.employee_data?.employee_number : "",
			employeeStatus: result ? detail?.employee_data?.employee_type : "",
			joinDate:result  ? detail?.employee_data?.join_date : "",
			startDate: result ? detail?.employee_data?.start_date : "",
			endDate: result ? detail?.employee_data?.end_date : "",
            signDate: result ? detail?.employee_data?.sign_date : "",
			branch: result ? detail?.employee_data?.branch_code : "",
			branchName: result ? detail?.employee_data?.branch_name : "",
			department: result ? detail?.employee_data?.department_code : "",
			departmentName: result ? detail?.employee_data?.department_name : "",
			CodeDivision: result ? detail?.employee_data?.division_code : "",
            NameDevision: result ? detail?.employee_data?.division_name : "",
            SupervisorCode: result ? detail?.employee_data?.supervisor_number : "",
			NameDirectSupervisor: result ? detail?.employee_data?.supervisor_name : "",
			LevelCode: result ? detail?.employee_data?.level_code : "",
			NameLevel: result ? detail?.employee_data?.level_name : "",
			CodePosition:  result? detail?.employee_data?.position_code : "",
			NamePosition:  result? detail?.employee_data?.position_name : "",
            LevelSequence:  result? detail?.employee_data?.level_sequence : "",
            updateApproval: result ? (detail?.employee_data?.update_approval_setting === null ? true : detail?.employee_data?.update_approval_setting) : true
        },
        enableReinitialize: true,
        validationSchema: validationEmployee,
        onSubmit: async (values) => {
            setLoading(true)
            try {
                const res = result ? await EditEmployeeData(values, id,companyStore) : await addEmployeeData(values, employeeId);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                    setLoading(false)
                } else {
                    message.success({
                        content: result ? "Employee successfully updated" : "Employee successfully saved",
                    });
                    setLoading(false)
                    setEmployeeId(res?.data?.data?.employee_id)
                    setTabValue("3")
                    if(result){
                        setFlag("4")
                    }else{
                        setFlag("3")
                    }
                   
                    level = values.LevelSequence
                    
                    // if(AllowedPayroll != 'ALL ACCESS'){
                        if(level > UserSequence){
                            setTabValue("4")
                            setFlag("4")
                        }
                    // }
                }
                
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setLoading(false)
                setTabValue("3")
                setFlag("3")
            }
        },
    });
	const payrollForm = useFormik({
        initialValues: {
            basicSalary: result? detail?.payroll?.basic_salary : "",
            salaryType: result? detail?.payroll?.salary_type : "",
            paymentSchedule: result? detail?.payroll?.payment_schedule : "",
            bankName: result? detail?.payroll?.bank_name : "",
            accountNumber: result? detail?.payroll?.account_number : "",
            accountName: result? detail?.payroll?.account_name : "",
            prorateSetting: result? detail?.payroll?.prorate_setting : "",
            allowedForOvertime: result? detail?.payroll?.allowed_overtime ? 'yes' : 'no' : "",
            overtimeWorkingDay: result? detail?.payroll?.overtime_working_day : "",
            overtimeDayOff: result? detail?.payroll?.overtime_dayoff : "",
            overtimeNationalHoliday: result? detail?.payroll?.overtime_national_holiday : "",
            ptkpStatus: result? detail?.payroll?.ptkp_status : "",
            taxMethode: result? detail?.payroll?.tax_method : "",
            taxableDate: result? detail?.payroll?.taxable_date : "",
            beginingNeto: result? detail?.payroll?.begining_netto : "",
            npwp: result? (detail?.payroll?.taxpayer_identification_number != null ? detail?.payroll?.taxpayer_identification_number : 0) : "",
            employeeTaxStatus: result? detail?.payroll?.employee_tax_status : "",
            pph21: result? detail?.payroll?.pph21_paid : "",
            bpjsKetenagakerjaan: result? (detail?.payroll?.bpjstk_number != null ? detail?.payroll?.bpjstk_number : "") : "",
            bpjsKetenagakerjaanDate: result? detail?.payroll?.bpjstk_date : "",
            bpjsKetenagakerjaanType: result? detail?.payroll?.bpjstk_type : "",
            nppBpjsKetenagakerjaan: result? detail?.payroll?.npp_bpjstk_name : "",
            bpjsKesehatanNumber: result? (detail?.payroll?.bpjskes_number != null ? detail?.payroll?.bpjskes_number : "") : "",
            bpjsKesehatanDate: result? detail?.payroll?.bpjskes_date : "",
            bpjsKesehatan: result? detail?.payroll?.bpjsKesehatan : "",
            bpjsKesehatanCost: result? detail?.payroll?.bpjskes_type : "",
            bpjsKesehatanFamily: result? detail?.payroll?.bpjskes_family : "",
            jhtCostName: result? detail?.payroll?.jht_type : "",
            InsuranceCode: result? detail?.payroll?.insurance_code : "",
            componentOvertime: result? detail?.payroll?.component_overtime : "",
            TunjanganMakan: result? detail?.payroll?.tunjangan_makan : "",
            TunjanganJabatan: result? detail?.payroll?.tunjangan_jabatan : "",
            TunjanganKehadiran: result? detail?.payroll?.tunjangan_kehadiran : "",
            TunjanganProfesi: result? detail?.payroll?.tunjangan_profesi : "",
            jaminanPensiunCost: result? detail?.payroll?.pension_cost : "",
            jaminanPensiunDate: result? detail?.payroll?.pension_date : "",
            InsuranceNumber: result? detail?.payroll?.insurance_number : "",
            InsuranceDate: result? detail?.payroll?.insurance_date : "",
            Insurance: result? detail?.payroll?.insurance : "",
            BpuTk: result? detail?.payroll?.bpu_tk : "",
            BpuTkProbation: result? detail?.payroll?.bpu_tk_probation : "",
        },
        enableReinitialize: true,
        validationSchema: validationPayroll,
        validate : (values) => {
            const errors = {};
            if (!values.Insurance && values.bpjsKesehatan === 2) {
                errors.Insurance = "Field is required";
            }
            if (!values.InsuranceNumber && values.bpjsKesehatan === 2 && values.Insurance == 1) {
                errors.InsuranceNumber = "Field is required";
            }
            if (!values.bpjsKetenagakerjaan && values.bpjsKetenagakerjaanType === "PU" || !values.bpjsKetenagakerjaan &&  values.bpjsKetenagakerjaanType === "BPU" ) {
                errors.bpjsKetenagakerjaan = "Field is required";
            }
            if (!values.bpjsKesehatan) {
                errors.bpjsKesehatan = "Field is required";
            }
            if (!values.bpjsKesehatanCost && values.bpjsKesehatan === 1) {
                errors.bpjsKesehatanCost = "Field is required";
            }
            if (!values.InsuranceCode  && values.bpjsKesehatan === 2 && values.Insurance === 1) {
                errors.InsuranceCode = "Field is required";
            }
            if (!values.bpjsKesehatanNumber && values.bpjsKesehatan === 1) {
                errors.bpjsKesehatanNumber = "Field is required"; 
            }
            if (!values.bpjsKesehatanCost && values.bpjsKesehatan === 1) {
                errors.bpjsKesehatanCost = "Field is required";
            }
            if (!values.jhtCostName && values.bpjsKetenagakerjaanType === "PU") {
                errors.jhtCostName = "Field is required";
            }
            if (!values.jaminanPensiunCost && values.bpjsKetenagakerjaanType === "PU") {
                errors.jaminanPensiunCost = "Field is required";
            }
            return errors
        },
        onSubmit: async (values) => {
            
            setLoading(true)
            try {
                const res = result ? await EditPayrollData(values, id,companyStore) : await addPayrollData(values, employeeId);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                    setLoading(false)
                } else {
                    message.success({
                        content: result ? "BPJS configuration has been successfully changed" : "BPJS configuration has been successfully saved",
                    });
                    setLoading(false)
                    // personalForm.resetForm();
                    sessionStorage.removeItem("bjsValue")
                    setEmployeeId(res?.data?.data?.employee_id)
                    setTabValue("4")
                    setFlag("4")
                }
                
                console.log(res);
            } catch (error) {
                message.error({
                    content: "BPJS configuration failed to save, please try again",
                });
                setLoading(false)
            }
        },
    });
	const onBoardingForm = useFormik({
        initialValues: {
            supportingDocument: result ? detail?.onboarding?.submitted_main_docs : '',
			visiMisi: result ? detail?.onboarding?.intro_vision_mission_company : '',
			organization: result ? detail?.onboarding?.intro_orchart_company : '', 
			jobDescription: result ? detail?.onboarding?.explain_jobdesc : '',
			roleAccess: result ? detail?.onboarding?.explain_role_access :'',
            isAssets: result ? detail?.onboarding?.assets_given ? detail?.onboarding?.assets_given : false : false, 
			assets: result ? detail?.onboarding?.assets_given : "",
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            setLoading(true)
            console.log(values)
            try {
                const dataEmployeId = result ?  detail.personal_data.employee_id : ""
                const res = result ? await EditOnBoadingData(values,dataEmployeId,companyStore) : await addOnBoadingData(values, employeeId);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                    setLoading(false)
                } else {
                    message.success({
                        content: result ? "Employee successfully updated" : "Employee successfully saved",
                    });
                    setLoading(false)
                    // personalForm.resetForm();
                    setEmployeeId(res?.data?.data?.employee_id)
                    // localStorage.setItem("Employee_Id", res?.data?.data?.employee_id);
                    navigate('/employee')
                }
                
                console.log(res);
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setLoading(false)
            }
        },
    });

    const handleBack = () => {
        navigate('/employee')
    }

    const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/employee"
		},
		{
			title: "Employee",
			url: "/employee"
		},
		{
			title: `${result ?  'Edit Employee' : 'Add Employee'}` ,
			// url: `${ result ?  '/employee/edit-employee' : '/employee/add-employee'}`
		},
	]

    var items = [
    {
        key: '1',
        label: `Personal Data`,
        // children: <PersonalForm form={personalForm} handleBack={() => handleBack()} fetching={loading}/>,
        children: <PersonalForm form={personalForm} result={result ? '/employee/edit-employee' :'' } handleBack={() => handleBack()} fetching={loading}/>,
    },
    {
        key: '2',
        label: `Employment Data`,
        children: <EmployeeForm result={result} employeeid={company} form={employeeForm} handleBack={() => handleBack()} fetching={loading} switchStat={switchStat || false}/>,
        disabled: flag !== "2" && flag !== "3" && flag !== "4",
    },
    {
        key: '3',
        label: `Payroll`,
        children: <PayrollForm form={payrollForm} handleBack={() => handleBack()} fetching={loading}/>,
        disabled: flag !== "3" &&  flag !== "4",
    },
    {
        key: '4',
        label: `Onboarding`,
        children: <OnBoardingForm form={onBoardingForm} handleBack={() => handleBack()} fetching={loading}/>,
        disabled: flag !== "4",
    },
    ];

   
    // if(AllowedPayroll != 'ALL ACCESS'){
        level = employeeForm.values.LevelSequence ? employeeForm.values.LevelSequence: 0;
        if(level > UserSequence)
        items = items.filter(function(value, index, arr){ 
            return value.key != 3;
        });
    // }
   
   
    

    return (
        <>
            <div className="title-section">
				{result ? "Edit Employee" : "Add Employee"}
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <Tabs activeKey={tabValue} className="tab-wrapper" items={items} onChange={onChange} />
        </>
    )
}

export default FormAddEmployee
