import { Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import "../styles.scss";
import { getHistoryEmployee } from "../../../api/employee";
import { decryptData } from "../../../helper/cryptojs";
import { decode } from "html-entities";
import moment from "moment";

const History = ({ data }) => {

  const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(0)
	const [search, setSearch] = useState("");
  const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));

  const location = window.location.href;
  var n = location.split("/");
  var result =  n[5];
  const decode = decodeURIComponent(result)
  var dec = decode.lastIndexOf('?');
  var employeeNumber = decode.substring(dec + 1);
  const employeeList = async () => {
		setLoading(true);
		try {
		  const res = await getHistoryEmployee(search, limit, offset, page,company,employeeNumber)
		  console.log('testing apakah sama',res?.data?.data)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		  console.log(res?.data)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			employeeList();
		  }
	}, [search, limit, offset, page, company,employeeNumber]);

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      render: (text,record) =>{ 
        return (
          <div className={`detail-btn-wp `}>
            {text}
          </div>
        )
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text,record) =>{
        return (
          <div className={`detail-btn-wp `}>
            {moment(text).format("YYYY-MM-DD")}
          </div>
        )
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text,record) =>{
        return (
          <div className={`detail-btn-wp `}>
            {text}
          </div>
        )
      }
    },
    {
      title: 'User',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (text,record) =>{
        return (
          <div className={`detail-btn-wp `}>
            {decryptData(text) || text || "-"}
          </div>
        )
      }
    }
    ];

    function dataTableWithNumber() {
      const showNumber = limit * (page - 1);
      return listTable?.map((data, index) => {
        return {
          ...data,
          key: index + 1,
          no: showNumber + (index + 1),
        };
      });
    }
  return (
    <div className="detail-wrapper">
      <div className="table-wrapper">
        <Table dataSource={dataTableWithNumber()} columns={columns} />
      </div>
    </div>
  );
};
//notes
export default History;
