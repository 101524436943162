import { sso } from "./hris";
import { api_key } from "./login";

export const getAplication = async (search,limit, offset) => {
    let paylaod = {
        search_by : "name",
        api_key : api_key,
        search_value : search.length > 0 ? search : undefined,
        limit : limit,
        offset : offset
    }
    return sso.post("/getaplicationlist", paylaod);
}

// export const addRole = async (data) => {
//   let payload = {
//     search_by : "phone_number",
//     api_key : api_key,
//     code: data?.code,
//     name: data?.name,
//     branch: data?.branch,
//     aplication: data?.aplication,
//     menu: JSON.stringify(data?.menu),
//     created_by: "SYSTEM",

//   };
//   return await sso.post("/addrole", payload);
// };

// export const getRoleDetail = async (id) => {
//   let payload = {
//     role_id: id,
//     api_key : api_key,
//   }
//   return sso.post("/getroleid", payload);
// }

// export const deleteRole = async (id) => {
//   let payload = {
//     role_id : id,
//     deleted_by: "SYSTEM",
//     api_key : api_key,
//   }
//   return sso.post("/deleterole", payload);
// }

// export const editRole = async (data, id) => {
//   let payload = {
//     role_id : id,
//     api_key : api_key,
//     code: data?.code,
//     name: data?.name,
//     branch: data?.branch,
//     aplication: data?.aplication,
//     menu: data?.menu,
//     created_by: "SYSTEM"

//   };
//   return await sso.post("/updaterole", payload);
// }
