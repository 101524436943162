/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, EditFilled } from "@ant-design/icons";
import { decryptData, encryptData } from "../../helper/cryptojs";
import { formatCurrency } from "../../helper/formatCurrency";
import { getLoanTransaction } from "../../api/loan";

export default function LoanSetting() {
  const navigate = useNavigate();
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const RoleAccessLoadSettingCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeLoanSettingCreate")
  );
  const RoleAccessLoadSettingUpdate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeLoanSettingUpdate")
  );
  const limitSession = sessionStorage.getItem("sessionPageLoanSetting");
  const filterSession = decryptData(
    sessionStorage.getItem("filterStatusLoanSetting")
  );
  const offsetSession = sessionStorage.getItem("sessionOffsetLoanSetting");
  const sessionSearch = sessionStorage.getItem("sessionSearchLoanSetting");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch ?? "");
  const [active, setActive] = useState(filterSession === 0 ? 0 : 1 || 1);

  const LoanTransaction = async () => {
    setLoading(true);
    try {
      const res = await getLoanTransaction(
        company,
        active,
        page,
        offset,
        limit,
        search
      );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageLoanSetting", page);
  }, [page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageLoanSetting");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      LoanTransaction();

      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageLoanSetting");
        sessionStorage.removeItem("filterStatusLoanSetting");
        sessionStorage.removeItem("sessionOffsetLoanSetting");
        sessionStorage.removeItem("sessionSearchLoanSetting");

        setSearch("")
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, active, page, offset, limit, search]);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - 10);
    }
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan-setting/detail/${encodeURIComponent(
                  "id?" + record.m_loan_id
                )}`
              )
            }>
            {index + 1 + (page - 1) * limit}
          </div>
        );
      },
    },
    {
      title: "Loan Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan-setting/detail/${encodeURIComponent(
                  "id?" + record.m_loan_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Maximum Tenor",
      dataIndex: "max_tenor",
      key: "max_tenor",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan-setting/detail/${encodeURIComponent(
                  "id?" + record.m_loan_id
                )}`
              )
            }>
            {`${text} months`}
          </div>
        );
      },
    },
    {
      title: "Maximum Loan",
      dataIndex: "max_loan",
      key: "max_loan",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan-setting/detail/${encodeURIComponent(
                  "id?" + record.m_loan_id
                )}`
              )
            }>
            {formatCurrency(text)}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan-setting/detail/${encodeURIComponent(
                  "id?" + record.m_loan_id
                )}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      render: (_, record, index) => {
        return (
          RoleAccessLoadSettingUpdate && (
            <Row justify={"space-between"}>
              <div
                className="icon-action"
                onClick={() =>
                  navigate(
                    `/loan-setting/edit-loan-setting/${encodeURIComponent(
                      "id?" + record?.m_loan_id
                    )}`
                  )
                }>
                <EditFilled />
              </div>
            </Row>
          )
        );
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Loan Management",
      url: "/loan-setting",
    },
    {
      title: "Loan Setting",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageLoanSetting", pageNumber);
    sessionStorage.setItem("sessionOffsetLoanSetting", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchLoanSetting", e.target.value);
  };

  const dataFilter = [
    {
      value: 1,
      title: "Active",
    },
    {
      value: 0,
      title: "Inactive",
    },
  ];

  return (
    <>
      <div className="title-section">Loan Setting</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              <Select
                placeholder="Select Company"
                value={active == 1 ? "Active" : "Inactive"}
                onChange={(val) => {
                  setActive(val);
                  setPage(1);
                  sessionStorage.setItem(
                    "filterStatusLoanSetting",
                    encryptData(val)
                  );
                }}
                className="select-company w-24 md:w-36 text-xs ">
                {dataFilter.map((data, index) => {
                  return (
                    <Select.Option
                      style={{ height: "42px" }}
                      key={index}
                      value={data.value}>
                      {data.title}
                    </Select.Option>
                  );
                })}
              </Select>
              <InputWithIcon
                type="text"
                placeholder="Search Loan Name"
                img={iconSearch}
                className="search-input btn-sh-sec"
                classImg="op-icon"
                onChange={onChangeSearch}
                value={search}
              />

              {RoleAccessLoadSettingCreate && (
                <Button
                  className="btn-sh-p"
                  type="primary"
                  shape="circle"
                  onClick={() => navigate("/loan-setting/add-loan-setting")}
                  icon={<PlusOutlined />}
                  size={"large"}
                />
              )}
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
