import { Button, Collapse, DatePicker, Form as FormAntd, message } from "antd";
import { useRef, useState } from "react";
import { SelectOptionWithLabel, TextAreaWithLabel } from "../../Form/Input";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { AddResultPA } from "../../../api/personalAssessment";
import { useFormik } from "formik";
import { Result } from "../../../assets/icons";
import * as yup from "yup";
import dayjs from "dayjs";

export default function ResultPA({
  valuesProcess,
  isPrivillege,
  fetchAsessment,
  fetchDataPersonalAssessmentProcess,
}) {
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const inputRef = useRef(null);

  const [fileName, setFileName] = useState(null);
  const [fetching, setFetching] = useState(false);

  const validation = yup.object().shape({
    result_status: yup.string().required("Field is required"),
    result_attachment: yup.string().required("Field is required"),
    result_effective_date: yup.string().required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      request_id: id,
      result_status: null,
      result_attachment: null,
      result_effective_date: null,
      result_remarks: null,
    },
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const res = await AddResultPA({
          ...values,
        });
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: "Data successfully saved ",
          });
        }
        fetchAsessment();
        fetchDataPersonalAssessmentProcess();
        setFetching(false);
      } catch (error) {
        message.error({
          content: error.message,
        });
        setFetching(false);
      }
    },
  });

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const newFileName = file.name.replace(/['"]+/g, "");
      const renamedFile = new File([file], newFileName, { type: file.type });
      setFileName(renamedFile.name);
      form.setValues({
        ...form.values,
        result_attachment: renamedFile,
      });
    } else {
      setFileName(null);
      message.error(
        "Please upload a PDF,JPEG,JPG,PNG file with a maximum size of 2 MB"
      );
    }
  };

  const handleFileDelete = () => {
    inputRef.current.value = "";
    setFileName(null);
    form.setValues({
      ...form.values,
      result_attachment: null,
    });
  };

  return (
    <div>
      {" "}
      <Collapse expandIcon={() => null}>
        <Collapse.Panel
          disabled={valuesProcess?.process?.length ? false : true}
          header={
            <div className="flex gap-2 items-center">
              <Result size={20} />
              Result
            </div>
          }
          key="1">
          <>
            <FormAntd.Item>
              <SelectOptionWithLabel
                allowClear={true}
                name="result_status"
                label={
                  <div>
                    Result Assessment
                    <span className="text-red-500">*</span>
                  </div>
                }
                placeholder="Select one"
                value={form.values.result_status || null}
                items={[
                  {
                    value: "Qualified",
                    title: "Qualified",
                  },
                  {
                    value: "Unqualified",
                    title: "Unqualified",
                  },
                ]}
                onChange={(val) =>
                  form.setValues({
                    ...form.values,
                    result_status: val,
                  })
                }
                disabled={!isPrivillege}
                onBlur={form.handleBlur}
              />
              {form.touched.result_status && form.errors.result_status && (
                <div className="mt-4">
                  <span className="text-error absolute">
                    {form.errors.result_status}
                  </span>
                </div>
              )}
            </FormAntd.Item>
            <FormAntd.Item>
              <div className="flex flex-col">
                <div className="text-slate-500">
                  {"Effective Date"}
                  <span className="text-red-500">*</span>
                </div>
                <DatePicker
                  disabled={!isPrivillege}
                  name="result_effective_date"
                  format="DD-MM-YYYY"
                  placeholder="DD-MM-YYYY"
                  allowClear={true}
                  value={
                    form.values.result_effective_date
                      ? dayjs(form.values.result_effective_date, "YYYY-MM-DD")
                      : null
                  }
                  onChange={(date) => {
                    const formattedDate = date
                      ? dayjs(date).format("YYYY-MM-DD")
                      : null;
                    form.setFieldValue("result_effective_date", formattedDate);
                  }}
                  className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                />
                {form.touched.result_effective_date &&
                  form.errors.result_effective_date && (
                    <span className="text-error">
                      {form.errors.result_effective_date}
                    </span>
                  )}
              </div>
            </FormAntd.Item>
            <FormAntd.Item>
              <div className="text-[#8697a8] text-base mb-2">
                {"Attachment"}
                <span className="text-red-500 label-input">*</span>
              </div>
              <label
                className={`file-upload ${
                  isPrivillege ? "cursor-pointer" : "cursor-not-allowed"
                }`}>
                {isPrivillege ? (
                  <>
                    <input
                      type="file"
                      name="result_attachment"
                      id="result_attachment"
                      onChange={handleFileUpload}
                      accept=".jpeg, .jpg, .png, .pdf"
                      ref={inputRef}
                    />
                    <CloudUploadOutlined /> Upload Attachment Assessment
                  </>
                ) : (
                  <>
                    <input
                      disabled
                      type="file"
                      name="result_attachment"
                      id="result_attachment"
                      onChange={handleFileUpload}
                      accept=".jpeg, .jpg, .png, .pdf"
                      ref={inputRef}
                    />
                    <CloudUploadOutlined /> Upload Attachment Assessment
                  </>
                )}
              </label>

              <div>
                {fileName && (
                  <div>
                    <p>
                      {fileName}{" "}
                      <Button
                        shape="circle"
                        onClick={handleFileDelete}
                        icon={<DeleteOutlined style={{ color: "red" }} />}
                        size={"small"}
                      />{" "}
                    </p>
                  </div>
                )}
              </div>
              {form.touched.result_attachment &&
                form.errors.result_attachment && (
                  <span className="text-error">
                    {form.errors.result_attachment}
                  </span>
                )}
            </FormAntd.Item>

            <FormAntd.Item>
              <div>
                <TextAreaWithLabel
                  disabled={!isPrivillege}
                  name="result_remarks"
                  label={"Remarks"}
                  placeholder="......."
                  maxLength={300}
                  showCount
                  onChange={form.handleChange}
                  value={form.values.result_remarks}
                />
              </div>
            </FormAntd.Item>
            {isPrivillege && (
              <div className="footer-add mt-4 flex justify-end">
                <Button
                  key="submit"
                  type="primary"
                  className="btn btn-primary btn-sh-p"
                  loading={fetching}
                  onClick={form.handleSubmit}
                  disabled={form.isSubmitting}>
                  Submit
                </Button>
              </div>
            )}
          </>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
