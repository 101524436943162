import {
  Button,
  Col,
  DatePicker,
  Modal,
  Pagination,
  Row,
  Tooltip
} from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import "./styles.scss";
import { SyncOutlined, DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../helper/cryptojs";
import { getDataPayroll, downloadPayroll } from "../../api/payslip";
export default function GeneratePayslipIndex() {
  const navigate = useNavigate();
  const sessionPage = sessionStorage.getItem("sessionPagePayslip");
	const sessionOffset = sessionStorage.getItem("sessionOffsetPayslip");
	const sessionDate = sessionStorage.getItem("sessionDatePayslip");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([{}]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(sessionOffset ?? 0);
  const [selectedDate, setSelectedDate] = useState(sessionDate ?? null);
  const [dateVal, setDateVal] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const fetchPayrollData = async (company,selectedDate) => {
    try {
      setLoading(true);
      const res = await getDataPayroll(company,selectedDate,limit, offset, page);
      console.log(res.data.data);
      setListTable(res.data.data);
      setTotalData(res.data.recordsTotal);
    } catch (error) {
      console.error("Error fetching payroll data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      fetchPayrollData(company,selectedDate);
      const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPagePayslip");
				sessionStorage.removeItem("sessionOffsetPayslip");
				sessionStorage.removeItem("sessionDatePayslip");
				setPage(1)
				setOffset(0)
				setSelectedDate(null)
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
    }
  }, [company,selectedDate,limit, offset, page]);

  useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPagePayslip", page);
		sessionStorage.setItem("sessionOffsetPayslip", offsetSession);
	},[page])


  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "15px",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Periode",
      dataIndex: "periode",
      key: "periode",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Generate Date",
      dataIndex: "generate_date",
      key: "generate_date",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Total Data Uploaded",
      dataIndex: "totalRow",
      key: "status",
      render: (text, record) => {
        return <div className="detail-btn-wp">
          {record.status === 'PROCESSING' ? 'Processing' : (record.status !== 'PAYROLL_COMPLETED' ? '-' : text)}
        </div>;
      },
    },
    {
      title: "Total Success",
      dataIndex: "successRow",
      key: "status",
      render: (text, record) => {
        return <div className="detail-btn-wp">
            <Tooltip placement="right" color='white' title="Payslip available on mobile">
              {record.status === 'PROCESSING' ? 'Processing' : (record.status !== 'PAYROLL_COMPLETED' ? '-' : text)}
            </Tooltip>
        </div>;
      },
    },
    {
      title: "Total Failed",
      dataIndex: "failedRow",
      key: "failedRow",
      render: (text, record) => {
        return <div className="detail-btn-wp">
          {record.status === 'PROCESSING' ? 'Processing' : (record.status !== 'PAYROLL_COMPLETED' ? '-' : text)}
        </div>;
      },
    },
    {
      title: "Result",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return <div className="detail-btn-wp">
          <Button type="link" disabled={text !== "PAYROLL_COMPLETED"} onClick={() => actDownloadPayroll(record.payroll_id, record.original)} target="_blank">
            <DownloadOutlined />
          </Button>
        </div>;
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/generate-payslip",
    },
    {
      title: "Generate Payslip",
      url: "/generate-payslip",
    },
  ];

  useEffect(() => {
		previousPage.current = page;
	}, [page]);

  const onChangeTable = (pageNumber, type) => {
    setPage(pageNumber);
    setOffset(pageNumber * limit - 10);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };

  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDatePickerChange = (date, dateString) => {
    setPage(1)
    setSelectedDate(dateString);
    sessionStorage.setItem("sessionDatePayslip", dateString);
  };

  const actDownloadPayroll = (payrollId, filename) => {
    downloadPayroll(payrollId).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);
    
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `result-${filename}`); //or any other extension
        document.body.appendChild(link);
        link.click();
    
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
  }

  return (
    <>
      <>
        <div className="title-section">Generate Payslip</div>
        <Modal
          title="Delete"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <p>Are you sure to delete this data?</p>
        </Modal>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
          <Col span={24}>
            <Breadcrumb items={dataBreadcrumb} />
          </Col>
        </div>
        <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
          <Row
            style={{ marginBottom: "24px", justifyContent: "end" }}
            align={"middle"}
          >
            <Col md={{ top: 20 }} className="mt-5 md:mt-0">
              <Row justify={"end"} style={{ gap: 15 }} md={14}>
                <DatePicker
                  picker="month"
                  className="search-input btn-sh-sec"
                  onChange={handleDatePickerChange}
                  placeholder="Select Month"
                />
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="btn-sh-p"
                    type="primary"
                    onClick={() =>
                      navigate("/generate-payslip/execute-generate-payslip")
                    }
                    icon={<SyncOutlined />}
                    size={"large"}
                  >
                    Generate Payslip
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="table-wrapper">
            {loading ? (
              <>
                <Table id={"my-table"} columns={columns} loading={loading} />
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={page}
                    current={page}
                    total={totalData}
                    onChange={onChangeTable}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChange}
                  />
                </div>
              </>
            ) : (
              <>
                <Table
                  id={"my-table"}
                  dataSource={dataTableWithNumber()}
                  columns={columns}
                  loading={loading}
                />
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={page}
                    current={page}
                    total={totalData}
                    onChange={onChangeTable}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
}
