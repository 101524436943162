import {
  Button,
  Col,
  Pagination,
  Row,
  Tooltip
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import Table from "../../../component/Table";
import "../styles.scss";
import { DownloadOutlined, UploadOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../../helper/cryptojs";
import { getDataPayroll, downloadPayroll } from "../../../api/payslip";
import { getImportEmployeeList } from "../../../api/employee";
export default function ImportIndex() {
  const navigate = useNavigate();
  const sessionPage = sessionStorage.getItem("sessionPagePayslip");
  const sessionOffset = sessionStorage.getItem("sessionOffsetPayslip");
  const sessionDate = sessionStorage.getItem("sessionDatePayslip");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([{}]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(sessionOffset ?? 0);
  const [selectedDate, setSelectedDate] = useState(sessionDate ?? null);
  const [dateVal, setDateVal] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const fetchPayrollData = async (company, selectedDate) => {
    try {
      setLoading(true);
      const res = await getImportEmployeeList(limit, offset, page, company);
      setListTable(res.data.data);
      setTotalData(res.data.recordsTotal);
    } catch (error) {
      console.error("Error fetching payroll data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      fetchPayrollData(company, selectedDate);
      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPagePayslip");
        sessionStorage.removeItem("sessionOffsetPayslip");
        sessionStorage.removeItem("sessionDatePayslip");
        setPage(1)
        setOffset(0)
        setSelectedDate(null)
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, selectedDate, limit, offset, page]);

  useEffect(() => {
    let offsetSession = 0;
    if (page > 1) {
      offsetSession = page * limit - 10
    }
    setOffset(offsetSession)
    sessionStorage.setItem("sessionPagePayslip", page);
    sessionStorage.setItem("sessionOffsetPayslip", offsetSession);
  }, [page])


  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "15px",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Total Data",
      dataIndex: "total_records",
      key: "total_records",
      render: (text, record) => {
        return <div className="detail-btn-wp">
          {record.status === 'PROCESSING' ? 'Processing' : (record.status !== 'COMPLETED' ? '-' : text)}
        </div>;
      },
    },
    {
      title: "Total Success",
      dataIndex: "success_records",
      key: "success_records",
      render: (text, record) => {
        return <div className="detail-btn-wp">
          <Tooltip placement="right" color='white' title="Payslip available on mobile">
            {record.status === 'PROCESSING' ? 'Processing' : (record.status !== 'COMPLETED' ? '-' : text)}
          </Tooltip>
        </div>;
      },
    },
    {
      title: "Total Failed",
      dataIndex: "failed_records",
      key: "failed_records",
      render: (text, record) => {
        return <div className="detail-btn-wp">
          {record.status === 'PROCESSING' ? 'Processing' : (record.status !== 'COMPLETED' ? '-' : text)}
        </div>;
      },
    },
    {
      title: "Result",
      dataIndex: "status",
      key: "result",
      render: (text, record) => {
        return <div className="detail-btn-wp">
          <Button type="link" disabled={text !== "COMPLETED"} onClick={() => actDownloadResult(record.id)} target="_blank">
            <DownloadOutlined />
          </Button>
        </div>;
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Master Data",
      url: "/employee",
    },
    {
      title: "Employee",
      url: "/employee",
    },
    {
      title: "Import Bulky Employee",
      url: "employee/import-employee",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    setPage(pageNumber);
    setOffset(pageNumber * limit - 10);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };

  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDatePickerChange = (date, dateString) => {
    setPage(1)
    setSelectedDate(dateString);
    sessionStorage.setItem("sessionDatePayslip", dateString);
  };

  const actDownloadResult = (id) => {
    window.open(`${process.env.REACT_APP_HRIS_URL}employee-import-download/${id}`, '_blank');
  }

  return (
    <>
      <>
        <div className="title-section">
          <Button className="btn-sh-p" type="primary" shape="circle" style={{ marginRight: "14px" }} onClick={() => navigate('/employee')} icon={<ArrowLeftOutlined />} size={"large"} />
          Import Bulky Employee
        </div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
          <Col span={24}>
            <Breadcrumb items={dataBreadcrumb} />
          </Col>
        </div>
        <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
          <Row
            style={{ marginBottom: "24px", justifyContent: "end" }}
            align={"middle"}
          >
            <Col md={{ top: 20 }} className="mt-5 md:mt-0">
              <Row justify={"end"} style={{ gap: 15 }} md={14}>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="btn-sh-p"
                    type="primary"
                    onClick={() =>
                      navigate("/employee/add-import-employee")
                    }
                    icon={<UploadOutlined />}
                    size={"large"}
                  >
                    Import
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="table-wrapper">
            {loading ? (
              <>
                <Table id={"my-table"} columns={columns} loading={loading} />
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={page}
                    current={page}
                    total={totalData}
                    onChange={onChangeTable}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChange}
                  />
                </div>
              </>
            ) : (
              <>
                <Table
                  id={"my-table"}
                  dataSource={dataTableWithNumber()}
                  columns={columns}
                  loading={loading}
                />
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={page}
                    current={page}
                    total={totalData}
                    onChange={onChangeTable}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChange}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    </>
  );
}
