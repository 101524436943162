import { Button, Col, Form as FormAntd, message,Radio ,Space,Input, Row,Switch,Select} from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { InputWithLabel,RadioGroupWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addBranch, editBranch, getBranchDetail,getBranch } from "../../api/branch";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import { getSuggestion, getPlaceDetail } from "../../api/autocompleteAddress";
import PinMapIcon from "../../assets/img/pin-map.png";

const { Option } = Select;
const FormAddBranch = () => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const deBranchCode = decryptData(localStorage.getItem("DefaultBranchCode"));
    const deCompanyId = decryptData(localStorage.getItem("DefaultCompanyId"));
    const companyStoreId = decryptData(sessionStorage.getItem("selectCompanyId"));
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode?.lastIndexOf('?');
    var id = decode?.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const [listBranch, setListBranc] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const BestpraticeUseEffect1 = useRef(false) 
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectRadio, setSelectRadio] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [sessionToken, setSessionToken] = useState("");
    
      useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                  setLatitude(position.coords.latitude);
                  setLongitude(position.coords.longitude);
                  setLoading(false);
                },
                (error) => {
                  console.error(error);
                  setLoading(false);
                }
              );
              
        } else {
          console.error("Geolocation is not supported by this browser.");
          setLoading(false);
        }
      }, []); 

      
    
      const GoToLocation = ({ coords }) => {
        const map = useMap();
        map.setView(coords, map.getZoom());
        return null;
      };
      const handleSearchChange = async (value) => {
        if (value) {
            setFetching(true);
            try {
                if(value.length >= 10) {
                    const res = await getSuggestion(value);
                    setSessionToken(res.data.data.sessionToken);
                    setSearchResults(res.data.data.suggestions);
                }
            } catch (error) {
                console.error('Error searching location:', error);
            } finally {
                setFetching(false);
            }
        } else {
            setSearchResults([]);
        }
    };
    
    const handleSelectLocation = async (value) => {
        const res = await getPlaceDetail(value, sessionToken);
        if (res.data.success) {
            const { lat, lng } = res.data.data.address.geometry.location;
            form.setFieldValue('latitude', parseFloat(lat));
            form.setFieldValue('longitude', parseFloat(lng));
        }
    };
    const positionList = async () => {
		setFetching(true);
		try {
		  const res = await getBranchDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if(result){
                positionList();
            }
          }
       
	}, [flag]);

    const branchList = async () => {
		try {
		  const res = await getBranch("", 0, 0, "",companyStore,deBranchCode)
		  const data = res?.data?.data
		  setListBranc(data.map((item) => {
			return {
                idx : item.branch_id,
				value : item.code,
				title : item.name
			}
		}))
		} catch (err) {
		  console.log(err);
		}
	};

    useEffect(() => {
        if (!BestpraticeUseEffect1.current) {
            BestpraticeUseEffect1.current = true;
          } else {
            branchList();
          }
		
	}, []);

    const validationUser = yup.object().shape({
        name: yup.string().required("Field is required"),
        companyCode: yup.string().required("Field is required"),
        address : yup.string().required("Field is required"),
        city : yup.string().required("Field is required"),
        province : yup.string().required("Field is required"),
        postalCode : yup.number().required("Field is required"),
        contactPersonEmail: yup.string().email("Must be a valid email").nullable(),
        contactPersonNumber: yup.string().min(10, "Min 10 characters").nullable(),
        radius : selectRadio === true ? yup.number().required("Field is required").min(5, "min 5") : ""
    });
    const form = useFormik({
        initialValues: {
            name: result ? detail?.name : "",
            companyCode: result ? detail?.company_id : companyStoreId ? companyStoreId : deCompanyId,
            address : result ? detail?.address: "",
            city : result ? detail?.city: "",
            province : result ? detail?.province: "",
            postalCode : result ? detail?.postal_code: "",
            contactPersonNumber : result ? detail?.phone_number: null,
            contactPersonEmail : result ? detail?.email: null,
            latitude: result ? detail?.latitude : selectRadio ? latitude : 0,
            longitude: result ? detail?.longitude : selectRadio ? longitude : 0,
            Checked: result ? detail?.checked || selectRadio : selectRadio,
            radius: result ? detail.radius : "",  
        },
        enableReinitialize: result ? true : false,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
            try {
                const res = result ? await editBranch(values, id) : await addBranch(values);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Branch successfully updated" : "Branch successfully saved",
                    });
		    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    navigate("/branch")
                    form.resetForm();
                    setFlag((prev) => prev + 1)

                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });

    const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/branch"
		},
		{
			title: "Branch",
			url: "/branch"
		},
		{
			title: `${result ?  'Edit Branch' : 'Add Branch'}` ,
			// url: `${ result ?  '/branch/edit-branch' : '/branch/add-branch'}`
		},
	]

    
    // useEffect(() => {
    //     if (form.values.radius !== null) {
    //         setSelectRadio(true);
    //     }else{
    //         setSelectRadio(false);
    //     }
    // }, [form.values.radius]);

    return (
        <>
        <div className="title-section">
             {result ? "Edit Branch" : "Add Branch"}
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >  
                       
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Branch Name<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Name"
                        value={form?.values?.name ? form?.values?.name : ""}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.name && form.errors.name ? (
                        <span className="text-error">{form.errors.name}</span>
                    ) : null}
                </FormAntd.Item>

                <FormAntd.Item>
                    <InputWithLabel
                        name="address"
                        label={<div>Address<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Address"
                        value={form?.values?.address}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.address && form.errors.address ? (
                        <span className="text-error">{form.errors.address}</span>
                    ) : null}
                </FormAntd.Item>

                <FormAntd.Item>
                    <InputWithLabel
                        name="postalCode"
                        label={<div>Postal Code<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Postal Code"
                        value={form?.values?.postalCode}
                        // onChange={form.handleChange}
                        onChange={ (e) => {
                            const telNo = e.target.value;
                            const re = /^[0-9\b]+$/;
                            if (telNo === '' || re.test(telNo)) {
                                form.handleChange(e)
                            }
                        }}
                        onBlur={form.handleBlur}
                        maxLength={6}
                    />
                    {form.touched.postalCode && form.errors.postalCode ? (
                        <span className="text-error">{form.errors.postalCode}</span>
                    ) : null}
                </FormAntd.Item>
                
                <FormAntd.Item>
                    <InputWithLabel
                        name="province"
                        label={<div>Province<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Province"
                        value={form?.values?.province}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.province && form.errors.province ? (
                        <span className="text-error">{form.errors.province}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="city"
                        label={<div>City<span style={{color:"#FF0000"}}> *</span></div>}    
                        placeholder="City"
                        value={form?.values?.city}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.city && form.errors.city ? (
                        <span className="text-error">{form.errors.city}</span>
                    ) : null}
                </FormAntd.Item>
                <Row className="gap-4 mb-6">
                    <Col span={11}>
                    <div className="border-dashed border-2 border-sky-500 bg-white p-3 rounded-md shadow-lg h-auto">     
                        <Col>
                            <FormAntd.Item>
                            <Select
                                showSearch
                                placeholder="Search for a location"
                                onChange={handleSelectLocation}
                                onSearch={handleSearchChange}
                                optionLabelProp="name"
                                loading={fetching}
                                filterOption={false}
                            >
                                {searchResults.map(result => (
                                    <Option key={result.place_id} value={result.place_id} name={result.address}>
                                        {result.address}
                                    </Option>
                                ))}
                            </Select>

                            </FormAntd.Item>
                        </Col>
                        {loading ? (
                            <div>Loading...</div>
                            ) : (
                                <MapContainer
                                    center={[form.values.latitude ? form.values.latitude : 0, form.values.longitude ? form.values.longitude : 0 ]}
                                    zoom={14}
                                    style={{ height: '400px', width: '97%' }}
                                    className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg"
                                >
                                    <TileLayer
                                        attribution="Google Maps"
                                        url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                                    />
                                    <Marker
                                        draggable={true}
                                        onDragend
                                        position={[form.values.latitude ? form.values.latitude : 0, form.values.longitude ? form.values.longitude : 0 ]}
                                        icon={L.icon({
                                            iconUrl: PinMapIcon,
                                            iconSize: [32, 32],
                                            iconAnchor: [16, 32],
                                            popupAnchor: [0, -32]
                                        })}
                                        eventHandlers={{
                                            moveend: (event) => {
                                              const { lat, lng } = event.target.getLatLng();
                                              form.setFieldValue('latitude', lat);
                                              form.setFieldValue('longitude', lng);
                                            }
                                          }}
                                          

                                        >
                                            <Popup> Latitude : {form.values.latitude ? form.values.latitude : 0} , Longitude : {form.values.longitude ? form.values.longitude : 0} </Popup>
                                        </Marker>
                                    <GoToLocation coords={[form.values.latitude ? form.values.latitude : 0, form.values.longitude ? form.values.longitude : 0 ]} />
                                </MapContainer>
                            )}
                            </div>   
                    </Col>
                    <Col span={11} >
                        <div className="border-dashed border-2 border-sky-500 bg-white mb-3 p-3 rounded-md shadow-lg h-auto">
                            <div className="flex flex-row  gap-4">
                                <Switch 
                                    checked={ result ? form.values.Checked : selectRadio} 
                                    onChange={(checked) => {
                                        setSelectRadio(checked);
                                        form.setFieldValue('Checked', checked);
                                        if (!checked) {
                                            setSelectRadio(false);
                                            form.setFieldValue('latitude','');
                                            form.setFieldValue('longitude', '');
                                            form.setFieldValue('radius', '');
                                        }
                                        else{
                                            setSelectRadio(true);
                                            form.setFieldValue('latitude',latitude);
                                            form.setFieldValue('longitude', longitude);
                                        }
                                    }} 
                                   
                                />
                                <div className="flex flex-col ">
                                    <span className="text-md font-bold">GPS LOCATION ON MOBILE HRIS</span>
                                    <span className="text-xs">User need to turn on GPS and accuracy will be identified </span>
                                    <span className="text-xs">Set the radius for check in / check out</span>
                                    {(result ? form.values.Checked : selectRadio)&& (
                                        <FormAntd.Item>
                                            <div className="w-48">
                                            <InputWithLabel
                                                addonAfter={"M"} 
                                                name="radius"
                                                placeholder="Radius"
                                                value={form?.values?.radius ? form?.values?.radius : ""}
                                                onChange={(e) => {
                                                    const telNo = e.target.value;
                                                    const re = /^[0-9\b]+$/;
                                                    if (telNo === '' || re.test(telNo)) {
                                                        form.handleChange(e)
                                                    }
                                                }}
                                                onBlur={form.handleBlur}
                                                maxLength={4}
                                            />
                                            {selectRadio === true && (
                                                <>
                                                 {form.touched.radius && form.errors.radius ? (
                                                    <span className="text-error">{form.errors.radius}</span>
                                                ) : null}
                                                </>
                                            )}
                                            
                                            </div>
                                            <span className="text-xs">Min radius 5m for better accuracy</span>
                                        </FormAntd.Item>
                                 )}
                                </div>
                             
                            </div>
                        </div>
                    </Col>
                </Row>

                <FormAntd.Item>
                    <InputWithLabel 
                        name="contactPersonNumber"
                        label={"Phone Number"}
                        placeholder="08**********"
                        value={form?.values?.contactPersonNumber}
                        onChange={ (e) => {
                            const telNo = e.target.value;
                            const re = /^[0-9\b]+$/;
                            if (telNo === '' || re.test(telNo)) {
                                form.handleChange(e)
                            }
                        }}
                        onBlur={form.handleBlur}
                        maxLength={13}
                        minLength={10}
                    />
                    {form.touched.contactPersonNumber && form.errors.contactPersonNumber ? (
                        <span className="text-error">{form.errors.contactPersonNumber}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="contactPersonEmail"
                        label={"Email"}
                        placeholder="Email"
                        value={form?.values?.contactPersonEmail}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.contactPersonEmail && form.errors.contactPersonEmail ? (
                        <span className="text-error">{form.errors.contactPersonEmail}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="companyCode"
                        type="hidden"
                        placeholder="Name"
                        value={form?.values?.companyCode}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.companyCode && form.errors.companyCode ? (
                        <span className="text-error">{form.errors.companyCode}</span>
                    ) : null}
                </FormAntd.Item>
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/branch')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
        </>
        
    )
}

export default FormAddBranch;
