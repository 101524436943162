/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, message, Row,Timeline } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getOvertimeTransactionDetail } from "../../../api/overtimeTransaction";

const OvertimeTransactionDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
   
    const overtimeTransactionList = async () => {
		try {
		  const res = await getOvertimeTransactionDetail(id)
          console.log(res)
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
	  };
    }

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            overtimeTransactionList();
          }
		
	}, []);
    const dataBreadcrumb = [
        {
			title: "Attendance Management",
			url: "/overtime-transaction"
		},
		{
			title: "Overtime Transaction",
			url: "/overtime-transaction"
		},
        {
			title: "Overtime Transaction Detail",
			// url: "/overtime-transaction-detail"
		},
	];


    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}}  onClick={() => navigate('/overtime-transaction')} icon={<ArrowLeftOutlined />} size={"large"} />
				Overtime Transactions Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">NIK</div>
                            <div className="subtitle">{detail?.employee_number || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Employee Name</div>
                            <div className="subtitle">{detail?.full_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Division</div>
                            <div className="subtitle">{detail?.division_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Department</div>
                            <div className="subtitle">{detail?.department_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Position</div>
                            <div className="subtitle">{detail?.position_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Date</div>
                            <div className="subtitle">{detail?.date  || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Start time</div>
                            <div className="subtitle">{ detail?.start_time ? moment(detail?.start_time, "HH:mm").format("HH:mm") : "--:--"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">End time</div>
                            <div className="subtitle">{detail?.end_time ? moment(detail?.end_time, "HH:mm").format("HH:mm") :  "--:--"}</div>
                        </div>
                    </Col>
                     <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Break Duration</div>
                            <div className="subtitle">{detail?.break_duration || '0 hour 0 minute'}</div>
                        </div>
                    </Col>
                     <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Duration</div>
                            <div className="subtitle">{detail?.duration || '0h 0m'}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Description</div>
                            <div className="subtitle">{detail?.description || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                    <div className="detail-card">
            <div className="title">Attachment</div>
            <div className="text-sm text-gray-500 flex items-center">
                <div>
                <svg
                    className="w-4 h-4 mr-2 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20">
                    <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                </svg>
                </div>
                        {
                        detail?.attachment_url ? (
                            <Link
                            to={detail?.attachment_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                          >
                            {detail?.attachment_url
                              .replace(
                                `${process.env.REACT_APP_HRIS_URL}/backend/company/image/overtime/`,
                                ""
                              )
                             }
                          </Link>
                        ) : (
                          <span key={detail?.data?.change_shift_id} className="text-gray-500">
                            No File
                          </span>
                        )
                        }
                </div>
                </div>
                    </Col>
                    
                     <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Status</div>
                            <div className="subtitle">{detail?.status || "-"}</div>
                        </div>
                    </Col>
                    {detail?.status == "REJECTED"  && (
                        <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Reason</div>
                            <div className="subtitle">{detail?.reason ? detail?.reason : "-" || "-"}</div>
                        </div>
                        </Col>
                    )}
                  
                     
                    <Col span={24}>
                <div className="detail-card">
                  <div className="title">Status Detail</div>
                  <div className="subtitle mt-5">
                 
                  <Timeline
                        items={detail?.detail_status?.tracking_data?.map((item) => ({
                          children: (
                            <div
                            dangerouslySetInnerHTML={{
                              __html: ` ${item.web_wording} <br />${item.submitted_date ? item.submitted_date : "-"}`,
                            }}
                            />
                          ),
                        })).concat([
                          {
                            children: (
                              <div>
                                {detail?.request_datetime ? (
                                  <div>
                                    Time of requested
                                    <br />
                                    {detail.request_datetime}
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </div>
                            ),
                          },
                        ])}
                      />
                
                  </div>
                </div>
              </Col>
                 
                    
                </Row>
            </div>
        </>
    )
}

export default OvertimeTransactionDetail