import { Button, Col, Collapse, message, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined ,SettingOutlined} from "@ant-design/icons";
import { getMOvertimeDetail } from "../../../api/movertime";
import { formatCurrency } from "../../../helper/formatCurrency";
const MOvertimeDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    let n = location.lastIndexOf('/');
    let result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    let dec = decode.lastIndexOf('?');
    let id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)

    const overtimeList = async () => {
		try {
		const res = await getMOvertimeDetail(id)
		setDetail(res?.data?.data);
		} catch (err) {
			message.error({content: "Request Not Found"});
        }
	};
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else{
            overtimeList();
        }
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data - Overtime",
			url: "/overtime"
		},
        {
			title: "Overtime Detail",
			url: `/overtime/detail/${result}`
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/overtime')} icon={<ArrowLeftOutlined />} size={"large"} />
				Overtime Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Overtime Name</div>
                            <div className="subtitle">{detail?.name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Compensation Type</div>
                            {detail.compensation_type == 'paid' ?
                            <div className="subtitle">Paid Overtime</div>:
                            <div className="subtitle">Leave Overtime</div>}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Minimum Overtime (Minutes)</div>
                            <div className="subtitle">{`${detail.min_minute} Minutes`}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Maximum Overtime (Hours) </div>
                            <div className="subtitle">{`${detail.max_hour} Hours`}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Rounding</div>
                            <div className="subtitle mb-3">{`${detail.rounding>0?'Yes':'No'}`}</div>
                            {detail.rounding === 1 && (
                            <>
                            {detail?.rounding_arr?.length > 0 ? (
                                detail?.rounding_arr.map((rounding, index) => (
                                    <div className="flex flex-row gap-2">
                            <div className="subtitle" key={index}>{`${rounding?.start_minute} Minutes`}</div>
                            <div className="subtitle" key={index}>{`to ${rounding?.end_minute} Minutes`}</div>
                            <div className="subtitle" key={index}>{`= ${rounding?.val_minute}`}</div>
                            </div>
                            ))
                            ) : (
                                <div className="subtitle">-</div>
                            )}
                            </>
                            )}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Description</div>
                            <div className="subtitle">{detail?.description || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                    <div className="detail-card">
                            <div className="title">Applies to</div>
                            <div className="subtitle">{detail?.applies_to}</div>
                     </div>
                    </Col>
                    <Col span={12}></Col>
                    <>
                    {detail?.applies_to != "Default" ? (
                        <>
                        <Col span={12}>
                        <div className="detail-card">
                        <div className="title">Level</div>
                        {detail?.applies_to_level?.length > 0 ? (
                            detail?.applies_to_level.map((level, index) => (
                            <div className="subtitle" key={index}>{`${level}`}</div>
                            ))
                        ) : (
                            <div className="subtitle">-</div>
                        )}
                        </div>
                        </Col>
                        <Col span={12}>
                        <div className="detail-card">
                        <div className="title">Position</div>
                        {detail?.applies_to_position?.length > 0 ? (
                            detail?.applies_to_position.map((level, index) => (
                                <div className="subtitle" key={index}>{`${level}`}</div>
                                ))
                                ) : (
                                    <div className="subtitle">-</div>
                                    )}
                            </div>
                        </Col>
                    </>
                    ):null}
                    </>
                <Col className="mt-5" span={24}>
                {detail.compensation_type == 'paid' ? (
            <Collapse  expandIcon={() => null} defaultActiveKey={['1']}>
            <Collapse.Panel disabled header={
                <div>
                    <SettingOutlined style={{ marginRight: '8px' }} />
                    Overtime Settings
                </div>
                    }
                    key="1"
                >
                <>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                                <div className="title">Day Type</div>
                                <div className="subtitle">{detail?.day_type}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                                <div className="title">Rate Type</div>
                                <div className="subtitle">
                                {detail?.rate_type === 'formula' ? 'Formula' : detail?.rate_type === 'custom' ? 'Custom' : 'Flat Per Day'}
                        </div>
                        </div>
                        {detail?.rate_type === 'custom' ? (

                    <div className="detail-card">
                        <div className="subtitle">
                        {detail?.custom_arr?.map((item, index) => (
                            <div key={index}>
                            {item.type} {item.time.replace(":00","")} = {formatCurrency(item?.allowance)}
                            </div>
                        ))}
                        </div>
                    </div>
                    ):null}
                    {detail?.rate_type === 'flat' ? (
                        <div className="detail-card">
                            <div className="title">Workdays Allowance</div>
                            <div className="subtitle">{formatCurrency(detail?.flat_allowance)}</div>
                        </div>
                    ):null}
                    {detail?.rate_type === 'formula' ? (
                        <div className="detail-card">
                            <div className="title">Ratio</div>
                            <div className="subtitle">
                            {detail?.formula_arr?.map((item, index) => (
                            <div key={index}>
                                Ratio {item.ratio}
                            </div>
                    ))}
                        </div>
                        </div>
                    ):null}
                    </Col>
                </Row>
                    <div className="mt-5">
                    </div>
                </>
            </Collapse.Panel>
            </Collapse>
                    ):null}
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default MOvertimeDetail