/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Form as FormAntd,
  message,
  Row,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  InputWithLabel,
  SelectOptionWithLabel,
  SelectSearchWithLabel,
} from "../../component/Form/Input";
import { json, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import "./styles.scss";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import dayjs from "dayjs";
import {
  AddBPersonalAssessmentMaster,
  getListPersonalAssessmentBranchSelect,
  getListPersonalAssessmentDepartmentSelect,
  getListPersonalAssessmentDivisionSelect,
  getListPersonalAssessmentEmployeeSelect,
  getListPersonalAssessmentGradeSelect,
  getListPersonalAssessmentLevelSelect,
  getListPersonalAssessmentPositionSelect,
  getListPersonalAssessmentSpvSelect,
} from "../../api/personalAssessment";
const FormAddPersonalAssessmentMaster = () => {
  const navigate = useNavigate();
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));

  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  const branchCode = branchSession || branch !== null ? branch : branchDefault;

  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [valueBranch, setValueBranch] = useState([]);
  const [valueDepartment, setValueDepartment] = useState([]);
  const [valueDivision, setValueDivision] = useState([]);
  const [valuePosition, setValuePosition] = useState([]);
  const [valueLevel, setValueLevel] = useState([]);
  const [valueGrade, setValueGrade] = useState([]);
  const [valueDirect, setValueDirect] = useState([]);

  const validationUser = yup.object().shape({
    employeeNumber: yup.string().required("Field is required"),
    requestDate: yup.string().required("Field is required"),
    period: yup.string().required("Field is required"),
    type: yup.string().required("Field is required"),
    recommendedBranch: yup.string().required("Field is required"),
    recommendedDepartament: yup.string().required("Field is required"),
    recommendedDivision: yup.string().required("Field is required"),
    recommendedPosition: yup.string().required("Field is required"),
    recommendedLevel: yup.string().required("Field is required"),
    recommendedGrade: yup.string().required("Field is required"),
    recommendedDirectSPV: yup.string().required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      employeeNumber: "",
      requestDate: "",
      period: "",
      type: "",
      recommendedBranch: "",
      recommendedDepartament: "",
      recommendedDivision: "",
      recommendedPosition: "",
      recommendedLevel: "",
      recommendedGrade: "",
      recommendedDirectSPV: "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = await AddBPersonalAssessmentMaster(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: "Request personal assessment successfully submitted",
          });
          navigate("/personal-assessment");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Request personal assessment already exis",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Performance Management - Personal Assessment",
      url: "/personal-assessment",
    },
    {
      title: "Add Personal Assessment",
      url: "",
    },
  ];

  const handleSelectChange = (value) => {
    form.setValues({
      ...form.values,
      employeeNumber: value,
    });

    const selectedData = options.find(
      (option) => option.employee_number === value
    );
    if (selectedData) {
      form.setFieldValue(
        "EmployeeName",
        selectedData ? selectedData.full_name : ""
      );
      form.setFieldValue(
        "employeeType",
        selectedData ? selectedData.employee_type : ""
      );
      form.setFieldValue(
        "currentBranch",
        selectedData ? selectedData.branch_name : ""
      );
      form.setFieldValue(
        "currentDepartment",
        selectedData ? selectedData.department_name : ""
      );
      form.setFieldValue(
        "currentDivision",
        selectedData ? selectedData.division_name : ""
      );
      form.setFieldValue(
        "currentPosition",
        selectedData ? selectedData.position_name : ""
      );
      form.setFieldValue(
        "currentLevel",
        selectedData ? selectedData.level_name : ""
      );
      form.setFieldValue(
        "currentGrade",
        selectedData ? selectedData.grade_value : ""
      );
      form.setFieldValue(
        "currentDirectSPV",
        selectedData ? selectedData.upline_name : ""
      );
    } else {
      form.setFieldValue("EmployeeName");
      form.setFieldValue("employeeType");
      form.setFieldValue("currentBranch");
      form.setFieldValue("currentDepartment");
      form.setFieldValue("currentDivision");
      form.setFieldValue("currentPosition");
      form.setFieldValue("currentLevel");
      form.setFieldValue("currentGrade");
      form.setFieldValue("currentDirectSPV");
    }
    fetchBranchList();
    fetchDepartmentList();
    fetchLevelList(companyStore, selectedData?.employee_number);

    form.setFieldValue("recommendedBranch", "");
    form.setFieldValue("recommendedBranch", "");
    form.setFieldValue("recommendedDepartment", "");
    form.setFieldValue("recommendedDivision", "");
    form.setFieldValue("recommendedPosition", "");
    form.setFieldValue("recommendedLevel", "");
    form.setFieldValue("recommendedGrade", "");
    form.setFieldValue("recommendedDirectSPV", "");
    form.setFieldValue("period", "");
    form.setFieldValue("type", "");
    form.setFieldValue("requestDate", "");
  };

  const fieldsAndSelectOptions = [
    {
      input: {
        label: "Current Branch",
        name: "branch",
        placeholder: "Current Branch",
        value: form.values.currentBranch || null,
      },
      select: {
        name: "recommendedBranch",
        label: "Recommended Branch",
        value: form.values.recommendedBranch,
        items: valueBranch?.map((item) => ({
          value: item.branch_id,
          title: item.name,
        })),
        error: "recommendedBranch",
      },
    },
    {
      input: {
        label: "Current Department",
        name: "department",
        placeholder: "Current Department",
        value: form.values.currentDepartment || null,
      },
      select: {
        name: "recommendedDepartament",
        label: "Recommended Department",
        items: valueDepartment?.map((item) => ({
          value: item.department_id,
          title: item.name,
        })),
        error: "recommendedDepartament",
      },
    },
    {
      input: {
        label: "Current Division",
        name: "division",
        placeholder: "Current Division",
        value: form.values.currentDivision || null,
      },
      select: {
        name: "recommendedDivision",
        label: "Recommended Divison",
        items: valueDivision?.map((item) => ({
          value: item.division_id,
          title: item.name,
        })),
        error: "recommendedDivision",
      },
    },
    {
      input: {
        label: "Current Position",
        name: "position",
        placeholder: "Current Position",
        value: form.values.currentPosition || null,
      },
      select: {
        name: "recommendedPosition",
        label: "Recommended Position",
        items: valuePosition?.map((item) => ({
          value: item.position_id,
          title: item.name,
        })),
        error: "recommendedPosition",
      },
    },
    {
      input: {
        label: "Current Level",
        name: "level",
        placeholder: "Current Level",
        value: form.values.currentLevel || null,
      },
      select: {
        name: "recommendedLevel",
        label: "Recommended Level",
        items: valueLevel?.map((item) => ({
          value: item.level_id,
          title: item.name,
        })),
        error: "recommendedLevel",
      },
    },
    {
      input: {
        label: "Current Grade",
        name: "grade",
        placeholder: "Current Grade",
        value: form.values.currentGrade || null,
      },
      select: {
        name: "recommendedGrade",
        label: "Recommended Grade",
        items: valueGrade?.map((item) => ({
          value: item.id,
          title: item.grade,
        })),
        error: "recommendedGrade",
      },
    },
    {
      input: {
        label: "Current Direct SPV",
        name: "directSPV",
        placeholder: "Current Direct SPV",
        value: form.values.currentDirectSPV || null,
      },
      select: {
        name: "recommendedDirectSPV",
        label: "Recommended Direct SPV",
        items: valueDirect?.map((item) => ({
          value: item.employee_id,
          title: item.full_name,
        })),
        error: "recommendedDirectSPV",
      },
    },
  ];

  const employeeNumberActive = async () => {
    try {
      const res = await getListPersonalAssessmentEmployeeSelect(
        companyStore,
        JSON.parse(branchCode)
      );
      const data = res?.data?.data;
      setOptions(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchBranchList = async () => {
    try {
      const res = await getListPersonalAssessmentBranchSelect(companyStore);
      const data = res?.data?.data;
      setValueBranch(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchDepartmentList = async () => {
    try {
      const res = await getListPersonalAssessmentDepartmentSelect(companyStore);
      const data = res?.data?.data;
      setValueDepartment(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchDivisionList = async (id) => {
    try {
      const res = await getListPersonalAssessmentDivisionSelect(id);
      const data = res?.data?.data;
      setValueDivision(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchPositionList = async (id) => {
    try {
      const res = await getListPersonalAssessmentPositionSelect(id);
      const data = res?.data?.data;
      setValuePosition(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchLevelList = async (companyNumber, id) => {
    try {
      const res = await getListPersonalAssessmentLevelSelect(companyNumber, id);
      const data = res?.data?.data;
      setValueLevel(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchGradeList = async (id) => {
    try {
      const res = await getListPersonalAssessmentGradeSelect(id);
      const data = res?.data?.data;
      setValueGrade(data);
    } catch (err) {
      console.error(err);
    }
  };
  const fetchSpvList = async (companyNumber, id) => {
    try {
      const res = await getListPersonalAssessmentSpvSelect(companyNumber, id);
      const data = res?.data?.data;
      setValueDirect(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDivisionList(form.values?.recommendedDepartament);
    form.setFieldValue("recommendedDivision", null);
  }, [form.values?.recommendedDepartament]);

  useEffect(() => {
    fetchPositionList(form.values?.recommendedDivision);
    form.setFieldValue("recommendedPosition", null);
  }, [form.values?.recommendedDivision]);

  useEffect(() => {
    fetchGradeList(form.values?.recommendedLevel);
    form.setFieldValue("recommendedGrade", null);
  }, [form.values?.recommendedLevel]);
  useEffect(() => {
    fetchSpvList(companyStore, form.values?.recommendedLevel);
    form.setFieldValue("recommendedGrade", null);
  }, [form.values?.recommendedLevel]);

  useEffect(() => {
    employeeNumberActive();
    if (form.values.employeeNumber) {
      fetchDivisionList();
      fetchBranchList();
      fetchDepartmentList();
      fetchLevelList();
    }
  }, []);

  return (
    <>
      <div className="title-section">Add Personal Assessment</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectSearchWithLabel
                      name="employeeNumber"
                      labelname={
                        <div>
                          Employee Name
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      showSearch
                      allowClear
                      placeholder="Select Employee"
                      optionFilterProp="children"
                      onChange={handleSelectChange}
                      value={form.values.employeeNumber || null}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {options?.map((option) => (
                        <Select
                          key={option.employee_number}
                          value={option.employee_number}>
                          {option.employee_name}
                        </Select>
                      ))}
                    </SelectSearchWithLabel>
                    {form.touched.employeeNumber &&
                    form.errors.employeeNumber ? (
                      <span className="text-error relative top-2">
                        {form.errors.employeeNumber}
                      </span>
                    ) : null}
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <InputWithLabel
                    label={"Employement Status"}
                    disabled
                    maxWidth={10}
                    name="code"
                    placeholder="Employement status"
                    value={form.values.employeeType}
                    onBlur={form.handleBlur}
                    maxLength={20}
                  />
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="text-slate-500 label-input flex gap-1">
                Request Date
                <span className="text-red-500">*</span>
              </div>
              <DatePicker
                name="requestDate"
                format="DD-MM-YYYY"
                placeholder="Select date"
                allowClear={true}
                value={
                  form.values.requestDate
                    ? dayjs(form.values.requestDate, "YYYY-MM-DD")
                    : null
                }
                onChange={(date) => {
                  const formattedDate = date
                    ? dayjs(date).format("YYYY-MM-DD")
                    : null;
                  form.setFieldValue("requestDate", formattedDate);
                }}
                className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
              />
              {form.touched.requestDate && form.errors.requestDate && (
                <span className="text-error">{form.errors.requestDate}</span>
              )}
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="text-slate-500 label-input flex gap-1">
                Period
                <span className="text-red-500">*</span>
              </div>
              <DatePicker
                name="period"
                format="MMMM"
                placeholder="Select date"
                picker="month"
                allowClear={true}
                value={
                  form.values.period
                    ? dayjs(form.values.period, "YYYY-MM")
                    : null
                }
                onChange={(date) => {
                  const formattedDate = date
                    ? dayjs(date).format("YYYY-MM")
                    : null;
                  form.setFieldValue("period", formattedDate);
                }}
                className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
              />

              {form.touched.period && form.errors.period && (
                <span className="text-error">{form.errors.period}</span>
              )}
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabel
                      name="type"
                      label={
                        <div>
                          Type
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "Promotion",
                          title: "Promotion",
                        },
                        {
                          value: "Talent Mapping",
                          title: "Talent Mapping ",
                        },
                      ]}
                      value={form?.values?.type ? form?.values?.type : null}
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          type: val,
                        });
                      }}
                      placeholder="Select one"
                      onBlur={form.handleBlur}
                    />
                    {form.touched.type && form.errors.type ? (
                      <span className="text-error relative top-2">
                        {form.errors.type}
                      </span>
                    ) : null}
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <>
            {fieldsAndSelectOptions?.map((fieldAndSelect, index) => (
              <Row key={index} justify={"space-between"}>
                <Col span={11}>
                  <FormAntd.Item>
                    <div className="flex flex-col">
                      <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                        <div className="flex-grow">
                          <InputWithLabel
                            label={fieldAndSelect.input.label}
                            maxWidth={10}
                            name={fieldAndSelect.input.name}
                            placeholder={fieldAndSelect.input.placeholder}
                            value={fieldAndSelect.input.value}
                            onBlur={form.handleBlur}
                            maxLength={25}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </FormAntd.Item>
                </Col>

                <Col span={12}>
                  <FormAntd.Item>
                    <SelectOptionWithLabel
                      name={fieldAndSelect?.select.name}
                      label={
                        <div>
                          {fieldAndSelect.select.label}
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      placeholder="Select one"
                      items={fieldAndSelect?.select?.items}
                      value={form?.values[fieldAndSelect?.select?.name] || null}
                      onChange={(val) =>
                        form.setValues({
                          ...form.values,
                          [fieldAndSelect.select.name]: val,
                        })
                      }
                      disabled={!form.values.employeeNumber}
                    />
                    {form.touched[fieldAndSelect.select.name] &&
                    form.errors[fieldAndSelect.select.name] ? (
                      <span className="text-error relative top-2">
                        {form.errors[fieldAndSelect.select.name]}
                      </span>
                    ) : null}
                  </FormAntd.Item>
                </Col>
              </Row>
            ))}
          </>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/personal-assessment")}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddPersonalAssessmentMaster;
