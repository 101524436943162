import { Button, Col, Form as FormAntd, message,Select } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { InputWithLabel, SelectMultipleWithLabel, SelectSearchWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { addRole, editRole, getFeatures, getRoleDetail } from "../../api/role";
import { getBranch } from "../../api/branch";
import { useNavigate } from "react-router-dom";
import { getAplication } from "../../api/application";
import { getLevel } from "../../api/level";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import { getCompany } from "../../api/company";

const FormAddRole = () => {
    const navigate = useNavigate();
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
    const role = decryptData(localStorage.getItem("Role"));
    const [fetching, setFetching] = useState(false);
    const validationRole = yup.object().shape({
        name: yup.string().test({
            name: 'conditionalRequired',
            exclusive: true,
            test: function(value) {
                return (role !== 'ROOT' && value && value.length > 0 && value.toUpperCase() !== 'ROOT') || (role === 'ROOT' && value && value.length > 0);
            },
            message: role !== 'ROOT'? "Field is required and cannot be equal to 'ROOT'": "Field is required",
        }),
        companyList: yup.array()
            .test({
            name: 'conditionalRequired',
            exclusive: true,
            test: function(value) {
                const name = this.parent.name;

                if( role === 'ROOT'){
                    if(name !== undefined && name.toUpperCase() === 'ROOT'){
                        return true
                    }
                    return Array.isArray(value) && value.length > 0;
                }
                return true
            },
            message: "Field is required",
        }).of(yup.string().max(255)),
        branch: yup.array().test({
            name: 'conditionalRequired',
            exclusive: true,
            test: function(value) {
                const name = this.parent.name;
                const companyList = this.parent.companyList;
                if( role === 'ROOT'){
                    if(name !== undefined && name.toUpperCase() === 'ROOT' || Array.isArray(companyList) && companyList.length>1){
                        return true
                    }
                }
                return Array.isArray(value) && value.length > 0
            },
            message: "Field is required",
        }).of(yup.string().max(255)),
        application: yup.array().test({
            name: 'conditionalRequired',
            exclusive: true,
            test: function(value) {
                return Array.isArray(value) && value.length > 0
            },
            message: "Application field must have at least 1 items",
        }).of(yup.string().max(255)),
        // application: yup.array().of(yup.string().max(255)).min(1).required("Field is required"),
    });
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const location = window.location.href;
    var n = location.split("/");
    var result = n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState({});
    const [detailTemp, setDetailTemp] = useState({});
    const [flag, setFlag] = useState(0);
    const [listBranch, setListBranch] = useState([]);
    const [listCompany, setListCompany] = useState([]);
    const [listApplication, setListApplication] = useState([]);
    const [optionsLevel, setOptionsLevel] = useState([]);
    const [feature, setFeature] = useState([])
    const { Option } = Select;
    const BestpraticeUseEffect = useRef(false)
    const BestpraticeUseEffect1 = useRef(false)
    const BestpraticeUseEffect2 = useRef(false)
    const BestpraticeUseEffect3 = useRef(false)
    const BestpraticeUseEffect4 = useRef(false)

    const roleList = async () => {
        setFetching(true);
        try {
            const res = await getRoleDetail(id)
            const detail = res?.data?.data

            if(detail.branch){
                let branchDetail = JSON.parse(res?.data?.data?.branch);
                const resBranch = await getBranch("", 100, 0, 1, company)
                const branchs = resBranch?.data?.data

                const branchCodes = branchs.map(branch => branch.code);

                detail.branch = JSON.stringify(branchDetail.filter(code => branchCodes.includes(code)));
            }
            setDetailTemp(detail);
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if (result) {
                roleList();
            }
          }
      
    }, [flag]);

    // List feature
    const featureList = async () => {
        setFetching(true);
        try {
            const res = await getFeatures();
            const data = res?.data?.data
            setFeature(res?.data?.data)

            if (result) {
                if (detailTemp.privileges && detailTemp.privileges.length > 0) {
                    detailTemp.privileges.forEach(datum => {
                        const index = feature.findIndex(x => x.feature_id === datum.feature_id)
                        if (index >= 0) {
                            feature[index].access = datum.access
                        }
                    })
                }

                detailTemp['privilege'] = feature
                setDetail(detailTemp)
            }
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect1.current) {
            BestpraticeUseEffect1.current = true;
          } else {
            featureList();
          }
       
    }, [detailTemp]);


    const [selectedLevel, setSelectedLevel] = useState('');

    const handleSelectChangeLevel = (value) => {
        if (value === 'no-level') {
            setSelectedLevel('No Level'); // Perbarui selectedLevel jika memilih 'no-level'
        } else {
            setSelectedLevel(value);
        }
        form.setValues({
            ...form.values,
            level: value,
        });
    };
    
    
    const form = useFormik({
        initialValues: {
            // code: result ? detail.code : "",
            name: result ? detail.name : "",
            branch: result ? detail.branch ? JSON.parse(detail.branch) : [] : [],
            companyList: result ? detail.company_list ? JSON.parse(detail.company_list) : [] : [],
            company: result ? detail.company : companyStore ? companyStore: deCompanyCode,
            application: result ? detail.application ? JSON.parse(detail.application) : [] : [],
            privilege: result ? detail.privilege ? detail.privilege : feature : feature,
            level: result ? detail.allowed_payroll ? detail.allowed_payroll : "" : "",
        },
        enableReinitialize: true,
        validationSchema: validationRole,
        onSubmit: async (values) => {
            setFetching(true)
            try {
                const res = result ? await editRole(values, id) : await addRole(values);
                if (!res?.data?.success) {
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Access role successfully updated" : "Access role successfully saved",
                    });
                    navigate('/role');
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                }
                setFetching(false)
            } catch (error) {
                if (error.response.data) {
                    message.error({
                        content: error.response.data.error,
                    });
                } else {
                    message.error({
                        content: "Request not found",
                    });
                }
                setFetching(false)
            }
        },
    });
    useEffect(() => {
        form.resetForm();
    }, [])

    const branchList = async () => {
        setFetching(true);
        try {
            const res = await getBranch("", 100, 0, 1, company)
            const data = res?.data?.data
            setListBranch(data.map((item) => {
                return {
                    value: item.code,
                    title: item.name
                }
            }))
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    const companyList = async () => {
        setFetching(true);
        try {
            const res = await getCompany("", 1000, 0, 1, 1)
            const data = res?.data?.data
            setListCompany(data.map((item) => {
                return {
                    value: item.code,
                    title: item.name
                }
            }))
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect2.current) {
            BestpraticeUseEffect2.current = true;
          } else {
            branchList();
            companyList();
          }
       
    }, []);

    // List Aplications
    const aplicationList = async () => {
        setFetching(true);
        try {
            const res = await getAplication("", 100, 0, 1, "")
            const data = res?.data?.data
            setListApplication(data.map((item) => {
                return {
                    value: item.code,
                    title: item.name
                }
            }))
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect3.current) {
            BestpraticeUseEffect3.current = true;
          } else {
            aplicationList();
          }
      
    }, []);

    //List Levels

    const levelList = async () => {
        setFetching(true);
		try {
		  const res = await getLevel("", -1, -1, 1, company)
          const data = res?.data?.data
          setOptionsLevel(data)
		} catch (err) {
		  console.log(err);
		} finally {
            setFetching(false);
		}
	};
	
	useEffect(() => {
        if (!BestpraticeUseEffect4.current) {
            BestpraticeUseEffect4.current = true;
          } else {
            levelList();
          }
	}, []);

    const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/role"
		},
		{
			title: "Access Role",
			url: "/role"
		},
		{
			title: `${result ?  'Edit Access Role' : 'Add Access Role'}` ,
			// url: `${ result ?  '/role/edit-role' : '/company/add-role'}`
		},
	]

    return (
       <>
        <div className="title-section">
                {result ? "Edit Access Role" : "Add Access Role"}
        </div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                <div className="title-divider">Role Info</div>
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Name<span style={{color:"#FF0000"}}>*</span></div>}
                        placeholder="Name"
                        value={form?.values?.name}
                        // onChange={form.handleChange}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.name && form.errors.name ? (
                        <span className="text-error">{form.errors.name}</span>
                    ) : null}
                </FormAntd.Item>
                { role  === 'ROOT' ? form.values.name != undefined && form.values.name.toUpperCase()  !== 'ROOT' ? (
                    <FormAntd.Item>
                        <SelectMultipleWithLabel
                            name="companyList"
                            label={<div>Company List<span style={{color:"#FF0000"}}>*</span></div>}
                            items={listCompany}
                            placeholder="Select Company"
                            value={form?.values?.companyList}
                            onChange={(val) =>
                                form.setValues({
                                    ...form.values,
                                    companyList: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        {form.touched.branch && form.errors.companyList ? (
                            <span className="text-error">{form.errors.companyList}</span>
                        ) : null}
                    </FormAntd.Item>): (
                    <FormAntd.Item>
                        <SelectMultipleWithLabel
                            name="companyList"
                            disabled
                            label={<div>Company List<span style={{color:"#FF0000"}}>*</span></div>}
                            items={[{
                                value: "",
                                title: "All Company"
                            }]}
                            placeholder="Select Company"
                            value={""}
                            onBlur={form.handleBlur}
                        />
                        {form.touched.branch && form.errors.companyList ? (
                            <span className="text-error">{form.errors.companyList}</span>
                        ) : null}
                    </FormAntd.Item>)
                    : null
                }
                { !(form.values.name != undefined && form.values.name.toUpperCase()  === 'ROOT' || form.values.companyList.length > 1 )? (
                    <FormAntd.Item>
                        <SelectMultipleWithLabel
                            name="branch"
                            label={<div>Branch<span style={{color:"#FF0000"}}>*</span></div>}
                            items={listBranch}
                            // placeholder="Select Branch"
                            value={form?.values?.branch}
                            onBlur={form.handleBlur}
                            onChange={(val) =>
                                form.setValues({
                                    ...form.values,
                                    branch: val,
                                })
                            }
                            
                        />
                        {form.touched.branch && form.errors.branch ? (
                            <span className="text-error">{form.errors.branch}</span>
                        ) : null}
                    </FormAntd.Item>): (
                        <FormAntd.Item>
                        <SelectMultipleWithLabel
                            name="branch"
                            disabled
                            label={<div>Branch<span style={{color:"#FF0000"}}>*</span></div>}
                            items={[{
                                value: "",
                                title: "All Branch"
                            }]}
                            placeholder="Select Branch"
                            value={""}
                            onBlur={form.handleBlur}
                            
                        />
                        {form.touched.branch && form.errors.branch ? (
                            <span className="text-error">{form.errors.branch}</span>
                        ) : null}
                    </FormAntd.Item>
                    )
                }
                <FormAntd.Item>
                    <SelectMultipleWithLabel
                        name="aplication"
                        label={<div>Aplication<span style={{color:"#FF0000"}}>*</span></div>}
                        items={listApplication}
                        value={form?.values?.application}
                        onChange={(val) =>
                            form.setValues({
                                ...form.values,
                                application: val,
                            })
                        }
                        onBlur={form.handleBlur}
                    />
                    {form.touched.application && form.errors.application ? (
                        <span className="text-error">{form.errors.application}</span>
                    ) : null}
                </FormAntd.Item>
                {form.values.name !== undefined && form.values.name.toUpperCase()  === 'ROOT'? (<FormAntd.Item>
                        <SelectSearchWithLabel 
                            allowClear
                                labelname={<div className="hover-text">Allowed Level Payroll <span className="tooltip-text">Don't fill when you want to set this role to 'No-Level Payroll Allowed'</span></div>}
                                name="level"
                                disabled
                                onChange={handleSelectChangeLevel}
                                placeholder="Allowed Level Payroll" 
                                value={"ALL"}
                                onBlur={form.handleBlur}
                        >
                            <Option value="ALL">ALL</Option>
                        </SelectSearchWithLabel>
                        {form.touched.level && form.errors.level ? (
                            <span className="text-error">{form.errors.level}</span>
                        ) : null}
                    </FormAntd.Item>):
                    (<FormAntd.Item>
                        <SelectSearchWithLabel 
                            allowClear
                                labelname={<div className="hover-text">Allowed Level Payroll <span className="tooltip-text">Don't fill when you want to set this role to 'No-Level Payroll Allowed'</span></div>}
                                name="level"
                                onChange={handleSelectChangeLevel}
                                placeholder="Allowed Level Payroll" 
                                value={selectedLevel ? selectedLevel :  detail.allowed_payroll ? selectedLevel ? selectedLevel :  detail.allowed_payroll  : "no-level"}
                                onBlur={form.handleBlur}
                        >
                                {role == 'ROOT' ?(<Option value="ALL">ALL</Option>):null}
                                {optionsLevel.map(option => (
                                    <Option key={option.code} value={option.code}>{option.name}</Option>
                                ))} 
                                <Option value="no-level">No Level</Option>
                        </SelectSearchWithLabel>
                        {form.touched.level && form.errors.level ? (
                            <span className="text-error">{form.errors.level}</span>
                        ) : null}
                    </FormAntd.Item>)
                }
                {form.values.application.includes('HRIS') && (form.values.name !== undefined && form.values.name.toUpperCase()) !== 'ROOT' ? (
                    <div>
                        <div className="title-divider">Menu Access</div> 
                        {feature && feature.map(datum => {
                            return (
                                <FormAntd.Item>
                                    <SelectMultipleWithLabel
                                        name={datum.name}
                                        label={datum.title}
                                        items={datum.permissionList}
                                        value={form?.values?.privilege[form?.values?.privilege.findIndex(x => x.feature_id === datum.feature_id)]?.access}
                                        onChange={(val) => {
                                            let privilegeData = JSON.parse(JSON.stringify(form.values.privilege))
                                            privilegeData[form?.values?.privilege.findIndex(x => x.feature_id === datum.feature_id)].access = val
                                            form.setValues({
                                                ...form.values,
                                                privilege: privilegeData
                                            })
                                        }

                                        }
                                        onBlur={form.handleBlur}
                                    />
                                </FormAntd.Item>
                            )
                        })}
                    </div>
                ) : null}
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{ marginRight: "14px" }} onClick={() => navigate('/Role')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
       </>
    )
}

export default FormAddRole;