import { master } from "./hris";

export const getSuggestion = async (search) => {
  let payload = {
    search: search,
  }
  return master.post("/autocomplete-address", payload);
}

export const getPlaceDetail = async (placeId, sessionToken) => {
  let payload = {
    place_id: placeId,
    session_token: sessionToken
  }
  return master.post("/address-detail", payload);
}