import CryptoJS from 'crypto-js';
const secretKey = 'wertyuidbq72361238gjajsdasdasd78asd8as7d8a6sd86a8s6d8as6d';
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (encryptedData) => {
  try {
    if (typeof encryptedData === 'string') {
      //  keterangan : Tambahkan pengujian apakah encryptedData adalah sebuah string
      const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
      const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } else {
      return null; 
      // keterangan : Kembalikan objek kosong jika encryptedData bukan sebuah string
    }
  } catch (error) {
    console.error('Gagal mendekripsi data cuy:', error);
    return null; // keterangan :  Kembalikan objek kosong jika terjadi kesalahan
  }
};