import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getCompany = async (search, limit, offset, page, active,exception) => {
    let payload = {
        search_by : "name",
        search_value : search?.length > 0 ? search : undefined,
        status : active,
        start : search?.length > 0 ? 0 : offset,
        length : search?.length > 0 ? 0 :limit,
        exception : exception?.length > 0 ? exception : "",
        draw : page
    }
    return master.post("/company",payload);
}

export const addCompany = async (values) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        name: values.companyName, 
        description: 'add',
        email: values.email,
        address: values.address,
        province: values.province,
        postal_code: values.postalCode,
        city: values.city,
        phone_number: values.companyPhoneNumber,
        industry: values.industry,
        status: values.status,
        company_npwp: values.companyNpwp,
        company_tax_date: values.companyTaxDate,
        tax_person_name: values.taxPersonName,
        tax_person_npwp: values.taxPersonNpwp,
        contact_person_name: values.contactPersonName,
        contact_person_number: values.contactPersonNumber,
        cut_off_type: values.cut_off_type,
        cut_off_date: values.cut_off_date,
        divisor_proportional: values.divisor_proportional,
        divisor_include_holiday: values.divisor_include_holiday,
        divisor_custom_number: values.divisor_custom_number,
        working_day: JSON.stringify(values.working_day),
        proration: values.proration,
        // absence_cal_ref: values.absence_cal_ref,
        // absence_cal_ref_amount: values.absence_cal_ref_amount,
        // absence_cal_ref_custom: values.absence_cal_ref_custom,
        // calculation_base: values.calculation_base,
        created_by: user,
        company_logo_url: values.companyLogo,
        company_template_payroll: values.companyTemplatePayroll,
        contact_person_email: values.contactPersonEmail
    }
    return master.post("/company-store", payload);
}

export const getCompanyDetail = async (id) => {
    let payload = {
        company_id: id,
    }
    return master.post("/company-detail", payload);
}

export const deleteCompany = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id: id,
        deleted_by: user
    }
    return master.post("/company-delete", payload);
}

export const editCompany = async (values, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id : id,
        name: values.companyName, 
        description: 'edit',
        email: values.email,
        address: values.address,
        province: values.province,
        postal_code: values.postalCode,
        city: values.city,
        phone_number: values.companyPhoneNumber,
        industry: values.industry,
        status: values.status,
        company_npwp: values.companyNpwp,
        company_tax_date: values.companyTaxDate,
        tax_person_name: values.taxPersonName,
        tax_person_npwp: values.taxPersonNpwp,
        contact_person_name: values.contactPersonName,
        contact_person_number: values.contactPersonNumber,
        cut_off_type: values.cut_off_type,
        cut_off_date: values.cut_off_date,
        divisor_proportional: values.divisor_proportional,
        divisor_include_holiday: values.divisor_include_holiday,
        divisor_custom_number: values.divisor_custom_number,
        working_day: JSON.stringify(values.working_day),
        proration: values.proration,
        // absence_cal_ref: values.absence_cal_ref,
        // absence_cal_ref_amount: values.absence_cal_ref_amount,
        // absence_cal_ref_custom: values.absence_cal_ref_custom,
        // calculation_base: values.calculation_base,
        modified_by: user,
        company_logo_url: values.companyLogo ? values.companyLogo : null,
        company_template_payroll: values.companyTemplatePayroll ? values.companyTemplatePayroll : null,
        contact_person_email: values.contactPersonEmail
    }
    return master.post("/company-update", payload);
}

export const editCompanyAbsenceSetting = async (values, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id : id,
        absence_cal_ref: values.absence_cal_ref,
        absence_cal_ref_amount: values.absence_cal_ref_amount,
        absence_cal_ref_custom: values.absence_cal_ref_custom,
        calculation_base: values.calculation_base,
        modified_by: user,
    }
    return master.post("/company-absence-setting-update", payload);
}

export const getCompanyAbsenceSetting = async (id) => {
    let payload = {
        company_id: id,
    }
    return master.post("/company-absence-setting", payload);
}

export const roleCompany = async (companys) => {
    let payload = {
        company: companys
    }
    return master.post("/company-role", payload);
}