import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const getLoanTransaction = async (
  company,
  active,
  page,
  offset,
  limit,
  search
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    isActive: active,
    draw: page,
    start: offset,
    length: limit,
    search_by: "name",
    search_value: search?.length > 0 ? search : undefined,
  };
  return master.post("/mloan", payload);
};

const getListDropdownListInputLoan = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/list-level-input", payload);
};

const AddLoanSetting = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    name: data.name,
    companyCode: data.companyCode,
    max_tenor: data.max_tenor,
    max_loan: data.max_loan.replace(/,/g, ""),
    min_work_year: data.min_work_year,
    min_work_month: data.min_work_month,
    applies_to: data.applies_to,
    applies_to_level_id: JSON.stringify(data.applies_to_level_id),
    created_by: user,
  };

  return master.post("/mloan-create", payload);
};

const EditLoanSetting = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    m_loan_id: id,
    name: data.name,
    companyCode: data.companyCode,
    max_tenor: data.max_tenor,
    max_loan: data.max_loan.replace(/,/g, ""),
    min_work_year: data.min_work_year,
    min_work_month: data.min_work_month,
    applies_to: data.applies_to,
    applies_to_level_id: JSON.stringify(data.applies_to_level_id),
    is_active: data.is_active,
    modified_by: user,
  };

  return master.post("/mloan-update", payload);
};

const getLoanSettingDetail = async (id) => {
  let payload = {
    m_loan_id: id,
  };
  return master.post("/mloan-detail", payload);
};

const getLoanMangement = async (
  company,
  branch,
  active,
  page,
  offset,
  limit,
  search,
  status
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch : branchDefault,
    isActive: active,
    draw: page,
    start: offset,
    length: limit,
    search_by: "full_name",
    search_value: search?.length > 0 ? search : undefined,
    status: status,
  };
  return master.post("/loan", payload);
};
const UpdateLoanManagementId = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    loanId: data.loanId,
    loanInstallmentId: data.loanInstallmentId,
    paid_date: data.paid_date.split("-").reverse().join("-"),
    created_by: user,
  };
  return master.post("/loan-paid", payload);
};

const getDetailLoanManagement = async (id) => {
  let payload = {
    loanId: id,
  };
  return master.post("/loan-detail", payload);
};

const AddLoanManagement = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  const StartDate = sessionStorage.getItem("start_date");
  const EndDate = sessionStorage.getItem("end_date");
  const employee = sessionStorage.getItem("selectedIdShift");
  const valuecache = sessionStorage.getItem("cal_ref");
  const tenorCache = sessionStorage.getItem("tenor");
  const custom = sessionStorage.getItem("custom");
  const firstInstallmentCustom = sessionStorage.getItem(
    "first_installment_custom"
  );
  let payload = {
    employeeNumber: employee,
    m_loan_id: data.m_loan_id,
    loan_date: data.loan_date,
    loan_amount: parseInt(data.loan_amount.replace(/,/g, "")),
    disburse_date: data.disburse_date,
    cal_ref: data.cal_ref,
    installment: parseInt(data.installment.replace(/,/g, "")),
    tenor: valuecache === "period" ? data.tenor : Math.floor(tenorCache),
    first_installment_custom:
      custom === "true" && firstInstallmentCustom !== ""
        ? parseInt(data.first_installment_custom.replace(/,/g, ""))
        : null,
    first_installment_period:
      data.first_installment_period === 1
        ? "after"
        : data.first_installment_period === 0
        ? "same"
        : data.first_installment_period === ""
        ? null
        : null,
    start_date: StartDate,
    end_date: EndDate,
    description: data.description,
    attachment: data.attachment,
    createdBy: user,
  };

  return master.post("/loan-create", payload);
};
const DropdownMaterLoan = async (id) => {
  let payload = {
    employeeNumber: id || "",
  };

  return master.post("/mloan-list-input", payload);
};

export {
  getLoanTransaction,
  getListDropdownListInputLoan,
  AddLoanSetting,
  getLoanSettingDetail,
  EditLoanSetting,
  getLoanMangement,
  getDetailLoanManagement,
  UpdateLoanManagementId,
  AddLoanManagement,
  DropdownMaterLoan,
};
