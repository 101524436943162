import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getCompany = async (search, limit, offset, page, active,exception) => {
    let payload = {
        search_by : "name",
        search_value : search?.length > 0 ? search : undefined,
        status : active,
        start : search?.length > 0 ? 0 : offset,
        length : search?.length > 0 ? 0 :limit,
        exception : exception?.length > 0 ? exception : "",
        draw : page
    }
    return master.post("/company",payload);
}

export const addCompany = async (data, fileUpload) => {
    // var bodyFormData = new FormData();
    // bodyFormData.append('name', data.companyName);
    // bodyFormData.append('description', "add");
    // bodyFormData.append('email', data.email);
    // bodyFormData.append('address', data.address);
    // bodyFormData.append('province', data.province);
    // bodyFormData.append('postal_code', data.postalCode);
    // bodyFormData.append('city', data.city);
    // bodyFormData.append('phone_number:', '62' + data.companyPhoneNumber.toString());
    // bodyFormData.append('industry', data.industry);
    // bodyFormData.append('company_npwp', data.companyNpwp);
    // bodyFormData.append('company_tax_date', data.companyTaxDate);
    // bodyFormData.append('created_by', "SYSTEM");
    // bodyFormData.append('company_logo_url', fileUpload);
    // bodyFormData.append('contact_person_name', data.contactPersonName);
    // bodyFormData.append('contact_person_email', data.contactPersonEmail);
    // bodyFormData.append('contact_person_number', '62' + data.contactPersonNumber);
    // axios({
    //     method: "post",
    //     url: "https://tesapihris.waizly.id/api/company-store",
    //     data: bodyFormData,
    //     headers: { "Content-Type": "multipart/form-data" },
    // })
    // bodyFormData.append(' contact_person_name', contactPersonName);

    // let payload = {
    //     // parent_id: data.parentId,
    //     name: data.name,
    //     description: data.description,
    //     created_by: "test",
    //     // name : data.companyName,
    //     email: data.email,
    //     address: data.address,
    //     province: data.province,
    //     postal_code: data.postalCode,
    //     city: data.city,
    //     phone_number: '62' + data.companyPhoneNumber.toString(),
    //     // company_logo_url : fileUpload,
    //     industry : data.industry,
    //     company_npwp : data.companyNpwp,
    //     company_tax_date : data.companyTaxDate,
    //     tax_person_name : data.taxPersonName,
    //     tax_person_npwp : data.taxPersonNpwp,
    //     description : "JAWS",
    //     created_by : "SYSTEM"
    // }
    // return master.post("/company-store", payload);
}

export const getCompanyDetail = async (id) => {
    let payload = {
        company_id: id,
    }
    return master.post("/company-detail", payload);
}

export const deleteCompany = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id: id,
        deleted_by: user
    }
    return master.post("/company-delete", payload);
}

export const editCompany = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id: id,
        // parent_id: data.parentId,
        name: data.name,
        description: data.description,
        created_by:user,
        email: data.email,
        address: data.address,
        province: data.province,
        postal_code: data.postalCode.toString(),
        city: data.city,
        phone_number: '62' + data.companyPhoneNumber.toString(),
        // company_logo_url : fileUpload,
        industry : data.industry,
        company_npwp : data.companyNpwp,
        company_tax_date : data.companyTaxDate,
        tax_person_name : data.taxPersonName,
        tax_person_npwp : data.taxPersonNpwp,
        contact_person_name: data.contactPersonName,
        contact_person_email: data.contactPersonEmail,
        contact_person_number: '62' + data.contactPersonNumber,
        modified_by : user
    }
    return master.post("/company-update", payload);
}

export const roleCompany = async (companys) => {
    let payload = {
        company: companys
    }
    return master.post("/company-role", payload);
}