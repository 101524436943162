import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getBranch = async (search, limit, offset, page,company,exception) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        search_by : "name",
        search_value : search?.length > 0 ? search : undefined,
        exception : exception?.length > 0 ? exception : "",
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/branch",payload);
}

export const getBranchCompany = async (company) => {
    const companyId = decryptData(sessionStorage.getItem("selectCompanyId"));
    let payload = {
        company_id : companyId || company !== null ? company : ""
    }
    return master.post("/branch-getbycompany",payload);
}

export const addBranch = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id : data.companyCode,
        name : data.name,
        address : data.address,
        city : data.city,
        province : data.province,
        email : data.contactPersonEmail,
        latitude:data.latitude,
        longitude:data.longitude,
        checked: data.Checked,
        radius: data.radius,
        postal_code : data.postalCode,
        phone_number : data.contactPersonNumber,
        created_by : user
    }
    return master.post("/branch-store", payload);
}

export const getBranchDetail = async (id) => {
    let payload = {
        branch_id: id,
    }
    return master.post("/branch-detail", payload);
}

export const deleteBranch = async (id) => {
    let payload = {
        branch_id: id,
        "deleted_by" : "orang"
    }
    return master.post("/branch-delete", payload);
}

export const editBranch = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        branch_id: id,
        company_id : data.companyCode,
        name : data.name,
        address : data.address,
        city : data.city,
        province : data.province,
        email : data.contactPersonEmail,
        latitude:data.latitude,
        longitude:data.longitude,
        checked: data.Checked,
        radius: data.radius,
        postal_code : data.postalCode,
        phone_number : data.contactPersonNumber,
        modified_by : user
    }
    return master.post("/branch-update", payload);
}