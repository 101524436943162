import { Button, Col, Form as FormAntd, Radio, message } from "antd";
import React, { useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { CheckboxOptionWithLabel1, InputWithLabel, RadioGroupWithLabel, TimeWithLabel } from "../../../component/Form/Input";
import { useFormik } from "formik";
import { decryptData } from "../../../helper/cryptojs";
import { addShift, editShift, getShiftDetail } from "../../../api/shift";
import Breadcrumb from "../../../component/Breadcrumb";

const FormAddShiftingShift = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result = n[6];
    const deCompanyId = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const BestpraticeUseEffect = useRef(false)
	
    const shiftListDetail = async () => {
      setFetching(true);
      try {
        const res = await getShiftDetail(id)
        console.log('detail test',res)
        setDetail(res?.data?.data);
      } catch (err) {
        console.log(err);
      } finally {
          setFetching(false);
      }
    };

      const calculateWorkHours = (scheduleIn, scheduleOut, breakPeriod) => {
      const scheduleInTime = new Date(`2023-09-06T${scheduleIn}:00`);
      const scheduleOutTime = new Date(`2023-09-06T${scheduleOut}:00`);
      const breakPeriodHours = parseInt(breakPeriod);
      if (scheduleOutTime < scheduleInTime) {
        scheduleOutTime.setDate(scheduleOutTime.getDate() + 1); 
      }
    
      const totalMilliseconds = scheduleOutTime - scheduleInTime;
      const totalHours = totalMilliseconds / (1000 * 60 * 60);
    
      const workHours = totalHours - breakPeriodHours;
      return workHours >= 0 ? workHours.toFixed() : "0";
    };
    
      

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if(result){
              shiftListDetail()
            }
          }
	}, [flag]);

    const form = useFormik({
        initialValues: {
            companyCode: result  ? deCompanyId : deCompanyId,
            name: result ? detail?.name : "",
            scheduleIn: result ? detail.schedule_in : "",
            scheduleOut: result ? detail.schedule_out : "",
            tolerance: result ? detail.tolerance : "",
            breakStart: result ? detail.break_start : "",
            breakPeriod: result ? detail.break_period : "",
            workhour: result ? detail.workhour : "",
            isDefault: result ? (detail.is_default ? 1 : 0) : null,
            defaultType: result ? detail.default_type : "",
           
        },
        enableReinitialize: true,
        onSubmit: async(values) => {
          // setFetching(true)
          const res =  result ? await  editShift(values, id) : await addShift(values);
          if(!res?.data?.success){
              message.error({
                  content: res?.data?.error,
              });
          }else{
              if(result){
                  message.success('Edit Data Success')
              }else{
                  message.success("Add Data Success");
              }
              navigate("/shifting/shift")
              form.resetForm();
              setFlag((prev) => prev + 1)
          }
      },
    });

    const dataBreadcrumb = [
      {
        title: "Master Data",
        url: "/shifting/shift"
      },
      {
        title: "Shifting",
        url: "/shifting/shift"
      },
      {
        title: "Shift",
        url: "/shifting/shift"
      },
      {
        title: `${result ?  'Edit Shift' : 'Add Shift'}` ,
        // url: `${ result ?  '/shifting/shift/edit-shift' : '/shifting/shift/add-shift'}`
      },
    ];

    return (
      <>
        <div className="title-section">
				      {result ? "Edit Shift" : "Add Shift"}
			  </div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
          <Col span={24}>
            <Breadcrumb items={dataBreadcrumb}/>
          </Col>
        </div>
       <div className="add-wrapper">
          
            <FormAntd
                onFinish={form.handleSubmit}
            >
            <FormAntd.Item>
            <InputWithLabel
                label={<div>Shift Name<span style={{ color: "#FF0000" }}> *</span></div>}
                placeholder="Shift Name"
                name="name"
                value={form?.values?.name}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
            />
            </FormAntd.Item>
            <div className="input-wrapper">
          <p className="label-input">Default Schedule Time<span style={{ color: "#FF0000" }}> *</span> </p>
        </div>
        <FormAntd.Item>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
          <FormAntd.Item>
            <TimeWithLabel
              name="scheduleIn"
              label={"Schedule In"}
              style={{ borderRadius: "10px" }}
              value={form?.values?.scheduleIn}
              onChange={form.handleChange}
              onBlur={(e) => {
                form.handleBlur(e);
                const newWorkHours = calculateWorkHours(
                  e.target.value,
                  form.values.scheduleOut,
                  form.values.breakPeriod
                );
                form.setFieldValue("workhour", newWorkHours);
              }}
            />
          </FormAntd.Item>
          <FormAntd.Item>
            <TimeWithLabel
              name="scheduleOut"
              label={"Schedule Out"}
              style={{ borderRadius: "10px" }}
              value={form?.values?.scheduleOut}
              onChange={form.handleChange}
              onBlur={(e) => {
                form.handleBlur(e);
                const newWorkHours = calculateWorkHours(
                  form.values.scheduleIn,
                  e.target.value,
                  form.values.breakPeriod
                );
                form.setFieldValue("workhour", newWorkHours);
              }}
            />
          </FormAntd.Item>
            <FormAntd.Item>
              <InputWithLabel
                name="tolerance"
                label={"Tolerance (Minutes)"}
                style={{ width: 100, marginRight: 10 }}
                onChange={ (e) => {
                  const regex = e.target.value;
                  const reg = /^[0-9\b]+$/;
                  if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                      form.handleChange(e)
                  }
                }}
                value={form?.values?.tolerance}
              />
            </FormAntd.Item>
          </div>
        </FormAntd.Item>
        <FormAntd.Item>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <FormAntd.Item>
              <TimeWithLabel
                name="breakStart"
                label={"Break Start"}
                style={{ borderRadius: "10px" }}
                value={form?.values?.breakStart}
                onChange={form.handleChange}
              />
            </FormAntd.Item>
            <FormAntd.Item>
            <InputWithLabel
              name="breakPeriod"
              label={"Break Period"}
              min={1}
              max={10}
              onChange={(e) => {
                const regex = e.target.value;
                const reg = /^[0-9\b]+$/;
                if (regex === "" || (reg.test(regex) && parseInt(regex) <= 10)) {
                  form.handleChange(e);
                  const newWorkHours = calculateWorkHours(
                    form.values.scheduleIn,
                    form.values.scheduleOut,
                    e.target.value
                  );
                  form.setFieldValue("workhour", newWorkHours);
                }
              }}
              value={form?.values?.breakPeriod}
              addonAfter="hours"
              style={{ width: "120px" }}
            />
            </FormAntd.Item>
            <FormAntd.Item>
              <InputWithLabel
                name="workhour"
                label={"Work Hours"}
                onChange={(e) => {
                  const regex = e.target.value;
                  const reg = /^[0-9\b]+$/;
                  if (regex === "" || (reg.test(regex) && parseInt(regex) <= 10)) {
                    form.handleChange(e);
                  }
                }}
                disabled
                value={form?.values?.workhour}
                addonAfter="hours"
                style={{ width: "120px" }}
              />
            </FormAntd.Item>
          </div>

            <FormAntd.Item>
           
            <CheckboxOptionWithLabel1
                name="isDefault"
                onChange={(e) => {
                  form.handleChange(e);
                }}
                label={'Set As Default'}
                checked={form.values.isDefault}
                value={form.values?.isDefault === 1 ? true : false}
              />
            
            </FormAntd.Item>
              <div> {form.values.isDefault ?  (
                    <>
                        <FormAntd.Item>
                            <RadioGroupWithLabel
                                name="defaultType"
                                placeholder="Type"
                                value={  form.values?.defaultType}
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                            >
                                <Radio value={'WEEKDAY'}>
                                  <div className="text-lg">
                                     Weekdays 
                                  </div>
                                  <div className="text-xs">
                                     Valid from Monday - Friday
                                  </div>
                                </Radio>
                                <Radio value={'WEEKEND'}>
                                <div className="text-lg">
                                     Weekend 
                                  </div>
                                  <div className="text-xs">
                                     Only for Saturday
                                  </div>
                                </Radio>
                            </RadioGroupWithLabel>
                        </FormAntd.Item>    
                    </>
                        ): ''}
                </div>
            </FormAntd.Item>
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/shifting/shift')}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
      </>
       
    )
}

export default FormAddShiftingShift;