import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import Navbar from "../Header";
import Sidebar from "../Sidbar";
import Loader from "../../common/Loader";
const { Content } = Layout;

const DefaultLayout = ({ content }) => {
  const [loading, setLoading] = useState(true);
  const [focusContent, setFocusContent] = useState(false);
  useEffect(() => {
    handleReload();
  }, []);

  const handleReload = () => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const renderContent = () => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <Layout style={{ height: "100vh" }}>
          <Navbar changeFocus={()=> setFocusContent(false)} focusContent={focusContent}/>
          <Content onClick={()=>{
            setFocusContent(true)
            }} style={{ overflow: "auto" }}>{content}</Content>
        </Layout>
      );
    }
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Sidebar />
      {renderContent()}
    </Layout>
  );
};

export default DefaultLayout;
