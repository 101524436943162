import { Button, Col, message, Modal, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, DeleteFilled, EditFilled,StopOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deleteLevel, getLevel } from "../../api/level";
import { decryptData } from "../../helper/cryptojs";

export default function Level() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageLevel");
	const sessionOffset = sessionStorage.getItem("sessionOffsetLevel");
	const sessionSearch = sessionStorage.getItem("sessionSearchLevel");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "")
	const [flag, setFlag] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));

	const levelList = async () => {
		setLoading(true);
		try {
		  const res = await getLevel(search, limit, offset, page, company)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	};
	
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			levelList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageLevel");
				sessionStorage.removeItem("sessionSearchLevel");
				sessionStorage.removeItem("sessionOffsetLevel");
				setPage(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		  }
	}, [limit, offset, search,page,flag]);

	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageLevel", page);
		sessionStorage.setItem("sessionOffsetLevel", offsetSession);
	},[page])

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            const res = await deleteLevel(id);
            message.success({
                content: "Data successfully deleted",
            });
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
                content: "Request Not Found",
            });
            setLoading(false)
        }
    }

      
	
	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		width: '25px',
        render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/level/detail/${encodeURIComponent("id?" + record.level_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Level',
		dataIndex: 'name',
		key: 'name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/level/detail/${encodeURIComponent("id?" + record.level_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Sequence',
		dataIndex: 'sequence',
		key: 'sequence',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/level/detail/${encodeURIComponent("id?" + record.level_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		title: 'Action',
		key: 'action',
		width: "50px",
		render: (_,record,index) => {
            return RoleAccessLevelUpdate && RoleAccessLevelDelete ? (
                <Row justify={"space-between"}>
					<div className="icon-action" >
					  <DeleteFilled onClick={() => showModal(record.level_id)}/>
				  	</div>
					
					 <div className="icon-action" onClick={() => navigate(`/level/edit-level/${encodeURIComponent("id?" + record.level_id)}`)}>
					 <EditFilled />
					 </div>
                </Row>
            ) : (
				<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
				</Row>
			)
        }
	},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/level"
		},
		{
			title: "Level",
			url: "/level"
		},
	];


	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchLevel", e.target.value);
	}

	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const RoleAccessLevelCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLevelCreate'));
	const RoleAccessLevelUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLevelUpdate'));
	const RoleAccessLevelDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLevelDelete'));

    return (
        <>
		  <>
		  <div className="title-section">
					Level
				</div>
				<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
						<p>Are you sure to delete this data?</p>
				</Modal>
				<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
					<Col span={24}>
						<Breadcrumb items={dataBreadcrumb}/>
					</Col>
				</div>
				<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
					<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}>
						<Col md={{top:20}} className="mt-5 md:mt-0">
							<Row justify={"end"} style={{gap:15}} md={14}>
								<InputWithIcon
										type="text"
										placeholder="Search Level"
										img={iconSearch}
										className="search-input btn-sh-sec"
										classImg="op-icon"
										value={search}
										onChange={onChangeSearch} />

									{RoleAccessLevelCreate && (
										<Col style={{ display: "flex", justifyContent: "flex-end" }}>
											<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/level/add-level')} icon={<PlusOutlined />} size={"large"} />
										</Col>
									)}
									
								
							</Row>
						</Col>
					</Row>
					<div className="table-wrapper">
					{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}
					</div>
				</div>
					</>
		
          
        </>
    )
}