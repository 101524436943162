import { Button, Col, message, Pagination, Row,Select, Tooltip } from "antd";
import React, { useEffect, useRef,useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, EditFilled, StopOutlined, SettingFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deleteCompany, getCompany } from "../../api/company";

export default function Company() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageCompany");
	const sessionOffset = sessionStorage.getItem("sessionOffsetCompany");
	const sessionSearch = sessionStorage.getItem("sessionSearchCompany");
	const sessionActive = sessionStorage.getItem("sessionActiveCompany");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "")
	const [flag, setFlag] = useState(1)
	const [active, setActive] = useState(sessionActive ? parseInt(sessionActive) : 1)
	const BestpraticeUseEffect = useRef(false)
	const [debounceSearch, setDebounceSearch] = useState(sessionSearch ? sessionSearch : "")

	const companyList = async () => {
		setLoading(true);
		try {
		  const res = await getCompany(debounceSearch, limit, offset,page,active)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		} else {
			companyList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageCompany");
				sessionStorage.removeItem("sessionSearchCompany");
				sessionStorage.removeItem("sessionOffsetCompany");
				sessionStorage.removeItem("sessionActiveCompany");
				setPage(1)
				setActive(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		}
	}, [limit, offset, debounceSearch,page,flag,active]);

	useEffect(() => {
		if(search.length > 2 || search.length === 0) {
			const timeoutId = setTimeout(() => {
				setDebounceSearch(search)
			}, 500)
			return () => clearTimeout(timeoutId);
		}
	}, [search])

	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageCompany", page);
		sessionStorage.setItem("sessionOffsetCompany", offsetSession);
	},[page])

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            const res = await deleteCompany(id);
            message.success({
                content: "Delete Company Success",
            });
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
                content: "Request Not Found",
            });
            setLoading(false)
        }
    }

	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/company/detail/${encodeURIComponent("id?" + record.company_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		title: 'Company Name',
		dataIndex: 'name',
		key: 'name',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/company/detail/${encodeURIComponent("id?" + record.company_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		title: 'Address',
		dataIndex: 'address',
		key: 'address',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/company/detail/${encodeURIComponent("id?" + record.company_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		title: 'Province',
		dataIndex: 'province',
		key: 'province',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/company/detail/${encodeURIComponent("id?" + record.company_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
    {
		title: 'City',
		dataIndex: 'city',
		key: 'city',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/company/detail/${encodeURIComponent("id?" + record.company_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
    {
		title: 'Phone Number',
		dataIndex: 'phone_number',
		key: 'phone_number',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/company/detail/${encodeURIComponent("id?" + record.company_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/company/detail/${encodeURIComponent("id?" + record.company_id)}`)}>
					<span style={text === 1 ? {color:'green'} : {color:'red'}}>
					{text === 1 ? "Active" : "Inactive" || '-'}
					</span>
				</div>
			)
		}
	},
	{
		title: 'Action',
		key: 'action',
		width: "25px",
		render: (_,record,index) => {
            return RoleAccessCompanyUpdate ? (
                <Row justify={"space-between"}>
						<div className="icon-action" onClick={() => navigate(`/company/edit-company/${encodeURIComponent("id?" + record.company_id)}`)}>
							<EditFilled />
						</div>
						<div className="icon-action" onClick={() => navigate(`/company/absence-setting/${encodeURIComponent("id?" + record.company_id)}`)}>
							<SettingFilled />
						</div>
                </Row>
            ) : (
				<Row justify={"space-between"}>
					<Tooltip title="No Access">
						<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
					</Tooltip>
				</Row>
			)
        }
	},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/company"
		},
		{
			title: "Company",
			url: "/company"
		},
	];


	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		// if(pageNumber < previousPage.current){
		// 	setOffset(offset - limit)
		// } else if(pageNumber > previousPage.current) {
		// 	setOffset(offset + limit)
		// }
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchCompany", e.target.value);
	}

	const dataFilter = [
		{
			value : 1,
			title : "Active"
		},
		{
			value : 0,
			title : "Inactive"
		}
	]

	const RoleAccessCompanyCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeCompanyCreate'));
	const RoleAccessCompanyUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeCompanyUpdate'));
    return (
        <>
		 <>
		    <div className="title-section">
				Company
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}>
					<Col md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
								<Select
									placeholder="Select Company"
									value={active}
									onChange={(val) => {
										setActive(val)
										sessionStorage.setItem("sessionActiveCompany", val);
										setPage(1)
									}}
									className="select-company w-24 md:w-36 text-xs "
								>
									{dataFilter.map((data, index) => {
										return (
											<Select.Option style={{height: "42px"}} key={index} value={data.value}>{data.title}</Select.Option>
										)
									})}
								</Select>
								<InputWithIcon
									type="text"
									placeholder="Search Company"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>
								{RoleAccessCompanyCreate && (
									<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/company/add-company')} icon={<PlusOutlined />} size={"large"} />
								)}

						</Row>
					</Col>
				</Row>
				<div className="table-wrapper">
					{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}
				</div>
			</div>
			
		 </>
        </>
    )
}