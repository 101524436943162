import React, { useEffect, useState, useRef } from "react";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import "./styles.scss";
import authBg from "../../assets/img/icon-profile.png";
import { ExportOutlined,ContainerOutlined ,BellOutlined,NotificationOutlined,AlignLeftOutlined,CaretDownOutlined,SolutionOutlined,AppstoreAddOutlined,DashboardOutlined,CloseOutlined,AccountBookOutlined} from "@ant-design/icons";
import {  useNavigate } from "react-router-dom";
import { Button, Dropdown, Menu, message, notification, Select } from "antd";
import { getCompany } from "../../api/company";
import { getBranch } from "../../api/branch";
import logo from '../../assets/img/content/logo-ethos.png';
import { decryptData, encryptData } from "../../helper/cryptojs";
import { getListNotification, getReadAllNotification, getReadByIdNotification, upsertToken } from "../../api/notification";
import moment from "moment";

const { SubMenu } = Menu;

const firebaseConfig = {
  apiKey: "AIzaSyCjdhzJCuQWLnnuURmtdbpNWDVh9_bK9zw",
  authDomain: "hris-ethos.firebaseapp.com",
  projectId: "hris-ethos",
  storageBucket: "hris-ethos.appspot.com",
  messagingSenderId: "420833272867",
  appId: "1:420833272867:web:119adf5e955b40a4f7fd96",
  measurementId: "G-W51C48EV53"
};


firebase.initializeApp(firebaseConfig);
// const auth = firebase.auth();
// const firestore = firebase.firestore();
// const messaging = firebase.messaging();

const Navbar = ({ collapse, collapsed, onShowMobile, showMobile }) => {
    const role = decryptData(localStorage.getItem("Role"));
    const user = decryptData(localStorage.getItem("Username"));
    const accessConsole = decryptData(JSON.parse(localStorage.getItem('AccessConsole')));
    const deBranchCode = decryptData(localStorage.getItem("DefaultBranchCode"));
    const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
    const deBranchName = decryptData(localStorage.getItem("DefaultBranchName"));
    const deBranchId = decryptData(localStorage.getItem("DefaultBranchId"));
    const deCompanyName = decryptData(localStorage.getItem("DefaultCompanyName"));
    const deCompanyId = decryptData(localStorage.getItem("DefaultCompanyId"));
    const deBranchArr = decryptData(JSON.parse(localStorage.getItem('BranchArr')));
    const deCompanyArr = decryptData(JSON.parse(localStorage.getItem('CompanyArr')));
    const [isOpenSidebar, setIsOpenSidebar] = useState(false);
    const BestpraticeUseEffect = useRef(false)
    const userid =  decryptData(localStorage.getItem("userId"));
    useEffect(() => {
      if (!BestpraticeUseEffect.current) {
        BestpraticeUseEffect.current = true;
      } else{
        Notification.requestPermission().then(permission => {
          if (permission === 'granted') {
            const messaging = firebase.messaging();
            messaging.getToken().then(token => {
              // console.log('Token:', token);
              // bisa kirim ke database be    
              if(messaging){
                upsertToken(userid,token)
              }        
              messaging.onMessage(payload => {
                // console.log('Received background message', payload);
                const { title, body } = payload.data;
                notification.open({
                  message: title,
                  description: body,
                  icon:<NotificationOutlined />
                });
              });
            }).catch(error => {
              console.log('Error getting token:', error);
            });
          }
        });
      }
      
    }, []);
  
    const branchArr = deBranchArr!= null? deBranchArr.map((item) => {
        return {
            idx: item.branch_id,
            value: item.code,
            title: item.name
        }
    }):[
       
    ];
 

    var branchARRRR =  decryptData(sessionStorage.getItem('BranchArrStore')) != undefined ? '[]' : decryptData(sessionStorage.getItem('BranchArrStore'));

     const branchArrStore = branchARRRR ?  branchARRRR != undefined ? JSON.parse(branchARRRR) : []: [];
    
    const navigate = useNavigate();
    const [flag, setFlag] = useState(false);

    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const companyStoreId = decryptData(sessionStorage.getItem("selectCompanyId"));
    const companyStoreName = decryptData(sessionStorage.getItem("selectCompanyName"));

    const branchStore = decryptData(sessionStorage.getItem("selectBranch"));
    const branchStoreName = decryptData(sessionStorage.getItem("selectBranchName"));
    const branchStoreId = decryptData(sessionStorage.getItem("selectBranchId"));


    const [listCompany, setListCompany] = useState([
        {
            idx: deCompanyId,
            value: deCompanyCode,
            title: deCompanyName,
        },
    ]);
    const [listBranch, setListBranch] = useState(
      companyStore === null || companyStore === deCompanyCode
        ? branchArr.length > 0
          ? [
              {
                idx: "all",
                value: JSON.stringify([deBranchCode, ...branchArr.map(b=>b.value)]),
                title: "All Branch",
              },
              {
                idx: deBranchId,
                value: deBranchCode,
                title: deBranchName,
              },
              ...branchArr,
            ]
          : [
              {
                idx: deBranchId,
                value: deBranchCode,
                title: deBranchName,
              },
              ...branchArr,
            ]
        : branchArrStore != null
        ? branchArrStore.length > 0
          ? [
              {
                idx: "all",
                value: JSON.stringify(branchArrStore.map(b=>b.value)),
                title: "All Branch",
              },
              ...branchArrStore,
            ]
          : branchArrStore
        : []
    );

    const [companyName, setCompanyName] = useState(companyStoreName === null ? deCompanyName : companyStoreName);
    const [companyId, setCompanyId] = useState(companyStoreId === null ? deCompanyId : companyStoreId);
    const [branchName, setBranchName] = useState(branchStoreName === null ? deBranchName : branchStoreName);
    const [branchId, setBranchId] = useState(branchStoreId === null ? deBranchId : branchStoreId);

    const [active, setActive] = useState(1)
    const [signoutTime, setSignoutTime] = useState(900000);
    const [warningTime, setWarningTime] = useState(840000);

    const [company, setCompany] = useState();
    const [branch, setBranch] = useState();
    // const [company, setCompany] = useState(companyStore === null ? deCompanyCode : companyStore);
    // const [branch, setBranch] = useState(branchStore === null ? deBranchCode : branchStore);

    let warnTimeout;
    let logoutTimeout;


    const warn = () => {
        message.error({
            content: 'Warning you will be automatically logged out in 1 minute',
        });
        // console.log('Warning');
    };
    const logout = () => {
        handleLogout();
        // console.log('You have been loged out');
    }

    const setTimeouts = () => {
        warnTimeout = setTimeout(warn, warningTime);
        logoutTimeout = setTimeout(logout, signoutTime);
    };

    const clearTimeouts = () => {
        if (warnTimeout) clearTimeout(warnTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    useEffect(() => {
        const events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        const resetTimeout = () => {
            clearTimeouts();
            setTimeouts();
        };

        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        setTimeouts();
        return () => {
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        }
    }, []);

    const handleUser = () => {
        if (flag) {
            setFlag(false)
        } else {
            setFlag(true)
        }
    }
    const handleLogout = () => {
        localStorage.clear();
        sessionStorage.clear();
        message.success({
            content: "Logout Success",
        });
        navigate('/');
        window.location.reload();
    }

    const companyList = async () => {
        try {
            const res = await getCompany("", 1000, 0, 0, active, deCompanyCode)
            const data = res?.data?.data
            let listComp = data.map((item) => {
                return {
                    idx: item.company_id,
                    value: item.code,
                    title: item.name
                }
            })

            if(deCompanyArr.length>0 && !accessConsole === true){
              listComp = listComp.filter(x=> deCompanyArr.includes(x.value));
            }

            listComp = [
                {
                    idx: deCompanyId,
                    value: deCompanyCode,
                    title: deCompanyName,
                }, ...listComp
            ];


            setListCompany(listComp)
        } catch (err) {
            console.log(err);
        }

    };

    const branchList = async () => {
      if (accessConsole === true || deCompanyArr.length>0) {
        try {
          const companyCode = companyStore != null ? companyStore : deCompanyCode;
          const res = await getBranch("", 1000, 0, "", companyCode, deBranchCode);
          const data = res?.data?.data;
          let newBranch = data.length > 0 ? data.map((item) => {
              return {
                  idx: item.branch_id,
                  value: item.code,
                  title: item.name
              };
          }) : () => {
              return [];
          };
          if (companyCode === deCompanyCode) {
              newBranch = [{
                  idx: deBranchId,
                  value: deBranchCode,
                  title: deBranchName,
              }, ...newBranch];
          }

          // Add "Select All Branches" option
          
          localStorage.setItem('BranchArr', JSON.stringify(encryptData(newBranch) ? encryptData(newBranch) : []));
          sessionStorage.setItem("BranchArrStore", encryptData(JSON.stringify(newBranch)));
          if(newBranch.length>1){
            newBranch.unshift({
                idx: "all",
                value: JSON.stringify(newBranch.map(b=>b.value)),
                title: "All Branch"
            });
          }
          setListBranch(newBranch);

      } catch (err) {
          console.log(err);
      }
  }
};

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if (accessConsole === true || deCompanyArr.length>0) {
                companyList();
            }
            branchList();
          }
    }, []);

    const newListCompany = [...listCompany];
    const newListBranch = [...listBranch];

    useEffect(()=>{
      if(newListBranch.length>0){
        setBranch(branchStore === null ? deBranchCode : branchStore);
        setCompany(companyStore === null ? deCompanyCode : companyStore);
      }
    },[newListBranch])

    const handleChangeCompany = async (val, title) => {
        if (accessConsole === true || deCompanyArr.length>0) {
            try {
                const res = await getBranch("", 1000, 0, "", val, deBranchCode)
                const data = res?.data?.data
                let newBranch = data.length > 0 ? data.map((item) => {
                    return {
                        idx: item.branch_id,
                        value: item.code,
                        title: item.name
                    }
                }) : () => {
                    return []
                }
                if (val === deCompanyCode) {
                    newBranch = [{
                        idx: deBranchId,
                        value: deBranchCode,
                        title: deBranchName,
                    }, ...newBranch]
                }

                if (newBranch.length > 0) {
                    sessionStorage.setItem("selectBranch", encryptData(newBranch[0]["value"]));
                    sessionStorage.setItem("selectBranchName", encryptData(newBranch[0]['title']));
                    sessionStorage.setItem("selectBranchId", encryptData(newBranch[0]['idx']));
                } else {
                    sessionStorage.setItem("selectBranch", encryptData(""));
                    sessionStorage.setItem("selectBranchName",encryptData(""));
                    sessionStorage.setItem("selectBranchId", encryptData(""));
                }
                sessionStorage.setItem("BranchArrStore", encryptData(JSON.stringify(newBranch)));

            } catch (err) {
                console.log(err);
            }
        }
        setCompany(val)
        setCompanyName(title.children)
        setCompanyId(title.idx)
        sessionStorage.setItem("selectCompany", encryptData(val));
        sessionStorage.setItem("selectCompanyName", encryptData(title.children));
        sessionStorage.setItem("selectCompanyId", encryptData(title.idx));
        navigate("/dashboard")
        window.location.reload()

    }
    const handleChangeBranch = (val, title) => {
      if (val === "all") {
          sessionStorage.setItem("selectBranch", encryptData(""));
          sessionStorage.setItem("selectBranchName", encryptData(""));
          sessionStorage.setItem("selectBranchId", encryptData(""));
      } else {
          setBranch(val);
          setBranchName(title.children);
          setBranchId(title.idx);
          sessionStorage.setItem("selectBranch", encryptData(val));
          sessionStorage.setItem("selectBranchName", encryptData(title.children));
          sessionStorage.setItem("selectBranchId", encryptData(title.idx));
      }
      window.location.reload();
  };



      const [notificationCount, setNotificationCount] = useState(0);
      const [notifications, setNotifications] = useState([]);

      useEffect(() => {
        fetchNotificationsFromBackend()
          .then((data) => {
            setNotifications(data);
            setNotificationCount(getUnreadNotificationCount(data));
          })
          .catch((error) => {
            console.error('Error fetching notifications:', error);
          });
      }, []);


      const fetchNotificationsFromBackend = async() => {
        try {
          const UserId = decryptData(localStorage.getItem('userId'));
          const response = await getListNotification(UserId);
          // console.log('Fetched notifications:', response.data.data);
          // const notifId = response.data.data.map((data) => data.web_notification_id);
          // localStorage.setItem("notifId",notifId)
          // console.log('notifId',notifId)
          return response.data.data;
        } catch (error) {
          console.error('Error fetching notifications:', error);
          throw error;
        }
      };

      const getUnreadNotificationCount = (notifications) => {
        return notifications.filter((notification) => !notification.read_at).length;
      };
      const getUnreadNotificationIds = (notifications) => {
        return notifications
          .filter(notification => !notification.read_at && notification.web_notification_id)
          .map(notification => notification.web_notification_id);
      };

      const getTimeAgo = (timestamp) => {
        const now = new Date(); // untuk mendapatkan date & time yak
        const diffTime = now - new Date(timestamp); // kalkulasi datetime yak
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
          return 'Today';
        } else if (diffDays === 1) {
          return 'Yesterday';
        } else {
          return `${diffDays} days ago`;
        }
      };

      const handleNotificationClick = async () => {
        try {
          const updatedNotifications = notifications.map((notification) => ({
            ...notification,
            read_at: new Date().toISOString(), // Menandai notifikasi sebagai terbaca yak
          }));
          setNotifications(updatedNotifications);
          setNotificationCount(0);


          // ini untuk check all semua yak
          const UserId = decryptData(localStorage.getItem('userId'));
          await getReadAllNotification(UserId);
          navigate('/approval-list')
        } catch (error) {
          console.error('Error marking notifications as read:', error);
        }
      };


      const handleNotificationItemClick = async (notificationId) => {
        const updatedNotifications = notifications.map((notification) => {
          if (notification.web_notification_id === notificationId && !notification.read_at) {
            return {
              ...notification,
              read_at: new Date().toISOString(),
            };
          }
          return notification;
        });
        setNotifications(updatedNotifications);

        await getReadByIdNotification(notificationId); // ini untuk Menandai notifikasi sebagai terbaca di backend yak

        const unreadCount = getUnreadNotificationCount(updatedNotifications);
        setNotificationCount(unreadCount);
        navigate('/approval-list')
      };

      const menu = (
        <Menu className="w-auto md:w-auto overflow-y-auto max-h-96">
          <div style={{ position: 'sticky', top: -4, zIndex: 1, background: 'white' }}>
            <h1 className="mr-4 text-lg md:ml-4">Notification</h1>
            <div className="ml-0 md:ml-2">
              {notifications.length > 0 ? (
                <Button type="link" onClick={handleNotificationClick}>
                  Check All
                </Button>
              ) : (
                <span></span>
              )}
            </div>
          </div>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <Menu.Item
                key={notification.web_notification_id}
                onClick={() => handleNotificationItemClick(notification.web_notification_id)}
                className={notification.read_at ? 'read' : ''}
              >
                <div
                  style={
                    notification.read_at
                      ? {
                          color: 'black',
                          background: '#EFEFEF',
                          borderRadius: '10px',
                          height: 'auto',
                          margin: 10,
                        }
                      : {
                          color: 'white',
                          background: '#85CDFD',
                          borderRadius: '10px',
                          height: 'auto',
                          margin: 10,
                        }
                  }
                >
                  <div className="overflow-auto" style={{ padding: 2, marginLeft: 10, marginRight: 10 }}>
                    <div>
                      <div>
                        <div className="mt-3">{notification.message}</div>
                        <div className="mt-2 mb-3">{moment(notification.created_at).format('YYYY-MM-DD  HH:mm')}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ margin: 2, marginLeft: 10, marginRight: 10 }}>{getTimeAgo(notification.created_at)}</div>
              </Menu.Item>
            ))
          ) : (
            <div style={{ padding: '16px' }}>No notifications to display.</div>
          )}
        </Menu>
      );


      const menusidebar = decryptData(JSON.parse(localStorage.getItem('Menusidbar')));
      // console.log('menusidebar', menusidebar);
      const [openKeys, setOpenKeys] = useState([]);
       const handleMenuOpenChange = (keys) => {
          setOpenKeys(keys);
          localStorage.setItem('OpenKeys', JSON.stringify(keys));
        };

      let arrayMenu = [];
      for (var key in menusidebar) {
        if (menusidebar) {
          arrayMenu.push({
            id: menusidebar?.[key].id ? menusidebar?.[key].id : '',
            mainmenu: menusidebar?.[key].menu ? menusidebar?.[key].menu : '',
            submenu: menusidebar?.[key].submenu ? menusidebar?.[key].submenu : [{}],
          });
        }
      }

      // console.log('arrayMenu', arrayMenu[1].submenu[0].menu);
      // console.log('arrayMenust', arrayMenu?.[key].submenu);

      const accessAttendance = decryptData(JSON.parse(localStorage.getItem('AccessAttendance')));
      const accessMaster = decryptData(JSON.parse(localStorage.getItem('AccessMaster')));
      // console.log('accessMaster', accessMaster);
      const accessShifting = decryptData(JSON.parse(localStorage.getItem('AccessShifting')));
      const location = window.location.href;
      var n = location.lastIndexOf('/');
      var result = location.substring(n);
      const menuList = decryptData(JSON.parse(localStorage.getItem("Menusidbar")));
      const renderSubmenu = (v) => {
        if (v.submenu.length === 0) {
          return (
            <Menu.Item
              key={v.key}
              className="menu-sidebar flex ml-0 items-center"
              onClick={() => navigate(v.link)}>
              <div  className="ml-3">{v.menu}</div>
            </Menu.Item>
          );
        } else {
          return (
            <SubMenu
              key={v.key}
              className="submenu-head1"
              title={
                <span>{!collapsed && <span key={v.key}>{v.menu}</span>}</span>
              }>
              {v.submenu && v.submenu.map((v2) => renderSubmenu(v2))}
            </SubMenu>
          );
        }
      };
      const menuIcon = [
        {
          menu: 'Dashboard',
          icon: DashboardOutlined
        },
        {
          menu: 'Master Data',
          icon: AppstoreAddOutlined
        },
        {
          menu: 'Attendance Management',
          icon: SolutionOutlined
        },
        {
          menu: 'Payroll Management',
          icon: AccountBookOutlined
        },
        {
          menu: 'Approval List',
          icon: ContainerOutlined
        },
        {
          menu: 'Announcement',
          icon: NotificationOutlined
        },
      ];

      useEffect(() => {
        const storedOpenKeys = JSON.parse(localStorage.getItem('OpenKeys'));
        if (storedOpenKeys) {
          setOpenKeys(storedOpenKeys);
        }
      }, []);


      const HandleclickSidbar = (keys) => {
        setIsOpenSidebar(!isOpenSidebar);
        localStorage.setItem('OpenKeys', JSON.stringify(keys));
      }
    return (
        <>
        <div className="navbar-wrapper">
            <div className="md:hidden ml-6 p-2 bg-slate-100 shadow-lg rounded-md items-center flex justify-center h-5 w-6" onClick={HandleclickSidbar}>
               {/* <MenuFoldOutlined/> */}
               {!isOpenSidebar && (
                  <AlignLeftOutlined />
                )}
            </div>
            <div className="flex flex-row justify-between ml-3">
                <Select
                    placeholder="Select Company"
                    value={company}
                    onChange={(val, title) => handleChangeCompany(val, title)}
                    className="select-company w-24 md:w-auto text-xs "
                >

                    {newListCompany.map((data, index) => {
                        return (
                            <Select.Option style={{ height: "50px" }} key={index} idx={data.idx} value={data.value}>{data.title}</Select.Option>
                        )
                    })}
                </Select>
                <Select
                    placeholder="Select Branch"
                    value={branch}
                    onChange={(val, title) => handleChangeBranch(val, title)}
                    className="select-company w-24 md:w-auto text-xs  select-branch-nav"
                >
                    {newListBranch.map((data, index) => {
                        return (
                            <Select.Option style={{ height: "50px" }} key={index} idx={data.idx} value={data.value}>{data.title}</Select.Option>
                        )
                    })}
                </Select>
            </div>
           
            <div className="flex flex-row justify-between items-center">
                <div className="user-wrapper "  onClick={handleUser}>
                
                <div className="img-wrapper w-9 h-10 md:w-40 md:h-40 ml-9 md:ml-0 rounded-full relative overflow-hidden">
                    <img src={authBg} className="absolute w-full h-full object-cover transition duration-300" alt="..."/>
                </div>
                <div className="text-base font-semibold text-neutral-700 ml-0 mr-0 md:mr-2 md:ml-3 hidden w-full md:block md:w-auto">{user}</div>
                <CaretDownOutlined className="hidden w-full md:block md:w-auto"/>
                <div className={`dropdown-wrapper ${flag ? 'active' : ''}`} onClick={handleLogout}>
                    <div className="title text-xs md:text-sm">Logout</div>
                    <ExportOutlined className="text-xs md:text-sm hidden md:block" />
                </div>
                </div>
                <div className="mr-7 ml-2 md:mr-5 md:ml-5 ">
                <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                    <div className="notification-icon">
                    <BellOutlined style={{ fontSize: '31px', color: '#185ADB' }} />
                    {notificationCount > 0 && (
                        <div className="notification-count">{notificationCount}</div>
                    )}
                    </div>
                </Dropdown>
                </div>
            </div>
        </div>
        {isOpenSidebar && (
            <aside className="overflow-auto fixed top-0 left-0 z-40 w-80 h-screen block md:hidden transition-transform -translate-x-0 bg-white shadow border-r border-gray-50 xl:translate-x-0 dark:bg-white">
                <div className=" md:hidden mr-3 mt-5 flex justify-end" onClick={HandleclickSidbar} >
                    {isOpenSidebar && (
                        <div className="border items-center flex justify-center h-8 w-8 bg-slate-100 shadow-lg rounded-lg ">
                            <CloseOutlined  className="text-black"/>
                        </div>
                    )}
                </div>
                <div className="mt-2 flex justify-center">
                     <img src={logo} className="w-30 h-20"  />
                </div>
                <div className="">
                <nav className="flex flex-col py-4 gap-9">
                    <Menu
                      defaultSelectedKeys={[result]}
                      defaultOpenKeys={['sub1']}
                      mode="inline"
                      inlineCollapsed={collapsed}
                      className="menu-wrapper"
                      openKeys={openKeys}
                      onOpenChange={handleMenuOpenChange}
                  >
                  {menuList && menuList.map(mainMenu => {
                              const iconMenu = menuIcon.find(menu => menu.menu == mainMenu.menu) ?? menuIcon.find(menu => menu.menu == 'Payroll Management')
                              if (mainMenu.submenu.length > 0) {
                                return (
                                <SubMenu
                                  key={mainMenu.key}
                                  className="submenu-head"
                                  title={
                                    <span>
                                      <iconMenu.icon />
                                      {!collapsed && <span key={mainMenu.key}>{mainMenu.menu}</span>}
                                    </span>
                                  }
                                >
                                  {mainMenu.submenu && mainMenu.submenu.map(v => renderSubmenu(v))}
                                </SubMenu>
                                )
                              } else {
                                return (
                                  <Menu.Item className="menu-sidebar">
                                    <div className=" flex ml-0 items-center  menu-sidebar" key={mainMenu.link} onClick={() => navigate(mainMenu.link)}>
                                        <div>
                                        <DashboardOutlined />
                                        </div>
                                        <div className="ml-3" >{mainMenu.menu}</div>
                                    </div>
                                </Menu.Item>
                                )
                              }
                            })}
                </Menu>
                </nav>
                </div>
            </aside>
        )}


        </>
    )
}

export default Navbar
