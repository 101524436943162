/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, DatePicker, message, Tabs } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import { decryptData } from "../../helper/cryptojs";
import TabPane from "antd/es/tabs/TabPane";
import {
  getListReportBpjsKesehatan,
  getListReportBpjsKetenagaKerjaan,
} from "../../api/bpjs";
import { formatCurrency } from "../../helper/formatCurrency";
import dayjs from "dayjs";
import "./styles.scss";
export default function ReportBpjs() {
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  const branchCode = branchSession || branch !== null ? branch : branchDefault;
  const RoleAccessReportBpjsDownload = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeBpjsReportDownload")
  );

  const limitSession = sessionStorage.getItem("sessionPageBpjs");
  const limitSessionBpjs = sessionStorage.getItem(
    "sessionPageBpjsKetenagaKerjaan"
  );
  const offsetSession = sessionStorage.getItem("sessionOffsetBpjs");
  const offsetSessionBpjs = sessionStorage.getItem(
    "sessionOffsetBpjsKetenagaKerjaan"
  );

  const sessionDate = decryptData(
    sessionStorage.getItem("sessionDateBpjsReport")
  );
  const sessionDateKetenagaKerjaan = decryptData(
    sessionStorage.getItem("sessionDateBpjsReportKetenagaKerjaan")
  );
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [listTableKetenagaKerjaan, setListTableKetenagaKerjaan] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalDataBpjs, setTotalDataBpjs] = useState(0);
  const [page, setPage] = useState(limitSession ? limitSession : 1);
  const [pageBpjs, setPageBpjs] = useState(
    limitSessionBpjs ? limitSessionBpjs : 1
  );
  const [limit, setLimit] = useState(10);
  const [limitBpjs, setLimitBpjs] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [offsetBpjs, setOffsetBpjs] = useState(
    offsetSessionBpjs ? offsetSessionBpjs : 0
  );
  const [selectedDate, setSelectedDate] = useState(
    sessionDate ? sessionDate : dayjs().format("YYYY-MM")
  );
  const [selectedDateKetenagaKerjaan, setSelectedDateKetenagaKerjaan] =
    useState(sessionDateKetenagaKerjaan ? selectedDateKetenagaKerjaan : dayjs().format("YYYY-MM"));

  const previousPage = useRef(1);
  const previousPageBpjs = useRef(1);
  const BestpraticeUseEffect = useRef(false);

  const ReportBpjsKesehatan = async () => {
    setLoading(true);
    try {
      const res = await getListReportBpjsKesehatan(
        company,
        branch,
        page,
        offset,
        limit,
        selectedDate
      );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const ReportBpjsKetenagaKerjaan = async () => {
    setLoading(true);
    try {
      const res = await getListReportBpjsKetenagaKerjaan(
        company,
        branch,
        pageBpjs,
        offsetBpjs,
        limitBpjs,
        selectedDateKetenagaKerjaan
      );
      setListTableKetenagaKerjaan(res?.data?.data);
      setTotalDataBpjs(res?.data?.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageBpjs", page);
  }, [page]);
  useEffect(() => {
    sessionStorage.setItem("sessionPageBpjsKetenagaKerjaan", pageBpjs);
  }, [pageBpjs]);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      ReportBpjsKesehatan();
      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageBpjs");
        sessionStorage.removeItem("sessionOffsetBpjs");
        sessionStorage.removeItem("sessionDateBpjsReport");
        setPage(1);
        setOffset(0);
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, offset, limit, page, selectedDate]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageBpjs");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    let offsetSession = 0;
    if (page > 1) {
      offsetSession = page * limit - 10;
    }
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageBpjs", page);
    sessionStorage.setItem("sessionOffsetBpjs", offset);
  }, [page]);
  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      ReportBpjsKetenagaKerjaan();
      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageBpjsKetenagaKerjaan");
        sessionStorage.removeItem("sessionOffsetBpjsKetenagaKerjaan");
        sessionStorage.removeItem("sessionDateBpjsReportKetenagaKerjaan");
        setPageBpjs(1);
        setOffsetBpjs(0);
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, offsetBpjs, limitBpjs, pageBpjs, selectedDateKetenagaKerjaan]);

  useEffect(() => {
    const limitSessionBpjs = sessionStorage.getItem(
      "sessionPageBpjsKetenagaKerjaan"
    );
    if (limitSessionBpjs) {
      setPageBpjs(parseInt(limitSessionBpjs));
    }
  }, []);

  useEffect(() => {
    let offsetSession = 0;
    if (pageBpjs > 1) {
      offsetSession = pageBpjs * limitBpjs - 10;
    }
    setOffsetBpjs(offsetSession);
    sessionStorage.setItem("sessionPageBpjsKetenagaKerjaan", pageBpjs);
    sessionStorage.setItem("sessionOffsetBpjsKetenagaKerjaan", offsetBpjs);
  }, [pageBpjs]);

  const columnsBpjsKesehatan = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, index) => {
        return <div className="detail-btn-wp ">{text || "-"}</div>;
      },
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return <div className="detail-btn-wp ">{text || "-"}</div>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "BPJS Kesehatan Number",
      dataIndex: "bpjskes_number",
      key: "bpjskes_number",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Wages",
      dataIndex: "wages",
      key: "wages",
      render: (text, record) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Paid by Company",
      dataIndex: "bpjskes_cost_company",
      key: "bpjskes_cost_company",
      render: (text, record) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Paid by Employee",
      dataIndex: "bpjskes_cost_employee",
      key: "bpjskes_cost_employee",
      render: (text, record) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Additional Family",
      dataIndex: "bpjskes_cost_family",
      key: "bpjskes_cost_family",
      render: (text, record) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "0"}</div>
        );
      },
    },
    {
      title: "Total Deductions Covered by the Company",
      dataIndex: "total_paid_company",
      key: "total_paid_company",
      render: (text, record) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Total Deductions Covered by the Employee",
      dataIndex: "total_paid_employee",
      key: "total_paid_employee",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
  ];

  const columnsBpjsKetenagakerjaan = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, index) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "BPJS Ketenagakerjaan Number",
      dataIndex: "bpjstk_number",
      key: "bpjstk_number",
      render: (text) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Wages",
      dataIndex: "wages",
      key: "wages",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "JHT Company",
      dataIndex: "jht_company_paid",
      key: "jht_company_paid",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "JKK",
      dataIndex: "jkk_paid",
      key: "jkk_paid",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {text === "-" ? "Rp0" : formatCurrency(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "JKM",
      dataIndex: "jkm_paid",
      key: "jkm_paid",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {text === "-" ? "Rp0" : formatCurrency(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "JP Company",
      dataIndex: "jp_company_paid",
      key: "jp_company_paid",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "JHT Employee",
      dataIndex: "jht_employee_paid",
      key: "jht_employee_paid",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "JP Employee",
      dataIndex: "jp_employee_paid",
      key: "jp_employee_paid",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Total Deductions Covered by the Company",
      dataIndex: "total_paid_company",
      key: "total_paid_company",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Total Deductions Covered by the Employee",
      dataIndex: "total_paid_employee",
      key: "total_paid_employee",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Report",
      url: "/bpjs-report",
    },
    {
      title: "BPJS Report",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);
  useEffect(() => {
    previousPageBpjs.current = pageBpjs;
  }, [pageBpjs]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageBpjs", pageNumber);
    sessionStorage.setItem("sessionOffsetBpjs", offsetSession);
    //
  };
  const onChangeTableBpjs = (pageNumber, type) => {
    const offsetSession = pageNumber * limitBpjs - 10;
    setPageBpjs(pageNumber);
    setOffsetBpjs(offsetSession);
    sessionStorage.setItem("sessionPageBpjsKetenagaKerjaan", pageNumber);
    sessionStorage.setItem("sessionOffsetBpjsKetenagaKerjaan", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  const onShowSizeChangeBpjs = (_, pageSize) => {
    setLimitBpjs(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  function dataTableWithNumberBpjs() {
    const showNumber = limitBpjs * (pageBpjs - 1);
    return listTableKetenagaKerjaan?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(dateString);
    setPage(1);
    setOffset(0)
    sessionStorage.setItem("sessionDateBpjsReport", dateString);
  };
  const handleDatePickerChangeBpjsKetenagaKerjaan = (date, dateString) => {
    setSelectedDateKetenagaKerjaan(dateString);
    setPageBpjs(1);
    setOffsetBpjs(0)
    sessionStorage.setItem("sessionDateBpjsReportKetenagaKerjaan", dateString);
  };
  const disabledDate = (current) => {
    const currentYear = dayjs().year();
    const twoYearsLater = dayjs().add(1, "year").year();

    const selectedYear = current.year();

    return selectedYear < currentYear || selectedYear > twoYearsLater;
  };
  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}bpjs-kesehatan-report?companyCode=${company}&branchCode=${branchCode}&month=${selectedDate}`;
  };
  const handleDownloadBpjs = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}bpjs-ketenagakerjaan-report?companyCode=${company}&branchCode=${branchCode}&month=${selectedDateKetenagaKerjaan}`;
  };

  return (
    <>
      <div className="title-section">BPJS Report</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <Tabs defaultActiveKey="1" className="tab-wrapper" type="card">
        <TabPane tab="BPJS Kesehatan" key="1">
          <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
            {" "}
            <Row
              style={{ marginBottom: "24px", justifyContent: "end" }}
              align={"middle"}>
              <Col md={{ top: 20 }} className="mt-5 md:mt-0">
                <Row justify={"end"} style={{ gap: 15 }} md={14}>
                  {RoleAccessReportBpjsDownload && (
                    <Button
                      size={"large"}
                      type="primary"
                      onClick={handleDownload}>
                      Download
                    </Button>
                  )}
                  <DatePicker
                    placeholder="Select Month"
                    allowClear={false}
                    picker="month"
                    className="search-input btn-sh-sec"
                    defaultValue={dayjs(selectedDate, "YYYY-MM")}
                    onChange={handleDatePickerChange}
                    disabledDate={disabledDate}
                  />
                </Row>
              </Col>
            </Row>
            {loading ? (
              <>
                <div className="table-wrapper-bpjs">
                  <Table
                    id={"my-table"}
                    columns={columnsBpjsKesehatan}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={page}
                    current={page}
                    total={totalData}
                    onChange={onChangeTable}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChange}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="table-wrapper-bpjs">
                  <Table
                    id={"my-table"}
                    dataSource={dataTableWithNumber()}
                    columns={columnsBpjsKesehatan}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={page}
                    current={page}
                    total={totalData}
                    onChange={onChangeTable}
                    onShowSizeChange={onShowSizeChange}
                    showSizeChanger={false}
                  />
                </div>
              </>
            )}
          </div>
        </TabPane>
        <TabPane tab="BPJS Ketenagakerjaan" key="2">
          <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
            {" "}
            <Row
              style={{ marginBottom: "24px", justifyContent: "end" }}
              align={"middle"}>
              <Col md={{ top: 20 }} className="mt-5 md:mt-0">
                <Row justify={"end"} style={{ gap: 15 }} md={14}>
                  {RoleAccessReportBpjsDownload && (
                    <Button
                      size={"large"}
                      type="primary"
                      onClick={handleDownloadBpjs}>
                      Download
                    </Button>
                  )}
                  <DatePicker
                    allowClear={false}
                    placeholder="Select Month"
                    picker="month"
                    className="search-input btn-sh-sec"
                    defaultValue={dayjs(selectedDateKetenagaKerjaan, "YYYY-MM")}
                    onChange={handleDatePickerChangeBpjsKetenagaKerjaan}
                    disabledDate={disabledDate}
                  />
                </Row>
              </Col>
            </Row>
            {loading ? (
              <>
                <div className="table-wrapper-bpjs">
                  <Table
                    className=""
                    id={"my-table"}
                    columns={columnsBpjsKetenagakerjaan}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={pageBpjs}
                    current={pageBpjs}
                    total={totalDataBpjs}
                    onChange={onChangeTableBpjs}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChangeBpjs}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="table-wrapper-bpjs">
                  <Table
                    id={"my-table"}
                    dataSource={dataTableWithNumberBpjs()}
                    columns={columnsBpjsKetenagakerjaan}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={pageBpjs}
                    current={pageBpjs}
                    total={totalDataBpjs}
                    onChange={onChangeTableBpjs}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChangeBpjs}
                  />
                </div>
              </>
            )}
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}
