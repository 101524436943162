import { Button, Row, Col, Form as FormAntd, message, Upload, Modal } from "antd";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { decryptData } from "../../../helper/cryptojs";
import Breadcrumb from "../../../component/Breadcrumb";
import { CloudUploadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { uploadPayroll } from "../../../api/payslip";
import { uploadImportEmployee } from "../../../api/employee";
import { getCompanyDetail } from "../../../api/company";
import Link from "antd/es/typography/Link";

const { Dragger } = Upload;
const FormImportEmployee = () => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const companyId = decryptData(sessionStorage.getItem("selectCompanyId"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
  const validationUser = yup.object().shape({
    doc_file: yup.string().required("Field is required")
  });
  const form = useFormik({
    initialValues: {
      doc_file: null,
      company_id: companyId,
    },
    enableReinitialize: true,
    validationSchema: validationUser,
    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = await uploadImportEmployee(values);
        if (res.data.success) {
          Modal.success({
            title: "",
            content: (
              <div className="text-center">
                <h2>Import Bulky Employee Success</h2>
                <p>Please check the results in the table</p>
              </div>
            ),
            onOk: navigate("/employee/import-employee"),
          });
          navigate("/employee/import-employee");
          form.resetForm();
        } else {
          message.error({
            content: res.data.error ?? "Import Bulky Employee Failed",
          });
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Request Not Found",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Master Data",
      url: "/employee",
    },
    {
      title: "Employee",
      url: "/employee",
    },
    {
      title: "Import Bulky Employee",
      url: "employee/import-employee",
    },
  ];

  const handleFileUpload = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      // form.setFieldValue("doc_file", info.file);
      if (info.file.size <= 12582912) {
        form.setFieldValue("doc_file", info.file);
      } else {
        console.log("File size exceeds 12MB limit");
        message.error("File size exceeds 12MB limit");
      }
    } if (status === "removed") {
      form.setFieldValue("doc_file", null);
    };
  }

  return (
    <>
      <div className="title-section">Import Bulky Employee</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <div>
            <Row align="bottom">
              <Col span={6} className="text-center" style={{ flex: "unset" }}>
                <h4>Download Template</h4>
                <FileExcelOutlined style={{ fontSize: "4em" }} />
              </Col>
              <Col span={18}>
                <p className="text-gray-400">
                  Import bulky employee template
                </p>
                <Link href={`${process.env.REACT_APP_HRIS_URL}template-import-employee`}>
                  Download the following template and please fill in the data
                </Link>
              </Col>
            </Row>
          </div>
          <div className="mt-5">
            <h4>Upload Attachment<span className="text-red-500">*</span></h4>
            <Dragger
              beforeUpload={() => false}
              onChange={handleFileUpload}
              accept=".xlsx, .xls"
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                Select a file or drag and drop here{" "}
              </p>
              <p className="ant-upload-hint">XLSX, file size no more 12MB </p>
            </Dragger>
            {form.touched.doc_file && form.errors.doc_file ? (
              <span className="text-error">{form.errors.doc_file}</span>
            ) : null}
          </div>
        </FormAntd>
        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormImportEmployee;
