import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getInsurance = async (search, limit, offset, page,company,exception) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        search_by : "name",
        search_value : search?.length > 0 ? search : undefined,
        exception : exception?.length > 0 ? exception : "",
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/insurance",payload);
}

export const addInsurance = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id: data.companyId,
        name: data.name,
        code: data.code,
        premium_value: data.premiumValue.replaceAll(",", ""),
        created_by : user
    }
    return master.post("/insurance-create", payload);
}

export const getInsuranceDetail = async (id) => {
    let payload = {
        insurance_id: id,
    }
    return master.post("/insurance-detail", payload);
}

export const deleteInsurance = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        insurance_id: id,
        "deleted_by" : user
    }
    return master.post("/insurance-delete", payload);
}

export const editInsurance = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        insurance_id: id,
        company_id: data.companyId,
        name: data.name,
        code: data.code,
        premium_value: data.premiumValue.replaceAll(",", ""),
        modified_by: user
    }
    return master.post("/insurance-update", payload);
}
export const ListReportInsurance = async (companyCode,month,page,offset,limit) => {
    let payload = {
        companyCode: companyCode,
        month: month,
        draw : page,
        start : offset,
        length : limit
    }
    return master.post("/report-list-insurance", payload);
}