import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";
export const uploadPayroll = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        file : data.doc_file,
        company_code: data.company_code,
        month:data.month,
        created_by :user
    }
    return master.post("/upload", payload);
}
export const getDataPayroll = async (company,period,limit, offset, page) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        company_code : companyStore || company !== null ? company : "",
        periode: period,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/get-payslip-list", payload);
}

export const downloadPayroll = async(payrollId) => {
    return master.get(`/download-payslip/${payrollId}`, { 'responseType': 'blob' });
}