import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getLeave = async (startDate, endDate, search, company, limit,offset, page) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    startDate: startDate,
    endDate: endDate,
    companyCode: companyStore || company !== null ? company : "",
    filterColumn:
      search?.length > 0 && (search === "paid" || search === "unpaid")
        ? "regulation"
        : "leave_name",
    filter: search?.length > 0 ? search : undefined,
    length: limit,
    start:  offset,
    draw : page,

  };
  return master.post("/leave", payload);

};
export const getLeaveDropdown = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/leave/dropdown", payload);

};

export const getLeaveDetail = async (id) => {
  let payload = {
    mleaveCode: id,
  };
  return master.get(`/leave/${id}`, payload);
};
export const getGenerateDetailAnnual = async (id) => {
  let payload = {
    mleaveCode: id,
  };
  return master.get(`/leave-generate/${id}`, payload);
};
export const getLeaveDropdownGenerate = async () => {
  return master.get("/leave-generate/dropdown");
};


export const AddLeave = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    name: data.name,
    companyCode: data.companyCode,
    regulation: data.regulation,
    leaveType: data.leaveType,
    leaveBalance: data.leaveBalance,
    allowance: data.allowance,
    balanceDeducted: data.balanceDeducted,
    maxRequest: data.maxRequest,
    attachment: data.attachment,
    createdBy: user,
  };

  return master.post("/leave/create", payload);

};
export const AddLeaveBalance = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: data.companyCode,
    mLeaveId: data.mLeaveId,
    leaveBalance: data.leaveBalanceGenerate,
    effectiveDate: data.effectiveDate,
    policyType: data.policyType,
    firstEmerge: data.firstEmerge,
    firstEmergeRemark: data.firstEmergeRemark,
    isProrate: data.isProrate,
    carryOver: data.carryOver,
    carryOverMax: data.carryOverMax,
    hasExpired: data.hasExpired  || 0,
    expiredDate: data.expiredDate,
    createdBy: user,
  };

  return master.post("/leave-generate", payload);
};
export const EditLeaveBalance = async (data,id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: data.companyCode,
    mLeaveId: data.mLeaveId,
    leaveBalance: data.leaveBalanceGenerate,
    effectiveDate: data.effectiveDate,
    policyType: data.policyType,
    firstEmerge: data.firstEmerge,
    firstEmergeRemark: data.firstEmergeRemark,
    isProrate: data.isProrate,
    carryOver: data.carryOver,
    carryOverMax: data.carryOverMax,
    hasExpired: data.hasExpired,
    expiredDate: data.expiredDate,
    activePeriod: data.activePeriod,
    modifiedBy: user,
  };

  return master.post(`/leave-generate/update/${id}`, payload);
};

export const EditLeave = async (data, id,company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    mleaveCode: id,
    name: data.name,
    companyCode: companyStore || company !== null ? company : "",
    regulation: data.regulation,
    leaveType: data.leaveType,
    leaveBalance: data.leaveBalance,
    allowance: data.allowance,
    balanceDeducted: data.balanceDeducted,
    maxRequest: data.maxRequest,
    attachment: data.attachment,
    modifiedBy: user,
  };

  return master.post(`/leave/update/${id}`, payload);
};

export const DeleteLeave = async (id, company) => {
  const user = decryptData(localStorage.getItem("Username"));
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode:  companyStore || company !== null ? company : "",
    modifiedBy: user,
  };
  return master.post(`/leave/delete/${id}`,payload);
};
