import { Button, Col, message, Modal, Pagination, Row, Select, Tooltip, Form as FormAntd, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import { TextAreaWithLabel, SelectOptionWithLabel, CheckboxOptionWithLabelrow } from "../../component/Form/Input";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import "./styles.scss"
import { PlusOutlined,EditFilled,StopOutlined} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { deleteEmployee, getEmployee, setEmployeeActive, setEmployeeInactive } from "../../api/employee";
import { decryptData, encryptData } from "../../helper/cryptojs";
import {decode} from 'html-entities';
import { useFormik } from "formik";
import dayjs from "dayjs";
import * as yup from "yup";

export default function Employee() {
	const navigate = useNavigate();
	const limitSession = sessionStorage.getItem("sessionPageEmployee");
	const activeSession = sessionStorage.getItem("sessionFilterActive");
	const sessionSearch = sessionStorage.getItem("sessionSearchEmployee");
	const sessionOffset = sessionStorage.getItem("sessionOffsetEmployee");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
	const [debounceSearch, setDebounceSearch] = useState(sessionSearch ? sessionSearch : "")
	const [flag, setFlag] = useState(1);
	const [active, setActive] = useState(activeSession ? activeSession : "active")
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalInactiveOpen, setIsModalInactiveOpen] = useState(false);
	const [isModalActiveOpen, setIsModalActiveOpen] = useState(false);
	const [errors, setErrors] = useState({});
	const [activationEmployee, setActivationEmployee] = useState(null);
	const [activationEmployeeNumber, setActivationEmployeeNumber] = useState(null);
	const [isOpen, setIsOpen] = useState(false);
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const branch = decryptData(sessionStorage.getItem("selectBranch"));
	const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  	const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
	const branchCode = branchSession || branch !== null ? branch  : branchDefault
	const employeeList = async () => {
		setLoading(true);
		try {
		  const res = await getEmployee(debounceSearch, limit, offset, page,company,active)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	  useEffect(() => {
			employeeList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageEmployee");
				sessionStorage.removeItem("sessionFilterActive");
				sessionStorage.removeItem("sessionSearchEmployee");
				sessionStorage.removeItem("sessionOffsetEmployee");
				setPage(1)
				setActive("active")
				setSearch("")
				setOffset(0)
			  };
			  window.addEventListener("beforeunload", beforeUnloadHandler);
			  return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			  };
	}, [debounceSearch, limit, offset, page, flag, company, active]);

	useEffect(() => {
		if(search.length > 2 || search.length === 0) {
			const timeoutId = setTimeout(() => {
				setDebounceSearch(search)
			}, 500)
			return () => clearTimeout(timeoutId);
		}
	}, [search])

	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageEmployee", page);
		sessionStorage.setItem("sessionOffsetEmployee", offsetSession);
	},[page])

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            const res = await deleteEmployee(id);
            message.success({
                content: "Delete Employee Success",
            });
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
                content: "Request Not Found",
            });
            setLoading(false)
        }
    }

	const handleActivateEmployee = async (id) => {
		setLoading(true)
        try {
            const res = await setEmployeeActive(id);
			if(!res?.data?.success){
				message.error({
					content: res?.data?.error,
				});
				setActivationEmployee(null)
				setLoading(false)
				setActivationEmployeeNumber(null)
			} else {
				message.success({
					content: "The employee's status has been successfully changed to active.",
				});	
				setActivationEmployee(null)
				setLoading(false)
				setFlag((prev) => prev + 1)
				navigate(`/employee/edit-employee/${activationEmployeeNumber}`, {state :{activeTab: '2'}})
				setActivationEmployeeNumber(null)
			}
        } catch (error) {
            message.error({
                content: "Request Not Found",
            });
            setLoading(false)
        }
	} 

	const handleInactivateEmployee = async (id) => {
		setLoading(true)
        try {
            const res = await setEmployeeInactive(form.values,id);
			if(!res?.data?.success){
				message.error({
					content: res?.data?.error,
				});
				setActivationEmployee(null)
				setLoading(false)
			} else {
				message.success({
					content: "The employee's status has been successfully changed to inactive.",
				});
				setActivationEmployee(null)
				setLoading(false)
				setFlag((prev) => prev + 1)
			}
        } catch (error) {
            message.error({
                content: error?.response?.data?.error,
            });
            setLoading(false)
        }
	} 

	const columnsActive = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist; 
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text}
				</div>
			)
		}
	},
	{
		title: 'NIK',
		dataIndex: 'employee_number',
		key: 'employee_number',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text}
				</div>
			)
		}
	},
	{
		title: 'Employee Name',
		dataIndex: 'full_name',
		key: 'full_name',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{decode(text)}
				</div>
			)
		}
	},
	{
		title: 'Email',
		dataIndex: 'official_email',
		key: 'official_email',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{decode(text) || "-"}
				</div>
			)
		}
	},
	{
		title: 'Phone No',
		dataIndex: 'phone_number',
		key: 'phone_number',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text || "-"}
				</div>
			)
		}
	},
	{
		title: 'Branch',
		dataIndex: 'branch_name',
		key: 'branch_name',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text || "-"}
				</div>
			)
		}
	},
	{
		title: 'Position',
		dataIndex: 'position_name',
		key: 'position_name',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{decode(text)}
				</div>
			)
		}
	},
	{
		title: 'Join Date',
		dataIndex: 'join_date',
		key: 'join_date',
		width: '12%',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text}
				</div>
			)
		}
	},
	{
		title: 'Status',
		dataIndex: 'is_active',
		key: 'is_active',
		// width: '12%',
		// render: (_,record,i) =>{
		// 	const isInActiveBacklist = record.in_active_backlist;
		// 	const handleSelectStatus = (e) => {
		// 		setActivationEmployee(null)
		// 		setActivationEmployeeNumber(null)
		// 		if(e === 'Active'){
		// 			setIsModalActiveOpen(true)
		// 			setActivationEmployee(record.employee_id)
		// 			setActivationEmployeeNumber(record.employee_number)
		// 		}else{
		// 			setIsModalInactiveOpen(true)
		// 			setActivationEmployee(record.employee_id)
		// 		}
		// 	}
		// 	 return (
		// 		<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}>
		// 			<Select 
		// 				key={i}
		// 				size="large"
		// 				value={record.is_active}
		// 				onSelect={handleSelectStatus}
		// 				options={[
		// 					{
		// 						label: 'Active',
		// 						value: 'Active'
		// 					},
		// 					{
		// 						label: 'Inactive',
		// 						value: 'Inactive'
		// 					},
		// 				]}
		// 			></Select>
		// 		</div>
		// 	)
		// }
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text || "-"}
				</div>
			)
		}
	},
	{
		title: 'Action',
		key: 'action',
		width: "15px",
		render: (_,record,index) => {
			return RoleAccessEmployeeUpdate ? (
				<Row justify={"center"}>
						<div className="icon-action">
							<EditFilled onClick={() => navigate(`/employee/edit-employee/${record.employee_number}`)}/>
						</div>
				</Row>
			) : (
				<Row justify={"center"}>
					<div className="icon-action">
					<Tooltip title="No Access">
						<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
					</Tooltip>
					</div>
				</Row>
			)
		}
	},
	];

	// const columnsInactive = [ // later for deployment CR-Employee
	// 	{
	// 		title: 'No',
	// 		dataIndex: 'no',
	// 		key: 'no',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist; 
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{text}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'NIK',
	// 		dataIndex: 'employee_number',
	// 		key: 'employee_number',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{text}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Employee Name',
	// 		dataIndex: 'full_name',
	// 		key: 'full_name',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{decode(text)}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Email',
	// 		dataIndex: 'official_email',
	// 		key: 'official_email',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{decode(text) || "-"}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Phone No',
	// 		dataIndex: 'phone_number',
	// 		key: 'phone_number',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{text || "-"}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Branch',
	// 		dataIndex: 'branch_name',
	// 		key: 'branch_name',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{text || "-"}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Position',
	// 		dataIndex: 'position_name',
	// 		key: 'position_name',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{decode(text)}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Join Date',
	// 		dataIndex: 'join_date',
	// 		key: 'join_date',
	// 		width: '12%',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{text}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Inactive Date',
	// 		dataIndex: 'in_active_endate',
	// 		key: 'in_active_endate',
	// 		width: '12%',
	// 		render: (text,record) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
	// 					{dayjs(text).format('D MMM YYYY')}
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Status',
	// 		dataIndex: 'is_active',
	// 		key: 'is_active',
	// 		width: '12%',
	// 		render: (_,record,i) =>{
	// 			const isInActiveBacklist = record.in_active_backlist;
	// 			const handleSelectStatus = (e) => {
	// 				setActivationEmployee(null)
	// 				setActivationEmployeeNumber(null)
	// 				if(e === 'Active'){
	// 					setIsModalActiveOpen(true)
	// 					setActivationEmployee(record.employee_id)
	// 					setActivationEmployeeNumber(record.employee_number)
	// 				}else{
	// 					setIsModalInactiveOpen(true)
	// 					setActivationEmployee(record.employee_id)
	// 				}
	// 			}
 	// 			return (
	// 				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}>
	// 					<Select 
	// 						key={i}
	// 						size="large"
	// 						value={record.is_active}
	// 						onSelect={handleSelectStatus}
	// 						options={[
	// 							{
	// 								label: 'Active',
	// 								value: 'Active'
	// 							},
	// 							{
	// 								label: 'Inactive',
	// 								value: 'Inactive'
	// 							},
	// 						]}
	// 					></Select>
	// 				</div>
	// 			)
	// 		}
	// 	},
	// 	{
	// 		title: 'Action',
	// 		key: 'action',
	// 		width: "15px",
	// 		render: (_,record,index) => {
	// 			return RoleAccessEmployeeUpdate ? (
	// 				<Row justify={"center"}>
	// 						<div className="icon-action">
	// 							<EditFilled onClick={() => navigate(`/employee/edit-employee/${record.employee_number}`)}/>
	// 						</div>
	// 				</Row>
	// 			) : (
	// 				<Row justify={"center"}>
	// 					<div className="icon-action">
	// 					<Tooltip title="No Access">
	// 						<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
	// 					</Tooltip>
	// 					</div>
	// 				</Row>
	// 			)
	// 		}
	// 	},
	// 	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/employee"
		},
		{
			title: "Employee",
			url: "/employee"
		},
	]

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchEmployee", e.target.value)
	}

	const dataFilter = [
		{
			value : "active",
			title : "Active"
		},
		{
			value : "inactive",
			title : "Inactive"
		}
	]

	const validation = yup.object().shape({
		in_active_reason:  yup.string().required("Field is required"),
		in_active_endate:  yup.string().required("Field is required"),
	}
	);

	const form = useFormik({
        initialValues: {
            in_active_reason : "",
            in_active_description : "",
            in_active_endate : "",
            in_active_backlist: null,
        },
        enableReinitialize: true,
        validationSchema:validation,
		onSubmit: () => {
			handleOkInactive()
		}
    });


	const handleOkInactive = () => {
		setIsModalInactiveOpen(false);
		handleInactivateEmployee(activationEmployee)
	};
	
	const handleCancelInactive = () => {
		setIsModalInactiveOpen(false);
	};

	const handleOkActive = () => {
		setIsModalActiveOpen(false);
		handleActivateEmployee(activationEmployee)
	};
	
	const handleCancelActive = () => {
		setIsModalActiveOpen(false);
	};

	const handleOk = () => {
		setIsModalOpen(false);
		// handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleDownload = () => {
		const filter = active === 'active' ? 1 : 0
		window.open(`${process.env.REACT_APP_HRIS_URL}download-employee-report?companyCode=${company}&branchCode=${branchCode}&filter=${filter}&search=${search}`, '_blank');
	};

	  const RoleAccessEmployeeCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeCreate'));
	  const RoleAccessEmployeeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeUpdate'));
	  const RoleAccessEmployeeDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeDownload'));
    return (
        <>
		<>
		<div className="title-section">
				Employee
			</div>
			<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<p>Are you sure to delete this data?</p>
			</Modal>
			<Modal title="Confirmation" open={isModalActiveOpen} onOk={handleOkActive} onCancel={handleCancelActive}>
				<p>Are you sure to activate this employee?</p>
			</Modal>
			<Modal title="Additional Info" open={isModalInactiveOpen} footer={null} closable={false}>
				<FormAntd
					onFinish={form.handleSubmit}
					className="add-wrapper"
				>
					<div className="mt-2">
						<FormAntd.Item>
						<SelectOptionWithLabel 
								name="in_active_reason"
								label={<div>Inactive Reason<span style={{color:"#FF0000"}}> *</span></div>}
								placeholder={"In Active Reason"}
								items={[
									{
										value: "Normal Resignation",
										title: "Normal Resignation"
									},
									{
										value: "Contract Expired",
										title: "Contract Expired"
									},
									{
										value: "Termination",
										title: "Termination"
									},
									{
										value: "Passed Away",
										title: "Passed Away"
									},
									{
										value: "Others",
										title: "Others"
									},
								]}
								value={form?.values?.in_active_reason ? form?.values?.in_active_reason :null} 
								onChange={(val) => {
									setErrors({ ...errors, in_active_reason: '' });
									form.setValues({
										...form.values,
										in_active_reason: val,
									});
									if (val !== 'Others') {
										setErrors({ ...errors, in_active_description: '' });
									}
									if (val) {
										setErrors({ ...errors, in_active_reason: '' });
									}
								}}
								onBlur={form.handleBlur}
								
							/>
							<div style={{marginTop: '1rem'}}>
								{form.touched.in_active_reason && form.errors.in_active_reason ? (
									<span className="text-error">{form.errors.in_active_reason}</span>
								) : errors.in_active_reason && <span className="text-error">{errors.in_active_reason}</span>}
							</div>
						</FormAntd.Item>
						<FormAntd.Item>
							<TextAreaWithLabel
								name="in_active_description"
								label={"Description"}
								placeholder="Description"
								rows={4}
								onBlur={form.handleBlur}
								maxLength={500}
								onChange={(e) => {
									form.handleChange(e);
									// Penanganan kesalahan di sini
									setErrors({ ...errors, in_active_description: '' });
									if (e.target.value.length <= 500) {
										form.setFieldValue('in_active_description', e.target.value);
										form.setFieldError('in_active_description', ''); // Menghapus error jika nilai valid
									} else {
										form.setFieldError('in_active_description', 'Description cannot exceed 500 characters');
									}
								}}
								value={form?.values?.in_active_description}
							/>
							{errors.in_active_description && <span className="text-error">{errors.in_active_description}</span>}
						</FormAntd.Item>
						<FormAntd.Item>
							<div className="flex flex-col">
							<div className="text-slate-500">{"End Date"}<span className="text-red-500">*</span></div>
							<DatePicker
								name="in_active_endate"
								format="DD-MM-YYYY"
								placeholder="DD-MM-YYYY"
								
								allowClear={true}
								value={form.values.in_active_endate ? dayjs(form.values.in_active_endate, 'YYYY-MM-DD') : null}
								onChange={in_active_endate => {
								const formattedDate = in_active_endate ? dayjs(in_active_endate).format('YYYY-MM-DD') : null;
								form.setFieldValue('in_active_endate', formattedDate);
								setErrors({ ...errors, in_active_endate: '' });
								}}
								className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
							/>
							</div>
							<div style={{marginTop: '1rem'}}>
								{form.touched.in_active_endate && form.errors.in_active_endate ? (
									<span className="text-error">{form.errors.in_active_endate}</span>
								) : errors.in_active_endate && <span className="text-error">{errors.in_active_endate}</span>}
							</div>
						</FormAntd.Item>
						<FormAntd.Item>
								<CheckboxOptionWithLabelrow
								name="in_active_backlist"
								onChange={(e) => {
									form.setValues({
										...form.values,
										in_active_backlist: e.target.checked, // Update nilai in_active_backlist dengan status checkbox
									});
								}}
								label={'BlackList'}
								checked={form.values.in_active_backlist || false} // Pastikan nilai checked adalah boolean
							/>
						</FormAntd.Item>
					</div>
					<div className="footer-add">
						<Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={handleCancelInactive}>
							Cancel
						</Button>
						<Button key="submit" type="primary" className="btn btn-primary btn-sh-p" onClick={form.handleSubmit} disabled={form.isSubmitting}>
							Ok
						</Button>
					</div>
				</FormAntd>
			</Modal>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col md={{top:20}}>
						<Row justify={"end"} style={{gap:12}} md={14}>
								<Select
									placeholder="Select Position"
									value={active}
									onChange={(val) => {
										setActive(val)
										sessionStorage.setItem("sessionFilterActive", val);
										setPage(1)
									}}
									className="select-company w-24"
								>
									{dataFilter.map((data, index) => {
										return (
											<Select.Option style={{height: "42px"}} key={index} value={data.value}>{data.title}</Select.Option>
										)
									})}
								</Select>
								<InputWithIcon
									type="text"
									placeholder="Search Employee"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>

								<>
								{/* <Button size={"large"}  type="primary" onClick={() => navigate('/employee/employee-transfer')} >Employee Transfer</Button> */}
								{RoleAccessEmployeeDownload && (
									<Button size={"large"}  type="primary" onClick={handleDownload} >Download</Button>
								)}
								{RoleAccessEmployeeCreate && (
									<Button className="btn-sh-p" type="primary" shape="circle"  onClick={() => setIsOpen(!isOpen)} icon={<PlusOutlined />} size={"large"} />
								)}
								{isOpen && (
								<div className="absolute z-10 right-3 mt-12 w-48 bg-white rounded shadow sm:w-56 md:w-64 lg:w-72 xl:w-[250px]">
								<div className="px-4 py-3" role="none">
								  <p className="text-lg text-gray-900" role="none">
									<Link to={'/employee/add-employee'}> + Add Employee</Link>
								  </p>
								  <p className="text-lg text-gray-900" role="none">
									<Link to={'/employee/import-employee'}> + Import Bulky Employee</Link>
								  </p>
								</div>
							  </div>
							  
								)}	
								</>
						</Row>
					</Col>
				
				</Row>

				<div className="table-wrapper">
				{loading ? (
						<>
						<Table id={'my-table'}  columns={columnsActive}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columnsActive} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	

				</div>
			</div>
		</>		
            
        </>
    )
}
