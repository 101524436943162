import { Button, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { getFeatures, getPermissions, getRoleBranch, getRoleDetail } from "../../../api/role";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getBranch } from "../../../api/branch";
import { decryptData } from "../../../helper/cryptojs";
import { roleCompany } from "../../../api/company";


const RoleDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const [detailTemp, setDetailTemp] = useState([]);
    const [branch, setBranch] = useState([]);
    const [branchCode, setBranchCode] = useState([]);
    const [application, setApplication] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const BestpraticeUseEffect1 = useRef(false)
    const BestpraticeUseEffect2 = useRef(false)
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));

    const roleList = async () => {
        setLoading(true);
        try {
            const res = await getRoleDetail(id)
            const detail = res?.data?.data
            if(detail.branch){
                let branchDetail = JSON.parse(detail.branch);
                const resBranch = await getBranch("", 100, 0, 1, company)
                const branchs = resBranch?.data?.data

                const branchCodes = branchs.map(branch => branch.code);

                detail.branch = JSON.stringify(branchDetail.filter(code => branchCodes.includes(code)));
            }
            if(detail.company_list != null){
                const companyList = JSON.parse(detail.company_list);

                const resCompany = await roleCompany(companyList);

                detail['companyList'] = resCompany?.data?.data
            }
            setDetailTemp(detail);
            setBranchCode(JSON.parse(detail.branch));
            setApplication(detail.application ? JSON.parse(detail.application) : [])
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const branchrole = async (branch) => {
        try {
            const res = await getRoleBranch(branch)
            setBranch(res?.data?.data ? res.data.data : [])
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            roleList();
          }
        
    }, []);


    useEffect(() => {
        if (!BestpraticeUseEffect1.current) {
            BestpraticeUseEffect1.current = true;
          } else {
            branchrole(branchCode);
          }
       
    }, [branchCode]);

    const featureList = async () => {
        try {
            const response = await getPermissions()
            const permission = response?.data?.data.map(item => ({
                value: item.permission_id,
                title: item.description
            }))

            const res = await getFeatures()
            const data = res?.data?.data
            let feature = data.map((item) => {
                const name = item.name

                return {
                    feature_id: item.feature_id,
                    title: name === 'attendance' ? "Attendance Management" : item.description,
                    access: [],
                    accessName: []
                }
            })

            if (result) {
                if (detailTemp.privileges && detailTemp.privileges.length > 0) {
                    detailTemp.privileges.forEach(datum => {
                        const index = feature.findIndex(x => x.feature_id === datum.feature_id)
                        if (index >= 0) {
                            feature[index].access = datum.access
                            feature[index].accessName = datum.accessName
                        }
                    })
                }

                detailTemp['privilege'] = feature
                setDetail(detailTemp)
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect2.current) {
            BestpraticeUseEffect2.current = true;
          } else {
            featureList();
          }
       
    }, [detailTemp]);

    const dataBreadcrumb = [
        {
            title: "Master Data",
            url: "/role"
        },
        {
            title: "Access Role",
            url: "/role"
        },
        {
            title: "Access Role Detail",
            // url: "/role-detail"
        },
    ];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{ marginRight: "14px" }} onClick={() => navigate('/role')} icon={<ArrowLeftOutlined />} size={"large"} />
                Access Role Detail
            </div>
            <div style={{ marginBottom: "24px" }}>
                <Breadcrumb items={dataBreadcrumb} />
            </div>
            <div className="detail-wrapper">
                <div className="title-divider" style={{ marginTop: "32px" }}>Role Info</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Role Name</div>
                            <div className="subtitle">{detail?.name}</div>
                        </div>
                    </Col>
                    {detail?.companyList?.length > 0? <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company</div>
                            {detail?.companyList.length > 0 ?
                                <ul>
                                    {detail?.companyList.map(item => {
                                        return (
                                            <li className="subtitle" key={item}>{item}</li>
                                        )
                                    })}
                                </ul> : <div className="subtitle">-</div>}
                        </div>
                    </Col> : null }
                </Row>
                <Row>
                    {detail.name === 'ROOT' || detail?.companyList?.length > 1 ? 
                        <Col span={12}>
                            <div className="detail-card">
                                <div className="title">Branch</div>
                                <li className="subtitle">All Branch</li>
                            </div>
                        </Col>:
                        <Col span={12}>
                            <div className="detail-card">
                                <div className="title">Branch</div>
                                {branch.length > 0 ?
                                    <ul>
                                        {branch.map(item => {
                                            return (
                                                <li className="subtitle" key={item}>{item}</li>
                                            )
                                        })}
                                    </ul> : <div className="subtitle">-</div>}
                            </div>
                        </Col> }
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Application</div>
                            {application.length > 0 ?
                                <ul>
                                    {application.map(app => {
                                        return (
                                            <li className="subtitle" key={app}>{app}</li>
                                        )
                                    })}
                                </ul> : <div className="subtitle">-</div>}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Allowed Level Payroll</div>
                            <div className="subtitle">{detail?.allowed_payroll_level?.name ?? detail?.allowed_payroll}</div>
                        </div>
                    </Col>
                </Row>
                <div>
                    <div className="title-divider" style={{ marginTop: "32px" }}>Menu Access</div>
                    <Row>
                        {detail.privileges &&
                            detail.privileges.map(datum => {
                                return (
                                    <Col span={12}>
                                        <div className="detail-card">
                                            <div className="title">{datum.title}</div>
                                            {datum.accessName.length > 0 ?
                                                <ul>
                                                    {datum.accessName.map(item => {
                                                        return (
                                                            <li className="subtitle" key={item}>{item}</li>
                                                        )
                                                    })}
                                                </ul> : <div className="subtitle">-</div>}
                                        </div>
                                    </Col>
                                )
                            })
                        }
                    </Row>

                </div>
            </div>
        </>
    )
}

export default RoleDetail