/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form as FormAntd, message,Modal } from "antd";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  NumberFormat,
  SelectOptionWithLabelApplies,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import "./styles.scss";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import {
  AddPtkpStatus,
  EditPtkpStatus,
  getPtkpStatusDetail,
} from "../../api/ptkpStatus";
const FormAddPtkpStatus = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [fetching, setFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detail, setDetail] = useState([]);


  const getDetailPtkpStatus = async () => {
    try {
      const res = await getPtkpStatusDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetailPtkpStatus();
    form.resetForm();
  }, []);

  const validationUser = yup.object().shape({
    ptkp_type: yup.string().required("Field is required"),
    ptkp_status: yup.string().required("Field is required"),
    ptkp_value: yup.string().required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      ptkp_type: result ? detail?.ptkp_type : "",
      ptkp_status: result ? detail?.ptkp_status : "",
      ptkp_value: String(result ? detail?.ptkp_value : ""),
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = result
          ? await EditPtkpStatus(values, id)
          : await AddPtkpStatus(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: result
              ? "PTKP Status successfully updated"
              : "PTKP Status successfully saved",
          });
          navigate("/ptkp-status");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "PTKP already exist",
        });
        setFetching(false);
      }
    },
  });

  const showModal = async () => {
    if (form.isValid) {
      if (result) {
        setIsModalOpen(true);
      } else {
        form.handleSubmit();
      }
    }else{
      await form.handleSubmit();
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.handleSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/ptkp-status",
    },
    {
      title: "PPh21",
    },
    {
      title: `${result ? "Edit PTKP Status" : "Add PTKP Status"}`,
    },
  ];

  return (
    <>
      <div className="title-section">
        {result ? "Edit PTKP Status" : "Add PTKP Status"}
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <Modal
        title="Edit"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure want to change the data?</p>
      </Modal>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="ptkp_type"
                      label={
                        <div>
                          PTKP Type<span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "Tidak Kawin (TK)",
                          title: "Tidak Kawin (TK)",
                        },
                        {
                          value: "Kawin (K)",
                          title: "Kawin (K)",
                        },
                        {
                          value: "Kawin + Istri (K/I)",
                          title: "Kawin + Istri (K/I)",
                        },
                      ]}
                      value={form?.values?.ptkp_type ? form?.values?.ptkp_type : null}
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          ptkp_type: val,
                          ptkp_status: "",
                        });
                      }}
                      placeholder="Select One"
                      onBlur={form.handleBlur}
                    />
                   <div
                    className={
                      form.touched.ptkp_type &&
                      form.errors.ptkp_type === "Field is required"
                        ? "mb-[-22px] mt-2"
                        : "mt-0 mb-[-10px]"
                    }>
                    {form.touched.ptkp_type && form.errors.ptkp_type ? (
                      <span className="text-error">{form.errors.ptkp_type}</span>
                    ) : null}
                  </div>
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="ptkp_status"
                      label={
                        <div>
                          PTKP Status<span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={form.values.ptkp_type === "Tidak Kawin (TK)"? [
                        {
                          value: "TK/0",
                          title: "TK/0",
                        },
                        {
                          value: "TK/1",
                          title: "TK/1",
                        },
                        {
                          value: "TK/2",
                          title: "TK/2",
                        },
                        {
                          value: "TK/3",
                          title: "TK/3",
                        },
                      ] : form.values.ptkp_type === "Kawin (K)" ? [{
                        value: "K/0",
                        title: "K/0",
                      },
                      {
                        value: "K/1",
                        title: "K/1",
                      },
                      {
                        value: "K/2",
                        title: "K/2",
                      },
                      {
                        value: "K/3",
                        title: "K/3",
                      }
                    ] : form.values.ptkp_type === "Kawin + Istri (K/I)" ? [
                      {
                          value: "K/I/0",
                          title: "K/I/0",
                        },
                      {
                          value: "K/I/1",
                          title: "K/I/1",
                        },
                      {
                          value: "K/I/2",
                          title: "K/I/2",
                        },
                      {
                          value: "K/I/3",
                          title: "K/I/3",
                        },
                      ] : null
                        }
                      value={form?.values?.ptkp_status ? form?.values?.ptkp_status : null}
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          ptkp_status: val,
                        });
                      }}
                      placeholder="Select One"
                      onBlur={form.handleBlur}
                    />

                   <div
                    className={
                      form.touched.ptkp_status &&
                      form.errors.ptkp_status === "Field is required"
                        ? "mb-0 mt-2"
                        : "mt-3 mb-[12px]"
                    }>
                    {form.touched.ptkp_status && form.errors.ptkp_status ? (
                      <span className="text-error">{form.errors.ptkp_status}</span>
                    ) : null}
                  </div>
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
           <FormAntd.Item>
            <div className="flex flex-col mt-[-20px]">
              <div className="flex items-center gap-6 md:gap-[90px] mb-1">
                <div className="flex-grow">
                  <NumberFormat
                    label={
                      <div>
                        PTKP Value<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                   
                    allowNegative={false}
                    name="ptkp_value"
                    type="text"
                    placeholder="Rp."
                    thousandSeparator={true}
                    value={form?.values?.ptkp_value ? form?.values?.ptkp_value : null}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    maxLength={13}
                  />
                </div>
              </div>
                <div
                      className={
                        form.touched.ptkp_value &&
                        form.errors.ptkp_value === "Field is required"
                          ? "mb-[-22px] mt-[-5px] ml-0"
                          : "mt-3 mb-[-22px]"
                      }>
                      {form.touched.ptkp_value && form.errors.ptkp_value ? (
                        <span className="text-error">
                          {form.errors.ptkp_value}
                        </span>
                      ) : null}
                    </div>
            </div>
          </FormAntd.Item>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/ptkp-status")}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={showModal}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddPtkpStatus;
