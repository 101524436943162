import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getCompanyDetail } from "../../../api/company";
import { Link } from "react-router-dom";

const CompanyDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const companyList = async () => {
		setLoading(true);
		try {
		  const res = await getCompanyDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            companyList();
          }
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data",
			url: "/company"
		},
		{
			title: "Company",
			url: "/company"
		},
        {
			title: "Company Detail",
			// url: "/company-detail"
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/company')} icon={<ArrowLeftOutlined />} size={"large"} />
				Company Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <div className="title-divider">Company Logo</div>

                <img src={process.env.REACT_APP_HRIS_URL+`company/image/${detail?.company_logo_url}`} height="250px"/>
                 
                <div className="title-divider">Company Info</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company Name</div>
                            <div className="subtitle">{detail?.name != 'null' ? (detail?.name || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company Phone Number</div>
                            <div className="subtitle">{detail?.phone_number !== 'null' ? (detail?.phone_number || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Email</div>
                            <div className="subtitle">{detail?.email !==  'null' ? (detail?.email || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Address</div>
                            <div className="subtitle">{detail?.address !== 'null' ? (detail?.address || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Postal Code</div>
                            <div className="subtitle">{detail?.postal_code !== 'null' ? (detail?.postal_code || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Province</div>
                            <div className="subtitle">{detail?.province !== 'null' ? (detail?.province || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">City</div>
                            <div className="subtitle">{detail?.city !== 'null' ? (detail?.city || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Industry</div>
                            <div className="subtitle">{ detail?.industry !== 'null' ? (detail?.industry || "-") : "-"}</div>
                        </div>
                    </Col>
                </Row>
                <div className="title-divider" style={{marginTop: "32px"}}>Tax Info</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company NPWP</div>
                            <div className="subtitle">{detail?.company_npwp !== 'null'  ? (detail?.company_npwp || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company Taxable Date</div>
                            <div className="subtitle">{detail?.company_tax_date !== 'null' ? (detail?.company_tax_date || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Tax Person Name</div>
                            <div className="subtitle">{detail?.tax_person_name !== 'null'  ? (detail?.tax_person_name || "-") : "-" }</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Tax Person NPWP</div>
                            <div className="subtitle">{detail?.tax_person_npwp !== 'null' ? (detail?.tax_person_npwp || "-") : "-"}</div>
                        </div>
                    </Col>
                </Row>
                <div className="title-divider" style={{marginTop: "32px"}}>Other Info</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Contact Person Name</div>
                            <div className="subtitle">{detail?.contact_person_name !== 'null' ? (detail?.contact_person_name || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Contact Person Number</div>
                            <div className="subtitle">{detail?.contact_person_number !== 'null' ? (detail?.contact_person_number || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Contact Person Email</div>
                            <div className="subtitle">{detail?.contact_person_email !== 'null' ? (detail?.contact_person_email || "-") : "-"}</div>
                        </div>
                    </Col>
                    {/* <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Template Payroll</div>
                            <div className="text-sm text-gray-500 flex items-center">
                            <div>
                              <svg
                                className="w-4 h-4 mr-1 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                              </svg>
                            </div>
                            <>
                              {detail?.template_payroll ? (
                                <Link
                                    to={process.env.REACT_APP_HRIS_URL+`company/image/${detail?.template_payroll}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                >
                                    {detail?.template_payroll}
                                </Link>
                              ) : (
                                <div className="subtitle">
                                  -
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                    </Col> */}
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Status</div>
                            <div className="subtitle">{detail?.status === 1 ? "Active" : "Inactive"}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CompanyDetail