import React, { useState } from "react"
import "../Login/styles.scss"
import { Button, message} from "antd";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import passwordIcon from "../../assets/img/icons/icon-password.svg";
import lockImg from "../../assets/img/lock.png";
import { Form as FormAntd} from "antd";
import { InputPasswordWithIcon } from "../../component/Form/InputWithIcon";
import { resetPassword } from "../../api/login";
import * as yup from "yup";

export function ResetPasswordPage() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location?.substring(n + 1);
    const encode = atob(result);
    var arr = encode.split(',');
   
    var email =  arr[1];
    var expired =  arr[2];
    const validationReset = yup.object().shape({
        password: yup.string().matches(
            /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
            "Must Contain 8 Characters with Number"
          ).required("Field is required"),
        passwordConfirmation: yup.string()
            .test('passwords-match', 'Passwords must match', function(value){
            return this.parent.password === value
    })
    });
    const form= useFormik({
        initialValues: {
            password: "",
        },
        enableReinitialize: true,
        validationSchema: validationReset,
        onSubmit: async (values) => {
            setLoading(true)
            try {
                const res = await resetPassword(values, email,expired,result);
                if(!res?.data?.success){
                    message.error({
                        content: res?.response?.data?.error || "User Not Found",
                    });
                } else {
                    message.success({
                        content: "Reset Password Success"
                    });
                    navigate("/")
                }
                setLoading(false)
            } catch (error) {
                message.error({
                    content: error?.response?.data?.error,
                });
                setLoading(false)
            }
        },
    });
    return (
        <div id="login-page">
            <div className="bg-gradient">
                <div className="bg-left">
                    <div className="shape"> </div>
                </div>
                <div className="bg-right">
                    <div className="shape"> </div>
                </div>
            </div>
            <div className="forgot-wrapper">
                <div className="section-form">
                    <img src={lockImg} className="img-forgot"></img>
                    <div className="title-section-login">
                    <div className="title">
                         Reset Password
                        </div>
                    </div>
                    <FormAntd
                        onFinish={form.handleSubmit}
                    >
                        <FormAntd.Item>
                            <InputPasswordWithIcon
                                name="password"
                                type="password"
                                placeholder="New Password"
                                value={form.values.password}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                img={passwordIcon}
                                className="form-input"
                            />
                            {form.touched.password && form.errors.password ? (
                                <span className="text-error">{form.errors.password}</span>
                            ) : null}
                        </FormAntd.Item>
                        <FormAntd.Item>
                            <InputPasswordWithIcon
                                name="passwordConfirmation"
                                type="passwordConfirmation"
                                placeholder="Confirmation Password"
                                value={form.values.passwordConfirmation}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                img={passwordIcon}
                                className="form-input"
                            />
                            {form.touched.passwordConfirmation && form.errors.passwordConfirmation ? (
                                <span className="text-error">{form.errors.passwordConfirmation}</span>
                            ) : null}
                        </FormAntd.Item>
                        
                    </FormAntd>
                    <div className="footer">
                        <div className="btn-wrapper">
                            <Button
                                type="submit"
                                className="btn btn-submit"
                                loading={loading}
                                onClick={form.handleSubmit}
                            >
                                Reset Password
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}