import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getOvertimeTransaction = async (search, startDate, endDate, limit, offset, page, company,branch) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch  : branchDefault ,
    startDate: startDate,
    endDate: endDate,
    filterColumn: "employees.full_name",
    filter: search?.length > 0 ? search : undefined,
    start:  offset,
    length: limit,
    draw: page,
  };
  return master.post("/dash-overtime", payload);
};

export const getOvertimeTransactionDetail = async (id) => {
  let payload = {
    overtimeId: id,
  };
  return master.post("/dash-overtime-detail", payload);
};

export const getListOvertimeTransactionEmployeeNumber = async (company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
   let payload = {
    companyCode : companyStore || company !== null ? company : "",
   }
   return master.post("/overtime-employee-list-input", payload);
  }

export const addOvertimeTransaction = async (data) => {
  const user = decryptData(localStorage.getItem("Username")) ;
  let payload = {
    employeeNumber: data.EmployeeNumber,
    date: data.date,
    start_time: data.StartTime,
    end_time: data.EndTime,
    break_hour: data.BreakHour,
    break_minute: data.BreakMinute,
    attachment: data.AttechmentUpload,
    description: data.Description,
    created_by: user,
  };

  return master.post("/dash-overtime-create", payload);
};

export const addOImport0vertimeTransaction = async (data,company) => {
  const user =  decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode : data.company,
    overtime: data.Overtime,
    created_by: user,
  };

  return master.post("/dash-overtime-import", payload);
};

export const editOvertimeTransaction = async (data,id) => {
  const user =  decryptData(localStorage.getItem("Username"));
  let payload = {
    overtimeId: id,
    employeeNumber: data.EmployeeNumber,
    date: data.date,
    start_time: data.StartTime,
    end_time: data.EndTime,
    break_hour: data.BreakHour,
    break_minute: data.BreakMinute,
    attachment: data.AttechmentUpload,
    description: data.Description,
    modified_by: user
  };

  return master.post("/dash-overtime-update", payload);
};


export const deleteOvertimeTransaction = async (id,employeNumber) => {
  const user =  decryptData(localStorage.getItem("Username"));
  let payload = {
      overtimeId: id,
      employeeNumber:employeNumber,
      deleted_by: user
  }
  return master.post("/dash-overtime-delete", payload);
}
