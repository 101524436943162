import { Button, Col, Form as FormAntd, Modal, Row, message,DatePicker } from "antd";
import { useEffect, useState } from "react";
import { getBranch } from "../../../api/branch";
import { TextAreaWithLabel, InputWithLabel, SelectOptionWithLabel, CheckboxOptionWithLabelrow } from "../../../component/Form/Input";
import dayjs from "dayjs";

const PersonalForm = ({form, handleBack, fetching, result}) => {
    // const [listBranch, setListBranc] = useState([]);
    // const branchList = async () => {
	// 	try {
	// 	  const res = await getBranch()
	// 	  const data = res?.data?.data
	// 	  setListBranc(data.map((item) => {
	// 		return {
	// 			value : item.code,
	// 			title : item.name
	// 		}
	// 	}))
	// 	} catch (err) {
	// 		message.error({
	// 			content: err.response.data.error,
	// 		});
	// 	  console.log(err);
	// 	}
	// };
    // useEffect(() => {
    //     branchList();
	// }, []);

    const [age, setAge] = useState("");

    useEffect(() => {
        const calculateAge = (dateOfBirth) => {
          const today = new Date();
          const birthDate = new Date(dateOfBirth);
          const age = today.getFullYear() - birthDate.getFullYear();
          const monthDiff = today.getMonth() - birthDate.getMonth();
          
          if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            return age - 1;
          }
          
          return age;
        };
      
        if (form.values.dateOfBirth) {
          const age = calculateAge(form.values.dateOfBirth);
          setAge(age.toString());
          
          form.setFieldValue("Age", age.toString());
        } else {
          setAge("");
          form.setFieldValue("Age", "");
        }
      }, [form.values.dateOfBirth]);
      
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [errors, setErrors] = useState({});
      const handleOk = async () => {
        const newErrors = {};
        if (!form.values.in_active_reason) {
            newErrors.in_active_reason = 'Field is required';
        }
        if (!form.values.in_active_endate) {
            newErrors.in_active_endate = 'Field is required';
        }
        if (form.values.in_active_reason === 'Others' && !form.values.in_active_description) {
            newErrors.in_active_description = 'Field is required ';
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            setIsModalOpen(false);
        }
    };
        const handleCancel = () => {
            form.setValues({
                ...form.values,
                in_active_reason: null,
                in_active_description: "",
                in_active_endate: null,
                in_active_backlist: 0 ,
            })
            setIsModalOpen(false);
            setErrors({});
        };  
    return (
        <>
            <FormAntd
                onFinish={form.handleSubmit}
                className="add-wrapper"
            >
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Name<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Name"
                        value={form?.values?.name}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.name && form.errors.name ? (
                        <span className="text-error">{form.errors.name}</span>
                    ) : null}
                </FormAntd.Item>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="emailOffice"
                                label={<div>Office Email<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Email"
                                value={form?.values?.emailOffice}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.emailOffice && form.errors.emailOffice ? (
                                <span className="text-error">{form.errors.emailOffice}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="emailPersonal"
                                label={"Personal Email"} 
                                placeholder="Email"
                                value={form?.values?.emailPersonal}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.emailPersonal && form.errors.emailPersonal ? (
                                <span className="text-error">{form.errors.emailPersonal}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={24}>
                        <FormAntd.Item>
                            <InputWithLabel 
                                name="placeOfBirth"
                                label={<div>Place of Birth <span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Place Of Birth"
                                value={form?.values?.placeOfBirth}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.placeOfBirth && form.errors.placeOfBirth ? (
                                <span className="text-error">{form.errors.placeOfBirth}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>

                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                    <FormAntd.Item>
                        <div className="flex flex-col">
                        <div className="text-slate-500">{"Date of Birth"}<span className="text-red-500">*</span></div>
                        <DatePicker
                            name="dateOfBirth"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.dateOfBirth ? dayjs(form.values.dateOfBirth, 'YYYY-MM-DD') : null}
                            onChange={dateOfBirth => {
                            const formattedDate = dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : null;
                            form.setFieldValue('dateOfBirth', formattedDate);
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                        />
                        </div>
                        {form.touched.dateOfBirth && form.errors.dateOfBirth ? (
                                <span className="text-error">{form.errors.dateOfBirth}</span>
                        ) : null}
                    </FormAntd.Item>
                    </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                            <InputWithLabel
                                disabled
                                name="Age"
                                label={<div>Age</div>}
                                placeholder="Age"
                                value={age} // Gunakan state umur yang dihitung
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            </FormAntd.Item>
                        </Col>
                </Row>
                



                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item
                        >
                            <InputWithLabel
                                name="nationaly" 
                                label={<div>Nationality<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Nationality"
                                value={form?.values?.nationaly}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.nationaly && form.errors.nationaly ? (
                                <span className="text-error">{form.errors.nationaly}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <SelectOptionWithLabel 
                                name="religion"
                                label={<div>Religion<span style={{color:"#FF0000"}}> *</span></div>}
                                items={[
                                    {
                                        value: "ISLAM",
                                        title: "ISLAM"
                                    },
                                    {
                                        value: "KHATOLIK",
                                        title: "KHATOLIK"
                                    },
                                    {
                                        value: "KRISTEN PROTESTAN",
                                        title: "KRISTEN PROTESTAN"
                                    },
                                    {
                                        value: "BUDHA",
                                        title: "BUDHA"
                                    },
                                    {
                                        value: "HINDU",
                                        title: "HINDU"
                                    },
                                    {
                                        value: "KONGHUCU",
                                        title: "KONGHUCU"
                                    },
                                ]}
                                value={form?.values?.religion ? form?.values?.religion : null} 
                                onChange={(val) =>
                                    form.setValues({
                                    ...form.values,
                                    religion: val,
                                    })
                                }
                                onBlur={form.handleBlur}
                            />
                            {form.touched.religion && form.errors.religion ? (
                                <span className="text-error">{form.errors.religion}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <SelectOptionWithLabel 
                                name="gender"
                                label={<div>Gender<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Gender"
                                items={[
                                    {
                                        value: "MALE",
                                        title: "MALE"
                                    },
                                    {
                                        value: "FEMALE",
                                        title: "FEMALE"
                                    },
                                ]}
                                value={form?.values?.gender ? form?.values?.gender : null}
                                onChange={(val) =>
                                    form.setValues({
                                    ...form.values,
                                    gender: val,
                                    })
                                }
                                onBlur={form.handleBlur}
                            />
                            {form.touched.gender && form.errors.gender ? (
                                <span className="text-error">{form.errors.gender}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <SelectOptionWithLabel
                                name="maritalStatus"
                                label={<div>Marital Status<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Marital Status"
                                items={[
                                    {
                                        value: "MARRIED",
                                        title: "MARRIED"
                                    },
                                    {
                                        value: "SINGLE",
                                        title: "SINGLE"
                                    },
                                    {
                                        value: "DIVORCED",
                                        title: "DIVORCED"
                                    },
                                    {
                                        value: "WIDOWED",
                                        title: "WIDOWED"
                                    },
                                    {
                                        value: "OTHER",
                                        title: "OTHER"
                                    },
                                ]}
                                value={form?.values?.maritalStatus ? form?.values?.maritalStatus : null}
                                onChange={(val) =>
                                    form.setValues({
                                    ...form.values,
                                    maritalStatus: val,
                                    })
                                }
                                onBlur={form.handleBlur}
                            />
                            {form.touched.maritalStatus && form.errors.maritalStatus ? (
                                <span className="text-error">{form.errors.maritalStatus}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={24}>
                        <FormAntd.Item>
                            <InputWithLabel 
                                name="phoneNumber"
                                label={<div>Phone Number<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="08**********"
                                value={form?.values?.phoneNumber}
                                onChange={ (e) => {
                                    const telNo = e.target.value;
                                    const re = /^[0-9\b]+$/;
                                    if (telNo === '' || re.test(telNo)) {
                                        form.handleChange(e)
                                    }
                                }}
                                onBlur={form.handleBlur}
                                maxLength={13}
                                minLength={10}
                            />
                            {form.touched.phoneNumber && form.errors.phoneNumber ? (
                                <span className="text-error">{form.errors.phoneNumber}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <SelectOptionWithLabel 
                                name="education"
                                label={<div>Education<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Education"
                                items={[
                                    {
                                        value: "S2",
                                        title: "S2"
                                    },
                                    {
                                        value: "S1",
                                        title: "S1"
                                    },
                                    {
                                        value: "D3",
                                        title: "D3"
                                    },
                                    {
                                        value: "SMA/SMK",
                                        title: "SMA/SMK"
                                    },
                                    {
                                        value: "SMP",
                                        title: "SMP"
                                    },
                                ]}
                                value={form?.values?.education ? form?.values?.education : null}
                                onChange={(val) =>
                                    form.setValues({
                                    ...form.values,
                                    education: val,
                                    })
                                }
                                onBlur={form.handleBlur}
                            />
                            {form.touched.education && form.errors.education ? (
                                <span className="text-error">{form.errors.education}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item
                        >
                            <InputWithLabel
                                name="EducationInstitution" 
                                label={<div>Institution<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Institution"
                                value={form?.values?.EducationInstitution}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.EducationInstitution && form.errors.EducationInstitution ? (
                                <span className="text-error">{form.errors.EducationInstitution}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item
                        >
                            <InputWithLabel
                                name="EducationField" 
                                label={<div>Major<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Major"
                                value={form?.values?.EducationField}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.EducationField && form.errors.EducationField ? (
                                <span className="text-error">{form.errors.EducationField}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item
                        >
                            <InputWithLabel
                                name="Gpa" 
                                label={<div>GPA<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="GPA"
                                value={form?.values?.Gpa}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.Gpa && form.errors.Gpa ? (
                                <span className="text-error">{form.errors.Gpa}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel 
                                name="identityNumber"
                                label={<div>Identity Card Number<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="320*************"
                                value={form?.values?.identityNumber}
                                onChange={ (e) => {
                                    const telNo = e.target.value;
                                    const re = /^[0-9\b]+$/;
                                    if (telNo === '' || re.test(telNo)) {
                                        form.handleChange(e)
                                    }
                                }}
                                onBlur={form.handleBlur}
                                maxLength={16}
                                minLength={16}
                               
                            />
                            {form.touched.identityNumber && form.errors.identityNumber ? (
                                <span className="text-error">{form.errors.identityNumber}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel 
                                name="familyCertificateNumber"
                                label={"Family Certificate Number"}
                                placeholder="320*************"
                                value={form?.values?.familyCertificateNumber}
                                onChange={ (e) => {
                                    const telNo = e.target.value;
                                    const re = /^[0-9\b]+$/;
                                    if (telNo === '' || re.test(telNo)) {
                                        form.handleChange(e)
                                    }
                                }}
                                // onChange={form.handleChange}
                                onBlur={form.handleBlur}
                                maxLength={16}
                                // minLength={16}
                            />
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel 
                                name="driverLicenseNumber"
                                label={"Driver License Number"}
                                placeholder="320*************"
                                value={form?.values?.driverLicenseNumber}
                                onChange={ (e) => {
                                    const telNo = e.target.value;
                                    const re = /^[0-9\b]+$/;
                                    if (telNo === '' || re.test(telNo)) {
                                        form.handleChange(e)
                                    }
                                }}
                                onBlur={form.handleBlur}
                                maxLength={16}
                            />
                        </FormAntd.Item>
                    </Col>
                     <Col span={11}>
                      <FormAntd.Item>
                        <div className="flex flex-col">
                          <div className="text-slate-500">{"Driver License Expired"}</div>
                        <DatePicker
                            name="driverLicenseExpired"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.driverLicenseExpired ? dayjs(form.values.driverLicenseExpired, 'YYYY-MM-DD') : null}
                            onChange={driverLicenseExpired => {
                              const formattedDate = driverLicenseExpired ? dayjs(driverLicenseExpired).format('YYYY-MM-DD') : null;
                              form.setFieldValue('driverLicenseExpired', formattedDate);
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          </div>
                      </FormAntd.Item>
                    </Col>
                </Row>
                <FormAntd.Item>
                    <InputWithLabel 
                        name="addressPermanent"
                        label={<div>Permanent Address<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Permanent Address"
                        value={form?.values?.addressPermanent}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.addressPermanent && form.errors.addressPermanent ? (
                        <span className="text-error">{form.errors.addressPermanent}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="postalCodePermanent" 
                        label={<div>Postal Code<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="00000"
                        value={form?.values?.postalCodePermanent}
                        onChange={ (e) => {
                            const telNo = e.target.value;
                            const re = /^[0-9\b]+$/;
                            if (telNo === '' || re.test(telNo)) {
                                form.handleChange(e)
                            }
                        }}
                        onBlur={form.handleBlur}
                        maxLength={6}
                    />
                    {form.touched.postalCodePermanent && form.errors.postalCodePermanent ? (
                        <span className="text-error">{form.errors.postalCodePermanent}</span>
                    ) : null}
                </FormAntd.Item>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="cityPermanent" 
                                label={<div>City<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="City"
                                value={form?.values?.cityPermanent}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.cityPermanent && form.errors.cityPermanent ? (
                                <span className="text-error">{form.errors.cityPermanent}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="provincePermanent" 
                                label={<div>Province<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Province"
                                value={form?.values?.provincePermanent}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.provincePermanent && form.errors.provincePermanent ? (
                                <span className="text-error">{form.errors.provincePermanent}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <FormAntd.Item>
                    <InputWithLabel
                        name="addressCurrent" 
                        label={<div>Current Address<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Current Address"
                        value={form?.values?.addressCurrent}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.addressCurrent && form.errors.addressCurrent ? (
                        <span className="text-error">{form.errors.addressCurrent}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="postalCodeCurrent" 
                        label={<div>Postal Code<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="00000"
                        value={form?.values?.postalCodeCurrent}
                        //onChange={form.handleChange}
                        onChange={ (e) => {
                            const telNo = e.target.value;
                            const re = /^[0-9\b]+$/;
                            if (telNo === '' || re.test(telNo)) {
                                form.handleChange(e)
                            }
                        }}
                        onBlur={form.handleBlur}
                        maxLength={6}
                    />
                    {form.touched.postalCodeCurrent && form.errors.postalCodeCurrent ? (
                        <span className="text-error">{form.errors.postalCodeCurrent}</span>
                    ) : null}
                </FormAntd.Item>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="cityCurrent" 
                                label={<div>City<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="City"
                                value={form?.values?.cityCurrent}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.cityCurrent && form.errors.cityCurrent ? (
                                <span className="text-error">{form.errors.cityCurrent}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="provinceCurrent" 
                                label={<div>Province<span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Province"
                                value={form?.values?.provinceCurrent}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.provinceCurrent && form.errors.provinceCurrent ? (
                                <span className="text-error">{form.errors.provinceCurrent}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="HusbandWifeName" 
                                label={<div>Husband/Wife Name</div>}
                                placeholder="Husband/Wife Name"
                                value={form?.values?.HusbandWifeName}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.HusbandWifeName && form.errors.HusbandWifeName ? (
                                <span className="text-error">{form.errors.HusbandWifeName}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="HusbandWifeMobile" 
                                label={<div>Husband / Wife Phone Number</div>}
                                placeholder="08**********"
                                value={form?.values?.HusbandWifeMobile || ''}
                                onChange={ (e) => {
                                    const telNo = e.target.value;
                                    const re = /^[0-9\b]+$/;
                                    if (telNo === '' || re.test(telNo)) {
                                        form.handleChange(e)
                                    }
                                }}
                                maxLength={13}
                                minLength={10}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.HusbandWifeMobile && form.errors.HusbandWifeMobile ? (
                                <span className="text-error">{form.errors.HusbandWifeMobile}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="HusbandWifeEmail" 
                                label={<div>Husband/Wife Email</div>}
                                placeholder="Husband/Wife Email"
                                value={form?.values?.HusbandWifeEmail  || ''}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.HusbandWifeEmail && form.errors.HusbandWifeEmail ? (
                                <span className="text-error">{form.errors.HusbandWifeEmail}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="HusbandWifeOccupation" 
                                label={<div>Husband/Wife Occupation</div>}
                                placeholder="Husband/Wife Occupation"
                                value={form?.values?.HusbandWifeOccupation}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.HusbandWifeOccupation && form.errors.HusbandWifeOccupation ? (
                                <span className="text-error">{form.errors.HusbandWifeOccupation}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="Child" 
                                label={<div>Number of Children</div>}
                                placeholder="Number of Children"
                                value={form?.values?.Child}
                                onChange={ (e) => {
                                    const telNo = e.target.value;
                                    const re = /^[0-9\b]+$/;
                                    if (telNo === '' || re.test(telNo)) {
                                        form.handleChange(e)
                                    }
                                }}
                                maxLength={13}
                                minLength={10}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.Child && form.errors.Child ? (
                                <span className="text-error">{form.errors.Child}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="Child1" 
                                label={<div>Child Name 1</div>}
                                placeholder="Child 1"
                                value={form?.values?.Child1}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="Child2" 
                                label={<div>Child Name 2</div>}
                                placeholder="Child 2"
                                value={form?.values?.Child2}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                        </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                            <InputWithLabel
                                name="Child3" 
                                label={<div>Child Name 3</div>}
                                placeholder="Child 3"
                                value={form?.values?.Child3}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row>
                <Col span={11}>
                    {result && (
                        <FormAntd.Item>
                            <SelectOptionWithLabel 
                                name="status"
                                label={'Status'}
                                placeholder="Status"
                                items={[
                                    {
                                        value: 1,
                                        title: "Active"
                                    },
                                    {
                                        value: 0,
                                        title: "Inactive",
                                    },
                                ]}
                                value={form?.values?.Status === 1 ? "Active" : "Inactive" }
                                onChange={(val) => {
                                    form.setValues({
                                        ...form.values,
                                        Status: val,
                                        in_active_reason: val === 1 ? "" : form.values.in_active_reason,
                                        in_active_description: val === 1 ? "" : form.values.in_active_description,
                                        in_active_endate: val === 1 ? null : form.values.in_active_endate,
                                        in_active_backlist: val === 1 ? false : form.values.in_active_backlist
                                    });
                                    if (val === 0) {
                                        setIsModalOpen(true);
                                    }
                                    
                                }}
                                onBlur={form.handleBlur}
                            />
                        </FormAntd.Item>
                    )}

                    {
                        result && (
                            <FormAntd.Item>
                                <Modal title="Additional Info" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                <div className="mt-2">
                                <FormAntd.Item>
                                    <SelectOptionWithLabel 
                                            name="in_active_reason"
                                            label={<div>Inactive Reason<span style={{color:"#FF0000"}}> *</span></div>}
                                            placeholder={"In Active Reason"}
                                            items={[
                                                {
                                                    value: "Normal Resignation",
                                                    title: "Normal Resignation"
                                                },
                                                {
                                                    value: "Contract Expired",
                                                    title: "Contract Expired"
                                                },
                                                {
                                                    value: "Fired by the company",
                                                    title: "Fired by the company"
                                                },
                                                {
                                                    value: "Others",
                                                    title: "Others"
                                                },
                                            ]}
                                            value={form?.values?.in_active_reason ? form?.values?.in_active_reason :null} 
                                            onChange={(val) => {
                                                setErrors({ ...errors, in_active_reason: '' });
                                                form.setValues({
                                                    ...form.values,
                                                    in_active_reason: val,
                                                });
                                                if (val !== 'Others') {
                                                    setErrors({ ...errors, in_active_description: '' });
                                                }
                                                if (val) {
                                                    setErrors({ ...errors, in_active_reason: '' });
                                                }
                                            }}
                                            onBlur={form.handleBlur}
                                            
                                        />
                                         {errors.in_active_reason && <span className="text-error">{errors.in_active_reason}</span>}
                                    </FormAntd.Item>
                                    <FormAntd.Item>
                                        <TextAreaWithLabel
                                            name="in_active_description"
                                            label={"Description"}
                                            placeholder="Description"
                                            rows={4}
                                            onBlur={form.handleBlur}
                                            maxLength={500}
                                            onChange={(e) => {
                                                form.handleChange(e);
                                                // Penanganan kesalahan di sini
                                                setErrors({ ...errors, in_active_description: '' });
                                                if (e.target.value.length <= 500) {
                                                    form.setFieldValue('in_active_description', e.target.value);
                                                    form.setFieldError('in_active_description', ''); // Menghapus error jika nilai valid
                                                } else {
                                                    form.setFieldError('in_active_description', 'Description cannot exceed 500 characters');
                                                }
                                            }}
                                            value={form?.values?.in_active_description}
                                        />
                                        {errors.in_active_description && <span className="text-error">{errors.in_active_description}</span>}
                                    </FormAntd.Item>
                                     <FormAntd.Item>
                                        <div className="flex flex-col">
                                        <div className="text-slate-500">{"End Date"}<span className="text-red-500">*</span></div>
                                        <DatePicker
                                            name="in_active_endate"
                                            format="DD-MM-YYYY"
                                            placeholder="DD-MM-YYYY"
                                            
                                            allowClear={true}
                                            value={form.values.in_active_endate ? dayjs(form.values.in_active_endate, 'YYYY-MM-DD') : null}
                                            onChange={in_active_endate => {
                                            const formattedDate = in_active_endate ? dayjs(in_active_endate).format('YYYY-MM-DD') : null;
                                            form.setFieldValue('in_active_endate', formattedDate);
                                            setErrors({ ...errors, in_active_endate: '' });
                                            }}
                                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                                        />
                                        </div>
                                        {errors.in_active_endate && <span className="text-error">{errors.in_active_endate}</span>}
                                    </FormAntd.Item>
                                    <FormAntd.Item>
                                         <CheckboxOptionWithLabelrow
                                            name="in_active_backlist"
                                            onChange={(e) => {
                                                form.setValues({
                                                    ...form.values,
                                                    in_active_backlist: e.target.checked, // Update nilai in_active_backlist dengan status checkbox
                                                });
                                            }}
                                            label={'BlackList'}
                                            checked={form.values.in_active_backlist || false} // Pastikan nilai checked adalah boolean
                                        />
                                    </FormAntd.Item>
                                    </div>
                                </Modal>
                            </FormAntd.Item>
                        )
                    }
                        
                    </Col>
                </Row>
                <div className="footer-add">
                    <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={handleBack}>
                        Cancel
                    </Button>
                    <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                        Next
                    </Button>
                </div>
            </FormAntd>
        </>
    )
}
export default PersonalForm;
