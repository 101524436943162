/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Tabs, Timeline } from "antd";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import { getDetailPersonalAssessment, getDetailPersonalAssessmentProcess } from "../../../api/personalAssessment";
import "../styles.scss";
import DetailCardList from "../../../component/molecules/DetailCardList";
import DetailAssessmentActivityReport from "../../../component/molecules/DetailAssessmentActivityReport";

const PersonalAssessmentDetailReport = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  const locationState = useLocation()
  const {employeeNumber} = locationState.state
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));

  const [data, setData] = useState([]);
  const [dataProcess, setDataProcess] = useState([]);


  const fetchDetailPersonalAssessment = async () => {
    try {
      const res = await getDetailPersonalAssessment(id, employeeNumber);
      setData(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchDetailPersonalAssessmentProcess = async () => {
    try {
      const res = await getDetailPersonalAssessmentProcess(id);
      setDataProcess(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchDetailPersonalAssessment();
    fetchDetailPersonalAssessmentProcess();
  }, [company]);

  const dataBreadcrumb = [
    {
      title: "Performance Management - Personal Assessment Report",
      url: "/personal-assessment",
    },
    {
      title: "Detail Personal Assessment Report",
      url: "",
    },
  ];

  const detailData = [
    [
      { title: "Personal Assessment Number", value: data?.personal_assessment_number || "-" },
      { title: "NIK", value: data?.employee_number || "-" },
    ],
    [
      { title: "Employee Name", value: data?.employee_number || "-" },
      { title: "Employment Status", value: data?.employee_status || "-" },
    ],
    [
      { title: "Direct SPV", value: data?.from_direct_upline_name || "-" },
      { title: "Request Date ", value: data?.request_date || "-" },
    ],
    [
      { title: "Period ", value: data?.period_month || "-" },
      { title: "Type ", value: data?.type || "-" },
    ],
    [
      { title: "Current Branch ", value: data?.from_branch_name || "-" },
      { title: "Recommended Branch ", value: data?.recommended_branch_name || "-" },
    ],
    [
      { title: "Current Department ", value: data?.from_department_name || "-" },
      {
        title: "Recommended Department ",
        value: data?.recommended_department_name || "-",
      },
    ],
    [
      { title: "Current Division ", value: data?.from_division_name || "-" },
      {
        title: "Recommended Division ",
        value: data?.recommended_division_name || "-",
      },
    ],
    [
      { title: "Current Position ", value: data?.from_position_name || "-" },
      {
        title: "Recommended Position ",
        value: data?.recommended_position || "-",
      },
    ],
    [
      { title: "Current Level ", value: data?.from_position_name || "-" },
      { title: "Recommended Level ", value: data?.recommended_level_name || "-" },
    ],
    [
      { title: "Current Grade ", value: data?.from_grade || "-" },
      { title: "Recommended Grade ", value: data?.recommended_grade_value || "-" },
    ],
    [
      { title: "Current Direct SPV ", value: data?.from_direct_upline_name || "-" },
      {
        title: "Recommended Direct SPV ",
        value: data?.recommended_direct_upline_name || "-",
      },
    ],   
  ];

  if(data?.status === 'Cancelled' || data?.status === 'Rejected'){
    detailData.push([
      { title: "Reason ", value: data?.cancel_reason || "-" },
      {
        title: "Attachment ",
        value: data?.cancel_attachment || "-",
      }
    ],
    [{ title: "Status ", value: data?.status || "-" }])
  }else{
    detailData.push([{ title: "Status ", value: data?.status || "-" }])
  }

  let items = [
    {
      key: "1",
      label: "Assessment Detail",
      children: (
        <div className="detail-wrapper">
          <div className="w-auto bg-white px-6  mt-3">
            <DetailCardList detailData={detailData} />
            <Col span={24}>
              <div className="detail-card">
                <div className="title">Status Detail</div>
                <div className="subtitle mt-5">
                  <Timeline
                    items={data?.tracking
                      ?.map((item) => ({
                        children: (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: ` ${item?.wording} <br />${
                                item?.formatted_date
                                  ? item?.formatted_date
                                  : "-"
                              }`,
                            }}
                          />
                        ),
                      }))
                    }
                  />
                </div>
              </div>
            </Col>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Assessment Process",
      disabled: data?.status === 'Cancelled' || data?.status === 'Rejected',
      children: (
        <div className="detail-wrapper">
          <div className="w-auto bg-white px-6  mt-3">
            <DetailAssessmentActivityReport detailData={dataProcess} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Detail Personal Assessment Report
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <>
        <Tabs defaultActiveKey="1" className="tab-wrapper" items={items} />
      </>
    </>
  );
};

export default PersonalAssessmentDetailReport;
