import { Button, Col, Form as FormAntd, Row, Select, Checkbox,DatePicker, Spin} from "antd";
import { useEffect, useRef, useState } from "react";
import { getBranch } from "../../../api/branch";
import { getDepartment } from "../../../api/department";
import { getDivision } from "../../../api/division";
import { getLevel } from "../../../api/level";
import { getPosition } from "../../../api/position";
import  { InputWithLabel, SelectOptionWithLabel, SelectSearchWithLabel, SelectSearchWithLabelSwitch } from "../../../component/Form/Input";
import moment from "moment";
import { getSupervisor } from "../../../api/employee";
import {decode} from 'html-entities';
import { decryptData } from "../../../helper/cryptojs";
import dayjs from "dayjs";
const EmployeeForm = ({form, handleBack, fetching,result,employeeid, switchStat}) => {
    const [listBranch, setListBranch] = useState('');
    const [listDepartment, setListDepartment] = useState('');
    const [listDivision, setListDivision] = useState('');
    const [listPosition, setListPosition] = useState('');
    const [listLevel, setListLevel] = useState('');
    const [toggleSwitch, setToggleSwitch] = useState(false)
    const [loadingSwitch, setLoadingSwitch] = useState(false);
    const [listSupervisor, setListSupervisor] = useState('');
    const [loading, setLoading] = useState(false)
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const employeeNumber =  decryptData(sessionStorage.getItem('employeeNumber'));
    const multiCompanyAccess = decryptData(localStorage.getItem('MultiCompanyAccess'))
    const multiCompanyLoad = decryptData(localStorage.getItem('MultiCompany'))
    const [optionsBranch, setOptionsBranch] = useState([]);
    const [optionsDepartement, setOptionsDepartement] = useState([]);
    const [optionsDevision, setOptionsDevision] = useState([]);
    const [optionsSupervisor, setOptionsSupervisor] = useState([]);
    const [optionsLevel, setOptionsLevel] = useState([]);
    const [optionsPosition, setOptionsPosition] = useState([]);
    const { Option } = Select;
    const [statusList, setStatusList] = useState([
        {
            value: "Contract",
            title: "Contract"
        },
        {
            value: "Permanent",
            title: "Permanent"
        },
        {
            value: "Probation",
            title: "Probation"
        },
        {
            value: "Intern",
            title: "Intern"
        },
    ])
    useEffect(() => {
        if(result) {
            const currentStatus = form.values.employeeStatus
            let returned = []
            switch(currentStatus) {
                case "Permanent":
                    returned = ["Permanent"]
                    break
                case "Contract":
                    returned = ["Permanent", "Contract"]
                    break
                case "Probation":
                    returned = ["Permanent", "Contract", "Probation"]
                    break
                default:
                    returned = ["Permanent", "Contract", "Probation", "Intern"]
            }
            const newStatusList = statusList.filter(item => {
                return returned.indexOf(item.value) > -1
            })
            setStatusList(newStatusList)
        }
    }, [])
    const departmentList = async () => {
		try {
		  const res = await getDepartment("", -1, 0, "", company)
		  const data = res?.data?.data
          setOptionsDepartement(data)
		} catch (err) {
		  console.log(err);
		}
	};
	
     const handleSelectChangeDepartment = (value) => {
        setListDepartment(value);
        form.setValues({
            ...form.values,
            department: value,
            NameDevision: '',
            NameDirectSupervisor: '',
            NamePosition: ''

        })
        setListDivision('')
        setListSupervisor('')
        setListPosition('')
    };


    const branchList = async () => {
		try {
		  const res = await getBranch("", 1000, 0,"",company)
		  const data = res?.data?.data
          setOptionsBranch(data)
		} catch (err) {
		  console.log(err);
		}
	};

    const handleSelectChangeBranch = (value) => {
        setListBranch(value);
        form.setValues({
            ...form.values,
            branch: value,
        })
    };
  

    const divisionList = async () => {
		try {
		  const res = await getDivision("", 1000, 0, "", form?.values?.department,company)
		  const data = res?.data?.data
          setOptionsDevision(data)
		} catch (err) {
		  console.log(err);
		}
	};

    const handleSelectChangeDevision = (value) => {
        setListDivision(value);
        form.setValues({
            ...form.values,
            CodeDivision: value,
            NameDirectSupervisor: '',
            NamePosition: ''
        })
        setListSupervisor('')
        setListPosition('')
        
    };

   

    const positionList = async () => {
		try {
		  const res = await getPosition("", 1000, 0, "", form?.values?.CodeDivision,company)
		  const data = res?.data?.data
          setOptionsPosition(data)
		//   setListPosition(data.map((item) => {
		// 	return {
		// 		value : item.code,
		// 		title : item.position_name
		// 	}
		// }))
		} catch (err) {
		  console.log(err);
		}
	};

    const handleSelectChangePosition = (value) => {
        setListPosition(value);
        form.setValues({
            ...form.values,
            CodePosition: value,
        })
    };
    

    const levelList = async () => {
		try {
		  const res = await getLevel("", 1000, 0, 1,company)
		  const data = res?.data?.data
          setOptionsLevel(data)
		//   setListLevel(data.map((item) => {
		// 	return {
		// 		value : item.code,
		// 		title : item.name
		// 	}
		// }))
		} catch (err) {
		  console.log(err);
		}
	};


    const handleSelectChangeLevel = (value,sequence) => {
        setListLevel(value);
        form.setValues({
            ...form.values,
            LevelCode: value,
            LevelSequence: sequence.sequence,
        })
    };


    const supervisorList = async (bool = false, code) => {
        setLoading(true)
		try {
		  const res = bool ? await getSupervisor(company,employeeNumber, code, multiCompanyAccess, multiCompanyLoad) : await getSupervisor(company,employeeNumber, code)
		  const data = res?.data?.data
          setOptionsSupervisor(data)
		//   setListSupervisor(data.map((item) => {
		// 	return {
		// 		value : item.employee_number,
		// 		title : item.full_name
		// 	}
		// }))
		} catch (err) {
		  console.log(err);
		}finally {
            setLoading(false)
        }
	};

   
    const handleSelectChangeSupervisor = (value) => {
        setListSupervisor(value);
        form.setValues({
            ...form.values,
            SupervisorCode: value,
        })
    };

    const changeMode = async (bool) => {
        setLoadingSwitch(true);
        if(!bool){
            setListSupervisor(null);
            form.setValues({
                ...form.values,
                SupervisorCode: null,
            })
        }
        setToggleSwitch(bool)
        supervisorList(bool, listLevel)
        setLoadingSwitch(false)
    }

    useEffect(() => {
        departmentList();
        branchList();
        // divisionList();
        levelList();
        // positionList();
	}, []);

    useEffect(() => {
        // form.setValues({
        //     ...form.values,
        //     position: "",
        //     directSupervisor: ""
        //     })
        positionList();
    },[form?.values?.CodeDivision])
    
    
    useEffect(() => {
        if(form?.values?.LevelCode){
            setListLevel(form?.values?.LevelCode)
        }
        if(switchStat){
            setToggleSwitch(switchStat)
        }
        
        supervisorList(switchStat, form?.values?.LevelCode);
    },[form?.values?.LevelCode])

    useEffect(() => {
        // form.setValues({
        //     ...form.values,
        //     division: "",
        //     position: "",
        //     directSupervisor: "",
        //     level: "",
        //     })
        divisionList()
    },[form?.values?.department])


    return (
        <FormAntd
            onFinish={form.handleSubmit}
            className="add-wrapper"
        >
            {loading ?
                <Row justify={'center'} align={'center'}>
                    <Spin />
                </Row>
            :
                <>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="employee_number"
                            label={ employeeid === 1 && 5 ? <div>NIK <span style={{color:"#FF0000"}}> *</span></div> : "NIK"}
                            placeholder="NIK"
                            value={form?.values?.employee_number}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            disabled={result ? true : false || (employeeid !== 1 && employeeid !== 5)? true :false}
                        />
                    {(employeeid === 1 && employeeid === 5) && (
                        form.touched.employee_number && form.errors.employee_number ? (
                            <span className="text-error">{form.errors.employee_number}</span>
                        ) : null
                        )}
                    </FormAntd.Item>
                    <Row justify={"space-between"}>
                        <Col span={11}>
                            <FormAntd.Item>
                                <SelectOptionWithLabel 
                                    name="employeeStatus"
                                    label={<div>Employee Status<span style={{color:"#FF0000"}}> *</span></div>}
                                    allowClear
                                    placeholder="Employee Status"
                                    items={statusList}
                                    value={form?.values?.employeeStatus ? form?.values?.employeeStatus : null}
                                    onChange={(val) => {
                                        form.setValues({
                                        ...form.values,
                                        employeeStatus: val,
                                        })
                                        if(val === "Permanent" && !form.values.endDate) {
                                            let endDate = moment().format("YYYY-MM-DD")
                                            form.setValues({
                                                ...form.values,
                                                endDate: endDate,
                                                employeeStatus: val
                                            })
                                        }
                                    }}
                                    onBlur={form.handleBlur}
                                />
                                {form.touched.employeeStatus && form.errors.employeeStatus ? (
                                    <span className="text-error">{form.errors.employeeStatus}</span>
                                ) : null}
                            </FormAntd.Item>
                        </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                                <div className="flex flex-col">
                                <div className="text-slate-500">{"Join Date"}<span className="text-red-500">*</span></div>
                                <DatePicker
                                    name="joinDate"
                                    format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"
                                    
                                    allowClear={true}
                                    value={form.values.joinDate ? dayjs(form.values.joinDate, 'YYYY-MM-DD') : null}
                                    onChange={joinDate => {
                                    const formattedDate = joinDate ? dayjs(joinDate).format('YYYY-MM-DD') : null;
                                    form.setFieldValue('joinDate', formattedDate);
                                    }}
                                    className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                                />
                                </div>
                                {form.touched.joinDate && form.errors.joinDate ? (
                                        <span className="text-error">{form.errors.joinDate}</span>
                                ) : null}
                            </FormAntd.Item>
                            </Col>
                    </Row>
                    <Row justify={"space-between"}>
                        <Col span={11}>
                            <FormAntd.Item>
                                <div className="flex flex-col">
                                <div className="text-slate-500">{"Start Date"}<span className="text-red-500">*</span></div>
                                <DatePicker
                                    name="startDate"
                                    format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"
                                    
                                    allowClear={true}
                                    value={form.values.startDate ? dayjs(form.values.startDate, 'YYYY-MM-DD') : null}
                                    onChange={startDate => {
                                    const formattedDate = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
                                    form.setFieldValue('startDate', formattedDate);
                                    }}
                                    className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                                />
                                </div>
                                {form.touched.startDate && form.errors.startDate ? (
                                        <span className="text-error">{form.errors.startDate}</span>
                                ) : null}
                            </FormAntd.Item>
                            </Col>
                        <Col span={11}>
                            {form.values.employeeStatus === 'Permanent' ? 
                            <FormAntd.Item>
                            <div className="flex flex-col">
                            <div className="text-slate-500">{"Sign Date"}<span className="text-red-500">*</span></div>
                            <DatePicker
                                name="signDate"
                                format="DD-MM-YYYY"
                                placeholder="DD-MM-YYYY"
                                
                                allowClear={true}
                                value={form.values.signDate ? dayjs(form.values.signDate, 'YYYY-MM-DD') : null}
                                onChange={signDate => {
                                const formattedDate = signDate ? dayjs(signDate).format('YYYY-MM-DD') : null;
                                form.setFieldValue('signDate', formattedDate);
                                }}
                                className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                            />
                            </div>
                            {form.touched.signDate && form.errors.signDate ? (
                                    <span className="text-error">{form.errors.signDate}</span>
                            ) : null}
                        </FormAntd.Item>
                            :
                            <FormAntd.Item>
                            <div className="flex flex-col">
                            <div className="text-slate-500">{"End Date"}<span className="text-red-500">*</span></div>
                            <DatePicker
                                name="endDate"
                                format="DD-MM-YYYY"
                                placeholder="DD-MM-YYYY"
                                
                                allowClear={true}
                                value={form.values.endDate ? dayjs(form.values.endDate, 'YYYY-MM-DD') : null}
                                onChange={endDate => {
                                const formattedDate = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
                                form.setFieldValue('endDate', formattedDate);
                                }}
                                className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                            />
                            </div>
                            {form.touched.endDate && form.errors.endDate ? (
                                    <span className="text-error">{form.errors.endDate}</span>
                            ) : null}
                        </FormAntd.Item>
                            }
                        </Col>
                    </Row>
                    <Row justify={"space-between"}>
                        <Col span={11}>
                            <FormAntd.Item>
                                <SelectSearchWithLabel 
                                        labelname={<div>Branch <span style={{color:"#FF0000"}}> *</span></div>}
                                        name="branch"
                                        placeholder={'Branch'}
                                        onChange={handleSelectChangeBranch}
                                        value={listBranch ? listBranch : form.values.branchName || form.values.branchName ? form.values.branchName : null  }
                                        onBlur={form.handleBlur}
                                >
                                    {optionsBranch.map(option => (
                                        <Option key={option.code} value={option.code}>{option.name}</Option>
                                        ))} 
                                </SelectSearchWithLabel>
                                {form.touched.branch && form.errors.branch ? (
                                    <span className="text-error">{form.errors.branch}</span>
                                ) : null}
                            </FormAntd.Item>
                        </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                                <SelectSearchWithLabel 
                                    labelname={<div>Department<span style={{color:"#FF0000"}}> *</span></div>}
                                    name="department"
                                    placeholder={'Department'}
                                    value={ listDepartment ? listDepartment : form?.values?.departmentName ? listDepartment ? listDepartment : form?.values?.departmentName : null}
                                    onChange={handleSelectChangeDepartment}
                                    onBlur={form.handleBlur}
                                >
                                    {optionsDepartement.map(option => (
                                        <Option key={option.code} value={option.code}>{decode(option.department_name)}</Option>
                                ))} 
                                </SelectSearchWithLabel>
                                {form.touched.department && form.errors.department ? (
                                    <span className="text-error">{form.errors.department}</span>
                                ) : null}
                            </FormAntd.Item>
                        </Col>
                    </Row>
                    <Row justify={"space-between"}>
                        <Col span={11}>
                            <FormAntd.Item>
                                <SelectSearchWithLabel 
                                    labelname={<div>Division<span style={{color:"#FF0000"}}> *</span></div>}
                                    name="CodeDivision"
                                    placeholder="Division"
                                    value={ listDivision ? listDivision : form?.values?.NameDevision ? listDivision ? listDivision : form?.values?.NameDevision : null}
                                    onChange={handleSelectChangeDevision}
                                    onBlur={form.handleBlur}
                                >
                                    {optionsDevision.map(option => (
                                        <Option key={option.code} value={option.code}>{decode(option.division_name)}</Option>
                                ))} 
                                </SelectSearchWithLabel>
                                {form.touched.CodeDivision && form.errors.CodeDivision ? (
                                    <span className="text-error">{form.errors.CodeDivision}</span>
                                ) : null}
                            </FormAntd.Item>
                        </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                                <SelectSearchWithLabelSwitch 
                                    labelname={<div>Direct Supervisor<span style={{color:"#FF0000"}}> *</span></div>}
                                    name="SupervisorCode"
                                    switchProps={{
                                        checked : toggleSwitch,
                                        onChange : (c, e) => {
                                            changeMode(c)
                                        },
                                        loading : loadingSwitch,
                                        tooltipMessage : 'Toggle to display multi-company approver options',
                                        size : 'small',
                                        className :'mx-2',
                                        multiCompanyAccess: multiCompanyAccess
                                    }}
                                    showSearch
                                    placeholder="Direct Supervisor"
                                    value={ form?.values?.SupervisorCode ? listSupervisor ? listSupervisor : form?.values?.SupervisorCode : null}
                                    onChange={handleSelectChangeSupervisor}
                                    onBlur={form.handleBlur}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    labelRender={(e)=>{
                                        const {label, key} = e
                                        return label && key?(
                                            <div 
                                                style={{
                                                    display: 'flex', 
                                                    flexDirection: 'column', 
                                                    alignItems: 'flex-start', 
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <span style={{fontSize: 14, height: '1rem'}}>{label[0].props.children}</span>
                                                <span style={{fontSize: 10}}>{label[1].props.children}</span>
                                            </div>
                                        ): <div>{e.value ? e.value : 'None'}</div>
                                    }}
                                >
                                    {optionsSupervisor.map(option => (
                                        <Option key={option.employee_number} value={option.employee_number}><div style={{fontSize: 14}}>{option.full_name}</div><div style={{fontSize: 10}}>{option.company_name}</div></Option>
                                ))} 
                                </SelectSearchWithLabelSwitch>
                    {form.touched.SupervisorCode && form.errors.SupervisorCode ? (
                                    <span className="text-error">{form.errors.SupervisorCode}</span>
                                ) : null}
                                <div>
                                    <Checkbox 
                                        name="updateApproval"
                                        onChange={e => {
                                            form.handleChange(e);
                                        }}
                                        checked={form?.values.updateApproval}
                                        className="mt-2"
                                    >
                                        <div className="label-input mb-0">Set as Approval 1</div>
                                    </Checkbox>
                                </div>
                            </FormAntd.Item>
                        </Col>
                    </Row>
                    <Row justify={"space-between"}>
                        <Col span={11}>
                            <FormAntd.Item>
                                <SelectSearchWithLabel 
                                    labelname={<div>Level<span style={{color:"#FF0000"}}> *</span></div>}
                                    name="NameLevel"
                                    placeholder="Level"
                                    value={ listLevel ? listLevel : form?.values?.NameLevel ? listLevel ? listLevel : form?.values?.NameLevel :null}
                                    onChange={handleSelectChangeLevel}
                                    onBlur={form.handleBlur}
                                >
                                    {optionsLevel.map(option => (
                                        <Option key={option.code} value={option.code} sequence={option.sequence}>{option.name}</Option>
                                ))} 
                                </SelectSearchWithLabel>
                                {form.touched.LevelCode && form.errors.LevelCode ? (
                                    <span className="text-error">{form.errors.LevelCode}</span>
                                ) : null}
                            </FormAntd.Item>
                        </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                                <SelectSearchWithLabel 
                                    labelname={<div>Position<span style={{color:"#FF0000"}}> *</span></div>}
                                    name="NamePosition"
                                    placeholder="Position"
                                    value={ listPosition ? listPosition : form?.values?.NamePosition ? listPosition ? listPosition : form?.values?.NamePosition : null}
                                    onChange={handleSelectChangePosition}
                                    onBlur={form.handleBlur}
                                >
                                    {optionsPosition.map(option => (
                                        <Option key={option.code} value={option.code}>{option.position_name}</Option>
                                ))} 
                                </SelectSearchWithLabel>
                                {form.touched.CodePosition && form.errors.CodePosition ? (
                                    <span className="text-error">{form.errors.CodePosition}</span>
                                ) : null}
                            </FormAntd.Item>
                        </Col>
                    </Row>
                </>
            }
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={handleBack}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Next
                </Button>
            </div>
        </FormAntd>
    )
}

export default EmployeeForm;
