import { Button, Col, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
// import "./styles.scss"
import { useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined , EditFilled,StopOutlined} from "@ant-design/icons";
export default function Announcement() {
	const navigate = useNavigate();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(0)
	const previousPage = useRef(1);
	const [search, setSearch] = useState("")
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.leavetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'TItle',
			dataIndex: 'tItle',
			key: 'tItle',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtimetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},

	
		{
			title: 'Effective date',
			dataIndex: 'effective_date',
			key: 'effective_date',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/announcement/detail/${encodeURIComponent("id?" + record.leavetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Expired date',
			dataIndex: 'expired_date',
			key: 'expired_date',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/announcement/detail/${encodeURIComponent("id?" + record.leavetransaction_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Action',
			key: 'action',
			width: "25px",
			render: (_,record,index) => {
				return RoleAccessnnouncementUpdate && RoleAccessnnouncementDelete ? (
					<Row justify={"space-between"}>
						<div className="icon-action" onClick={() => navigate(`/announcement/edit-announcement/${encodeURIComponent("id?" + record.attendance_id)}`)}>
							<EditFilled />
						</div>
					</Row>
				) : (
					<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
					</Row>
				)
			}
		},
	];
	const dataBreadcrumb = [
		{
			title: "Announcement",
			url: "/announcement"
		},
	];


	useEffect(() => {
		previousPage.current = page;
	}, [page]);

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
	}
	const RoleAccessnnouncementCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAnnouncementCreate'));
	const RoleAccessnnouncementUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAnnouncementUpdate'));
	const RoleAccessnnouncementDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAnnouncementDelete'));
    return (
        <>
            <div className="title-section">
				Announcement
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col  md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
								<InputWithIcon
									type="text"
									placeholder="Search announcement"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									onChange={onChangeSearch}
								/>
							{RoleAccessnnouncementCreate &&(
								<Button className="btn-sh-p" type="primary" shape="circle"  onClick={() => navigate('/announcement/add-announcement')}icon={<PlusOutlined />} size={"large"} />
							)}
						
						</Row>
					</Col>
				
				</Row>
				<div className="table-wrapper">
					<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading}/>
					<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
					<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
					</div>
				</div>
			</div>
        </>
    )
}