import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const getPtkpStatus = async (
  company,
  page,
  offset,
  limit,
  search
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    draw: page,
    start: offset,
    length: limit,
    search_by: "ptkp_type",
    search_value: search?.length > 0 ? search : undefined,
  };
  return master.post("/ptkp-status", payload);
};


const AddPtkpStatus = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: data.companyCode,
    ptkp_type: data.ptkp_type,
    ptkp_status: data.ptkp_status,
    ptkp_value: data.ptkp_value.replace(/,/g, ""),
    created_by: user,
  };

  return master.post("/ptkp-status-create", payload);
};



const EditPtkpStatus = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: data.companyCode,
    m_ptkp_status_id: id,
    ptkp_type: data.ptkp_type,
    ptkp_status: data.ptkp_status,
    ptkp_value: data.ptkp_value.replace(/,/g, ""),
    modified_by: user,
  };

  return master.post("/ptkp-status-update", payload);
};

const getPtkpStatusDetail = async (id) => {
  let payload = {
    m_ptkp_status_id: id,
  };
  return master.post("/ptkp-status-detail", payload);
};

const DeletePtkpStatus = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    m_ptkp_status_id: id,
    deleted_by: user,
  };
  return master.post("/ptkp-status-delete", payload);
};

export {
  getPtkpStatus,
  DeletePtkpStatus,
  AddPtkpStatus,
  EditPtkpStatus,
  getPtkpStatusDetail,
};
