import { Button, Col, Form as FormAntd, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { InputWithLabel, SelectOptionWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addDivision, editDivision, getDivisionDetail } from "../../api/division";
import { getDepartment } from "../../api/department";
import {decode} from 'html-entities';
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";

const FormAddDivision = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];;
    const decodee = decodeURIComponent(result)
    var dec = decodee.lastIndexOf('?');
    var id = decodee.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const [listDepartment, setListDepartment] = useState([]);
    const BestpraticeUseEffect = useRef(false)
	const BestpraticeUseEffect1 = useRef(false)
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));

    const divisionList = async () => {
		setFetching(true);
		try {
		  const res = await getDivisionDetail(id)
		  setDetail(res?.data?.data[0]);
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if(result){
                divisionList();
            }
          }
       
	}, [flag]);

    const departmentList = async () => {
		setFetching(true);
		try {
		  const res = await getDepartment("", -1, 0, 1, company)
		  const data = res?.data?.data
		  setListDepartment(data.map((item) => {
			return {
				value : item.code,
				title : decode(item.department_name),
			}
		}))
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	};
	
	useEffect(() => {
        if (!BestpraticeUseEffect1.current) {
            BestpraticeUseEffect1.current = true;
          } else {
            departmentList();
          }
	}, []);

    const validationUser = yup.object().shape({
        name: yup.string().required("Field is required"),
        departmentCode: yup.string().required("Field is required"),
    });
    const form = useFormik({
        initialValues: {
            name: result ? detail?.name : "",
            departmentCode: result ? detail?.department_code : undefined,
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
            try {
                const res = result ? await editDivision(values, id) : await addDivision(values);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Division successfully updated" : "Division successfully saved",
                    });
                    navigate('/division')
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });

    const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/division"
		},
		{
			title: "Division",
			url: "/division"
		},
		{
			title: `${result ?  'Edit Division' : 'Add Division'}` ,
			// url: `${ result ?  '/division/edit-division' : '/division/add-division'}`
		},
	]
  

    return (
      <>
        <div className="title-section">
				{result ? "Edit Division" : "Add Division"}
		</div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Name<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Name"
                        value={form?.values?.name}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.name && form.errors.name ? (
                        <span className="text-error">{form.errors.name}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <SelectOptionWithLabel 
                        name="departmentCode"
                        label={<div>Department<span style={{color:"#FF0000"}}> *</span></div>}
                        items={listDepartment}
                        value={form?.values?.departmentCode}
                        onChange={(val) =>
                            form.setValues({
                            ...form.values,
                            departmentCode: val,
                            })
                        }
                        onBlur={form.handleBlur}
                    />
                    {form.touched.departmentCode && form.errors.departmentCode ? (
                        <span className="text-error">{form.errors.departmentCode}</span>
                    ) : null}
                </FormAntd.Item>
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/division')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
      </>
    )
}

export default FormAddDivision;