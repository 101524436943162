import { Button, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { getBranchDetail } from "../../../api/branch";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import PinMapIcon from "../../../assets/img/pin-map.png";
import "leaflet/dist/leaflet.css";
import axios from "axios";
const BranchDetail = () => {
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const navigate = useNavigate()
    const [address, setAddress] = useState(null);
    const positionList = async () => {
		setLoading(true);
		try {
		  const res = await getBranchDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
      const GoToLocation = ({ coords }) => {
        const map = useMap();
        map.setView(coords, map.getZoom());
        return null;
      };

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            positionList();
          }
		
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data",
			url: "/branch"
		},
		{
			title: "Branch",
			url: "/branch"
		},
        {
			title: "Branch Detail",
			// url: "/branch-detail"
		},
	];
      useEffect(() => {
        fetch(`https://www.mapquestapi.com/geocoding/v1/reverse?key=KoodRZ4qfaRBxICWAnKWoCh6rP4usdgU&location=${detail.latitude},${detail.longitude}`)
          .then(response => response.json())
          .then(data => {
            setAddress(data.results[0].locations[0].street);
          })
          .catch(error => {
            console.error('Error fetching address:', error);
          });
      }, [detail.latitude,detail.longitude]);

    return (
        <>
            <div className="title-section">
            <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/branch')} icon={<ArrowLeftOutlined />} size={"large"} />
				Branch Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Branch Name</div>
                            <div className="subtitle">{detail?.name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Address</div>
                            <div className="subtitle">{detail?.address || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Postal Code</div>
                            <div className="subtitle">{detail?.postal_code || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Province</div>
                            <div className="subtitle">{detail?.province || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">City</div>
                            <div className="subtitle">{detail?.city || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Phone Number</div>
                            <div className="subtitle">{detail?.phone_number || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Email</div>
                            <div className="subtitle">{detail?.email || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Radius</div>
                            <div className="subtitle">{detail?.radius   || "-"}<span className="text-bold">  {detail?.radius ? "(M)" : ""}</span></div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Maps</div>
                            <div className="subtitle">
                            <MapContainer
                                center={[detail.latitude ? detail.latitude : 0, detail.longitude ? detail.longitude : 0 ]}
                                zoom={14}
                                style={{ height: '400px', width: '97%' }}
                                className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg"
                            >
                                <TileLayer
                                    attribution="Google Maps"
                                    url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                                />
                                <Marker
                                    draggable={true}
                                    position={[detail.latitude ? detail.latitude : 0, detail.longitude ? detail.longitude : 0 ]}
                                    icon={L.icon({
                                        iconUrl: PinMapIcon,
                                        iconSize: [32, 32],
                                        iconAnchor: [16, 32],
                                        popupAnchor: [0, -32]
                                    })}
                                    >
                                        <Popup>{`specified location : ${address}`}</Popup>
                                    </Marker>
                                <GoToLocation coords={[detail.latitude ? detail.latitude : 0, detail.longitude ? detail.longitude : 0 ]} />
                            </MapContainer>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default BranchDetail