import { Button, Col, Form as FormAntd, Row, Select, message,DatePicker} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  SelectSearchWithLabel,
  TextAreaWithLabel,
  TimeWithLabel,
  InputWithLabel
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import "./styles.scss";
import moment from "moment";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import {
  addAttendanceRequest,
  getListScheduleNikDate,
  getListTimeRequestEmployeeNumber,
} from "../../api/timeRequest";
import dayjs from "dayjs";
const FormAddTimeRequest = () => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const inputRef = useRef(null);
  const [flag, setFlag] = useState(0);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [detail, setDetail] = useState([]);
  const { Option } = Select;
  const [fileName, setFileName] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const [dataSchedule, setDatSchedule] = useState([]);
  const BestpraticeUseEffect = useRef(false);

  const employeeNumberActive = async () => {
    try {
      const res = await getListTimeRequestEmployeeNumber(companyStore);
      const data = res?.data?.data;
      setOptions(data);
    } catch (err) {
      console.log(err);
    }
  };
  const employeeSchedule = async () => {
    const employeeNumberSchedule = sessionStorage.getItem("EmployeeSchedule");
    const DateSchedule = sessionStorage.getItem("DateSchedule");
    try {
      const res = await getListScheduleNikDate(
        employeeNumberSchedule,
        DateSchedule
      );
      const data = res?.data?.data;
      console.log("test chedule", data);
      setDatSchedule(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      employeeNumberActive();
      employeeSchedule();
    }
  }, [flag]);

  const handleSelectChange = (value) => {
    setSelectedOption(value);
    sessionStorage.setItem("EmployeeSchedule", value);
    form.setValues({
      ...form.values,
      EmployeeNumber: value,
      checkIn: "",
      checkOut: "",
      date: sessionStorage.removeItem("DateSchedule"),
    });
    // Mendapatkan nilai full_name terkait dari data API
    const selectedData = options.find(
      (option) => option.employee_number === value
    );
    if (selectedData) {
      form.setFieldValue('EmployeeName',selectedData ? selectedData.full_name : '');
      form.setFieldValue('DepartmentName',selectedData ? selectedData.department_name : '');
      form.setFieldValue('DivisionName',selectedData ? selectedData.division_name : '');
      form.setFieldValue('PositionName',selectedData ? selectedData.position_name : '');
    } else {
      form.setFieldValue('EmployeeName');
      form.setFieldValue('DepartmentName');
      form.setFieldValue('DivisionName');
      form.setFieldValue('PositionName');
    }
    employeeSchedule();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      setFileName(file.name);
      form.setValues({
        ...form.values,
        AttechmentUpload: e.currentTarget.files[0],
      });
    } else {
      setFileName(null);
      message.error(
        "Please upload a PDF,JPG,PNG file with a maximum size of 2 MB"
      );
    }
  };

  const handleFileDelete = () => {
    setFileName(null);
  };

  const form = useFormik({
    initialValues: {
      EmployeeNumber: "",
      date: null,
      checkIn: null,
      checkOut: null,
      description: "",
      AttechmentUpload: null,
    },
    enableReinitialize: true,
    // validationSchema: validationUser,
    onSubmit: async (values) => {
      // setFetching(true)
      try {
        const res = await addAttendanceRequest(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: "Add Data Success",
          });
          sessionStorage.removeItem("EmployeeSchedule");
          sessionStorage.removeItem("DateSchedule");
          navigate("/time-request");
          form.resetForm();
          setFlag((prev) => prev + 1);
          // window.location.reload()
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Request Not Found",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Attendance Management",
      url: "/time-request",
    },
    {
      title: "Time Request",
      url: "/time-request",
    },
    {
      title: `${result ? "Edit Attendance Request" : "Add Attendance Request"}`,
    },
  ];
  return (
    <>
      <div className="title-section">{"Add Attendance Request"}</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <Col>
            <Row justify={"space-between"}>
              <Col span={24}>
                <FormAntd.Item>
                  <SelectSearchWithLabel
                    name="EmployeeNumber"
                    labelname={
                      <div>
                        Employee<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    showSearch
                    allowClear
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    value={
                      selectedOption
                        ? selectedOption
                        : detail?.map((data) => data.employee_number)[0]
                    }
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                    {options.map((option) => (
                      <Option
                        key={option.employee_number}
                        value={option.employee_number}>
                        {option.employee_name}
                      </Option>
                    ))}
                  </SelectSearchWithLabel>
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="DepartmentName"
                                    label={"Departement"} 
                                    placeholder="Departement"
                                    value={form.values.DepartmentName}
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="DivisionName"
                                    label={"Division"} 
                                    value={form.values.DivisionName}
                                    placeholder="Division"
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="PositionName"
                                    label={"Position"}
                                    value={form.values.PositionName} 
                                    placeholder="Position"
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                      <FormAntd.Item>
                        <div className="flex flex-col">
                          <div className="text-slate-500">{"Date"}<span className="text-red-500">*</span></div>
                        <DatePicker
                            name="date"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.date ? dayjs(form.values.date, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('date', formattedDate);
                              sessionStorage.setItem("DateSchedule", formattedDate);
                              employeeSchedule();
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          </div>
                      </FormAntd.Item>
                    </Col>
                </Row>

            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <div className="input-wrapper">
                    <div className="label-input items-center">{"Schedule"}</div>
                    {dataSchedule
                      ? dataSchedule.schedule
                        ? dataSchedule.schedule
                        : "-"
                      : "-"}
                    {dataSchedule && dataSchedule.time_off && (
                      <div>
                        Time 0ff -{" "}
                        {dataSchedule
                          ? dataSchedule.time_off
                            ? dataSchedule.time_off
                            : "-"
                          : "-"}
                      </div>
                    )}
                  </div>
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <TimeWithLabel
                    name="checkIn"
                    label={"Check In"}
                    value={
                      form?.values?.checkIn
                        ? moment(form?.values?.checkIn, "HH:mm").format("HH:mm")
                        : null
                        ? form?.values?.checkIn
                          ? moment(form?.values?.checkIn, "HH:mm").format(
                              "HH:mm"
                            )
                          : null
                        : dataSchedule &&
                          moment(
                            dataSchedule.previous_check_in,
                            "HH:mm"
                          ).format("HH:mm")
                        ? moment(
                            dataSchedule.previous_check_in,
                            "HH:mm"
                          ).format("HH:mm")
                        : ""
                    }
                    onChange={form.handleChange}
                    disabled={
                      form.values.TimeOff ||
                      (dataSchedule && dataSchedule.time_off === "Full Day")
                    }
                  />

                  {form.touched.checkIn && form.errors.checkIn ? (
                    <span className="text-error">{form.errors.checkIn}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <TimeWithLabel
                    name="checkOut"
                    label={"Check Out"}
                    value={
                      form?.values?.checkOut
                        ? moment(form?.values?.checkOut, "HH:mm").format(
                            "HH:mm"
                          )
                        : null
                        ? form?.values?.checkOut
                          ? moment(form?.values?.checkOut, "HH:mm").format(
                              "HH:mm"
                            )
                          : null
                        : dataSchedule &&
                          moment(
                            dataSchedule.previous_check_out,
                            "HH:mm"
                          ).format("HH:mm")
                        ? moment(
                            dataSchedule.previous_check_out,
                            "HH:mm"
                          ).format("HH:mm")
                        : ""
                    }
                    onChange={form.handleChange}
                    disabled={
                      form.values.TimeOff ||
                      (dataSchedule && dataSchedule.time_off === "Full Day")
                    }
                  />
                  {form.touched.checkOut && form.errors.checkOut ? (
                    <span className="text-error">{form.errors.checkOut}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <div>
                    <TextAreaWithLabel
                      name="description"
                      label={"Description"}
                      placeholder="......."
                      maxLength={200}
                      showCount
                      onChange={form.handleChange}
                      value={form.values.Description}
                    />
                  </div>
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <div className="mt-9">
                    <div>
                      <label className="file-upload">
                        <input
                          type="file"
                          name="AttechmentUpload"
                          id="AttechmentUpload"
                          onChange={handleFileUpload}
                          accept=".pdf, .jpg , .png"
                          ref={inputRef}
                        />
                        <CloudUploadOutlined /> Attachment
                      </label>
                    </div>
                    <div>
                      {fileName && (
                        <div>
                          <p>
                            {fileName}{" "}
                            <Button
                              shape="circle"
                              onClick={handleFileDelete}
                              icon={<DeleteOutlined style={{ color: "red" }} />}
                              size={"small"}
                            />{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </FormAntd.Item>
              </Col>
            </Row>
          </Col>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => {navigate("/time-request");sessionStorage.removeItem("DateSchedule");}}>
            Cancel
          </Button>
          
          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={
              form.isSubmitting ||
              (dataSchedule && dataSchedule.time_off === "Full Day")
            }>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddTimeRequest;
