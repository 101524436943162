import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const getLeaveTransaction = async (startDate, endDate, company,branch, search, offset, limit, page,leaveType,status) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  const role = decryptData(localStorage.getItem("Role"));
  let payload = {
    startDate: startDate,
    endDate: endDate,
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch  : branchDefault,
    isFinanceRole: role === "FINANCE" ? 1 : 0,
    search: search?.length > 0 ? search : undefined,
    start: offset,
    length: limit,
    page: page,
    leaveType: leaveType,
    status: status
  };
  return master.post("/leave-request/list", payload);
};

const getLeaveDetailTransaction = async (id,company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post(`/leave-request/${id}`, payload);
};

const AddLeaveTrasaction = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: data.companyCode,
    mLeaveId: data.mLeaveId,
    startDate: data.startDate,
    endDate: data.endDate,
    requestType: data.requestType,
    description: data.description,
    employeeId: data.employeeId,
    attachment: data.attachment,
    status: "APPROVED",
    createdBy: user,
  };

  return master.post("/leave-request/create", payload);
};



export { AddLeaveTrasaction, getLeaveTransaction, getLeaveDetailTransaction };
