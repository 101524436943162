import { useEffect, useRef, useState } from "react";
import { Filter, Minus, Notfound, Plus } from "../../../assets/icons";
import "./styles.scss";
import { message, Pagination, Spin } from "antd";
import { SelectOptionWithLabel } from "../../Form/Input";
import Search from "antd/es/transfer/search";
import { decryptData, encryptData } from "../../../helper/cryptojs";
import { getEmployeeByLevelAccess } from "../../../api/runPayroll";
import { getBranch } from "../../../api/branch";
import { getLevel } from "../../../api/level";
import { getPosition } from "../../../api/position";

const EmployeeSelector = (props) => {
  const {
    isModalOpen,
    setIsModalOpen,
    setIsSelectedEmployee,
    isSelectedEmployee,
    form,
    period,
  } = props;
  const previousPage = useRef(1);
  const allowedPayroll = decryptData(localStorage.getItem("AllowedPayroll"));
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
  const branch = decryptData(localStorage.getItem("DefaultBranchId"));
  const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const limitSession = decryptData(
    sessionStorage.getItem("sessionPageRunPayroll")
  );
  const offsetSession = decryptData(
    sessionStorage.getItem("sessionOffsetRunPayroll")
  );
  const sessionSearch = decryptData(
    sessionStorage.getItem("sessionSearchRunPayroll")
  );
  const activeSession = decryptData(
    sessionStorage.getItem("sessionFilterActiveRunPayroll")
  );
  const deBranchCode = decryptData(localStorage.getItem("DefaultBranchCode"));
  const deBranchName = decryptData(localStorage.getItem("DefaultBranchName"));
  const branchStore = decryptData(sessionStorage.getItem("selectStoreBranch"));
  const deBranchArr = decryptData(
    JSON.parse(localStorage.getItem("BranchArr"))
  );
  const branchArr =
    deBranchArr != null
      ? deBranchArr.map((item) => {
          return {
            idx: item.branch_id,
            value: item.branch_id,
            title: item.name,
          };
        })
      : [];

  let branchARRRR =
    decryptData(sessionStorage.getItem("BranchArrStore")) != undefined
      ? ""
      : decryptData(sessionStorage.getItem("BranchArrStore"));
  const branchArrStore = branchARRRR
    ? branchARRRR != undefined
      ? JSON.parse(branchARRRR)
      : []
    : [];
  const getInitialSelectedEmployees = () => {
    const storedEmployees = decryptData(
      sessionStorage.getItem("selectedEmployees")
    );
    return storedEmployees ? JSON.parse(storedEmployees) : [];
  };
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch ?? "");
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [previewData, setPreviewData] = useState(null);
  const [active, setActive] = useState(activeSession ? activeSession : "");
  const [level, setLevel] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [position, setPosition] = useState([]);
  const [isPosition, setIsPosition] = useState("");
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState(
    getInitialSelectedEmployees
  );
  const [listBranch, setListBranch] = useState(
    companyStore === null || companyStore === deCompanyCode
      ? branchArr.length > 0
        ? [
            {
              idx: "all",
              value: JSON.stringify([branch, ...branchArr.map((b) => b.value)]),
              title: "All Branch",
            },
            {
              idx: branch,
              value: deBranchName,
              title: deBranchName,
            },
            ...branchArr,
          ]
        : [
            {
              idx: branch,
              value: branch,
              title: deBranchName,
            },
            ...branchArr,
          ]
      : branchArrStore != null
      ? branchArrStore.length > 0
        ? [
            {
              idx: "all",
              value: JSON.stringify(branchArrStore.map((b) => b.value)),
              title: "All Branch",
            },
            ...branchArrStore,
          ]
        : branchArrStore
      : []
  );
  const [isBranchValue, setIsBranchValue] = useState("");
  const newListBranch = [...listBranch];

  const fetchGetRunPayroll = async () => {
    setLoading(true);
    try {
      const res = await getEmployeeByLevelAccess(
        allowedPayroll,
        company,
        isBranchValue,
        selectedLevel,
        isPosition,
        active,
        page,
        offset,
        limit,
        search,
        period
      );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
      if (!search) {
        setPreviewData(res?.data?.recordsFiltered);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const branchList = async () => {
    try {
      const companyCode = companyStore != null ? companyStore : deCompanyCode;
      const res = await getBranch("", 1000, 0, "", companyCode, deBranchCode);
      const data = res?.data?.data;
      let newBranch =
        data.length > 0
          ? data.map((item) => {
              return {
                idx: item.branch_id,
                value: item.branch_id,
                title: item.name,
              };
            })
          : () => {
              return [];
            };
      if (companyCode === deCompanyCode) {
        newBranch = [
          {
            idx: branch,
            value: branch,
            title: deBranchName,
          },
          ...newBranch,
        ];
      }

      localStorage.setItem(
        "BranchArr",
        JSON.stringify(encryptData(newBranch) ? encryptData(newBranch) : [])
      );
      sessionStorage.setItem(
        "BranchArrStore",
        encryptData(JSON.stringify(newBranch))
      );
      if (newBranch.length > 1) {
        newBranch.unshift({
          idx: "all",
          value: JSON.stringify(newBranch.map((b) => b.value)),
          title: "All Branch",
        });
      }
      setListBranch(newBranch);
    } catch (err) {
      console.error(err);
    }
  };

  const levelList = async () => {
    setLoading(true);
    try {
      const res = await getLevel(search, limit, offset, page, companyStore);
      const levels = res.data.data.map((index) => ({
        value: index.level_id,
        title: index.name,
      }));
      setLevel(levels);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const positionList = async () => {
    setLoading(true);
    try {
      const res = await getPosition(
        search,
        limit,
        offset,
        page,
        null,
        companyStore
      );
      const positions = res.data.data.map((index) => ({
        value: index.position_id,
        title: index.position_name,
      }));
      setPosition(positions);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageRunPayroll", encryptData(page));
  }, [page]);

  useEffect(() => {
    const limitSession = decryptData(
      sessionStorage.getItem("sessionPageRunPayroll")
    );
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    fetchGetRunPayroll();

    const beforeUnloadHandler = () => {
      sessionStorage.removeItem("sessionPageRunPayroll");
      sessionStorage.removeItem("sessionOffsetRunPayroll");
      sessionStorage.removeItem("sessionSearchRunPayroll");
      sessionStorage.removeItem("selectStoreBranch");
      sessionStorage.removeItem("selectedEmployees");
      setSearch("");
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [company, page, offset, limit, search]);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - limit);
    }
  }, [page]);

  useEffect(() => {
    branchList();
    levelList();
    positionList();
  }, []);

  useEffect(() => {
    if (newListBranch.length > 0) {
      setIsBranchValue(branchStore || "");
    }
  }, [newListBranch]);

  const handleChangeBranch = (val, title) => {
    setIsBranchValue(title.idx);
    sessionStorage.setItem("selectStoreBranch", encryptData(val));
    sessionStorage.setItem("selectBranchName", encryptData(title.children));
    sessionStorage.setItem("selectIsBranchId", encryptData(title.idx));
  };

  useEffect(() => {
    const employeesWithCode = selectedEmployees.map(
      (employee) => employee.employee_id
    );
    setIsSelectedEmployee(employeesWithCode);
  }, [selectedEmployees]);

  useEffect(() => {
    setIsSubmitDisabled(selectedEmployees.length === 0);
  }, [selectedEmployees]);

  const isSelected = (employeeId) =>
    selectedEmployees.some((emp) => emp.employee_id === employeeId);

  const handleSelect = (employee) => {
    setSelectedEmployees([...selectedEmployees, employee]);
    setIsSelectedEmployee([...isSelectedEmployee, employee]);
  };

  const handleDeselect = (employeeId) => {
    setSelectedEmployees(
      selectedEmployees.filter((emp) => emp.employee_id !== employeeId)
    );
  };

  const handleSelectFilterChange = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem(
      "sessionSearchRunPayroll",
      encryptData(e.target.value)
    );
  };

  const handleSelectedFilterChange = async (e) => {
    if (e.target.value !== "") {
      setIsLoading(true);
      setSelectedFilter(e.target.value);
      await new Promise((resolve) => setTimeout(resolve, 500));
    } else {
      setSelectedFilter(e.target.value);
    }

    setIsLoading(false);
  };

  const handleSelectAll = () => {
    const remainingEmployees = filteredEmployeesSelect.filter(
      (employee) => !isSelected(employee.employee_id)
    );
    setSelectedEmployees([...selectedEmployees, ...remainingEmployees]);
  };

  const handleClearSelection = () => {
    setSelectedEmployees([]);
    localStorage.removeItem("selectedEmployees");
  };

  const handleApplyFilter = (e) => {
    console.log("e", e);
    setFilterVisible(false);

    setPage(1);
    setOffset(0);
    fetchGetRunPayroll();
  };

  const filteredEmployees = listTable.filter((employee) =>
    employee.employee_name.toLowerCase().includes(search.toLowerCase())
  );

  const filteredEmployeesSelect = filteredEmployees.filter((employee) => {
    return !isSelected(employee.employee_id);
  });

  const filteredSelectedEmployeesSelected = selectedEmployees.filter(
    (employee) =>
      employee.employee_name
        .toLowerCase()
        .includes(selectedFilter.toLowerCase())
  );

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, _) => {
    const offsetSession = pageNumber * limit - limit;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageRunPayroll", encryptData(pageNumber));
    sessionStorage.setItem(
      "sessionOffsetRunPayroll",
      encryptData(offsetSession)
    );
  };
  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };

  const isFilterStatus = [
    {
      value: "Contract",
      title: "Contract",
    },
    {
      value: "Permanent",
      title: "Permanent",
    },
    {
      value: "Probation",
      title: "Probation",
    },
  ];

  const handleClearFilters = () => {
    setSelectedLevel("");
    setIsPosition("");
    setActive("");
    setIsBranchValue(sessionStorage.removeItem("selectStoreBranch") || "");
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 770;
      setIsMobile(isMobile);

      if (!isMobile) {
        window.removeEventListener("resize", handleResize);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const paginationStyle = {
    width: isMobile ? "100%" : "auto",
    maxWidth: isMobile ? "600px" : "auto",
    minWidth: isMobile ? "0px" : "auto",
    margin: isMobile ? "0 auto" : "inherit",
    padding: isMobile ? "10px" : "auto",
  };

  return (
    <div className="flex flex-col p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
      <div className="flex flex-col xl:flex-row justify-between gap-4 xl:gap-0">
        <div
          className="section-card
          w-full
          xl:max-w-[700px]
          min-w-[340px]
          2xl:min-w-[407px]
          h-auto
          max-h-[95vh]
          min-h-[55vh]">
          <div className="text-lg font-semibold px-4 py-2 xl:px-5 xl:py-3 select-employee">
            Select Employee
          </div>
          <div className="flex flex-col gap-3 py-2 xl:py-3 px-4 xl:px-5 justify-start">
            <span>
              Display {filteredEmployeesSelect.length} of {previewData}{" "}
              employee(s)
            </span>
            <Search
              placeholder="Search employee"
              className="w-full p-2 xl:p-4 border-none bg-[#F9FAFB] rounded"
              value={search}
              onChange={handleSelectFilterChange}
            />
          </div>
          <div className="flex justify-between px-4 xl:px-5 items-center mb-2">
            <div
              className={`mb-4 p-2  ${
                [isBranchValue, selectedLevel, isPosition, active].filter(
                  Boolean
                ).length
                  ? "bg-[#1677FF] text-white"
                  : "bg-[#F1F1F1]"
              } cursor-pointer text-black rounded`}
              onClick={() => setFilterVisible(!filterVisible)}>
              <div className="flex flex-row gap-2 items-center font-normal px-2 xl:px-4">
                <Filter
                  fill={`${
                    [isBranchValue, selectedLevel, isPosition, active].filter(
                      Boolean
                    ).length
                      ? "#F1F1F1"
                      : "#111111"
                  }`}
                />{" "}
                Filter
              </div>
            </div>
            <div
              className={`${
                filteredEmployeesSelect.length > 0
                  ? "text-blue-500 cursor-pointer"
                  : " text-gray-400 cursor-not-allowed"
              }`}
              onClick={handleSelectAll}>
              Select All
            </div>
          </div>
          <div className="relative z-[999]">
            {filterVisible && (
              <div className="absolute left-5 top-[-20px] w-full xl:w-[320px] p-4 xl:p-5 bg-[#F1F1F1] border rounded">
                <div className="mb-4">
                  <SelectOptionWithLabel
                    placeholder="Select Branch"
                    name="branch"
                    label={<div className="text-black text-sm">Branch</div>}
                    items={newListBranch.map((item) => ({
                      value: item.value,
                      title: item.title,
                    }))}
                    onChange={(val, title) => handleChangeBranch(val, title)}
                    value={isBranchValue || null}
                  />
                </div>
                <div className="mb-4">
                  <SelectOptionWithLabel
                    name="level"
                    label={<div className="text-black text-sm">Level</div>}
                    items={level}
                    onChange={(value) => setSelectedLevel(value)}
                    value={selectedLevel ? selectedLevel : null}
                  />
                </div>
                <div className="mb-4">
                  <SelectOptionWithLabel
                    name="position"
                    label={<div className="text-black text-sm">Position</div>}
                    items={position}
                    onChange={(value) => setIsPosition(value)}
                    value={isPosition ? isPosition : null}
                  />
                </div>
                <div className="mb-4">
                  <SelectOptionWithLabel
                    name="employeeStatus"
                    label={
                      <div className="text-black text-sm">Employee Status</div>
                    }
                    items={isFilterStatus.map((item) => ({
                      value: item.value,
                      title: item.title,
                    }))}
                    onChange={(value) => setActive(value)}
                    value={active ? active : null}
                  />
                </div>
                <div className="flex flex-row gap-2">
                  <div
                    className="px-2 mt-8 w-full text-center flex justify-center items-center text-xs py-2 bg-blue-500 text-white rounded-lg cursor-pointer"
                    onClick={(e) => handleApplyFilter(e)}>
                    Apply Filter
                  </div>
                  <div
                    className="px-2 text-center mt-8 w-full flex justify-center items-center text-xs py-2 bg-white text-[#0081FF] rounded-lg cursor-pointer"
                    onClick={handleClearFilters}>
                    Clear
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="relative ">
            {loading && (
              <div className="absolute z-[100] top-[-10px] flex justify-center items-center  bg-[rgba(255,255,255,0.8)]  w-full  h-full">
                <div className="absolute top-[160px]">
                  <Spin />
                </div>
              </div>
            )}

            <ul className="top-[-10px] max-h-[55vh] relative xl:max-h-[50vh] min-h-[50vh] w-full px-4 xl:px-5 overflow-auto">
              {filteredEmployeesSelect.length > 0 ? (
                filteredEmployeesSelect.map((employee) => (
                  <li
                    key={employee.id}
                    className="flex py-2 xl:py-0 justify-between items-center check-employee">
                    <div>
                      <p>
                        {employee.employee_name.replace(/&#039;/g, "'") || "-"}
                      </p>
                      <p className="text-gray-500">
                        {employee.employee_number} - {employee.level_name}
                      </p>
                    </div>
                    <div
                      onClick={() => handleSelect(employee)}
                      className="cursor-pointer">
                      <Plus />
                    </div>
                  </li>
                ))
              ) : (
                <li className="flex flex-col justify-center items-center relative top-[90px]  text-gray-500">
                  <Notfound />
                  <div className="font-semibold text-sm">That's all</div>
                  <p className="text-[#4D4D4D] text-center">
                    No more employees are available
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div
          className="section-card 
          w-full
          xl:max-w-[700px]
          min-w-[340px]
          2xl:min-w-[407px]
          h-auto
          max-h-[95vh]
          min-h-[55vh]">
          <div className="text-lg font-semibold px-4 py-2 xl:px-5 xl:py-3 select-employee">
            Selected Employee
          </div>
          <div className="flex flex-col gap-3 py-2 xl:py-3 px-4 xl:px-5 justify-start">
            <span>
              Selected {selectedEmployees.length} of {previewData} employee(s)
            </span>

            <Search
              placeholder="Search employee"
              className="w-full p-2 xl:p-4 border-none bg-[#F9FAFB] rounded"
              value={selectedFilter}
              onChange={handleSelectedFilterChange}
            />
          </div>
          <div className="flex justify-end px-4 xl:px-5 mb-2 py-2 xl:py-4">
            <div
              className={`${
                selectedEmployees.length === 0
                  ? "text-gray-400 cursor-not-allowed"
                  : "text-blue-500 cursor-pointer"
              }`}
              onClick={handleClearSelection}>
              Clear Selection
            </div>
          </div>
          <div className="relative ">
            {isLoading && (
              <div className="absolute z-[100] top-[-10px] flex justify-center items-center  bg-[rgba(255,255,255,0.8)]  w-full  h-full">
                <div className="absolute top-[160px]">
                  <Spin />
                </div>
              </div>
            )}
            <ul className="top-[-10px] max-h-[55vh] relative xl:max-h-[50vh] min-h-[50vh] w-full px-4 xl:px-5 overflow-auto">
              {filteredSelectedEmployeesSelected.length > 0 ? (
                filteredSelectedEmployeesSelected.map((employee) => (
                  <li
                    key={employee.id}
                    className="flex justify-between py-2 xl:py-0 items-center check-employee">
                    <div>
                      <p>
                        {employee.employee_name.replace(/&#039;/g, "'") || "-"}
                      </p>
                      <p className="text-gray-500">
                        {employee.employee_number} - {employee.level_name}
                      </p>
                    </div>
                    <div
                      onClick={() => handleDeselect(employee.employee_id)}
                      className="cursor-pointer">
                      <Minus />
                    </div>
                  </li>
                ))
              ) : (
                <li className="flex flex-col justify-center items-center relative top-[90px]  text-gray-500">
                  <Notfound />
                  <div className="font-semibold text-sm">
                    No employee has been selected
                  </div>
                  <p className="text-[#4D4D4D] text-center">
                    You need to select at least one employee
                    <br />
                    from the select employee list
                  </p>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 md:gap-0 xl:gap-2  xl:flex-row  2xl:flex-row justify-between py-3  px-4 section-footer">
        <div className="flex-1">
          <Pagination
            defaultCurrent={page}
            current={page}
            total={totalData}
            onChange={onChangeTable}
            onShowSizeChange={onShowSizeChange}
            responsive
            style={paginationStyle}
            showSizeChanger={true}
            simple
          />
        </div>
        <div className="flex justify-center 2xl:justify-end items-center">
          <div
            className="mr-2 px-8 py-2 bg-[#B2D9FF] text-[#0057FF] border cursor-pointer rounded text-center"
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setIsSelectedEmployee(
                form.values.select_employees?.length === 0
                  ? null
                  : sessionStorage.getItem("selectedEmployees")
              );
            }}>
            Cancel
          </div>
          <div
            className={`${
              isSubmitDisabled
                ? "bg-gray-300 cursor-not-allowed px-10 py-2"
                : "bg-blue-500 cursor-pointer px-10 py-2"
            } text-white rounded text-center`}
            onClick={() => {
              if (!isSubmitDisabled) {
                setIsModalOpen(!isModalOpen);
                message.success("Data has been submitted successfully");
                form.setFieldValue("all_employee", null);
              }

              sessionStorage.setItem(
                "selectedEmployees",
                encryptData(JSON.stringify(selectedEmployees))
              );
            }}
            disabled={isSubmitDisabled}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeSelector;
