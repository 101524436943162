import { Button, Col, DatePicker, message, Pagination, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import InputWithIcon from "../../../component/Form/InputWithIcon";
import Table from "../../../component/Table";
import iconSearch from "../../../assets/img/search-icon.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getAttendanceSummary } from "../../../api/attendance";
import { decryptData, encryptData } from "../../../helper/cryptojs";
import dayjs from "dayjs";
import {decode} from 'html-entities';
const { RangePicker } = DatePicker;
export default function AttendanceSummary() {
    const navigate = useNavigate();
    const limitSession = sessionStorage.getItem("sessionPageAttendanceSummary");
    const offsetSession = sessionStorage.getItem("sessionOffsetAttendanceSummary");
    const startDateSession = decryptData(sessionStorage.getItem("sessionStartDateOvertime"));
    const endDateSession = decryptData(sessionStorage.getItem("sessionEndDateOvertime"));
    const sessionSearch = sessionStorage.getItem("sessionSearchAttendanceSummary");
    const [loading, setLoading] = useState(false);
    const [listTable, setListTable] = useState([]);
    const [totalData, setTotalData] = useState(0)
    const [page, setPage] = useState(limitSession ? limitSession : 1);
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(offsetSession ? offsetSession : 0)
    const previousPage = useRef(1);
    const [search, setSearch] = useState(sessionSearch ?? "")
    const [debounceSearch, setDebounceSearch] = useState(sessionSearch ? sessionSearch : "")
    const [startDate, setStartDate] = useState(startDateSession ? startDateSession : null)
    const [endDate, setEndDate] = useState(endDateSession ? endDateSession : null)
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const branch = decryptData(sessionStorage.getItem("selectBranch")) ?? decryptData(localStorage.getItem("DefaultBranchCode"));
    const attendanceSummaryList = async () => {
        setLoading(true);
        try {
            const res = await getAttendanceSummary(debounceSearch, startDate, endDate, limit, offset, page, company, branch)
            setListTable(res?.data?.data);
            setTotalData(res?.data?.recordsTotal)
        } catch (err) {
            message.error({
                content: "Request Not Found",
            });
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else {
            attendanceSummaryList();
            const beforeUnloadHandler = () => {
                sessionStorage.removeItem("sessionPageAttendanceSummary");
                sessionStorage.removeItem("sessionOffsetAttendanceSummary");
                sessionStorage.removeItem('sessionStartDateAttendanceSummary');
                sessionStorage.removeItem('sessionEndDateAttendanceSummary');
                sessionStorage.removeItem("sessionSearchAttendanceSummary");
                setStartDate(null);
                setEndDate(null);
                setPage(1);
                setSearch("")
            };
            window.addEventListener("beforeunload", beforeUnloadHandler);
            return () => {
                window.removeEventListener("beforeunload", beforeUnloadHandler);
            };
        }
    }, [startDate, endDate, debounceSearch, limit, offset, page, company, branch]);

    useEffect(() => {
		if(search.length > 2 || search.length === 0) {
			const timeoutId = setTimeout(() => {
				setDebounceSearch(search)
			}, 500)
			return () => clearTimeout(timeoutId);
		}
	}, [search])

    useEffect(() => {
        sessionStorage.setItem("sessionPageAttendanceSummary", page);
    }, [page]);

    useEffect(() => {
        const limitSession = sessionStorage.getItem("sessionPageAttendanceSummary");
        if (limitSession) {
            setPage(parseInt(limitSession));
        }
    }, []);

    useEffect(() => {
        if (limitSession) {
            setPage(parseInt(limitSession));
        }
    }, []);

    useEffect(() => {
        if (page === 1) {
            setOffset(0)
        } else {
            setOffset(page * limit - 10)
        }
    }, [page])

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            render: (text, record, index) => {
                return (
                    <div className="detail-btn-wp">
                        {text}
                    </div>
                )
            }
        },
        {
            title: 'NIK',
            dataIndex: 'employee_number',
            key: 'employee_number',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {text || "-"}
                    </div>
                )
            }
        },
        {
            title: 'Employee Name',
            dataIndex: 'full_name',
            key: 'full_name',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {decode(text) || "-"}
                    </div>
                )
            }
        },
        {
            title: 'Branch',
            dataIndex: 'branch',
            key: 'branch',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {text || "-"}
                    </div>
                )
            }
        },
        {
            title: 'Working Days',
            dataIndex: 'working_days',
            key: 'working_days',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {text || "0"}
                    </div>
                )
            }
        },
        {
            title: 'Attendance',
            dataIndex: 'attendance',
            key: 'attendance',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {text || "0"}
                    </div>
                )
            }
        },
        {
            title: 'Leave',
            dataIndex: 'leave',
            key: 'leave',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {text || "0"}
                    </div>
                )
            }
        },
        {
            title: 'Absent',
            dataIndex: 'absent',
            key: 'absent',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {text || "0"}
                    </div>
                )
            }
        },
        {
            title: 'Percentage of Effective Days',
            dataIndex: 'percentage',
            key: 'percentage',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp">
                        {text + "%" || "0%"}
                    </div>
                )
            }
        }
    ];
    const dataBreadcrumb = [
        {
            title: "Attendance Management",
            url: "/attendance"
        },
        {
            title: "Attendance",
            url: "/attendance"
        },
        {
            title: "Summary Attendance"
        },
    ];


    useEffect(() => {
        previousPage.current = page;
    }, [page]);

    const onChangeTable = (pageNumber, type) => {
        const offsetSession = pageNumber * limit - 10
        setPage(pageNumber)
        setOffset(offsetSession)
        sessionStorage.setItem("sessionPageAttendanceSummary", pageNumber);
        sessionStorage.setItem("sessionOffsetAttendanceSummary", offsetSession);
        //
    };

    const onShowSizeChange = (_, pageSize) => {
        setLimit(pageSize)
    };

    function dataTableWithNumber() {
        const showNumber = limit * (page - 1);
        return listTable?.map((data, index) => {
            return {
                ...data,
                key: index + 1,
                no: showNumber + (index + 1),
            };
        });

    }

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        setLimit(10)
        setPage(1)
        sessionStorage.setItem("sessionSearchAttendanceSummary", e.target.value);
    }

    useEffect(() => {
        const startDate = decryptData(sessionStorage.getItem("sessionStartDateOvertime"));
        const endDate = decryptData(sessionStorage.getItem("sessionEndDateOvertime"));
        if (startDate && endDate) {
            setStartDate(startDate);
            setEndDate(endDate);
            setSelectedDateRange([dayjs(startDate), dayjs(endDate)])
        }
    }, []);

    const handleDateChange = (dates) => {
        if (dates && dates.length === 2) {
            const start = dates[0].startOf('day').format('YYYY-MM-DD');
            const end = dates[1].endOf('day').format('YYYY-MM-DD');
            sessionStorage.setItem('sessionStartDateAttendanceSummary', encryptData(start));
            sessionStorage.setItem('sessionEndDateAttendanceSummary', encryptData(end));
            setStartDate(start);
            setEndDate(end);
            setPage(1)

        } else {
            sessionStorage.removeItem('sessionStartDateAttendanceSummary');
            sessionStorage.removeItem('sessionEndDateAttendanceSummary');
            setStartDate(null);
            setEndDate(null);
            setPage(1);

        }
        setSelectedDateRange(dates);
    };

    const handleDownload = () => {
        let startdate = moment(startDate).format("YYYY-MM-DD");
        let enddate = moment(endDate).format("YYYY-MM-DD");

        if (startdate === "Invalid date" || enddate === "Invalid date") {
            startdate = ""; // Nilai default
            enddate = ""; // Nilai default
        }
        const today = moment();
        const selectedStartDate = moment(startdate);
        const diffInDays = today.diff(selectedStartDate, 'days');

        if (diffInDays > 60) {
            message.error("The date range must be less than 60 days ago");
            return;
        }
        window.open(`${process.env.REACT_APP_HRIS_URL}attendance-report-summary?startDate=${startdate}&endDate=${enddate}&companyCode=${company}&branchCode=${branch}&search=${search}`, '_blank');
    };

    const RoleAccessAttandanceDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAttandanceDownload'));

    const disabledDate = (current) => {
		//disable tanggal 3 bulan ke belakang
		return current && current < dayjs().subtract(3, 'month').startOf('month');
	};
    return (
        <>
            <div className="title-section">
            <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/attendance')}  icon={<ArrowLeftOutlined />} size={"large"} />
                Summary Attendance
            </div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
                <Col span={24}>
                    <Breadcrumb items={dataBreadcrumb} />
                </Col>
            </div>
            <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
                <Row style={{ marginBottom: "24px", justifyContent: 'end' }} align={"middle"} >
                    <Col md={{ top: 20 }} className="mt-5 md:mt-0">
                        <Row justify={"end"} style={{ gap: 15 }} md={14}>
                            <RangePicker
                                size={"large"}
                                inputReadOnly={true}
                                onChange={handleDateChange}
                                value={selectedDateRange}
                                allowClear={true}
                                format="YYYY-MM-DD"
                                disabledDate={disabledDate}
                            />
                            <InputWithIcon
                                type="text"
                                placeholder="Search Summary Attendance"
                                img={iconSearch}
                                className="search-input btn-sh-sec"
                                classImg="op-icon"
                                value={search}
                                onChange={onChangeSearch}
                            />
                            {RoleAccessAttandanceDownload && (
                                <Button size={"large"} type="primary"
                                    onClick={handleDownload}
                                >Download</Button>
                            )}
                        </Row>
                    </Col>

                </Row>
                <div className="table-wrapper">
                    {loading ? (
                        <>
                            <Table id={'my-table'} columns={columns} loading={loading} />
                            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                <Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange} />
                            </div>
                        </>
                    ) : (
                        <>
                            <Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
                            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                <Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}
