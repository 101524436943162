import { Button, Col, message, Modal, Pagination, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import "./styles.scss"
import { PlusOutlined, DeleteFilled, EditFilled,FileSearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deletePosition } from "../../api/position";
import { deleteBranch, getBranch } from "../../api/branch";
import { decryptData } from "../../helper/cryptojs";

export default function Area() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(0)
	const [flag, setFlag] = useState(0)
	const previousPage = useRef(1);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const [search, setSearch] = useState("");
	const [division, setDivision] = useState("");
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const [listDivision, setListDivision] = useState([]);
	const BestpraticeUseEffect = useRef(false)	
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
		  }
	}, [limit, offset, search,page,flag,company]);

	// const handleApiButtonClick = () => {
	// 	branchList();
	// };


	useEffect(() => {
		if(page === 1){
			setOffset(0)
		}else {
			setOffset(page*limit - 10)
		}
	},[page])

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            const res = await deleteBranch(id);
            message.success({
                content: res.data.message,
            });
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
                content: error.response.data.error,
            });
            setLoading(false)
        }
    }

	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/area/detail/${encodeURIComponent("id?" + record.branch_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Area',
			dataIndex: 'area',
			key: 'area',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/area/detail/${encodeURIComponent("id?" + record.branch_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Number of Branch',
			dataIndex: 'address',
			key: 'address',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/area/detail/${encodeURIComponent("id?" + record.branch_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Action',
			key: 'action',
			width: "25px",
			render: (_,record,index) => {
				return (
					<Row justify={"space-between"}>
						
							<div className="icon-action">
								<DeleteFilled onClick={() => showModal(record.branch_id)}/>
							</div>
						
						
						
							<div className="icon-action">
								<EditFilled onClick={() => navigate(`/branch/edit-branch/${encodeURIComponent("id?" + record.branch_id)}`)}/>
							</div>
						
					
					</Row>
				)
			}
		},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/area"
		},
		{
			title: "Area",
			url: "/area"
		},
	];

	useEffect(() => {
		previousPage.current = page;
	}, [page]);

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		// if(pageNumber < previousPage.current){
		// 	setOffset(offset - limit)
		// } else if(pageNumber > previousPage.current) {
		// 	setOffset(offset + limit)
		// }
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
	}

	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};


    return (
        <>
		  <>
		    <div className="title-section">
				Area
			</div>
			<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<p>Are you sure to delete this data?</p>
			</Modal>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
								<InputWithIcon
									type="text"
									placeholder="search area"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									onChange={onChangeSearch}
								/>
							{/* <Col span={2}>
							<Button className="btn-sh-p" size="large" loading={loading} type="primary" shape="circle" icon={<FileSearchOutlined />} onClick={handleApiButtonClick} />
							</Col> */}
					
								<Col  style={{display: "flex", justifyContent: "flex-end"}}>
									<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/area/add-area')} icon={<PlusOutlined />} size={"large"} />
								</Col>
							
						</Row>
					</Col>
				</Row>
				<div className="table-wrapper">
					<Table dataSource={dataTableWithNumber()} columns={columns} loading={loading}/>
					<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
						<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
					</div>
				</div>
			</div>
		  </>
		  
        </>
    )
}