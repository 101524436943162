import { decryptData } from "../helper/cryptojs";
import { sso, master } from "./hris";
import { api_key } from "./login";

export const getRole = async (company, search,limit, offset) => {
    const role = decryptData(localStorage.getItem("Role"));
    let paylaod = {
        api_key : api_key,
        companyCode: company,
        search_by : "name",
        search_value : search.length > 0 ? search : undefined,
        limit : limit,
        offset : offset,
        exception : role !== 'ROOT'? 'ROOT': null
    }
    return sso.post("/getrolelist", paylaod);
}

export const addRole = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    api_key : api_key,
    name: data?.name,
    company: data?.company,
    branch: data.branch != null && data.branch.length>0? data.branch : [],
    companyList: data.companyList != null && data.companyList.length>0? data.companyList : null,
    company: data?.company,
    application: data?.application,
    privilege:  JSON.stringify(data?.privilege.map(datum=>({feature_id:datum.feature_id, access:datum.access})).filter(datum=>datum.access.length>0)),
    allowed_payroll: data?.level,
    created_by: user,
  };
  return await sso.post("/addrole", payload);
};

export const getRoleDetail = async (id) => {
  let payload = {
    role_id: id,
    api_key : api_key,
  }
  return sso.post("/getroleid", payload);
}

export const deleteRole = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    role_id : id,
    deleted_by: user,
    api_key : api_key,
  }
  return sso.post("/deleterole", payload);
}

export const editRole = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    role_id : id,
    api_key : api_key,
    name: data?.name,
    branch: data.branch != null && data.branch.length>0? data.branch : [],
    companyList: data.companyList != null && data.companyList.length>0? data.companyList : null,
    company: data?.company,
    application: data?.application,
    privilege:  JSON.stringify(data?.privilege.map(datum=>({feature_id:datum.feature_id, access:datum.access})).filter(datum=>datum.access.length>0)),
    allowed_payroll: data?.level,
    created_by: user
  };
  return await sso.post("/updaterole", payload);
}

export const getRoleBranch = async (branch) => {
  let payload = {
    branch: branch,
    api_key : api_key,
  }
  return master.post("/branch-role", payload);
}

export const getFeatures = async () => {
  let payload = {
    api_key : api_key,
  }
  return sso.post("/getfeaturelist", payload);
}

export const getPermissions = async () => {
  let payload = {
    api_key : api_key,
  }
  return sso.post("/getpermissionlist", payload);
}
