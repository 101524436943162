import { Button, Col, Form as FormAntd, Radio, Row, Select, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { SelectSearchWithLabel } from "../../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { createDefaultsetting, getApprovalSettingEmployeeRoleList, getApprovalSettingRoleList, getListDefault } from "../../../api/approvalSetting";
import { decryptData } from "../../../helper/cryptojs";
import Breadcrumb from "../../../component/Breadcrumb";

const DefaultApprovalSetting = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [flag, setFlag] = useState(0);
    const [options, setOptions] = useState([]);
    const [options1, setOptions1] = useState([]);
    const { Option } = Select;
    const BestpraticeUseEffect = useRef(false)
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [detail, setDetail] = useState([]);
    const [isDetail, setIsDetail] = useState(false);
    const [isDefaultSelected, setIsDefaultSelected] = useState(false);
    const ListRole = async () => {
        setLoading(true)
        try {
            const res = await getApprovalSettingRoleList(company)
            const data = res?.data?.data;
            console.log(data)
            const dataRole =  res.data.data.map((role) => role.role_id ? role.role_id: {}) ;
            if(detail.role_id != null){
                sessionStorage.setItem('selectedRoleId',detail.role_id) 
            }else{
                sessionStorage.setItem('selectedRoleId',dataRole[0])  
            }
            setOptions(data)
            setLoading(false)
          } catch (err) {
            console.log(err);
          }
	  };
      
    const ListRole1 = async () => {
        try {
            const RoleId =  sessionStorage.getItem('selectedRoleId');
            const res = await getApprovalSettingEmployeeRoleList(RoleId ? RoleId : '')
            const data = res?.data?.data;
            console.log('testing role',data)
            setOptions1(data)
          } catch (err) {
            console.log(err);
          }
	  };
      const ListDataDefault = async () => {
        try {
            const res = await getListDefault(company)
            const data = res?.data?.data;
            if(data != null){
                if(data.role_id != null){
                    setIsDefaultSelected(true);
                }
                setDetail(data);
                setIsDetail(true);
            }else{
                setDetail([]);
                setIsDetail(false);
            }
          } catch (err) {
            console.log(err);
      }
    };


    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
          BestpraticeUseEffect.current = true;
        } else {
          const fetchData = async () => {
            await ListRole();
            ListDataDefault();
            await ListRole1();
          };
          fetchData();
          setIsDefaultSelected(detail.is_default)
        }
      }, [flag]);
      


      const form = useFormik({
        initialValues: {
            companyCode: company,
            roleId: isDetail? detail.role_id : '',
            employeeNumber: isDetail ?  detail.employee_number: null
        },
        enableReinitialize: true,
        // validationSchema: validationUser,
        onSubmit: async (values) => {
            setFetching(true);
            try {
                const res = await createDefaultsetting(values); 
    
                if (!res?.data?.success) {
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success('Default setting success');
                    navigate("/approval-setting");
                    form.resetForm();
                    setFlag((prev) => prev + 1);
                }
    
                setFetching(false);
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false);
            }
        },
    });
    



    const handleSelectChangRole = async (value) => {
        // sessionStorage.setItem('selectedRoleId',value) 
        form.setValues({
            ...form.values,
            roleId: value,
        })
        sessionStorage.setItem('selectedRoleId',value);
        ListRole1();


      
    };
    const handleSelectChangRole1 = async (value) => {
        form.setValues({
            ...form.values,
            employeeNumber: value,
        })

    };

    const handleDefaultChange = (e) => {
        setIsDefaultSelected(e.target.value === 'Yes');
        if (e.target.value === 'No') {
          form.setFieldValue('employeeNumber', null);
          form.setFieldValue('roleId', null);
        } else {
          form.setFieldValue('roleId', isDetail ? detail.role_id : '');
          form.setFieldValue('employeeNumber', isDetail ? detail.employee_number : null);
        }
      };
    
    const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/approval-setting"
		},
		{
			title: "Approval Setting",
			url: "/approval-setting"
		},
		{
			title: "Default Setting",
			url: "/approval-setting/default-setting"
		},
	]
    useEffect(() => {
        if (isDetail && detail.is_default !== null) {
            setIsDefaultSelected(detail.is_default);
        }
    }, [detail.is_default]);
    
  
    return (
        <>
         <div className="title-section">
				{"Default Setting"}
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
           
            <FormAntd >
            <Row justify="space-between">
            <Col span={24}>
            <FormAntd.Item>
                <div>
                <h3>Set As Default</h3>
                </div>
                <Radio.Group onChange={handleDefaultChange} value={isDefaultSelected ? 'Yes' : 'No'}>
                <Radio value="Yes">
                    <div className="text-base">Yes</div>
                    <div className="text-xs text-gray-400">By default last approval HR</div>
                </Radio>
                <Radio value="No">
                    <div className="text-base">No</div>
                    <div className="text-xs text-gray-400">Approval according to set in approval setting</div>
                </Radio>
                </Radio.Group>
            </FormAntd.Item>
            </Col>
            {isDefaultSelected && (
            <>
                <Col span={24}>
                <FormAntd.Item>
                    <SelectSearchWithLabel
                    labelname={<div>Role<span style={{color:"#FF0000"}}> *</span></div>}
                    name="Role"
                    showSearch
                    placeholder="Select a Role"
                    optionFilterProp="children"
                    value={ form.values.roleId ? form.values.roleId : detail.role_id }
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onChange={handleSelectChangRole}
                    >
                    {options.map(option => (
                        <Option key={option.role_id} value={option.role_id}>
                        {option.name}
                        </Option>
                    ))}
                    </SelectSearchWithLabel>
                </FormAntd.Item>
                </Col>
                <Col span={24}>
                    <FormAntd.Item>
                        <SelectSearchWithLabel
                        labelname={'Employee Name'}
                        name="employeeNumber"
                        showSearch
                        placeholder="Select an Employee Name"
                        optionFilterProp="children"
                        value={ (options1.length > 0 ? options1.employee_number : 'none') || form.values.employeeNumber || detail.employee_number }
                        onChange={handleSelectChangRole1}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                        {options1?.map(option => (
                            <Option key={option.employee_id} value={option.employee_number}>
                             {option.full_name}
                            </Option>
                        ))}
                        </SelectSearchWithLabel>
                    </FormAntd.Item>
               
                </Col>
            </>
        )}
      </Row>
            </FormAntd>

            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/approval-setting')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
        </>
    )
}

export default DefaultApprovalSetting;