import { sso } from "./hris";

export const getListNotification = async (userId) => {
   
    let payload = {
        user_id : userId
    }
    return sso.post("/listWebNotification", payload);
}
export const getReadAllNotification = async (userId) => {
   
    let payload = {
        user_id : userId
    }
    return sso.post("/readWebNotifAll", payload);
}

export const getReadByIdNotification = async (notifId) => {
   
    let payload = {
        notifId : notifId
    }
    return sso.post("/readWebNotifById", payload);
}

export const upsertToken = async (userid, token) => {
   
    let payload = {
        userId : userid,
        token : token
    }
    return sso.post("/token-web", payload);
}
