import { Button} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Tree, TreeNode } from 'react-organizational-chart';
import "../styles.scss";
import DownloadPage from "../../../helper/downloadPage"; 
import { getOrChart } from "../../../api/position";
import { decryptData } from "../../../helper/cryptojs";

const OrganizationStructure = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const company = decryptData(sessionStorage.getItem("selectCompanyName")) ? decryptData(sessionStorage.getItem("selectCompanyName")) : decryptData(localStorage.getItem("DefaultCompanyName"));
    const companyCode = decryptData(sessionStorage.getItem("selectCompany"))? decryptData(sessionStorage.getItem("selectCompany")): decryptData(localStorage.getItem("DefaultCompanyCode"));
    const branch = decryptData(sessionStorage.getItem("selectBranch")) ? decryptData(sessionStorage.getItem("selectBranch")) : decryptData(localStorage.getItem("DefaultBranchCode"));
    const BestpraticeUseEffect = useRef(false)
    const [orChart, setOrChart] = useState({});

    const orgChartFunc = (arrayEmployee)=>{
        orgChart.build_tree(arrayEmployee, "employee_id", "parent_id");
        return orgChart.tree.children[0];
    }

    var orgChart = {
        // The Tree
        "tree": {
            "children": []
        },
    
        // Main function to build the tree
        "build_tree": function (ary, id_field, parent_field) {
            this.tree[id_field] = "root";
            this._build(ary, id_field, parent_field);
            return true;
        },
    
        // Function to push children in arrays
        "_build": function (ary, id_field, parent_field) {
            var x,
                i;
    
            this.map = {};
    
            // Step 1. Create a "hash map" of all objects
            for (i = 0; i < ary.length; i++) {
                this.map[ary[i][id_field]] = ary[i];
            }
    
            // Step 2. Iterate over the "hash map"
            for (x in this.map) {
                // 2a. Does the current object have a parent on the map?
                if (this.map[this.map[x][parent_field]]) {
                    // This object has a parent. Hop in their kiddie pool (children array)!
                    if (this.map[this.map[x][parent_field]].children) {
                        this.map[this.map[x][parent_field]].children.push(this.map[x]);
                    } else {
                        this.map[this.map[x][parent_field]].children = [this.map[x]];
                    }
                } else {
                    // Object has no parent. It's a Node. Add to root's children.
                    this.tree.children.push(this.map[x]);
                }
            }
        },
    
        // Find a node by id in the map, and apply a function to it,
        // and all of its ancestors
        "_back_trace": function (id, parent_field, fx) {
            if (this.map[this.map[id][parent_field]]) {
                // Apply the function
                fx(this.map[id]);
                this._back_trace(this.map[id][parent_field], parent_field, fx);
            }
        }
    };

    const getOrChartList = async () => {
        setLoading(true);
        try {
            const res = await getOrChart(companyCode)
            console.log(res?.data)
            if(res?.data !=null && res?.data.length>0){
                setOrChart(orgChartFunc(res.data));
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            getOrChartList();
          }
    }, [companyCode]);

    const dataBreadcrumb = [
        {
            title: "Master Data",
            url: "/position"
        },
        {
            title: "Position",
            url: "/position"
        },
        {
            title: "Organization",
            // url: "#"
        },
    ];

    const renderChildren = (v) => {
        const arrName = v.full_name.split(" & ")
        return (
            <TreeNode
                label={
                    <div className="card-structur">
                        <div className="structur-position">{v.position_name}</div>
                        {arrName.length>1 && 
                            arrName.map((x,i)=>{
                               return(<div className="structur-name align-left">{`${i+1}. ${x}`}</div>)

                            })}
                        {arrName.length == 1 &&
                         <div className="structur-name">{v.full_name}</div>}
                    </div>
                }
            >
                {v.children && 
                    v.children.map((v2)=>{
                        return renderChildren(v2)
                    })
                }
            </TreeNode>
        );
      };

    const RoleAccessPositionDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionDownload'));

    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{ marginRight: "14px" }} onClick={() => navigate('/position')} icon={<ArrowLeftOutlined />} size={"large"} />
                Organization Structure {company}
            </div>
            <div style={{ marginBottom: "24px" }}>
                <Breadcrumb items={dataBreadcrumb} />
            </div>
            <div className="detail-wrapper">
                {RoleAccessPositionDownload && (
                    <DownloadPage rootElementId="pagedownload" downloadFileName="Struktur Organisasi" />
                )}
                <div id="pagedownload" style={{ padding: "24px", overflow:"auto" }}>
                    <Tree
                        lineWidth={'2px'}
                        lineColor={'#44566c'}
                        lineBorderRadius={'10px'}
                        label={
                            <div className="card-structur">
                                <div className="structur-position">{orChart.position_name}</div>
                                <div className="structur-name">{orChart.full_name}</div>
                            </div>
                        }
                    >
                        {orChart.children && 
                            orChart.children.map((child)=>{
                                return renderChildren(child)
                            })
                        }

                    </Tree>
                </div>
            </div>
        </>
    )
}

export default OrganizationStructure