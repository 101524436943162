import { Col, Row } from "antd";

const DetailCardList = ({ detailData }) => (
  <>
    {detailData.map((pair, index) => (
      <Row key={index}>
        {pair.map((detail, subIndex) => (
          <Col span={12} key={subIndex}>
            <div className="detail-card">
              <div className="title">{detail.title}</div>
              <div className="subtitle">{detail.value}</div>
            </div>
          </Col>
        ))}
      </Row>
    ))}
  </>
);

export default DetailCardList;
