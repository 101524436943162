/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, message, Modal, Pagination, Row, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, DeleteFilled, EditFilled, StopOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deleteMOvertime, getMOvertime } from "../../api/movertime";
import { decryptData } from "../../helper/cryptojs";
import {decode} from 'html-entities';
export default function MOvertime() {
    const navigate = useNavigate();
    const sessionPage = sessionStorage.getItem("sessionPageOvertime");
	  const sessionOffset = sessionStorage.getItem("sessionOffsetOvertime");
	  const sessionSearch = sessionStorage.getItem("sessionSearchOvertime");
    const [listTable, setListTable] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalData, setTotalData] = useState(0)
    const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
    const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
    const [flag, setFlag] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState();
    const BestpraticeUseEffect = useRef(false);
    const company = decryptData(sessionStorage.getItem("selectCompany") )? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));

  const overtimeList = async () => {
    setLoading(true);
    try {
      const res = await getMOvertime(company, search,offset, limit, page);
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      overtimeList();
      const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageOvertime");
				sessionStorage.removeItem("sessionSearchOvertime");
				sessionStorage.removeItem("sessionOffsetOvertime");
				setPage(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
    }
  }, [company, search,offset, limit, page]);


  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const res = await deleteMOvertime(id);
      message.success({
        content: "Delete Data Success",
      });
      if(res){
        window.location.reload();
      }
      setLoading(false);
      setFlag((prev) => prev + 1);
    } catch (error) {
      message.error({
        content: "Request Not Found",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageOvertime", page);
		sessionStorage.setItem("sessionOffsetOvertime", offsetSession);
	},[page])

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "25px",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(`/overtime/detail/${encodeURIComponent("id?" + record.m_overtime_id)}`)
            }
          >
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Overtime Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(`/overtime/detail/${encodeURIComponent("id?" + record.m_overtime_id)}`)
            }
          >
            {decode(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "Day Type",
      dataIndex: "day_type",
      key: "day_type",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(`/overtime/detail/${encodeURIComponent("id?" + record.m_overtime_id)}`)
            }
          >
           {decode(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "Rate Type",
      dataIndex: "rate_type",
      key: "rate_type",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(`/overtime/detail/${encodeURIComponent("id?" + record.m_overtime_id)}`)
            }
          >
           {decode(text === "custom" ? "Custom" : text === "flat" ? "Flat Per Day" : text === "formula" ? "Formula" : null) || "-"}
          </div>
        );
      },
    },

    {
      title: "Applies to",
      dataIndex: "applies_to",
      key: "applies_to",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(`/overtime/detail/${encodeURIComponent("id?" + record.m_overtime_id)}`)
            }
          >
            {text || '-'}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      render: (_, record, index) => {
        return RoleAccessOvertimeUpdate && RoleAccessOvertimeDelete ? (
          <Row justify={"space-between"}>
            <div className="icon-action">
            {record.applies_to != "Default" && <DeleteFilled onClick={() => showModal(record.m_overtime_id)} />}
            </div>
            <div className="icon-action" onClick={() => navigate(`/overtime/edit-overtime/${encodeURIComponent("id?" + record.m_overtime_id)}`)}>
              <EditFilled />
            </div>
          </Row>
        ): (
          <Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
					</Row>
        )
      },
    },
  ];

  const onChangeTable = (pageNumber, pageSize) => {
    setPage(pageNumber);
    setOffset((pageNumber - 1) * pageSize);
  };
  

	const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
    setOffset((page - 1) * pageSize);
  };
  

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchOvertime", e.target.value);
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    handleDelete(deleteId);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataBreadcrumb = [
	{
		title: "Master Data",
		url: "/overtime"
	},
	{
		title: "Overtime",
		url: "/overtime"
	},
];

const RoleAccessOvertimeCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeCreate'));
const RoleAccessOvertimeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeUpdate'));
const RoleAccessOvertimeDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeDelete'));

  return (
    <>
      <div className="title-section">Overtime</div>
      <Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>Are you sure to delete this data?</p>
      </Modal>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        <Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
          <Col md={{top:20}} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{gap:15}} md={14}>
                <InputWithIcon
                  type="text"
                  placeholder="Search Overtime"
                  img={iconSearch}
                  className="search-input btn-sh-sec"
                  classImg="op-icon"
                  onChange={onChangeSearch}
                  value={search}
                />
                {RoleAccessOvertimeCreate && (
                <Button
                  className="btn-sh-p"
                  type="primary"
                  shape="circle"
                  onClick={() => navigate('/overtime/add-overtime')}
                  icon={<PlusOutlined />}
                  size={"large"}
                />
                )}
               
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
        {loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	
        </div>
      </div>
    </>
  );
}