import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getApprovalSettingDetail } from "../../../api/approvalSetting";

const ApprovalSettingDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [loading, setLoading] = useState(false);
	
    const ApprovalemployeeNumberDetail = async () => {
        try {
            setLoading(true);
            const res = await getApprovalSettingDetail(id)
            const data = res?.data?.data;
            // console.log('testing',data)
            setDetail(data)
          } catch (err) {
            console.log(err);
          }finally {
            setLoading(false);
            
          };
	  };

	useEffect(() => {
        ApprovalemployeeNumberDetail();
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data - Approval Setting",
			url: "/approval-setting"
		},
        {
			title: "Approval Setting Detail",
			url: `/approval-setting/detail/${result}`
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/approval-setting')} icon={<ArrowLeftOutlined />} size={"large"} />
				Approval Setting Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">NIK</div>
                            <div className="subtitle">{detail.employee_number || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Employee Name</div>
                            <div className="subtitle">{detail.employee_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Position</div>
                            <div className="subtitle">{detail.position_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Division</div>
                            <div className="subtitle">{detail.division_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Approval 1</div>
                            <div className="subtitle">{detail.approval_1_name || "-"}</div>
                            <div className="title">{detail.approval_1_company_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Approval 2 </div>
                            <div className="subtitle">{detail.approval_2_name || "-"}</div>
                            <div className="title">{detail.approval_2_company_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Approval 3 </div>
                            <div className="subtitle">{detail.approval_3_name || "-" }</div>
                            <div className="title">{detail.approval_3_company_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Approval 4 </div>
                            <div className="subtitle">{detail.approval_4_name || "-"}</div>
                            <div className="title">{detail.approval_4_company_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Approval 5 </div>
                            <div className="subtitle">{detail.approval_5_name || "-"}</div>
                            <div className="title">{detail.approval_5_company_name || "-"}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ApprovalSettingDetail