/* eslint-disable react-hooks/exhaustive-deps */
import {  Button, Col, Pagination } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { decryptData } from "../../helper/cryptojs";
import { PersonalAssessmentMaster } from "../../api/personalAssessment";
import {
  DatePickerWithLabel,
  SelectOptionWithLabel,
} from "../../component/Form/Input";
import "./styles.scss";
import dayjs from "dayjs";
import { decode } from "html-entities";

export default function PersonalAssessmentReport() {
  const navigate = useNavigate();
  const previousPage = useRef(1);
  const locationPath = useLocation();
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));

  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  const branchCode = branchSession || branch !== null ? branch : branchDefault;

  const typeSession = sessionStorage.getItem("sessionPageType");
  const resultSession = sessionStorage.getItem("sessionPageResult");
  const statusSession = sessionStorage.getItem("sessionPageStatus");
  const sessionDate = decryptData(
    sessionStorage.getItem("sessionDatePersonalAssessmentReport")
  );
  const [type, setType] = useState(typeSession ? typeSession : null);
  const [result, setResult] = useState(resultSession ? resultSession : null);
  const [status, setStatus] = useState(statusSession ? statusSession : null);

  const limitSession = sessionStorage.getItem("sessionPagePersonalAssessmentReport");
  const offsetSession = sessionStorage.getItem(
    "sessionOffsetPersonalAssessmentReport"
  );
  const sessionSearch = sessionStorage.getItem(
    "sessionSearchPersonalAssessmentReport"
  );
  
  const RoleAccessPersonalAssessmentReportDownload = localStorage.getItem("RoleaccessPriviledgePersonalAssessmentReportDownload");
  const RoleaccessPriviledgeProcess = JSON.parse(localStorage.getItem("RoleaccessPriviledgePersonalAssessmentProcess"));
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch ?? "");

  const [selectedDate, setSelectedDate] = useState(
    sessionDate ? sessionDate : dayjs().format("YYYY-MM")
  );

  const handleDatePickerChange = (_, dateString) => {
    setSelectedDate(dateString);
    setPage(1);
    setOffset(0);
    sessionStorage.setItem("sessionDatePersonalAssessmentReport", dateString);
  };

  const disabledDate = (current) => {
    const now = dayjs();
      // Start of the month exactly two years ago
      const twoYearsAgo = now.subtract(2, 'year').startOf('year');

      // Disable dates outside the range from two years ago to now
      return current && (current.isAfter(now) || current.isBefore(twoYearsAgo));
  };
  

  const FetchPersonalAssessmentReportMaster = async () => {
    setLoading(true);
    try {
      const res =
        locationPath.pathname !== "/personal-assessment-report"
          ? null
          : await PersonalAssessmentMaster(
            RoleaccessPriviledgeProcess,
            company,
            branchCode,
            null,
            type,
            status,
            selectedDate,
            page,
            offset,
            limit,
            search,
            result
          );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    sessionStorage.setItem("sessionPagePersonalAssessmentReport", page);
  }, [page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem(
      "sessionPagePersonalAssessmentReport"
    );
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    FetchPersonalAssessmentReportMaster();
    const beforeUnloadHandler = () => {
      sessionStorage.removeItem("sessionPagePersonalAssessmentReport");
      sessionStorage.removeItem("sessionOffsetPersonalAssessmentReport");
      sessionStorage.removeItem("sessionSearchPersonalAssessmentReport");

      setSearch("");
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [company, page, offset, limit, search, type, status, selectedDate, result]);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - 10);
    }
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (_, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {index + 1 + (page - 1) * limit}
          </div>
        );
      },
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {decode(text) || "-"}
          </div>
        );
      },
    },

    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Period",
      dataIndex: "period_month",
      key: "period_month",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Result",
      dataIndex: "result",
      key: "result",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment-report/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`,
                {state: {employeeNumber: record.employee_number}}
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPagePersonalAssessmentReport", pageNumber);
    sessionStorage.setItem("sessionOffsetPersonalAssessmentReport", offsetSession);
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchPersonalAssessmentReport", e.target.value);
  };

  const dataBreadcrumb = [
    {
      title: "Report",
      url: "/personal-assessment-report",
    },
    {
      title: "History Assessment Report",
      url: "",
    },
  ];

  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}pa/history-report?companyCode=${company}&branchCode=${branchCode}${type ? `&type=${type}`: ''}${status ? `&status=${status}`: ''}&period=${selectedDate}${search ? `&search=${search}`: ''}`;
  };

  return (
    <>
      <div className="title-section">Personal Assessment Report</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        <div className="flex flex-wrap gap-2 justify-end items-center mb-10">
        <SelectOptionWithLabel
            allowClear
            classsNameLabel="input-type"
            placeholder="By Result"
            name="by_type"
            className="select-company w-20 md:w-32 text-xs"
            items={[
              {
                value: "Qualified",
                title: "Qualified",
              },
              {
                value: "Unqualified",
                title: "Unqualified ",
              },
            ]}
            value={result}
            onChange={(value) => {
              setResult(value);
              if (value) {
                sessionStorage.setItem("sessionPageResult", value);
              } else {
                sessionStorage.removeItem("sessionPageResult");
              }
              setPage(1);
            }}
          />
          <SelectOptionWithLabel
            allowClear
            classsNameLabel="input-type"
            placeholder="By Status"
            name="byStatus"
            className="select-company w-20 md:w-32 text-xs mt-0"
            items={[
              {
                value: "Rejected",
                title: "REJECTED",
              },
              {
                value: "Cancelled",
                title: "CANCELLED",
              },
              {
                value: "Done",
                title: "DONE",
              },
            ]}
            value={status}
            onChange={(value) => {
              setStatus(value);
              if (value) {
                sessionStorage.setItem("sessionPageStatus", value);
              } else {
                sessionStorage.removeItem("sessionPageStatus");
              }
              setPage(1);
            }}
          />
          <SelectOptionWithLabel
            allowClear
            classsNameLabel="input-type"
            placeholder="By Type"
            name="by_type"
            className="select-company w-20 md:w-32 text-xs"
            items={[
              {
                value: "Promotion",
                title: "Promotion",
              },
              {
                value: "Talent Mapping",
                title: "Talent Mapping ",
              },
            ]}
            value={type}
            onChange={(value) => {
              setType(value);
              if (value) {
                sessionStorage.setItem("sessionPageType", value);
              } else {
                sessionStorage.removeItem("sessionPageType");
              }
              setPage(1);
            }}
          />
          <DatePickerWithLabel
            placeholder="Select Month"
            allowClear={false}
            picker="month"
            value={selectedDate || ""}
            defaultValue={dayjs()}
            disabledDate={disabledDate}
            format={"YYYY-MM"} 
            onChange={handleDatePickerChange}
            className="w-full md:w-48 h-10 bg-[#F8FAFB] border-none mt-2"
            classsNameLabel="input-type"
          />
          <InputWithIcon
            type="text"
            placeholder="Search Employee Name"
            img={iconSearch}
            className="search-input w-full md:w-[250px] btn-sh-sec"
            classImg="op-icon"
            onChange={onChangeSearch}
            value={search}
          />
           {RoleAccessPersonalAssessmentReportDownload && (
                <Button size={"large"} type="primary" onClick={handleDownload}>
                  Download
                </Button>
              )}
        </div>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
