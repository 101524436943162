/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form as FormAntd, message, Modal } from "antd";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  SelectOptionWithLabelApplies,
  SelectOptionWithLabelAppliesAllvalue,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import "./styles.scss";
import { decryptData, encryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import {
  AddTaxMethod,
  EditTaxMethod,
  getListDropdownListInputTaxMethod,
  getTaxMethodDetail,
} from "../../api/taxMethod";
const FormAddTaxMethod = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const deAppiesTos = decryptData(sessionStorage.getItem("AppliesToTaxMethods"));
  const [fetching, setFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [optionsMLeave, setOptionsMLeave] = useState([]);
  const [detail, setDetail] = useState([]);

  console.log('Entering',deAppiesTos);

  const getDetailTaxMethod = async () => {
    try {
      const res = await getTaxMethodDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const dropdownListLevel = async () => {
    try {
      const res = await getListDropdownListInputTaxMethod(companyStore);
      const data = res?.data?.data;
      setOptionsMLeave(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetailTaxMethod();
    dropdownListLevel();
    form.resetForm();
  }, []);

  const validationUser = yup.object().shape({
    tax_method: yup.string().required("Field is required"),
    applies_to: yup.string().required("Field is required"),
    ...(deAppiesTos === "level"
      ? {
          applies_to_level_id: yup
            .number()
            .typeError("Field is required")
            .required("Field is required"),
        }
      : {}),
  });

  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      tax_method: result ? detail?.tax_method : "",
      applies_to: result ? detail?.applies_to : "",
      applies_to_level_id:
        result && detail?.applies_to_level_id
          ? JSON.parse(detail.applies_to_level_id)
          : [],
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    validate: (values) => {
      const errors = {};

      if (values.applies_to === "level") {
        if (
          values.applies_to_level_id &&
          values.applies_to_level_id.length === 0
        ) {
          errors.applies_to_level_id = "Please input level at least 1";
        }
      }

      return errors;
    },

    onSubmit: async (values) => {
      const selectedLevels = form.values.applies_to_level_id || [];
      values["applies_to_level_id"] = selectedLevels;
      setFetching(true);
      try {
        const res = result
          ? await EditTaxMethod(values, id)
          : await AddTaxMethod(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: result
              ? "Tax method successfully updated"
              : "Tax method has been successfully saved",
          });
          navigate("/tax-methods");
          sessionStorage.removeItem("AppliesToTaxMethod");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Tax method already exist",
        });
        setFetching(false);
      }
    },
  });

  const showModal = async () => {
    if (form.isValid) {
      if (result) {
        setIsModalOpen(true);
      } else {
        await form.handleSubmit();
      }
    }else{
      await form.handleSubmit();
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.handleSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/tax-methods",
    },
    {
      title: "PPh21",
    },
    {
      title: "Tax Method",
    },
    {
      title: `${result ? "Edit Tax Method" : "Add Tax Method"}`,
    },
  ];

  return (
    <>
      <div className="title-section">
        {result ? "Edit Tax Method" : "Add Tax Method"}
      </div>
      <Modal
        title="Edit"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure want to change the data?</p>
      </Modal>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="tax_method"
                      label={
                        <div>
                          Tax Method{" "}
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "Gross",
                          title: "Gross",
                        },
                        {
                          value: "Netto",
                          title: "Netto",
                        },
                        {
                          value: "Gross Up",
                          title: "Gross Up",
                        },
                      ]}
                      value={
                        form?.values?.tax_method
                          ? form?.values?.tax_method
                          : null
                      }
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          tax_method: val,
                        });
                      }}
                      placeholder="Select One"
                      onBlur={form.handleBlur}
                    />

                    <div
                      className={
                        form.touched.tax_method &&
                        form.errors.tax_method === "Field is required"
                          ? "mb-[-20px] mt-2"
                          : "mt-0 mb-0"
                      }>
                      {form.touched.tax_method && form.errors.tax_method ? (
                        <span className="text-error">
                          {form.errors.tax_method}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="applies_to"
                      label={
                        <div>
                          Applies to<span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "all",
                          title: "All Employees",
                        },
                        {
                          value: "level",
                          title: "Based on Level",
                        },
                      ]}
                      value={
                        form?.values?.applies_to
                          ? form?.values?.applies_to
                          : null
                      }
                      onChange={(val) => {
                        form.setValues(
                          {
                            ...form.values,
                            applies_to: val,
                          },
                          sessionStorage.setItem("AppliesToTaxMethods", encryptData(val))
                        );
                      }}
                      placeholder="Default by employee setting"
                      onBlur={form.handleBlur}
                    />
                    <div
                      className={
                        form.touched.applies_to &&
                        form.errors.applies_to === "Field is required"
                          ? "mb-0 mt-2"
                          : "mt-3 mb-[12px]"
                      }>
                      {form.touched.applies_to && form.errors.applies_to ? (
                        <span className="text-error">
                          {form.errors.applies_to}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                  {form.values.applies_to === "level" ? (
                    <div className="mt-10">
                      <SelectOptionWithLabelAppliesAllvalue
                        style={{
                          maxWidth: 420,
                        }}
                        className="w-auto h-auto"
                        name="applies_to_level_id"
                        mode="multiple"
                        options={optionsMLeave.map((item) => ({
                          label: item.name,
                          value: item.level_id,
                        }))}
                        value={form?.values?.applies_to_level_id}
                        onChange={(val) => {
                          form.setValues({
                            ...form.values,
                            applies_to_level_id: val,
                          });
                        }}
                        placeholder="Level"
                        onBlur={form.handleBlur}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />

                      <div
                        className={
                          form.touched.applies_to_level_id &&
                          form.errors.applies_to_level_id ===
                            "Field is required"
                            ? "mb-[-22px] mt-[-10px] ml-0"
                            : "mt-0 mb-[-22px]"
                        }>
                        {form.touched.applies_to_level_id &&
                        form.errors.applies_to_level_id ? (
                          <span className="text-error">
                            {form.errors.applies_to_level_id}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </FormAntd.Item>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/tax-methods")}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={showModal}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddTaxMethod;
