/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, Modal, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, EditFilled, DeleteFilled } from "@ant-design/icons";
import { decryptData } from "../../helper/cryptojs";
import { DeletePtkpStatus, getPtkpStatus } from "../../api/ptkpStatus";
import { formatCurrency } from "../../helper/formatCurrency";

export default function PtkpStatus() {
  const navigate = useNavigate();
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const RoleAccessPtkpStatusDelete = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgePtkpStatusDelete")
  );
  const RoleAccessPtkpStatusUpdate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgePtkpStatusUpdate")
  );
 
  const RoleAccessPtkpStatusCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgePtkpStatusCreate")
  );

  const limitSession = sessionStorage.getItem("sessionPagePtkpStatus");
  const offsetSession = sessionStorage.getItem("sessionOffsetPtkpStatus");
  const sessionSearch = sessionStorage.getItem("sessionSearchPtkpStatus");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch ?? "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const PtkpStatusMaster = async () => {
    setLoading(true);
    try {
      const res = await getPtkpStatus(company,page, offset, limit, search);
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    sessionStorage.setItem("sessionPagePtkpStatus", page);
  }, [page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPagePtkpStatus");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      PtkpStatusMaster();

      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPagePtkpStatus");
        sessionStorage.removeItem("sessionOffsetPtkpStatus");
        sessionStorage.removeItem("sessionSearchPtkpStatus");

        setSearch("");
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, page, offset, limit, search]);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - 10);
    }
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/ptkp-status/detail/${encodeURIComponent(
                  "id?" + record.m_ptkp_status_id
                )}`
              )
            }>
            {index + 1 + (page - 1) * limit}
          </div>
        );
      },
    },
    {
      title: "PTKP Type",
      dataIndex: "ptkp_type",
      key: "code",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/ptkp-status/detail/${encodeURIComponent(
                  "id?" + record.m_ptkp_status_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "PTKP Status",
      dataIndex: "ptkp_status",
      key: "item",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/ptkp-status/detail/${encodeURIComponent(
                  "id?" + record.m_ptkp_status_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "PTKP Value",
      dataIndex: "ptkp_value",
      key: "type",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/ptkp-status/detail/${encodeURIComponent(
                  "id?" + record.m_ptkp_status_id
                )}`
              )
            }>
            {formatCurrency(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      render: (_, record, index) => {
        return RoleAccessPtkpStatusUpdate && RoleAccessPtkpStatusDelete ? (
          <Row justify={"space-between"}>
            <div className="icon-action">
              <DeleteFilled
                onClick={() => showModal(record?.m_ptkp_status_id)}
              />
            </div>
            <div
              className="icon-action"
              onClick={() =>
                navigate(
                  `/ptkp-status/edit-ptkp-status/${encodeURIComponent(
                    "id?" + record?.m_ptkp_status_id
                  )}`
                )
              }>
              <EditFilled />
            </div>
          </Row>
        ) : null;
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/ptkp-status",
    },
    {
      title: "PPh21",
    },
    {
      title: "PTKP Status",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPagePtkpStatus", pageNumber);
    sessionStorage.setItem("sessionOffsetPtkpStatus", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchPtkpStatus", e.target.value);
  };

  const handleDelete = async (id, company) => {
    setLoading(true);
    try {
      const res = await DeletePtkpStatus(id, company);
      message.success({
        content: "PTKP successfully deleted",
      });
      setLoading(false);
      PtkpStatusMaster();
      console.log(res);
    } catch (error) {
      message.error({
        content: "PTKP failed to delete",
      });
      setLoading(false);
    }
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    handleDelete(deleteId, company);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure want to delete the data</p>
      </Modal>
      <div className="title-section">PTKP Status</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              <InputWithIcon
                type="text"
                placeholder="Search PTKP Status"
                img={iconSearch}
                className="search-input w-[250px] btn-sh-sec"
                classImg="op-icon"
                onChange={onChangeSearch}
                value={search}
              />
              {RoleAccessPtkpStatusCreate && (
                <Button
                  className="btn-sh-p"
                  type="primary"
                  shape="circle"
                  onClick={() => navigate("/ptkp-status/add-ptkp-status")}
                  icon={<PlusOutlined />}
                  size={"large"}
                />
              )}
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
