import { Button, Col, message, Row, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getEmployeeDetail } from "../../../api/employee";
import EmployeeDetail from "./EmployeeDetail";
import PayrollDetail from "./PayrollDetail";
import PersonalDetail from "./PersonalDetail";
import OnboardingDetail from "./Onboarding";
import { ArrowLeftOutlined } from "@ant-design/icons";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../component/Breadcrumb";
import { decryptData } from "../../../helper/cryptojs";
import History from "./History";

// const dataEmployee = {
//     nik: "110210210231231",
//     employeeStatus: "Contract",
//     joinDate: "12 Desember 2019",
//     startDate: "12 Desember 2019",
//     endDate: "12 Desember 2022",
//     branch: "Jakarta",
//     department: "Technologi",
//     division: "Developer",
//     directSupervisor: "Ahmad Subagjo",
//     level: "Senior",
//     position: "Developer",
// }

// const dataPersonal = {
//     name: "Ahmad Sofian",
//     full_name: "Ahmad Sofian",
//     email: "ahmad@gmail.com",
//     placeOfBirth: "Jakarta",
//     dateOfBirth: "12 Desember 1999",
//     nationaly: "Indonesia",
//     religion: "Islam",
//     gender: "Laki - Laki",
//     maritalStatus: "Single",
//     phoneNumber: "081291921234",
//     education: "University",
//     identityNumber: 3201011501111132,
//     familyCertificateNumber: 3201011501111132,
//     driverLicenseNumber: 3201011501111132,
//     driverLicenseExpired: "12 Desember 2024",
//     addressPermanent: "Jl. Pabuaran RT 04/02",
//     postalCodePermanent: 11201,
//     cityPermanent: "Bogor",
//     provincePermanent: "Jawa Barat",
//     addressCurrent: "Jl. Pabuaran RT 04/02",
//     postalCodeCurrent: 12201,
//     cityCurrent: "Bogor",
//     provinceCurrent: "Jawa Barat"
// }

// const dataPayroll = {
//     basicSallary: 7000000,
//     salaryType: "Monthly", 
//     paymentSchedule: "Monthly",
//     bankName: "BCA",
//     accountNumber: 12101291912,
//     accountName: "Zaki Muamir Ahmad",
//     npwp: 123010121212,
//     ptkpStatus: "Testing",
//     taxMethode: "Testing",
//     taxableDate: "12 Desember 2022",
//     employeeTaxStatus: "Testing",
//     beginningNeto: "Testing",
//     pph21: 700000,
//     bpjsKetenagakerjaan: "123112413121",
//     bpjsKetenagakerjaanDate: "12 Desember 2022",
//     nppBpjsKetenagakerjaanDate:"12 Desember 2022",
//     bpjsKesehatanNumber: "121121212121",
//     bpjsKesehatanDate: "12 Desember 2022",
//     bpjsKesehatanCost: 200000,
//     jhtCost: 100000,
//     jaminanPensiunCost: 3000000,
//     jaminanPensiunDate: "12 Desember 20225",
// }

const DetailEmployee = () => {
    const navigate = useNavigate();
    var level = 0;
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const [detail, setDetail] = useState([]);
    const [detailEmployee, setDetailEmployee] = useState([]);
    const [detailPayroll, setDetailPayroll] = useState([]);
    const [detailOnboarding, setDetailOnboarding] = useState([]);
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState([]);
    const AllowedPayroll = decryptData(localStorage.getItem("AllowedPayroll"));
    const UserSequence = decryptData(localStorage.getItem("LevelSequence"));
    const BestpraticeUseEffect = useRef(false)

    const employeeList = async () => {
		setLoading(true);
		try {
		  const res = await getEmployeeDetail(result)
            setDetail(res?.data?.data.personal_data);
            setDetailEmployee(res?.data?.data.employee_data);
            setDetailPayroll(res?.data?.data.payroll);
            setDetailOnboarding(res?.data?.data.onboarding);
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	};

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            employeeList();
          }
	}, []);


    const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/employee"
		},
		{
			title: "Employee",
			url: "/employee"
		},
		{
			title: "Employee Detail",
			// url: "/employee/detail"
		},
	]

    var items = [
    {
        key: '1',
        label: `Personal Data`,
        children: <PersonalDetail data={detail}/>,
    },
    {
        key: '2',
        label: `Employment Data`,
        children: <EmployeeDetail data={detailEmployee}/>,
    },
    {
        key: '3',
        label: `Payroll`,
        children: <PayrollDetail data={detailPayroll}/>
    },
    {
        key: '4',
        label: `Onboarding`,
        children: <OnboardingDetail data={detailOnboarding}/>,
    },
    {
        key: '5',
        label: `History`,
        children: <History data={history}/>,
    },
    ];

    // if(AllowedPayroll != 'ALL ACCESS'){
        level = detailEmployee.level_sequence ? detailEmployee.level_sequence != undefined? detailEmployee.level_sequence : 0 : 0;
        if(level > UserSequence){
            items = items.filter(function(value, index, arr){ 
                return value.key != 3;
            });
        }
       
    // }

    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/employee')} icon={<ArrowLeftOutlined />} size={"large"} />
				Employee Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
          
            <Tabs defaultActiveKey="1" className="tab-wrapper" items={items} />
           
        </>
    )
}

export default DetailEmployee