import { Button, Col, Form, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getAttendanceDetail } from "../../../api/attendance";
import Breadcrumb from "../../../component/Breadcrumb";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CheckboxOptionWithLabel } from "../../../component/Form/Input";
import TextArea from "antd/es/input/TextArea";

const AttendanceDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const attendanceList = async () => {
		setLoading(true);
		try {
		  const res = await getAttendanceDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            attendanceList();
          }
		
	}, []);
    const dataBreadcrumb = [
        {
			title: "Attendance Management",
			url: "/attendance"
		},
		{
			title: "Attendance",
			url: "/Attendance"
		},
        {
			title: "Attendance Detail",
			// url: "/Attendance-Detail"
		},
	];


    let UrlMaps = `https://www.google.com/maps/embed/v1/place?key=AIzaSyCNZNTdwRVgHIMrapkKQGfZUf7yslpPPzU&q=`
    let latitude, longitude;
    let latitudeout, longitudeout;
    let queryIn, queryOut;

    try {
      const url = new URL(`https://${detail.maps_in}`)
      const searchParams = new URLSearchParams(url.search);
      const query = searchParams.get("query");
      if (query) {
        [latitude, longitude] = query.split(",");
        if(query.split(",").length === 1) {
            queryIn = query
        }
      }
    } catch (err) {
      console.log("Invalid URL:", err);
    }

    try {
      const url = new URL(`https://${detail.maps_out}`)
      const searchParams = new URLSearchParams(url.search);
      const query = searchParams.get("query");
      if (query) {
        [latitudeout, longitudeout] = query.split(",");
        if(query.split(",").length === 1) {
            queryOut = query
        }
      }
    } catch (err) {
      console.log("Invalid URL:", err);
    }
    
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371000; 
        const dLat = (Math.PI / 180) * (lat2 - lat1);
        const dLon = (Math.PI / 180) * (lon2 - lon1);
    
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos((Math.PI / 180) * lat1) * Math.cos((Math.PI / 180) * lat2) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
    
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
    
        return distance;
    }

        const defaultLatitudeIn = parseFloat(detail?.latitude_radius_in);
        const defaultLongitudeIn = parseFloat(detail?.longitude_radius_in);
        const defaultRadiusIn = parseFloat(detail?.branch_radius_in);


        const defaultLatitudeOut = parseFloat(detail.latitude_radius_out);
        const defaultLongitudeOut = parseFloat(detail.longitude_radius_out);
        const defaultRadiusOut = parseFloat(detail.branch_radius_out);

        const checkinLatitude = parseFloat(detail?.latitude_in);
        const checkinLongitude = parseFloat(detail?.longitude_in);
        const checkoutLatitude = parseFloat(detail?.latitude_out);
        const checkoutLongitude = parseFloat(detail?.longitude_out);

        const distancein = calculateDistance(defaultLatitudeIn, defaultLongitudeIn, checkinLatitude, checkinLongitude);
        const distanceout = calculateDistance(defaultLatitudeOut, defaultLongitudeOut,checkoutLatitude,checkoutLongitude);
        // const chekedRadiusIn = detail.checked_radius_in
        // const checkedRadiusOut = detail.checked_radius_out
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/attendance')}  icon={<ArrowLeftOutlined />} size={"large"} />
				Attendance Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={24}>
                        <div className="detail-card">
                            <div className="title">NIK</div>
                            <div className="subtitle">{detail?.employee_number || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Employee Name</div>
                            <div className="subtitle">{detail.full_name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Branch</div>
                            <div className="subtitle">{detail.branch_name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Date</div>
                            <div className="subtitle">{detail.attendance_date}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Shift</div>
                            <div className="subtitle">{detail.shift_name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Schedule In</div>
                            <div className="subtitle">{detail.schedule_in || "-" }</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">	Schedule Out</div>
                            <div className="subtitle">{detail.schedule_out || "-"}</div>
                        </div>
                    </Col>
                    {distancein > defaultRadiusIn ? (
                        <Col span={12}>
                            <div className="detail-card">
                                <div className="title">Check In</div>
                                <div className="subtitle" >{ detail.attendance_time_in ? moment(detail.attendance_time_in, "HH:mm").format("HH:mm") : "--:--"}</div>
                                <span style={{ color: 'red' }} className="font-semibold">i employees are out of reach</span>
                            </div>
                        </Col>
                    ): (
                        <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Check In</div>
                            <div className="subtitle">{ detail.attendance_time_in ? moment(detail.attendance_time_in, "HH:mm").format("HH:mm") : "--:--"}</div>
                        </div>
                    </Col>
                    )}
                    {distanceout > defaultRadiusOut ? (
                     <Col span={12}>
                         <div className="detail-card">
                             <div className="title">Check Out</div>
                             <div className="subtitle">{detail.attendance_time_out ? moment(detail.attendance_time_out, "HH:mm").format("HH:mm") :  "--:--"}</div>
                             <span style={{ color: 'red' }} className="font-semibold">i employees are out of reach</span>
                         </div>
                     </Col> 
                    ): (
                        <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Check Out</div>
                            <div className="subtitle">{detail.attendance_time_out ? moment(detail.attendance_time_out, "HH:mm").format("HH:mm") :  "--:--"}</div>
                        </div>
                         </Col> 
                    )}
                     {/* <Col span={12}>
                            <div className="detail-card">
                                <div className="title">Check In</div>
                                <div className="subtitle" >{ detail.attendance_time_in ? moment(detail.attendance_time_in, "HH:mm").format("HH:mm") : "--:--"}</div>
                               { chekedRadiusIn ? <span style={{ color: 'red' }} className="font-semibold">i employees are out of reach</span> : null}
                            </div>
                        </Col>
                     <Col span={12}>
                         <div className="detail-card">
                             <div className="title">Check Out</div>
                             <div className="subtitle">{detail.attendance_time_out ? moment(detail.attendance_time_out, "HH:mm").format("HH:mm") :  "--:--"}</div>
                            { checkedRadiusOut ? <span style={{ color: 'red' }} className="font-semibold">i employees are out of reach</span> : null}
                         </div>
                     </Col>  */}
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Remark Check In</div>
                            <div className="subtitle"> <TextArea className="w-80" value={detail.remarks_in} disabled/></div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Remark Check Out</div>
                            <div className="subtitle"><TextArea className="w-80" disabled value={detail.remarks_out}/></div>
                        </div>
                    </Col>
                    <Col span={12}>
			        <div className="detail-card">
                            <div className="title">Minutes Of Late</div>
                            <div className="subtitle">{detail.minutes_of_late ? moment(detail.minutes_of_late, "HH:mm:ss").format("HH:mm:ss") :  "--:--:--"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Work Hour</div>
                            <div className="subtitle">{detail.work_hour ? moment(detail.work_hour, "HH:mm:ss").format("HH:mm:ss") :  "--:--:--"}</div>
                        </div>
                    </Col>
                     
		            {/* <Col span={24}>
                        <div className="detail-card">
                            <div className="title">Minutes of Late</div>
                            <div className="subtitle">{detail.late_minutes ? detail.late_minutes : "--:--"}</div>
                        </div>
                    </Col> */}
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Photo Check In</div>
                            <img src={detail.image_url} style={{ height: '700px', width: '500px', borderRadius: '10px' }} alt="image not available"></img>
                        </div>
                        {detail.match_status && detail.match_percentage != null && detail.face_recognition !== false && (
                            <div className="detail-card">
                                <div className="flex flex-row gap-1 items-center text-center mt-2">
                                    <div className="title">
                                        Face Accuracy -
                                    </div>
                                    <div className={`subtitle mb-2`}>
                                        <div className={`${detail.match_status === "Not Match" || detail.match_status === "Not Registered" ? 'text-red-500' : 'text-green-500'}`}>
                                         {`${detail.match_status} : ${parseFloat(detail.match_percentage).toFixed(2)} ${detail.match_status !== "Not Registered" ?  "%" : ""}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}                        
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Photo Check Out</div>
                            <img src={detail.image_url_out} style={{ height: '700px', width: '500px', borderRadius: '10px' }} alt="image not available"></img>
                        </div>
                        {detail.match_status_out && detail.match_percentage_out != null && detail.face_recognition !== false && (
                            <div className="detail-card">
                                <div className="flex flex-row gap-1 items-center text-center mt-2">
                                    <div className="title">
                                        Face Accuracy -
                                    </div>
                                    <div className={`subtitle mb-2`}>
                                        <div className={`${detail.match_status_out === "Not Match" || detail.match_status_out === "Not Registered" ? 'text-red-500' : 'text-green-500'}`}>
                                         {`${detail.match_status_out} : ${parseFloat(detail.match_percentage_out).toFixed(2)} ${detail.match_status_out !== "Not Registered" ? "%" : ""}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>

                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Location Check In</div>
                        </div>
                        <div>
                            {latitude && longitude ? (
                                <iframe
                                title="Map"
                                width="500"
                                height="350"
                                style={{ border: 2 }}
                                src={`${UrlMaps}${latitude},${longitude}`}
                                allowFullScreen
                                ></iframe>
                            ) : (
                                queryIn ? (
                                <iframe
                                    title="Map"
                                    width="500"
                                    height="350"
                                    style={{ border: 2 }}
                                    src={`${UrlMaps}${queryIn}`}
                                    allowFullScreen
                                ></iframe>
                                ) : 
                                <p style={{color:'red'}}>No Location</p>
                            )}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Location Check Out</div>
                        </div>
                        <div>
                            {latitudeout && longitudeout ? (
                                <iframe
                                title="Map"
                                width="500"
                                height="350"
                                style={{ border: 2 }}
                                src={`${UrlMaps}${latitudeout},${longitudeout}`}
                                allowFullScreen
                                ></iframe>
                            ) : (
                                queryOut ? (
                                <iframe
                                    title="Map"
                                    width="500"
                                    height="350"
                                    style={{ border: 2 }}
                                    src={`${UrlMaps}${queryOut}`}
                                    allowFullScreen
                                ></iframe>
                                ) : 
                                <p style={{color:'red'}}>No Location</p>
                            )}
                        </div>
                    </Col>
                   
              
                    <Col span={24}>
                            <Form.Item>
                            <CheckboxOptionWithLabel name="TimeOff"
                                label={'Time OFF'}
                                checked={detail.time_off}  
                                value={detail.time_off? detail.time_off !== 1 : null }
                             />
                            </Form.Item>
                    </Col>      
               
                {detail.time_off && detail.time_off ? (
                    <>
                     <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Type</div>
                            <div className="subtitle">{detail.leave_type ?? "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Time</div>
                            <div className="subtitle">{detail.leave_time ?? "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Document</div>
                            <div className="text-sm text-gray-500 flex items-center">
                            <div>
                              <svg
                                className="w-4 h-4 mr-1 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                              </svg>
                            </div>
                            <>
                              {detail?.document_url ? (
                                <Link
                                    to={process.env.REACT_APP_HRIS_URL + `leaves-transaction/file/${detail?.document_url}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                >
                                    {detail?.document_url}
                                </Link>
                              ) : (
                                <span className="text-gray-500">
                                  -
                                </span>
                              )}
                            </>
                          </div>
                        </div>
                </Col>
                    </>
                  ): ''} 
                </Row>
            </div>
        </>
    )
}

export default AttendanceDetail
