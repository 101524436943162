import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { LoginPage } from "./views/Login";
import Router from "./views/Router";
import "./App.scss";
import { ResetPasswordPage } from "./views/ResetPassword";
import { PrivacyPolicyPage } from "./views/PrivacyPolicy";
import { HealthPage } from "./views/Health";
import Maintenance from "./views/maintenance";


function App() {
  // const user = localStorage.getItem("Username");
  const location = window.location.href;
  var n = location.lastIndexOf('/');
  var result = location.substring(n);
  var n2 = location.split("/");
  var result2 =  n2[3];

  const maxHeightCondition  = result.toString() === "/" || result2 === "/reset-password" || result2 === "/privacy-policy"
  return (
    <BrowserRouter>
      <div className="app" style={maxHeightCondition ? {maxHeight: '100vh'}: {maxHeight: 'none'}}>
        {/* {user === null ? ( */}
        
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/reset-password/:id" element={<ResetPasswordPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/health" element={<HealthPage />} />
          </Routes>
          <Router /> 
        {/* ): <Router />    } */}
      </div>
    </BrowserRouter>
  );
}

export default App;
