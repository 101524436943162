import { Button, Col, Row,Collapse} from "antd";
import  { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {  getGenerateDetailAnnual, getLeaveDetail } from "../../../api/leave";
import { SettingOutlined } from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";


const LeaveDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    let n = location.lastIndexOf('/');
    let result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    let dec = decode.lastIndexOf('?');
    let id = decode.substring(dec + 1);
    const BestpraticeUseEffect = useRef(false)
    const [detail, setDetail] = useState([]);
    const [detailAnnual, setDetailAnnual] = useState([]);
    const [fetching, setFetching] = useState(false);
    
	
    const LeaveList = async () => {
		try {
		  const res = await getLeaveDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
		  console.log(err);
        }
	  };

      const DetailGenerateAnnual = async () => {
        setFetching(true);
        try {
          const res = await getGenerateDetailAnnual(id);
          setDetailAnnual(res?.data?.data);
        } catch (err) {
          console.log(err);
        } finally {
          setFetching(false);
        }
      };
      

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          }else{
            LeaveList();
            DetailGenerateAnnual();
          } 
	}, []);

   

    const dataBreadcrumb = [
        {
			title: "Master Data - Leave",
			url: "/leave"
		},
        {
			title: "Leave Detail",
            url: `/leave/detail/${result}`
		},
	];

    const maxRequestValue = detail?.max_request !== null
    ? `${detail?.max_request} Days`
    : '-';
    const leaveBalanceValue = detail?.leave_balance !== null
    ? `${detail?.leave_balance} Days`
    : '-';
const leaveBalanceAnnualValue = detailAnnual?.leave_balance !== undefined && detailAnnual?.leave_balance !== null
    ? `${detailAnnual?.leave_balance} Days`
    : '-';
  
    function replaceText(text) {
        return text
          ?.replace("first_emerge_status", "First Emerge Status")
          ?.replace("contract", "- Contract")
          ?.replace("start_date_of_current_year", "Start Date Of Current Year")
          ?.replace("start_date_next_year", "Start Date Next Year")
          ?.replace("annualy", "Annualy")
          ?.replace("monthly", "Monthly")
          ?.replace("permanent", "- Permanent");
      }

        const firstEmerge = detailAnnual?.first_emerge || "-";
        const firstEmergeRemark = replaceText(detailAnnual?.first_emerge_remark);
        const firstEmergeFormatted = firstEmergeRemark !== "null" && firstEmergeRemark
        ? `(${replaceText(detailAnnual?.first_emerge_remark)})`
        : "";

        const resultText = `${replaceText(firstEmerge)} ${firstEmergeFormatted}`;

        // Jika result kosong, ganti dengan "-"
        const finalResult = resultText.trim() || "-";


    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/leave')} icon={<ArrowLeftOutlined />} size={"large"} />
				Leave Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Name</div>
                            <div className="subtitle">{detail?.leave_name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Regulation</div>
                            <div className="subtitle">{detail?.regulation}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Type</div>
                            <div className="subtitle">{detail?.leave_type}</div>
                        </div>
                    </Col>
                    {detail?.is_annual === false ?  (
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Balance</div>
                            <div className="subtitle">{leaveBalanceValue}</div>
                        </div>
                    </Col>
                    ):null}
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Max Request</div>
                            <div className="subtitle">{maxRequestValue}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Attachment</div>
                            <div className="subtitle">{detail?.attachment}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Allowance</div>
                            <div className="subtitle">{detail?.allowance ? "Yes" : "No"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Balance Deducted</div>
                            <div className="subtitle">{detail?.balance_deducted ? "Yes" : "No"}</div>
                        </div>
                    </Col>
                    
                    <Col span={24}>
                    {detail?.is_annual && (
            <div className="mt-10">
                <Collapse  expandIcon={() => null} defaultActiveKey={['1']}>
                <Collapse.Panel disabled header={
                        <div>
                            <SettingOutlined style={{ marginRight: '8px' }} />
                            Annual Leave Setting
                        </div>
                    }
                    key="1"
                >
                <>
            
                <div className="title-section ml-1">
                {"Annual Leave Setting Detail"}
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Balance</div>
                            <div className="subtitle">{leaveBalanceAnnualValue || '-'}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Policy Type</div>
                            <div className="subtitle">{replaceText(detailAnnual?.policy_type) || '-'}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Effective As Of </div>
                            <div className="subtitle">
                         {detailAnnual?.effective_date ? dayjs(detailAnnual.effective_date).format("DD MMMM YYYY") : "-"}
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave First Emerge</div>
                            <div className="subtitle">
                            {finalResult || '-'}
                            </div>
                            </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">New Employee Proration</div>
                           <div className="subtitle">{detailAnnual?.is_prorate !== undefined ? (detailAnnual.is_prorate ? "Yes" : "No") : "-"}</div>
                        </div>
                    </Col>
                  
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Expired Leave</div>
                            <div className="subtitle">{detailAnnual?.expired_date ? moment(detailAnnual?.expired_date).format("D MMMM") : "-" || "-"}</div>
                        </div>
                    </Col>
                      
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Carry Over Balance</div>
                            <div className="subtitle">{detailAnnual?.carry_over !== undefined ? (detailAnnual.carry_over ? "Yes" : "No") : "-"}</div>
                        </div>
                    </Col>
                    {detailAnnual?.carry_over && (
                        <>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Maximum Balance To Be Carry Over</div>
                            <div className="subtitle">{`${detailAnnual?.carry_over_max} Days`}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Active Period</div>
                           <div className="subtitle">
                             {moment(detailAnnual?.active_period).isValid() ? moment(detailAnnual?.active_period).format("MMMM") : "-"}
                           </div>
                        </div>
                    </Col>
                        </>
                    )}
                    </Row>
                </div>
                </>
            </Collapse.Panel>
            </Collapse>
                </div>
            )}
                        </Col>
                </Row>
            </div>
        </>
    )
}

export default LeaveDetail