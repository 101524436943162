/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, DatePicker, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import { decryptData } from "../../helper/cryptojs";
import { ListReportInsurance } from "../../api/insurance";
import { formatCurrency } from "../../helper/formatCurrency";
import dayjs from "dayjs";
export default function ReportInsurance() {
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const RoleAccessInsuranceReportDownload = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeInsuranceReportDownload")
  );
  const limitSession = sessionStorage.getItem("sessionPageInsurance");
  const offsetSession = sessionStorage.getItem("sessionOffsetInsurance");
  const sessionDate = sessionStorage.getItem("sessionDateInsurance");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? limitSession : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [selectedDate, setSelectedDate] = useState(sessionDate ?? dayjs().format('YYYY-MM'));
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);

  const ReportInsurance = async () => {
    setLoading(true);
    try {
      const res = await ListReportInsurance(company,selectedDate,page,offset,limit);
      setListTable(res.data.data);
      setTotalData(res.data.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageInsurance", page);
  }, [page]);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      ReportInsurance();
      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageInsurance");
        sessionStorage.removeItem("sessionOffsetInsurance");
        sessionStorage.removeItem("sessionDateInsurance");
        setPage(1)
				setOffset(0)
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company,selectedDate,page,offset,limit]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageInsurance");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);



  useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
	  sessionStorage.setItem("sessionPageInsurance", page);
    sessionStorage.setItem("sessionOffsetInsurance", offset);
	},[page])

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => {
        return (
          <div
            className="detail-btn-wp">
            {text}
          </div>
        );
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp">
            {text}
          </div>
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp">
            {text}
          </div>
        );
      },
    },

    {
      title: "Insurance Number",
      dataIndex: "insurance_number",
      key: "insurance_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp">
            {text || ""}
          </div>
        );
      },
    },
    {
      title: "Monthly Premium",
      dataIndex: "premium_value",
      key: "premium_value",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp">
            {formatCurrency(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "Total Deduction Covered by The Company",
      dataIndex: "total_deduction",
      key: "total_deduction",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp">
            {formatCurrency(text) || "-"}
          </div>
        );
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Report",
      url: "/insurance-report",
    },
    {
      title: "Insurance Report",
      url: "/insurance-report",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageInsurance", pageNumber);
    sessionStorage.setItem("sessionOffsetInsurance", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }


  const handleDownload = () => {
    if (selectedDate !== null) {
      const url = `${process.env.REACT_APP_HRIS_URL}insurance-report?companyCode=${company}&month=${selectedDate}`;
      window.location.href = url;
    } else {
      window.location.href = `${process.env.REACT_APP_HRIS_URL}insurance-report?companyCode=${company}`;
    }
  };


  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(dateString);
    setPage(1);
    sessionStorage.setItem("sessionDateInsurance", dateString);
  };

  const disabledDate = (current) => {
    const currentYear = dayjs().year();
    const twoYearsLater = dayjs().add(1, 'year').year();
  
    const selectedYear = current.year();
  
    return selectedYear < currentYear || selectedYear > twoYearsLater;
  };

  return (
    <>
      <div className="title-section">Insurance Report</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              {RoleAccessInsuranceReportDownload && (
                <Button size={"large"} type="primary" onClick={handleDownload}>
                  Download
                </Button>
              )}
               <DatePicker
                  allowClear={false}
                  picker="month"
                  className="search-input btn-sh-sec"
                  defaultValue={dayjs(selectedDate, 'YYYY-MM')}
                  onChange={handleDatePickerChange}
                  placeholder="Select Month"
                  disabledDate={disabledDate}
                />
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
