import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { getPositionDetail } from "../../../api/position";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const PositionDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const positionList = async () => {
		setLoading(true);
		try {
		  const res = await getPositionDetail(id)
          console.log(res?.data?.data[0])
		  setDetail(res?.data?.data[0]);
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            positionList();
          }
		
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data",
			url: "/position"
		},
		{
			title: "Position",
			url: "/position"
		},
        {
			title: "Position Detail",
			// url: "/position-detail"
		},
	];
    return (
        <>
            <div className="title-section">
            <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/position')} icon={<ArrowLeftOutlined />} size={"large"} />
				Position Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Position Name</div>
                            <div className="subtitle">{detail?.name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Division Name</div>
                            <div className="subtitle">{detail?.division_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Parent</div>
                            <div className="subtitle">{detail?.parent_name || "-"}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PositionDetail