import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getShift = async (company, search, limit, offset, page) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        search_by : "name",
        search_value : search.length > 0 ? search : undefined,
        start :offset,
        length :limit,
        draw : page
    }
    
    return master.post("/mshifting",payload);
}

export const addShift = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        companyCode : data.companyCode,
        name : data.name,
        scheduleIn : data.scheduleIn,
        scheduleOut : data.scheduleOut,
        tolerance : data.tolerance,
        breakStart : data.breakStart,
        breakPeriod : data.breakPeriod,
        workhour : data.workhour,
        isDefault : data.isDefault ? 1 : 0,
        defaultType : data.defaultType,
        createdBy: user,
    }

    console.log(payload)
    
    return master.post("/mshifting-create", payload);
}

export const editShift = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        mshiftingId: id,
        companyCode : data.companyCode,
        name : data.name,
        scheduleIn : data.scheduleIn,
        scheduleOut : data.scheduleOut,
        tolerance : data.tolerance,
        breakStart : data.breakStart,
        breakPeriod : data.breakPeriod,
        workhour : data.workhour,
        isDefault : data.isDefault ? 1 : 0,
        defaultType : data.defaultType,
        modifiedBy: user
    }

    console.log(payload)
    return master.post("/mshifting-update", payload);
}

export const getShiftDetail = async (id) => {
    let payload = {
        mshiftingId: id,
    }
    return master.post("/mshifting-detail", payload);
}

export const deleteShift = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        mshiftingId: id,
        deletedBy: user
    }
    return master.post("/mshifting-delete", payload);
}

