/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form as FormAntd,
  Row,
  Select,
  message,
  DatePicker
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  InputWithLabel,
  SelectOptionWithLabel,
  SelectSearchWithLabel,
  TextAreaWithLabel,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import "./styles.scss";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import { AddLeaveTrasaction } from "../../api/leaveTransaction";
import { getLeaveDropdown } from "../../api/leave";
import { getListEmployeeActive } from "../../api/timeRequest";
import dayjs from "dayjs";
const FormAddLeaveTransaction = () => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const inputRef = useRef(null);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const { Option } = Select;
  const [fileName, setFileName] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsMLeave, setOptionsMLeave] = useState([]);
  const BestpraticeUseEffect = useRef(false);
  const employeeNumberActive = async () => {
    try {
      const res = await getListEmployeeActive(companyStore);
      const data = res?.data?.data;
      setOptions(data);
    } catch (err) {
      console.log(err);
    }
  };
  const dropdownMLeave = async () => {
    try {
      const res = await getLeaveDropdown(companyStore);
      const data = res?.data?.data;
      setOptionsMLeave(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dropdownMLeave();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSelectChange = (value) => {
    form.setValues({
        ...form.values,
        employeeId: value,
    })
    // Mendapatkan nilai full_name terkait dari data API
    const selectedData = options.find(option => option.employee_id === value);
    if (selectedData) {
    form.setFieldValue('DepartmentName',selectedData ? selectedData.department_name : '');
    form.setFieldValue('DivisionName',selectedData ? selectedData.division_name : '');
    form.setFieldValue('PositionName',selectedData ? selectedData.position_name : '');
    sessionStorage.setItem('selectedIdShift',selectedData ? selectedData.employee_number : '');
    } else {
    form.setFieldValue('EmployeeName');
    form.setFieldValue('DepartmentName');
    form.setFieldValue('DivisionName');
    form.setFieldValue('PositionName');
    }
    dropdownMLeave();
};

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      employeeNumberActive();
      form.resetForm();
    }
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const newFileName = file.name.replace(/['"]+/g, "");
      const renamedFile = new File([file], newFileName, { type: file.type });
      setFileName(renamedFile.name);
      form.setValues({
        ...form.values,
        attachment: renamedFile,
      });
    } else {
      setFileName(null);
      message.error(
        "Please upload a PDF,JPEG,JPG,PNG file with a maximum size of 2 MB"
      );
    }
  };

  const handleFileDelete = () => {
    inputRef.current.value = ""; // Clear the input field
    setFileName(null);
    form.setValues({
      ...form.values,
      attachment: null,
    });
  };

  const validationUser = yup.object().shape({
    startDate: yup.string().required("Field is required"),
    endDate: yup.string().required("Field is required"),
    mLeaveId: yup.string().required("Field is required"),
    requestType: yup.string().required("Field is required"),
    employeeId: yup.string().required("Field is required"),
   
});

  
  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      mLeaveId: "" ,
      startDate: "" ,
      endDate: "" ,
      requestType: "",
      LeaveType: "" ,
      description: "",
      employeeId: "",
      attachment: "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,
 
  onSubmit: async (values) => {
    try {
      const res = await AddLeaveTrasaction(values);
      if (!res?.data?.success) {
        message.error({
          content: res?.data?.error,
        });
      } else {
        message.success({
          content: "Leave successfully saved",
        });
        navigate("/leave-transaction");
        form.resetForm();
      }
      setFetching(false);
    } catch (error) {
      message.error({
        content: "Leave failed to saved",
      });
      setFetching(false);
    }
  },
});

  const dataBreadcrumb = [
    {
      title: "Attendance Management",
      url: "/leave-transaction",
    },
    {
      title: "Leave Transaction",
      url: "/leave-transaction",
    },
    {
      title: "Add Leave Transaction",
    },
  ];

  return (
    <>
      <div className="title-section">
        {result ? "Edit Leave Transaction" : "Add Leave Transaction"}
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <Col>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                <SelectSearchWithLabel
                    name="EmployeeNumber"
                    labelname={
                      <div>
                        Employee<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    showSearch
                    allowClear
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                    {options.map((option) => (
                      <Option
                        key={option.employee_id}
                        value={option.employee_id}>
                       {`${option.employee_number} - ${option.full_name}`}
                      </Option>
                    ))}
                  </SelectSearchWithLabel>
                  {form.touched.employeeId && form.errors.employeeId ? (
                    <span className="text-error">{form.errors.employeeId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
              <FormAntd.Item>
                    <InputWithLabel
                    disabled
                      name="PositionName"
                      label={"Position"}
                      value={form.values.PositionName} 
                      placeholder="Position"
                      onChange={form.handleChange}
              />
                  {form.touched.Position && form.errors.Position ? (
                    <span className="text-error">{form.errors.Position}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>

            <Row justify={"space-between"}>
            <Col span={11}>
              <FormAntd.Item>
                <InputWithLabel
                disabled
                  name="DepartmentName"
                  label={"Department"} 
                  placeholder="Department"
                  value={form.values.DepartmentName}
                  onChange={form.handleChange}
                  />
              </FormAntd.Item>
            </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                  disabled
                     name="DivisionName"
                    label={"Division"}
                    placeholder="Division"
                    value={form.values.DivisionName ? form.values.DivisionName : ""}
                    onChange={form.handleChange}
                  />
                  {form.touched.DivisionName && form.errors.DivisionName ? (
                    <span className="text-error">{form.errors.DivisionName}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                 <FormAntd.Item>
                          <div className="flex flex-col">
                          <div className="text-slate-500">{"Start Date"}<span className="text-red-500">*</span></div>
                          <DatePicker
                            name="startDate"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.startDate ? dayjs(form.values.startDate, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('startDate', formattedDate);
                      
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          {form.touched.startDate && form.errors.startDate && (
                            <span className="text-error">{form.errors.startDate}</span>
                          )}
                        </div>
                      </FormAntd.Item>
              </Col>
              <Col span={11}>
                 <FormAntd.Item>
                          <div className="flex flex-col">
                          <div className="text-slate-500">{"End Date"}<span className="text-red-500">*</span></div>
                          <DatePicker
                            name="endDate"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.endDate ? dayjs(form.values.endDate, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('endDate', formattedDate);
                      
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          {form.touched.endDate && form.errors.endDate && (
                            <span className="text-error">{form.errors.endDate}</span>
                          )}
                        </div>
                      </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Leave Type<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Leave Type"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                  name="requestType"
                    label={
                      <div>
                        Request Type<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Request Type"}
                    items={[
                      {
                        value: "Half Day",
                        title: "Half Day",
                      },
                      {
                        value: "Full Day",
                        title: "Full Day",
                      },
                    ]}
                    value={
                      form?.values?.requestType ? form?.values?.requestType : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        requestType: val,
                      })
                    }
                  />

                  {form.touched.requestType && form.errors.requestType ? (
                    <span className="text-error">{form.errors.requestType}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <div>
                    <TextAreaWithLabel
                       name="description"
                       label={"Description"}
                       placeholder="......."
                       maxLength={200}
                       showCount
                       onChange={form.handleChange}
                       value={form.values.description}
                    />
                  </div>
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <div className="mt-8">
                    <div>
                      <label className="file-upload">
                        <input
                          type="file"
                          name="attachment"
                          id="attachment"
                          onChange={handleFileUpload}
                          accept=".jpeg, .jpg, .png, .pdf"
                          ref={inputRef}
                        />
                        <CloudUploadOutlined /> Attachment
                      </label>
                    </div>
                    <div>
                      {fileName && (
                        <div>
                          <p>
                            {fileName}{" "}
                            <Button
                              shape="circle"
                              onClick={handleFileDelete}
                              icon={<DeleteOutlined style={{ color: "red" }} />}
                              size={"small"}
                            />{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </FormAntd.Item>
              </Col>
            </Row>
          </Col>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/leave-transaction")}>
            Cancel
          </Button>
          
          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddLeaveTransaction;
