import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getApprovallist = async (search, startDate, endDate,limit, offset, page,company,branch,employeeNumber,approvalType) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  let payload = {
    companyCode : companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch  : branchDefault ,
    employeeNumber: employeeNumber,
    startDate: startDate,
    endDate: endDate,
    filterColumn: "full_name",
    filter: search?.length > 0 ? search : undefined,
    approvalType: approvalType,
    start:  offset,
    length: limit,
    draw: page,
  };
  return master.post("/approval", payload);
};

export const getApprovalListDetail = async (id,row_type) => {
  let payload = {
    row_id: id,
    row_type: row_type
  };
  
  return master.post("/approval-detail", payload);
};


export const Singlecreate = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode : data.companyCode,
    employeeNumber :data.employeeNumber,
    row_id : data.row_id ? data.row_id : data.overtime_id,
    row_type: data.request_type,
    submit_type: data.submit_type,
    description: data.remark ? data.remark.length > 0 ? data.remark : undefined : undefined,
    created_by: user

  };
  return master.post("/approval-single-create", payload);
};
export const Bulkcreate = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode : data.companyCode,
    employeeNumber :data.employeeNumber,
    bulk_data : data.bulk_data,
    created_by: user

  };

  return master.post("/approval-bulk-create", payload,
  );
};




