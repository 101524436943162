import { Col, Row } from "antd"
import React from "react"

const EmployeeDetail = ({data}) => {
    return (
        <div className="detail-wrapper">
            <Row>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">NIK</div>
                        <div className="subtitle">{data.employee_number || "-"}</div>
                    </div>
                </Col>
                
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Employee Status</div>
                        <div className="subtitle">{data.employee_type || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Join Date</div>
                        <div className="subtitle">{data.join_date || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Start Date</div>
                        <div className="subtitle">{data.start_date || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    {data.employee_type === 'Permanent' ? 
                    <div className="detail-card">
                        <div className="title">Sign Date</div>
                        <div className="subtitle">{data.sign_date || "-"}</div>
                    </div>
                    :
                    <div className="detail-card">
                        <div className="title">End Date</div>
                        <div className="subtitle">{data.end_date || "-"}</div>
                    </div>
                    }
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Branch</div>
                        <div className="subtitle">{data.branch_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Department</div>
                        <div className="subtitle">{data.department_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Division</div>
                        <div className="subtitle">{data.division_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Direct Supervisor</div>
                        <div className="subtitle">{data.supervisor_name || "-"}</div>
                        <div className="title">{data.supervisor_company_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Level</div>
                        <div className="subtitle">{data.level_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Position</div>
                        <div className="subtitle">{data.position_name || "-"}</div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default EmployeeDetail