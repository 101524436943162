import { Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { getBranchDetail } from "../../../api/branch";

const AreaDetail = () => {
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const positionList = async () => {
		setLoading(true);
		try {
		  const res = await getBranchDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            positionList();
          }
		
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data",
			url: "/branch"
		},
		{
			title: "Branch",
			url: "/branch"
		},
        {
			title: "Branch Detail",
			// url: "/branch-detail"
		},
	];
    return (
        <>
            <div className="title-section">
				Branch Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Area </div>
                            <div className="subtitle">{detail?.name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Address</div>
                            <div className="subtitle">{detail?.address || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Postal Code</div>
                            <div className="subtitle">{detail?.postal_code || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Province</div>
                            <div className="subtitle">{detail?.province || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">City</div>
                            <div className="subtitle">{detail?.city || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Phone Number</div>
                            <div className="subtitle">{detail?.phone_number || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Email</div>
                            <div className="subtitle">{detail?.email || "-"}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AreaDetail