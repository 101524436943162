import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb as BreadcrumbAntd } from 'antd';
import "./styles.scss"

const Breadcrumb = ({items}) => (
  <BreadcrumbAntd className="breadcrumb text-xs md:text-sm">
    <BreadcrumbAntd.Item key={0} href="/dashboard">
      <HomeOutlined />
    </BreadcrumbAntd.Item>
    {items?.map((data, index) => (
        <BreadcrumbAntd.Item key={index + 1} href={data?.url}>
            <span>{data?.title}</span>
        </BreadcrumbAntd.Item>
    ))}
    
  </BreadcrumbAntd>
);

export default Breadcrumb;