import { Button, Form as FormAntd, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { InputWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { decryptData } from "../../helper/cryptojs";

const FormAddArea = () => {
    const companyStoreName = decryptData(sessionStorage.getItem("selectCompanyName"));
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const deBranchCode = decryptData(localStorage.getItem("DefaultBranchCode"));
    const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
    const deCompanyId = decryptData(localStorage.getItem("DefaultCompanyId"));
    const companyStoreId = decryptData(sessionStorage.getItem("selectCompanyId"));
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode?.lastIndexOf('?');
    var id = decode?.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const [listBranch, setListBranc] = useState([]);
    const BestpraticeUseEffect = useRef(false)


	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
           
          }
       
	}, [flag]);

    const validationUser = yup.object().shape({
        name: yup.string().required("Field is required"),
        province: yup.string().required("Field is required"),
    });
    const form = useFormik({
        initialValues: {
            name: result ? detail?.name : "",
            province: result ? detail?.name : "",
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
       
        },
    });
    return (
        <div className="add-wrapper">
            <div className="title-section">
				{result ? "Edit Area" : "Add Area"}
			</div>
            <FormAntd
                onFinish={form.handleSubmit}
            >  
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Area<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Name"
                        value={form?.values?.name}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.name && form.errors.name ? (
                        <span className="text-error">{form.errors.name}</span>
                    ) : null}
                </FormAntd.Item>
           
                <FormAntd.Item>
                    <InputWithLabel
                        name="province"
                        label={<div>Province<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Province"
                        value={form?.values?.province}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.province && form.errors.province ? (
                        <span className="text-error">{form.errors.province}</span>
                    ) : null}
                </FormAntd.Item>
             

             
             
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/area')}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default FormAddArea;