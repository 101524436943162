import {
  Button,
  Col,
  Form as FormAntd,
  Row,
  Select,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  SelectSearchWithLabel,
  SwitchLabel,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  getDetail,
  getManpowerSettingOptions,
  postManpowerSettingConfig,
} from "../../api/manpowerRequest";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";

const ManpowerRequest = () => {
  const navigate = useNavigate();
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const multiCompanyAccess = decryptData(
    localStorage.getItem("MultiCompanyAccess")
  );
  const multiCompanyLoad = decryptData(localStorage.getItem("MultiCompany"));
  const [detail, setDetail] = useState([]);
  const [optionsSeq1, setOptionsSeq1] = useState([]);
  const [optionsSeq2, setOptionsSeq2] = useState([]);
  const [optionsSeq3, setOptionsSeq3] = useState([]);
  const [optionsSeq4, setOptionsSeq4] = useState([]);
  const [optionsSeq5, setOptionsSeq5] = useState([]);

  const { Option } = Select;
  const [listApproval1, setListApproval1] = useState(null);
  const [listApproval2, setListApproval2] = useState(null);
  const [listApproval3, setListApproval3] = useState(null);
  const [listApproval4, setListApproval4] = useState(null);
  const [listApproval5, setListApproval5] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const changeMode = async (bool) => {
    setLoadingSwitch(true);
    if (!bool) {
      setListApproval1(null);
      setListApproval2(null);
      setListApproval3(null);
      setListApproval4(null);
      setListApproval5(null);
    }
    try {
      form.setValues({
        ...form.values,
        checked: bool,
      });

      const approvalSettings = [
        { key: null, setter: setOptionsSeq1 },
        { key: "approval_1", setter: setOptionsSeq2 },
        { key: "approval_2", setter: setOptionsSeq3 },
        { key: "approval_3", setter: setOptionsSeq4 },
        { key: "approval_4", setter: setOptionsSeq5 },
      ];

      for (const setting of approvalSettings) {
        const res = bool
          ? await getManpowerSettingOptions(
              detail[setting.key],
              company,
              multiCompanyAccess,
              multiCompanyLoad
            )
          : await getManpowerSettingOptions(detail[setting.key], company);
        const approvalData = res?.data?.data;
        setting.setter(approvalData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingSwitch(false);
    }
  };

  const ManpowerRequestDetail = async () => {
    try {
      setLoading(true);
      const res = await getDetail(company);
      const { data } = res?.data;
      if (data) {
        setDetail(data);

        form.setValues({
          checked: data.is_multi_company,
          approval_1: data.approval_1,
          approval_2: data.approval_2,
          approval_3: data.approval_3,
          approval_4: data.approval_4,
          approval_5: data.approval_5,
        });
        setListApproval1(data.approval_1_name);
        setListApproval2(data.approval_2_name);
        setListApproval3(data.approval_3_name);
        setListApproval4(data.approval_4_name);
        setListApproval5(data.approval_5_name);

        const approvalSettings = [
          { key: "approval_1", setter: setOptionsSeq2 },
          { key: "approval_2", setter: setOptionsSeq3 },
          { key: "approval_3", setter: setOptionsSeq4 },
          { key: "approval_4", setter: setOptionsSeq5 },
        ];

        for (const setting of approvalSettings) {
          const res = data?.is_multi_company
            ? await getManpowerSettingOptions(
                data[setting.key],
                company,
                multiCompanyAccess,
                multiCompanyLoad
              )
            : await getManpowerSettingOptions(data[setting.key], company);
          const approvalData = res?.data?.data;
          setting.setter(approvalData);
        }
        //always populate the approval 1 options
        const resOptSeq1 = data?.is_multi_company
          ? await getManpowerSettingOptions(
              null,
              company,
              multiCompanyAccess,
              multiCompanyLoad
            )
          : await getManpowerSettingOptions(null, company);
        setOptionsSeq1(resOptSeq1?.data?.data);
      } else {
        //always populate the approval 1 options
        const resOptSeq1 = await getManpowerSettingOptions(null, company);
        setOptionsSeq1(resOptSeq1?.data?.data);
      }
    } catch (err) {
      console.log(err);
      //always populate the approval 1 options
      const resOptSeq1 = await getManpowerSettingOptions(null, company);
      setOptionsSeq1(resOptSeq1?.data?.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await ManpowerRequestDetail();
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const form = useFormik({
    initialValues: {
      checked: detail ? detail.is_multi_company : false,
      approval_1: detail ? detail?.approval_1 : null,
      approval_2: detail ? detail?.approval_2 : null,
      approval_3: detail ? detail?.approval_3 : null,
      approval_4: detail ? detail?.approval_4 : null,
      approval_5: detail ? detail?.approval_5 : null,
    },
    enableReinitialize: true,
    // validationSchema: validationUser,
    onSubmit: async (values) => {
      try {
        // delete values.checked;
        const res = await postManpowerSettingConfig(values, company);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: "Set Approval Sequence successfully saved",
          });
          // navigate("/approval-setting")
          form.resetForm();
          await ManpowerRequestDetail()
          // setFlag((prev) => prev + 1);
        }
      } catch (error) {
        message.error({
          content: "Request Not Found",
        });
      }
    },
  });

  const handleSelectChangeApproval1 = async (value) => {
    setListApproval1(value === null ? null : value);
    setListApproval2(null);
    setListApproval3(null);
    setListApproval4(null);
    setListApproval5(null);
    form.setValues({
      ...form.values,
      approval_1: value,
      approval_2: null,
      approval_3: null,
      approval_4: null,
      approval_5: null,
    });
    if (value !== null) {
      try {
        const res = form.values.checked
          ? await getManpowerSettingOptions(
              value,
              company,
              multiCompanyAccess,
              multiCompanyLoad
            )
          : await getManpowerSettingOptions(value, company);
        const data = res.data.data;
        setOptionsSeq2(data);
        if (data.length === 0) {
          setOptionsSeq2([]);
          setOptionsSeq3([]);
          setOptionsSeq4([]);
          setOptionsSeq5([]);
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    }else{
      setOptionsSeq2([]);
      setOptionsSeq3([]);
      setOptionsSeq4([]);
      setOptionsSeq5([]);
    }
  };
  const handleSelectChangeApproval2 = async (value) => {
    setListApproval2(value === null ? null : value);
    setListApproval3(null);
    setListApproval4(null);
    setListApproval5(null);
    form.setValues({
      ...form.values,
      approval_2: value,
      approval_3: null,
      approval_4: null,
      approval_5: null,
    });
    if (value !== null) {
      try {
        const res = form.values.checked
          ? await getManpowerSettingOptions(
              value,
              company,
              multiCompanyAccess,
              multiCompanyLoad
            )
          : await getManpowerSettingOptions(value, company);
        const data = res.data.data;
        setOptionsSeq3(data);
        if (data.length === 0) {
          setOptionsSeq3([]);
          setOptionsSeq4([]);
          setOptionsSeq5([]);
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    }else{
      setOptionsSeq3([]);
      setOptionsSeq4([]);
      setOptionsSeq5([]);
    }
  };

  const handleSelectChangeApproval3 = async (value) => {
    setListApproval3(value === null ? null : value);
    setListApproval4(null);
    setListApproval5(null);
    form.setValues({
      ...form.values,
      approval_3: value,
      approval_4: null,
      approval_5: null,
    });
    if (value !== null) {
      try {
        const res = form.values.checked
          ? await getManpowerSettingOptions(
              value,
              company,
              multiCompanyAccess,
              multiCompanyLoad
            )
          : await getManpowerSettingOptions(value, company);
        const data = res.data.data;
        setOptionsSeq4(data);
        if (data.length === 0) {
          setOptionsSeq4([]);
          setOptionsSeq5([]);
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    }else{
      setOptionsSeq4([]);
      setOptionsSeq5([]);
    }
  };
  const handleSelectChangeApproval4 = async (value) => {
    setListApproval4(value === null ? null : value);
    setListApproval5(null);
    form.setValues({
      ...form.values,
      approval_4: value,
      approval_5: null,
    });
    if (value !== null) {
      try {
        const res = form.values.checked
          ? await getManpowerSettingOptions(
              value,
              company,
              multiCompanyAccess,
              multiCompanyLoad
            )
          : await getManpowerSettingOptions(value, company);
        const data = res.data.data;
        setOptionsSeq5(data);
        if (data.length === 0) {
          setOptionsSeq5([]);
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    }else{
      setOptionsSeq5([]);
    }
  };

  const handleSelectChangeApproval5 = async (value) => {
    setListApproval5(value === null ? null : value);
    form.setValues({
      ...form.values,
      approval_5: value,
    });
  };

  const dataBreadcrumb = [
    {
      title: "Recruitment - Manpower Request",
      url: "",
    },
    {
      title: `Manpower Setting`,
      url: ``,
    },
  ];

  const RoleAccessManpowerRequestCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeManpowerRequestCreate")
  );

  return (
    <>
      <div className="title-section">{"Manpower Setting"}</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          {multiCompanyAccess === "all" || multiCompanyAccess === "company" ? (
            <Row justify={"end"} align={"middle"}>
              <div className="label-input" style={{ fontSize: 12 }}>
                Multi Company
              </div>
              <SwitchLabel
                disabled={!RoleAccessManpowerRequestCreate}
                style={{ marginBottom: 8 }}
                checked={form?.values?.checked}
                onChange={(c, e) => {
                  changeMode(c);
                }}
                loading={loadingSwitch}
                tooltipMessage={
                  "Toggle to display multi-company approver options"
                }
                size={"small"}
                className="mx-2"
              />
            </Row>
          ) : null}
          <Row justify={"space-between"}>
            <Col span={11}>
              <FormAntd.Item>
                <SelectSearchWithLabel
                  disabled={!RoleAccessManpowerRequestCreate}
                  name="approval_1"
                  labelname={"Approval 1"}
                  loading={loading}
                  showSearch
                  placeholder="Select Approval 1"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  // value={listApproval1 ? listApproval1 : detail.approval_1_name}
                  value={
                    listApproval1
                  }
                  onChange={handleSelectChangeApproval1}
                >
                  <Option value={null}>None</Option>
                  {optionsSeq1.map((option) => (
                    <Option key={option.level_id} value={option.level_id}>
                      {option.company_code === company
                        ? option.level_name
                        : `${option.level_name} - ${option.company_name}`}
                    </Option>
                  ))}
                </SelectSearchWithLabel>
              </FormAntd.Item>
            </Col>
            <Col span={11}>
              <FormAntd.Item>
                <SelectSearchWithLabel
                  disabled={!RoleAccessManpowerRequestCreate}
                  name="approval_4"
                  labelname={"Approval 4"}
                  loading={loading}
                  showSearch
                  placeholder="Select Approval 4"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    listApproval4
                  }
                  onChange={handleSelectChangeApproval4}
                >
                  <Option value={null}>None</Option>
                  {optionsSeq4.map((option) => (
                    <Option key={option.level_id} value={option.level_id}>
                      {option.company_code === company
                        ? option.level_name
                        : `${option.level_name} - ${option.company_name}`}
                    </Option>
                  ))}
                </SelectSearchWithLabel>
              </FormAntd.Item>
            </Col>
          </Row>
          <Row justify={"space-between"}>
            <Col span={11}>
              <FormAntd.Item>
                <SelectSearchWithLabel
                  disabled={!RoleAccessManpowerRequestCreate}
                  name="approval_2"
                  labelname={"Approval 2"}
                  loading={loading}
                  showSearch
                  placeholder="Select Approval 2"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    listApproval2
                  }
                  onChange={handleSelectChangeApproval2}
                >
                  <Option value={null}>None</Option>
                  {optionsSeq2.map((option) => (
                    <Option key={option.level_id} value={option.level_id}>
                      {option.company_code === company
                        ? option.level_name
                        : `${option.level_name} - ${option.company_name}`}
                    </Option>
                  ))}
                </SelectSearchWithLabel>
              </FormAntd.Item>
            </Col>
            <Col span={11}>
              <FormAntd.Item>
                <SelectSearchWithLabel
                  disabled={!RoleAccessManpowerRequestCreate}
                  name="approval_5"
                  labelname={"Approval 5"}
                  loading={loading}
                  showSearch
                  placeholder="Select Approval 5"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    listApproval5
                  }
                  onChange={handleSelectChangeApproval5}
                >
                  <Option value={null}>None</Option>
                  {optionsSeq5.map((option) => (
                    <Option key={option.level_id} value={option.level_id}>
                      {option.company_code === company
                        ? option.level_name
                        : `${option.level_name} - ${option.company_name}`}
                    </Option>
                  ))}
                </SelectSearchWithLabel>
              </FormAntd.Item>
            </Col>
          </Row>
          <Row justify={"space-between"}>
            <Col span={11}>
              <FormAntd.Item>
                <SelectSearchWithLabel
                  disabled={!RoleAccessManpowerRequestCreate}
                  name="approval_3"
                  labelname={"Approval 3"}
                  loading={loading}
                  showSearch
                  placeholder="Select Approval 3"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={
                    listApproval3
                  }
                  onChange={handleSelectChangeApproval3}
                >
                  <Option value={null}>None</Option>
                  {optionsSeq3.map((option) => (
                    <Option key={option.level_id} value={option.level_id}>
                      {option.company_code === company
                        ? option.level_name
                        : `${option.level_name} - ${option.company_name}`}
                    </Option>
                  ))}
                </SelectSearchWithLabel>
              </FormAntd.Item>
            </Col>
          </Row>
        </FormAntd>
        {RoleAccessManpowerRequestCreate && (
          <div className="footer-add">
            <Button
              key="submit"
              type="primary"
              className="btn btn-primary btn-sh-p"
              onClick={form.handleSubmit}
              disabled={form.isSubmitting}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default ManpowerRequest;
