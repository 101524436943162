import { Modal, Form as FormAntd, Select, message, Button } from "antd";
import { useFormik } from "formik";
import * as yup from "yup";
import { InputWithLabel, SelectSearchWithLabel, DatePicker2WithLabel } from "../../component/Form/Input";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { editExpiredEmployee } from "../../api/employee";

export default function EditExpiredEmployee({ editEmployee, closeModal }) {
   const { Option } = Select;
   const [numberMonths, setNumberMonths] = useState([])
   const [period, setPeriod] = useState(6)
   const [status, setStatus] = useState(editEmployee.status)
   const [endDate, setEndDate] = useState(moment(editEmployee.end_date).format("YYYY-MM-DD"))
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      let numberMonths = [];
      for (let i = 1; i <= 12; i++) {
         numberMonths.push(i)
      }
      setNumberMonths(numberMonths)
      changePeriod(6)
   }, [])

   const changePeriod = (num) => {
      setPeriod(num)
      let oldEndDate = moment(editEmployee.end_date)
      let newEndDate = moment(oldEndDate).add(num, 'M').format("YYYY-MM-DD");
      setEndDate(newEndDate);
   }

   const validationEmployee = yup.object().shape({
      employeeNumber: yup.string().required("Field is required"),
      employeeStatus: yup.string().required("Field is required"),
      period: yup.string().required("Field is required"),
      signDate: yup.string().required("Field is required")
   });
   const form = useFormik({
      initialValues: {
         employeeNumber: editEmployee.employee_number,
         employeeStatus: status,
         period: period,
         signDate: moment().format("YYYY-MM-DD")
      },
      enableReinitialize: true,
      validationSchema: validationEmployee,
      onSubmit: async (values) => {
         setLoading(true)
         try {
            const res = await editExpiredEmployee(values)
            if (!res?.data?.success) {
               message.error({
                  content: res?.data?.error,
               });
               setLoading(false)
            } else {
               message.success({
                  content: "Employee successfully updated",
               });
               setLoading(false)
               closeModal(values, true)
            }

         } catch (error) {
            message.error({
               content: "Employee update failed to save",
            });
            setLoading(false)
         }
      },
   });
   return (
      <>
         <Modal
            title="Employee Update"
            open={editEmployee}
            onOk={form.handleSubmit}
            onCancel={closeModal}
            style={{
               top: 20,
            }}
            footer={[
               <Button key="back" onClick={closeModal}>
                  Cancel
               </Button>,
               <Button key="submit" type="primary" loading={loading} onClick={form.handleSubmit}>
                  Submit
               </Button>,
            ]}
         >
            <FormAntd>
               <FormAntd.Item>
                  <InputWithLabel
                     name="employeeNumber"
                     label={<div>NIK</div>}
                     value={editEmployee.employee_number}
                     disabled={true}
                  />
               </FormAntd.Item>
               <FormAntd.Item>
                  <InputWithLabel
                     name="employeeName"
                     label={<div>Employee Name</div>}
                     value={editEmployee.full_name}
                     disabled={true}
                  />
               </FormAntd.Item>
               <FormAntd.Item>
                  <SelectSearchWithLabel
                     labelname={<div>Employment Status</div>}
                     name="employmentStatus"
                     onChange={(val) => setStatus(val)}
                     value={status}
                  >
                     {editEmployee.status === "Probation" &&
                        <Option value="Probation">Probation</Option>
                     }
                     <Option value="Contract">Contract</Option>
                     <Option value="Permanent">Permanent</Option>
                  </SelectSearchWithLabel>
               </FormAntd.Item>
               {status === "Permanent" ?
                  <FormAntd.Item>
                     <DatePicker2WithLabel
                        name="signDate"
                        label={<div>Sign Date</div>}
                        value={form.values.signDate}
                        onChange={e => {
                           form.handleChange(e);
                        }}
                        onBlur={form.handleBlur}
                     />
                     {form.touched.signDate && form.errors.signDate ? (
                        <span className="text-error">{form.errors.signDate}</span>
                     ) : null}
                  </FormAntd.Item>
                  :
                  <>
                     <FormAntd.Item>
                        <SelectSearchWithLabel
                           labelname={<div>Period</div>}
                           name="period"
                           onChange={(val) => changePeriod(val)}
                           value={period}
                           onBlur={() => { }}
                        >
                           {numberMonths.map(num => <Option key={num} value={num}>{num} Months</Option>)}
                        </SelectSearchWithLabel>
                     </FormAntd.Item>
                     <FormAntd.Item>
                        <DatePicker2WithLabel
                           name="joinDate"
                           label={<div>End Date</div>}
                           value={endDate}
                           disabled={true}
                        />
                     </FormAntd.Item>
                  </>}
            </FormAntd>
         </Modal>
      </>
   )
}