import * as yup from "yup";

export const validationPersonal = yup.object().shape({
    name:  yup.string().required("Field is required"),
    emailOffice: yup.string().email("Must be a valid email").required("Field is required"),
    emailPersonal: yup.string().email("Must be a valid email"),
    placeOfBirth: yup.string().required("Field is required"),
    dateOfBirth: yup.string().required("Field is required"),
    nationaly: yup.string().required("Field is required"),
    religion: yup.string().required("Field is required"),
    gender: yup.string().required("Field is required"),
    maritalStatus: yup.string().required("Field is required"),
    phoneNumber: yup.string().min(10, "Min 10 characters").required("Field is required"),
    education: yup.string().required("Field is required"),
    identityNumber: yup.string().min(16, "Min 16 character").required("Field is required"),
    //familyCertificateNumber: yup.number().min(16, "Min 16 character"),
    //driverLicenseNumber: yup.number().min(12, "Min 12 character"),
    addressPermanent: yup.string().required("Field is required"),
    postalCodePermanent: yup.number().required("Field is required"),
    cityPermanent: yup.string().required("Field is required"),
    provincePermanent: yup.string().required("Field is required"),
    addressCurrent: yup.string().required("Field is required"),
    postalCodeCurrent: yup.number().required("Field is required"),
    cityCurrent: yup.string().required("Field is required"),
    provinceCurrent: yup.string().required("Field is required"),
    Age: yup.string().required("Field is required"),
    HusbandWifeEmail: yup.string().email("must be a valid email").nullable(),
    HusbandWifeMobile: yup.string().min(10, "Min 10 characters").nullable(),
    EducationInstitution: yup.string().required("Field is required"),
    EducationField: yup.string().required("Field is required"),
    Gpa: yup.string().required("Field is required"),
});

export const validationEmployee = yup.object().shape({
    // nik: yup.number().min(16, "Min 16 character"),
    // employeeStatus: yup.string(),
    joinDate: yup.string().required("Field is required"),
    startDate: yup.string().required("Field is required"),
    endDate: yup.string().required("Field is required"),
    // branch: yup.string().required("Field is required"),
    // department: yup.string().required("Field is required"),
    // division: yup.string().required("Field is required"),
    // directSupervisor: yup.string().required("Field is required"),
    // level: yup.string().required("Field is required"),
    // position: yup.string().required("Field is required"),
});

export const validationPayroll = yup.object().shape({
    basicSalary: yup.string().required("Field is required"),
    salaryType:  yup.string().required("Field is required"),
    paymentSchedule:  yup.string().required("Field is required"),
    bankName:  yup.string().required("Field is required"),
    accountNumber: yup.string().required("Field is required"),
    accountName:  yup.string().required("Field is required"),
    allowedForOvertime:  yup.string().required("Field is required"),
    npwp:  yup.number(),
    bpjsKetenagakerjaanType: yup.string().required("Field is required"),
});