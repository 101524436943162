import React from "react";
import InputWithIcon from "../../component/Form/InputWithIcon";
import userIcon from "../../assets/img/icons/icon-user.svg";
import lockImg from "../../assets/img/lock.png";
import { Button, Form as FormAntd} from "antd";

const ForgotPasswordForm = ({form, backToLogin, fetching}) => {
    return (
        <div className="forgot-wrapper">
            <div className="section-form">
                <img src={lockImg} className="img-forgot"></img>
                <div className="title-section-login">
                    <div className="title">
                        Forgot Password
                    </div>
                </div>
                <FormAntd
                    onFinish={form.handleSubmit}
                >
                    <FormAntd.Item
                        name="email"
                        rules={[
                            {
                            required: true,
                            message: "Please insert your email address.",
                            },
                            {
                            type: "email",
                            message: "Please insert valid email address.",
                            },
                        ]}
                        className="form-input"
                    >
                        <InputWithIcon
                            placeholder="Example@gmail.com"
                            value={form.values.email}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            img={userIcon}
                        />
                    </FormAntd.Item>
                    
                </FormAntd>
                <div className="footer">
                    <div className="btn-wrapper">
                        <Button
                            type="submit"
                            className="btn btn-submit"
                            loading={fetching}
                            onClick={form.handleSubmit}
                        >
                            Reset Password
                        </Button>
                        <Button
                            className="btn btn-register"
                            loading={false}
                            onClick={backToLogin}
                        >
                           Go Back to Login
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default ForgotPasswordForm