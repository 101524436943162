import { decryptData} from "../helper/cryptojs";
import { master } from "./hris";

export const getAttendance = async (search, startDate, endDate, limit, offset, page, company, branch) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  let payload = {
    startDate: startDate,
    endDate: endDate,
    filterColumn: "employees.full_name",
    filter: search?.length > 0 ? search : undefined,
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch  : branchDefault ,
    start:  offset,
    length: limit,
    draw: page,
  };
  return master.post("/attendance", payload);
};
export const getNoAttendance = async (search,startDate, endDate, limit, offset, page, company, branch) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  let payload = {
    startDate: startDate,
    endDate: endDate,
    filterColumn: "employees.full_name",
    filter: search?.length > 0 ? search : undefined,
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch  : branchDefault ,
    start:  offset,
    length: limit,
    draw: page,
  };
  return master.post("/no-attendance", payload);
};

export const getAttendanceDetail = async (id) => {
  let payload = {
    attendanceId: id,
  };
  return master.post("/attendance-detail", payload);
};


export const AddAttendance = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    employeeNumber: data.EmployeeNumber,
    employee_name: data.EmployeeName,
    date_from: data.DateFrom,
    date_to: data.DateFrom,
    check_in: data.CheckIn,
    check_out: data.CheckOut,
    type: data.Type,
    time_off: data.TimeOff,
    leave_type: data.LeaveType,
    leave_time: data.LeaveTime,
    document_url: data.document_url,
    created_by: user,
     modified_by: user
  };

  return master.post("/attendance-create", payload);
};

export const EditAttendance = async (data,id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    attendanceId: id,
    employeeNumber: data.EmployeeNumber,
    employee_name: data.EmployeeName,
    date_from: data.DateFrom,
    date_to: data.DateFrom,
    check_in: data.CheckIn,
    check_out: data.CheckOut,
    type: data.Type,
    time_off: data.TimeOff,
    leave_type: data.LeaveType,
    leave_time: data.LeaveTime,
    document_url: data.document_url,
    created_by: user,
    modified_by: user
  };

  return master.post("/attendance-data-modify", payload);
};

export const getAttendanceSummary = async (search,startDate, endDate, limit, offset, page, company, branch) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  let payload = {
    startDate: startDate,
    endDate: endDate,
    filterColumn: "employees.full_name",
    filter: search?.length > 0 ? search : undefined,
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch  : branchDefault ,
    start: offset,
    length: limit,
    draw: page,
  };
  return master.post("/attendance-summary", payload);
};