import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getMOvertime = async (company, search,offset, limit, page) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        search_by : "name",
        search_value : search.length > 0 ? search : undefined,
        start:  offset,
        length: limit,
        draw: page,
    }
    
    return master.post("/movertime",payload);
}

export const addMOvertime = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        companyCode : data.companyCode,
        name: data.name,
        description:data.description,
        max_hour:data.max_hour,
        min_minute:data.min_minute,
        rounding:data.rounding,
        compensation_type: data.compensation_type,
        rounding_arr: JSON.stringify(data.rounding_arr),
        day_type: data.day_type,
        applies_to : data.applies_to,
        custom_apply_level : data.applies_to === 'Custom'? JSON.stringify(data.custom_apply_level): null,
        custom_apply_position : data.applies_to === 'Custom'? JSON.stringify(data.custom_apply_position): null,
        rate_type : data.rate_type,
        flat_allowance : data.rate_type === "flat" ? parseInt(data.flat_allowance.replace(/,/g, "")) : null,
        formula_arr : data.rate_type === 'formula'?  JSON.stringify(data.formula_arr): null,
        custom_arr : data.rate_type === 'custom'? JSON.stringify(data.custom_arr): null,
        created_by: user,
    }
    
    return master.post("/movertime-create", payload);
}

export const getMOvertimeDetail = async (id) => {
    let payload = {
        m_overtime_id: id,
    }
    console.log(payload)
    return master.post("/movertime-detail", payload);
}

export const deleteMOvertime = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        m_overtime_id: id,
        deleted_by: user
    }
    return master.post("/movertime-delete", payload);
}

export const editMOvertime = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        m_overtime_id: id,
        companyCode : data.companyCode,
        name: data.name,
        description:data.description,
        max_hour:data.max_hour,
        min_minute:data.min_minute,
        rounding:data.rounding,
        compensation_type: data.compensation_type,
        rounding_arr: JSON.stringify(data.rounding_arr),
        day_type: data.day_type,
        applies_to : data.applies_to,
        custom_apply_level : JSON.stringify(data.custom_apply_level),
        custom_apply_position : JSON.stringify(data.custom_apply_position),
        rate_type : data.rate_type,
        flat_allowance : data.flat_allowance?? 0,
        formula_arr : data.rate_type === 'formula'?  JSON.stringify(data.formula_arr): null,
        custom_arr : data.rate_type === 'custom'? JSON.stringify(data.custom_arr): null,
        modified_by: user
    }
    return master.post("/movertime-update", payload);
}

export const getListLevel = async (companyCode) => {
    let payload = {
        companyCode: companyCode,
    }
    return master.post("/list-level-input", payload);
}

export const getListPosition = async (companyCode) => {
    let payload = {
        companyCode: companyCode,
    }
    return master.post("/list-position-input", payload);
}