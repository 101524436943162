import React from "react";
import { Input } from "antd";
import "./styles.scss"

const InputWithIcon = ({ img, classImg, ...props }) => {
    return (
        <div className="input-with-icon">
            <Input {...props}/>
            <img src={img} className={classImg}/>
        </div>
    )
}

export const InputPasswordWithIcon = ({ img, classImg, ...props }) => {
    return (
        <div className="input-with-icon">
            <Input.Password {...props}/>
            <img src={img} className={classImg}/>
        </div>
    )
}

export default InputWithIcon