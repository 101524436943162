import { Col, Row, Form as FormAntd, Checkbox } from "antd"
import React from "react"

const PersonalDetail = ({data}) => {
    return (
        <div className="detail-wrapper">
            <Row>
                <Col span={24}>
                    <div className="detail-card">
                        <div className="title">Name</div>
                        <div className="subtitle">{data.full_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Personal Email</div>
                        <div className="subtitle">{data.email || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Office Email</div>
                        <div className="subtitle">{data.official_email || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Education</div>
                        <div className="subtitle">{data.education || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Education Institution</div>
                        <div className="subtitle">{data.education_institution || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Education Field</div>
                        <div className="subtitle">{data.education_field || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">GPA</div>
                        <div className="subtitle">{data.gpa || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Place of Birth</div>
                        <div className="subtitle">{data.place_of_birth || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Date of Birth</div>
                        <div className="subtitle">{data.date_of_birth || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Age</div>
                        <div className="subtitle">{data.age || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Nationality</div>
                        <div className="subtitle">{data.nationality || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Religion</div>
                        <div className="subtitle">{data.religion || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Gender</div>
                        <div className="subtitle">{data.gender || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Marital Status</div>
                        <div className="subtitle">{data.marital_status || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Phone Number</div>
                        <div className="subtitle">{data.phone_number || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Identity Card Number</div>
                        <div className="subtitle">{data.identity_card_number || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Family Certificate Number</div>
                        <div className="subtitle">{data.family_certificate_number || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Driver License Number</div>
                        <div className="subtitle">{data.driver_license_number || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Driver License Expired</div>
                        <div className="subtitle">{data.driver_license_expiry || "-"}</div>
                    </div>
                </Col>
                
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Permanent Address</div>
                        <div className="subtitle">{data.permanent_address || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">City</div>
                        <div className="subtitle">{data.permanent_city || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Province</div>
                        <div className="subtitle">{data.permanent_province || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Postal Code</div>
                        <div className="subtitle">{data.permanent_postal_code || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Current Address</div>
                        <div className="subtitle">{data.current_address || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">City</div>
                        <div className="subtitle">{data.current_city || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Province</div>
                        <div className="subtitle">{data.current_province || "-"}</div>
                    </div>
                </Col>

                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Postal Code</div>
                        <div className="subtitle">{data.current_postal_code || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Husband/Wife Name</div>
                        <div className="subtitle">{data.husband_wife_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Husband / Wife Phone Number</div>
                        <div className="subtitle">{data.husband_wife_mobile || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Husband/Wife Email</div>
                        <div className="subtitle">{data.husband_wife_email || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Husband/Wife Occupation</div>
                        <div className="subtitle">{data.husband_wife_occupation || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Number of Children</div>
                        <div className="subtitle">{data.child || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Child Name 1</div>
                        <div className="subtitle">{data.child_1 || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Child Name 2</div>
                        <div className="subtitle">{data.child_2 || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Child Name 3</div>
                        <div className="subtitle">{data.child_3 || "-"}</div>
                    </div>
                </Col>

                <Col span={24}>
                    <div className="detail-card">
                        <div className="title">Status Employee</div>
                        <div className="subtitle">{data.is_active == 1?"Active":"Inactive" || "-"}</div>
                    </div>
                </Col>
                {data.is_active == 0  && (
                    <Col span={24}>
                    <div className="detail-card">
                        <div className="title">Description</div>
                        <div className="subtitle">{data.in_active_description || "-"}</div>
                    </div>
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default PersonalDetail