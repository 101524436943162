import { decryptData, encryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getDepartment = async (search, limit, offset, page,company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        startDate : "",
        endDate : "",
        filterColumn : "name",
        filter : search.length > 0 ? search : undefined,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/department", payload);
}

export const addDepartment = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        name: data.name,
        companyCode: data.companyCode,
        is_active: "1",
        created_by: user
    }
    return master.post("/department-create", payload);
}

export const getDepartmentDetail = async (id) => {
    let payload = {
        departmentCode: id,
    }
    return master.post("/department-detail", payload);
}

export const deleteDepartment = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let paylaod = {
        departmentCode: id,
        is_active: "0",
        modified_by: user
    }
    return master.post("/department-delete", paylaod);
}

export const editDepartment = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let paylaod = {
        departmentCode: id,
        name: data.name,
        companyCode: data.companyCode,
        is_active: "1",
        modified_by: user
    }
    return master.post("/department-update", paylaod);
}