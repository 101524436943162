import React, { useState } from "react";
import { Button, Modal, Form as FormAntd, Upload, message, Row, Col } from "antd";
import { CloudUploadOutlined, FileExcelOutlined } from "@ant-design/icons";
import * as yup from "yup";
import { useFormik } from "formik";
import { uploadBulkEditExpiredEmployee } from "../../api/employee";
import { decryptData } from "../../helper/cryptojs";
import Link from "antd/es/typography/Link";

export default function BulkUpdateExpiredEmployee({ openModal, closeModal }) {
   const company = sessionStorage.getItem("selectCompany")
      ? decryptData(sessionStorage.getItem("selectCompany"))
      : decryptData(localStorage.getItem("DefaultCompanyCode"));
   const branch = sessionStorage.getItem("selectBranch")
      ? decryptData(sessionStorage.getItem("selectBranch"))
      : decryptData(localStorage.getItem("DefaultBranchCode"));
   const [loading, setLoading] = useState(false);
   const { Dragger } = Upload;
   const validationUser = yup.object().shape({
      doc_file: yup.string().required("Field is required")
   });
   const form = useFormik({
      initialValues: {
         doc_file: null
      },
      enableReinitialize: true,
      validationSchema: validationUser,
      onSubmit: async (values) => {
         setLoading(true);
         try {
            const res = await uploadBulkEditExpiredEmployee(values);
            if (!res?.data?.success) {
               message.error({
                  content: res?.data?.error,
               });
            } else {
               const successNum = res.data.data.success
               const failedNum = res.data.data.failed
               Modal.success({
                  title: "Result data update expired employee",
                  content: (
                     <div>
                        <div className="grid grid-flow-col">
                           <p>Total Row : {successNum + failedNum}</p>
                           <p>Success Row : {successNum}</p>
                        </div>
                        <div className="grid grid-flow-col">
                           <p>Failed Row : {failedNum}</p>
                           {failedNum > 0 &&
                              <p>
                                 Result File :
                                 <a
                                    href={res.data.data.failed_file}
                                    download
                                 >
                                    Download
                                 </a>
                              </p>
                           }
                        </div>
                     </div>
                  ),
                  onOk: closeModal(values, true),
               });
            }
            setLoading(false);
         } catch (error) {
            message.error({
               content: "Employee update failed to save",
            });
            setLoading(false);
         }
      },
   });

   const handleFileUpload = (info) => {
      const { status } = info.file;
      if (status !== "uploading") {
         // form.setFieldValue("doc_file", info.file);
         if (info.file.size <= 2097152) {
            form.setFieldValue("doc_file", info.file);
         } else {
            message.error("File size exceeds 2MB limit");
         }
      } if (status === "removed") {
         form.setFieldValue("doc_file", null);
      };
   }
   return (
      <>
         <Modal
            title="Import Employees Update"
            open={openModal}
            onCancel={closeModal}
            style={{
               top: 20,
            }}
            footer={[
               <Button key="back" onClick={closeModal}>
                  Cancel
               </Button>,
               <Button key="submit" type="primary" loading={loading} onClick={form.handleSubmit}>
                  Submit
               </Button>,
            ]}
         >
            <FormAntd onFinish={form.handleSubmit}>
               <div>
                  <h4>Download Template</h4>
                  <Row align="middle">
                     <Col span={6} className="text-center">
                        <FileExcelOutlined style={{ fontSize: "4em" }} />
                     </Col>
                     <Col span={18}>
                        <p className="text-gray-400">
                           Employees update template
                        </p>
                        <Link href={`${process.env.REACT_APP_HRIS_URL}template-update-expired-employee?companyCode=${company}&branchCode=${branch}`}>
                           Download the following template and please fill in the data
                        </Link>
                     </Col>
                  </Row>
               </div>
               <div>
                  <h4>Upload Attachment <span className="text-error">*</span></h4>
               </div>
               <div className="mt-5">
                  <Dragger
                     beforeUpload={() => false}
                     onChange={handleFileUpload}
                     accept=".xlsx, .xls"
                     maxCount={1}
                  >
                     <p className="ant-upload-drag-icon">
                        <CloudUploadOutlined />
                     </p>
                     <p className="ant-upload-text">
                        Select a file or drag and drop here{" "}
                     </p>
                     <p className="ant-upload-hint">XLSX, file size no more 2MB </p>
                  </Dragger>
                  {form.touched.doc_file && form.errors.doc_file ? (
                     <span className="text-error">{form.errors.doc_file}</span>
                  ) : null}
               </div>
            </FormAntd>
         </Modal>
      </>
   )
}