import { Button, Col, Form as FormAntd, Row, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import  {  InputWithLabel, SelectSearchWithLabel, SwitchLabel } from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AddApprovalSetting, EditApprovalSetting, getApprovalSettingDetail, getApprovalSettingEmployeeNumber, getApprovalSettingList, getApprovalSettingList1 } from "../../api/approvalSetting";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
const FormAddApprovalSetting = () => {
    const navigate = useNavigate();
    const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const multiCompanyAccess = decryptData(localStorage.getItem('MultiCompanyAccess'))
    const multiCompanyLoad = decryptData(localStorage.getItem('MultiCompany'))
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [optionsSeq1, setOptionsSeq1] = useState([]);
    const [optionsSeq2, setOptionsSeq2] = useState([]);
    const [optionsSeq3, setOptionsSeq3] = useState([]);
    const [optionsSeq4, setOptionsSeq4] = useState([]);
    const [optionsSeq5, setOptionsSeq5] = useState([]);

    const [options1, setOptions1] = useState([]);
    const { Option } = Select;
    const [listApproval1, setListApproval1] = useState('');
    const [listApproval2, setListApproval2] = useState('');
    const [listApproval3, setListApproval3] = useState('');
    const [listApproval4, setListApproval4] = useState('');
    const [listApproval5, setListApproval5] = useState('');
    const [fetching, setFetching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSwitch, setLoadingSwitch] = useState(false);

  
    const ApprovalListSquence = async (bool = false)  => {
        setLoading(true);
        try {
            const test =  sessionStorage.getItem('selectedEmployeeNumber');
            const res = bool ? await getApprovalSettingList(test, multiCompanyAccess, multiCompanyLoad) : await getApprovalSettingList(test)
            const data = res.data.data
            setOptionsSeq1(data)
        } catch (err) {
            console.log(err);
        }finally {
            setLoading(false);
            
          };
    }

    const changeMode = async (bool) => {
        setLoadingSwitch(true);
        if(!bool){
            form.setValues({
                ...form.values,
                checked : bool,
                approval_1 :  "",
                approval_2 :  "",
                approval_3 :  "",
                approval_4 :  "",
                approval_5 :  "",
            })
            setListApproval1(null)
            setListApproval2(null)
            setListApproval3(null)
            setListApproval4(null)
            setListApproval5(null)
            setLoadingSwitch(false);
        }else{
            try {
                form.setValues({
                    ...form.values,
                    checked : bool,
                })
                const test =  sessionStorage.getItem('selectedEmployeeNumber');
                const res = bool ? await getApprovalSettingList(test, multiCompanyAccess, multiCompanyLoad) : await getApprovalSettingList(test)
                const data = res.data.data
                setOptionsSeq1(data)
    
                const approvalSettings = [
                    { key: 'approval_1', setter: setOptionsSeq2 },
                    { key: 'approval_2', setter: setOptionsSeq3 },
                    { key: 'approval_3', setter: setOptionsSeq4 },
                    { key: 'approval_4', setter: setOptionsSeq5 },
                    ];
                    
                    for (const setting of approvalSettings) {
                        if (detail?.[setting.key]) {
                        const resAp = bool
                        ? await getApprovalSettingList1(detail[setting.key], multiCompanyAccess, multiCompanyLoad)
                        : await getApprovalSettingList1(detail[setting.key]);
                        const approvalData = resAp?.data?.data;
                        bool ? setting.setter(approvalData) : setting.setter([])
                    }
                    if(!bool){
                        setListApproval1(null)
                        setListApproval2(null)
                        setListApproval3(null)
                        setListApproval4(null)
                        setListApproval5(null)
                    }
                    }
            } catch (err) {
                console.log(err);
            }finally {
                setLoadingSwitch(false);
              };
        }
    }

    const ApprovalemployeeNumberDetail = async () => {
        try {
          setLoading(true);
          const res = await getApprovalSettingDetail(id);
          const data = res?.data?.data;
          const approvalSettings = [
            { key: 'approval_1', setter: setOptionsSeq2 },
            { key: 'approval_2', setter: setOptionsSeq3 },
            { key: 'approval_3', setter: setOptionsSeq4 },
            { key: 'approval_4', setter: setOptionsSeq5 },
            ];
            
            for (const setting of approvalSettings) {
                if (data?.[setting.key]) {
                const res = data?.is_multi_company
                ? await getApprovalSettingList1(data[setting.key], multiCompanyAccess, multiCompanyLoad)
                : await getApprovalSettingList1(data[setting.key]);
                const approvalData = res?.data?.data;
                setting.setter(approvalData);
                }
            }
          setDetail(data);
          if (data) {
              setSelectedOption(data.employee_number);
            sessionStorage.setItem('selectedEmployeeNumber', data.employee_number);
            await ApprovalListSquence(data?.is_multi_company)
          }
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      };

    const ApprovalemployeeNumberActive = async () => {
        try {
            const res = await getApprovalSettingEmployeeNumber(company)
            const data = res?.data?.data;
            setOptions1(data)
          } catch (err) {
            console.log(err);
          }
	  };

    useEffect(() => {
          const fetchData = async () => {
            try {
              await ApprovalemployeeNumberDetail();
              await ApprovalemployeeNumberActive();
              
              if (!result) {
                setSelectedOption('');
                sessionStorage.removeItem('selectedEmployeeNumber');
                await ApprovalListSquence();
              }
            } catch (err) {
              console.log(err);
            }
          };
      
          fetchData();
      }, []);
      

     
    const handleSelectChange = async (value)  => {
        setSelectedOption(value);
        sessionStorage.setItem('selectedEmployeeNumber', value);
        form.setValues({
            ...form.values,
            employeeNumber: value,
        })
        // Mendapatkan nilai full_name terkait dari data API
        const selectedData = options1.find(option => option.employee_number === value);
        if (selectedData) {
        form.setFieldValue('EmployeeName',selectedData ? selectedData.full_name : '');
        form.setFieldValue('DepartmentName',selectedData ? selectedData.department_name : '');
        form.setFieldValue('DivisionName',selectedData ? selectedData.division_name : '');
        form.setFieldValue('PositionName',selectedData ? selectedData.position_name : '');
        } else {
        form.setFieldValue('EmployeeName');
        form.setFieldValue('DepartmentName');
        form.setFieldValue('DivisionName');
        form.setFieldValue('PositionName');
        }
            try {
                const test =  sessionStorage.getItem('selectedEmployeeNumber');
                const res = await getApprovalSettingList(test)
                const data = res.data.data
                // console.log('data 1',data)
                setOptionsSeq1(data)
            } catch (err) {
                console.log(err);
            }finally {
                setFetching(false);
              }
             result ? sessionStorage.removeItem('selectedEmployeeNumber1') : sessionStorage.removeItem('selectedEmployeeNumber1');
             result ? sessionStorage.removeItem('selectedEmployeeNumber2') : sessionStorage.removeItem('selectedEmployeeNumber2');
             result ? sessionStorage.removeItem('selectedEmployeeNumber3') : sessionStorage.removeItem('selectedEmployeeNumber3');
             result ? sessionStorage.removeItem('selectedEmployeeNumber4') : sessionStorage.removeItem('selectedEmployeeNumber4');
             result ? sessionStorage.removeItem('selectedEmployeeNumber5') : sessionStorage.removeItem('selectedEmployeeNumber5');
             result ?  setListApproval1(null) : setListApproval1('') 
             result ?  setListApproval2(null) : setListApproval2('') 
             result ?  setListApproval3(null) : setListApproval3('') 
             result ?  setListApproval4(null) : setListApproval4('') 
             result ?  setListApproval5(null) : setListApproval5('') 
        
    };

    const form = useFormik({
        initialValues: {
            employeeNumber: result ? detail?.employee_number : '',
            checked: result ? detail?.is_multi_company : null,
            approval_1 : result ? detail?.approval_1: '',
            approval_2 : result ? detail?.approval_2: '',
            approval_3 : result ? detail?.approval_3: '',
            approval_4 : result ? detail?.approval_4: '',
            approval_5 : result ? detail?.approval_5: '',
            approval_1_name : result ? detail?.approval_1_name: '',
            approval_2_name : result ? detail?.approval_2_name: '',
            approval_3_name : result ? detail?.approval_3_name: '',
            approval_4_name : result ? detail?.approval_4_name: '',
            approval_5_name : result ? detail?.approval_5_name: '',
            approval_1_company : result ? detail?.approval_1_company_name: '',
            approval_2_company : result ? detail?.approval_1_company_name: '',
            approval_3_company : result ? detail?.approval_1_company_name: '',
            approval_4_company : result ? detail?.approval_1_company_name: '',
            approval_5_company : result ? detail?.approval_1_company_name: '',
        },
        enableReinitialize: true,
        // validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
            try {
                // delete values.checked;
                const res = result ? await EditApprovalSetting(values, id) : await AddApprovalSetting(values);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Approval successfully updated" : "Approval successfully saved",
                    });
                    result ? sessionStorage.removeItem('selectedEmployeeNumber1') : sessionStorage.removeItem('selectedEmployeeNumber1');
                    result ? sessionStorage.removeItem('selectedEmployeeNumber2') : sessionStorage.removeItem('selectedEmployeeNumber2');
                    result ? sessionStorage.removeItem('selectedEmployeeNumber3') : sessionStorage.removeItem('selectedEmployeeNumber3');
                    result ? sessionStorage.removeItem('selectedEmployeeNumber4') : sessionStorage.removeItem('selectedEmployeeNumber4');
                    result ? sessionStorage.removeItem('selectedEmployeeNumber5') : sessionStorage.removeItem('selectedEmployeeNumber5');
                    navigate("/approval-setting")
                    form.resetForm();
                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });
    

    const handleSelectChangeApproval1 = async (value) => {
        setListApproval1(value === null ? null : value);
        setListApproval2(null)
        setListApproval3(null)
        setListApproval4(null)
        setListApproval5(null)
        sessionStorage.setItem('selectedEmployeeNumber1',value) 
        form.setValues({
            ...form.values,
            approval_1: value,
            approval_2 : null,
            approval_3 : null,
            approval_4 : null,
            approval_5 : null,
        })
        if (value !== null) {
            try {
                const employeeId = sessionStorage.getItem('selectedEmployeeNumber1');
                const res = form.values.checked ? await getApprovalSettingList1(employeeId, multiCompanyAccess, multiCompanyLoad) : await getApprovalSettingList1(employeeId)
                const data = res.data.data;
                setOptionsSeq2(data);
                if(data.length === 0){
                    setOptionsSeq2([]);
                    setOptionsSeq3([]);
                    setOptionsSeq4([]);
                    setOptionsSeq5([]);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setFetching(false);
            }
        }
    };
    const handleSelectChangeApproval2 = async (value) => {
        setListApproval2(value === null ? null : value);
        setListApproval3(null)
        setListApproval4(null)
        setListApproval5(null)
        sessionStorage.setItem('selectedEmployeeNumber2',value) 
        form.setValues({
            ...form.values,
            approval_2: value,
            approval_3 : null,
            approval_4 : null,
            approval_5 : null,
        })
     
        if (value !== null) {
            try {
                const employeeId = sessionStorage.getItem('selectedEmployeeNumber2');
                const res = form.values.checked ? await getApprovalSettingList1(employeeId, multiCompanyAccess, multiCompanyLoad) : await getApprovalSettingList1(employeeId)
                const data = res.data.data;
                setOptionsSeq3(data);
                if(data.length === 0){
                    setOptionsSeq3([]);
                    setOptionsSeq4([]);
                    setOptionsSeq5([]);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setFetching(false);
            }
        } 
    };
  
    const handleSelectChangeApproval3 = async(value) => {
        setListApproval3(value === null ? null : value);
        setListApproval4(null)
        setListApproval5(null)
        sessionStorage.setItem('selectedEmployeeNumber3',value) 
        form.setValues({
            ...form.values,
            approval_3: value,
            approval_4 : null,
            approval_5 : null,
        })
       
     if (value !== null) {
        try {
            const employeeId = sessionStorage.getItem('selectedEmployeeNumber3');
            const res = form.values.checked ? await getApprovalSettingList1(employeeId, multiCompanyAccess, multiCompanyLoad) : await getApprovalSettingList1(employeeId)
            const data = res.data.data;
            setOptionsSeq4(data);
            if(data.length === 0){
                setOptionsSeq4([]);
                setOptionsSeq5([]);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
        }
    };
    const handleSelectChangeApproval4 =  async (value) => {
        setListApproval4(value === null ? null : value);
        setListApproval5(null)
        sessionStorage.setItem('selectedEmployeeNumber4',value) 
        form.setValues({
            ...form.values,
            approval_4: value,
            approval_5 : null,
        })
       
        if (value !== null) {
            try {
                const employeeId = sessionStorage.getItem('selectedEmployeeNumber4');
                const res = form.values.checked ? await getApprovalSettingList1(employeeId, multiCompanyAccess, multiCompanyLoad) : await getApprovalSettingList1(employeeId)
                const data = res.data.data;
                setOptionsSeq5(data);
                if(data.length === 0){
                    setOptionsSeq5([]);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setFetching(false);
            }
        }
    };

   
    const handleSelectChangeApproval5 = async (value) => {
        setListApproval5(value === null ? null : value);
        sessionStorage.setItem('selectedEmployeeNumber5', value);
        form.setValues({
            ...form.values,
            approval_5: value,
        });
    };


    const dataBreadcrumb = [
		{
			title: "Master Data - Approval Setting",
			url: "/approval-setting"
		},
		{
			title: `${result ?  'Edit Approval Setting' : 'Add Approval Setting'}` ,
			url: `${ result ?  `/approval-setting/edit-approval-setting/${result}` : '/approval-setting/add-approval-setting'}`
		},
	]


  
    return (
        <>
        <div className="title-section">
				{result ? "Edit Approval Setting" : "Add Approval Setting"}
		</div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                <Row justify={"space-between"}>
                    <Col span={24}>
                        <FormAntd.Item>
                            <SelectSearchWithLabel
                                name="employeeNumber"
                                labelname={<div>Employee<span style={{color:"#FF0000"}}> *</span></div>}
                                showSearch
                                allowClear
                                placeholder="Select Employee"
                                optionFilterProp="children"
                                onChange={handleSelectChange}
                                value={selectedOption ? selectedOption : detail?.employee_number}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={result} 
                            >
                                {options1.map(option => (
                                <Option key={option.employee_number} value={option.employee_number}>{option.employee_option}</Option>
                                ))}
                            </SelectSearchWithLabel>
                        </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    name="PositionName"
                                    label={"Position"}
                                    value={  form.values.PositionName ? form.values.PositionName : detail?.position_name   } 
                                    placeholder="Position"
                                    onChange={form.handleChange}
                                    disabled={result} 
                                />
                            </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    name="DivisionName"
                                    label={"Division"} 
                                    value={  form.values.DivisionName ? form.values.DivisionName : detail?.division_name   }
                                    placeholder="Division"
                                    onChange={form.handleChange}
                                    disabled={result} 
                                />
                            </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={''} align={'middle'}>
                    <h1 style={{fontSize:"20px"}} >Approval Sequence</h1>
                    {
                        multiCompanyAccess === 'all' || multiCompanyAccess === 'company' ?
                        <SwitchLabel 
                            checked={form?.values?.checked}
                            onChange={(c, e) => {
                                changeMode(c)
                            }}
                            loading={loadingSwitch}
                            tooltipMessage={'Toggle to display multi-company approver options'}
                            size={'small'}
                            className='mx-2 mt-2'
                        />
                        :
                        null
                    }
                </Row>
                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                            <SelectSearchWithLabel
                                name="approval_1"
                                labelname={'Approval 1'}
                                showSearch
                                placeholder="Select Approval 1"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                // value={listApproval1 ? listApproval1 : detail.approval_1_name}
                                style={{height: 'max-content'}}
                                labelRender={(e)=>{
                                    const {label, key, value} = e
                                    return (label && key) || value ?(
                                        <div 
                                            style={{
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'flex-start', 
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <span style={{fontSize: 16, height: '2rem'}}>{label ? label[0].props.children : form.values.approval_1_name}</span>
                                            <span style={{fontSize: 12}}>{label ? label[1].props.children : form.values.approval_1_company}</span>
                                        </div>
                                    ): <div>{value ? value : 'None'}</div>
                                }}
                                value={listApproval1 !== '' ? listApproval1 : detail.approval_1}
                                onChange={handleSelectChangeApproval1}
                            >
                                 <Option value={null}><div style={{fontSize: 14}}>None</div><div style={{fontSize: 10}}>{''}</div></Option>
                                {optionsSeq1.map(option => (
                                <Option key={option.approver_id} value={option.approver_id}><div style={{fontSize: 14}}>{option.approver_name}</div><div style={{fontSize: 10}}>{option.company_name}</div></Option>
                                ))}
                            </SelectSearchWithLabel>
                            </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                            <FormAntd.Item>
                            <SelectSearchWithLabel
                                name="approval_4"
                                labelname={'Approval 4'}
                                showSearch
                                placeholder="Select Approval 4"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{height: 'max-content'}}
                                labelRender={(e)=>{
                                    const {label, key, value} = e
                                    return (label && key) || value ?(
                                        <div 
                                            style={{
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'flex-start', 
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <span style={{fontSize: 16, height: '2rem'}}>{label ? label[0].props.children : form.values.approval_4_name}</span>
                                            <span style={{fontSize: 12}}>{label ? label[1].props.children : form.values.approval_4_company}</span>
                                        </div>
                                    ): <div>{value ? value : 'None'}</div>
                                }}
                                value={listApproval4 !== '' ? listApproval4 : detail.approval_4}
                                onChange={handleSelectChangeApproval4}
                            >
                                <Option value={null}><div style={{fontSize: 14}}>None</div><div style={{fontSize: 10}}>{''}</div></Option>
                                {optionsSeq4.map(option => (
                                <Option key={option.approver_id} value={option.approver_id}><div style={{fontSize: 14}}>{option.approver_name}</div><div style={{fontSize: 10}}>{option.company_name}</div></Option>
                                ))}
                            </SelectSearchWithLabel>
                            </FormAntd.Item>
                </Col>
                </Row>
                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                            <SelectSearchWithLabel
                                name="approval_2"
                                labelname={'Approval 2'}
                                showSearch
                                placeholder="Select Approval 2"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{height: 'max-content'}}
                                labelRender={(e)=>{
                                    const {label, key, value} = e
                                    return (label && key) || value ?(
                                        <div 
                                            style={{
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'flex-start', 
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <span style={{fontSize: 16, height: '2rem'}}>{label ? label[0].props.children : form.values.approval_2_name}</span>
                                            <span style={{fontSize: 12}}>{label ? label[1].props.children : form.values.approval_2_company}</span>
                                        </div>
                                    ): <div>{value ? value : 'None'}</div>
                                }}
                                value={listApproval2 !== '' ? listApproval2 : detail.approval_2}
                                onChange={handleSelectChangeApproval2}
                            >
                                 <Option value={null}><div style={{fontSize: 14}}>None</div><div style={{fontSize: 10}}>{''}</div></Option>
                                {optionsSeq2.map(option => (
                                <Option key={option.approver_id} value={option.approver_id}><div style={{fontSize: 14}}>{option.approver_name}</div><div style={{fontSize: 10}}>{option.company_name}</div></Option>
                                ))}
                            </SelectSearchWithLabel>
                            </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                            <FormAntd.Item>
                            <SelectSearchWithLabel
                                name="approval_5"
                                labelname={'Approval 5'}
                                showSearch
                                placeholder="Select Approval 5"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{height: 'max-content'}}
                                labelRender={(e)=>{
                                    const {label, key, value} = e
                                    return (label && key) || value ?(
                                        <div 
                                            style={{
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'flex-start', 
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <span style={{fontSize: 16, height: '2rem'}}>{label ? label[0].props.children : form.values.approval_5_name}</span>
                                            <span style={{fontSize: 12}}>{label ? label[1].props.children : form.values.approval_5_company}</span>
                                        </div>
                                    ): <div>{value ? value : 'None'}</div>
                                }}
                                value={listApproval5 !== '' ? listApproval5 : detail.approval_5}
                                onChange={handleSelectChangeApproval5}
                            >
                                <Option value={null}><div style={{fontSize: 14}}>None</div><div style={{fontSize: 10}}>{''}</div></Option>
                                {optionsSeq5.map(option => (
                                <Option key={option.approver_id} value={option.approver_id}><div style={{fontSize: 14}}>{option.approver_name}</div><div style={{fontSize: 10}}>{option.company_name}</div></Option>
                                ))}
                            </SelectSearchWithLabel>
                            </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                            <SelectSearchWithLabel
                                name="approval_3"
                                labelname={'Approval 3'}
                                showSearch
                                placeholder="Select Approval 3"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                option.children[0].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{height: 'max-content'}}
                                labelRender={(e)=>{
                                    const {label, key, value} = e
                                    return (label && key) || value ?(
                                        <div 
                                            style={{
                                                display: 'flex', 
                                                flexDirection: 'column', 
                                                alignItems: 'flex-start', 
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <span style={{fontSize: 16, height: '2rem'}}>{label ? label[0].props.children : form.values.approval_3_name}</span>
                                            <span style={{fontSize: 12}}>{label ? label[1].props.children : form.values.approval_3_company}</span>
                                        </div>
                                    ): <div>{value ? value : 'None'}</div>
                                }}
                                value={listApproval3 !== '' ? listApproval3 : detail.approval_3}
                                onChange={handleSelectChangeApproval3}
                            >
                                 <Option value={null}><div style={{fontSize: 14}}>None</div><div style={{fontSize: 10}}>{''}</div></Option>
                                {optionsSeq3.map(option => (
                                <Option key={option.approver_id} value={option.approver_id}><div style={{fontSize: 14}}>{option.approver_name}</div><div style={{fontSize: 10}}>{option.company_name}</div></Option>
                                ))}
                            </SelectSearchWithLabel>
                            </FormAntd.Item>
                    </Col>
                </Row>  
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/approval-setting')}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
        </>
    )
}

export default FormAddApprovalSetting;