import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getDivision = async (search, limit, offset, page, department,company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        departmentCode : department,
        filterColumn : "name",
        filter : search.length > 0 ? search : undefined,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/division", payload);
}

export const addDivision = async (data) => {
    const user = decryptData(localStorage.getItem("Username")) ;
    let payload = {
        name: data.name,
        departmentCode: data.departmentCode,
        is_active: "1",
        created_by: user,
        modified_by: user
    }
    return master.post("/division-create", payload);
}

export const getDivisionDetail = async (id) => {
    let payload = {
        divisionCode: id,
    }
    return master.post("/division-detail", payload);
}

export const deleteDivision = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        divisionCode: id,
        is_active: "0",
        modified_by: user
    }
    return master.post("/division-delete", payload);
}

export const editDivision = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        divisionCode: id,
        name: data.name,
        departmentCode: data.departmentCode,
        is_active: "1",
        modified_by: user
    }
    return master.post("/division-update", payload);
}