import React, { useEffect, useState } from "react"
import authBg from "../../assets/img/bg-login.png"; 
import Logo from "../../assets/img/logo.png"; 
import "./styles.scss"
import { Button, Col, message, Row } from "antd";
import { useFormik } from "formik";
import  LoginForm  from "./Login";
import ForgotPasswordForm from "./ForgotPassword";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { checkMaintance, login, sendEmail } from "../../api/login";
import { decryptData, encryptData } from "../../helper/cryptojs";


export function LoginPage() {
    const navigate = useNavigate();
    const [forgotPassword, setForgotPassword] = useState(false)
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const role = decryptData(localStorage.getItem("Role"));
    useEffect(() => {
        if(role){
            navigate('/dashboard')
        }
    },[])
    // const validationLogin = yup.object().shape({
    //     email: yup.string().email("must be a valid email").required("Field is required").matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "must be a valid email"),
    //     password: yup.string().matches(
    //         /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
    //         "Must Contain 8 Characters with Number"
    //       ).required("Field is required")
    // });
    const validationLogin = yup.object().shape({
        loginIdentifier: yup.string().required("Field is required"),
        password: yup.string().matches(
          /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
          "Must Contain 8 Characters with Number"
        ).required("Field is required")
      });
      
    const formLogin = useFormik({
        initialValues: {
            // email: "",
            // employee_number: "",
            loginIdentifier: "",
            password: "",
        },
        enableReinitialize: true,
        validationSchema: validationLogin,
        onSubmit: async(values) => {
            // setLoading(true)
            
            const isEmail = /\S+@\S+\.\S+/.test(values.loginIdentifier);
            const loginData = isEmail
            ? { email: values.loginIdentifier, password: values.password }
            : { employee_number: values.loginIdentifier, password: values.password };
            try {
                const checkSSO = await checkMaintance();
                if(checkSSO.data.data.maintenance !== false){
                    navigate("/maintenance");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                   
                }else{
                    const res = await login(loginData);
                    if(res?.data?.data?.role !== null && res?.data?.data?.menu !== undefined && res?.data?.data?.menu.length !== 0){
                    console.log("testing : ",res)
                    message.success({
                        content: "User login successfully",
                    });
                    // setLoading(false)
                    localStorage.setItem("Role", encryptData(res?.data?.data?.role));
                    localStorage.setItem("Username", encryptData(res?.data?.data?.username));
                    localStorage.setItem("userId", encryptData(res?.data?.data?.user_id));
                    localStorage.setItem("EmployeeNumber", encryptData(res?.data?.data?.employee_number));
                    localStorage.setItem("DefaultBranchCode", encryptData(res?.data?.data?.defaultBranch.code));
                    localStorage.setItem("DefaultCompanyCode", encryptData(res?.data?.data?.roleCompanyDefault.code));
                    localStorage.setItem("DefaultBranchName", encryptData(res?.data?.data?.roleBranchDefault.name));
                    localStorage.setItem("DefaultCompanyName", encryptData(res?.data?.data?.roleCompanyDefault.name));
                    localStorage.setItem("DefaultBranchId", encryptData(res?.data?.data?.defaultBranch.branch_id));
                    localStorage.setItem("DefaultCompanyId", encryptData(res?.data?.data?.roleCompanyDefault.company_id));
                    localStorage.setItem('Menusidbar',JSON.stringify(encryptData(res?.data?.data.menu) ? encryptData(res?.data?.data.menu) : {}));
                    localStorage.setItem('BranchArr',JSON.stringify(encryptData(res?.data?.data.branch )? encryptData(res?.data?.data.branch ): [])); 
                    localStorage.setItem('CompanyArr',JSON.stringify(encryptData(res?.data?.data.company )? encryptData(res?.data?.data.company ): [])); 
                    localStorage.setItem('AccessConsole',JSON.stringify(encryptData(res?.data?.data.console.access) ? encryptData(res?.data?.data.console.access):{}));
                    localStorage.setItem("AllowedPayroll", encryptData(res?.data?.data?.allowed_payroll));
                    localStorage.setItem("LevelSequence", encryptData(res?.data?.data?.level_sequence));
                    localStorage.setItem("MultiCompanyAccess", encryptData(res?.data?.data?.multi_company_access));
                    localStorage.setItem("MultiCompany", encryptData(res?.data?.data?.multi_company));
                    //previlage Dashboard
                    // localStorage.setItem('contoh encrypt',JSON.stringify(encryptData() ? encryptData():{}));
                    localStorage.setItem('RoleaccessPriviledgeDashbord',res?.data?.data.priviledge.dashboard.index);
                    //previlage Attandance
                    localStorage.setItem('RoleaccessPriviledgeAttandanceIndex',res?.data?.data.priviledge.attendance.index);
                    localStorage.setItem('RoleaccessPriviledgeAttandanceCreate',res?.data?.data.priviledge.attendance.create);
                    localStorage.setItem('RoleaccessPriviledgeAttandanceUpdate',res?.data?.data.priviledge.attendance.update);
                    localStorage.setItem('RoleaccessPriviledgeAttandanceDownload',res?.data?.data.priviledge.attendance.download);
                    //previlage Branch
                    localStorage.setItem('RoleaccessPriviledgeBranchIndex',res?.data?.data.priviledge.branch.index);
                    localStorage.setItem('RoleaccessPriviledgeBranchCreate',res?.data?.data.priviledge.branch.create);
                    localStorage.setItem('RoleaccessPriviledgeBranchUpdate',res?.data?.data.priviledge.branch.update);
                    localStorage.setItem('RoleaccessPriviledgeBranchDelete',res?.data?.data.priviledge.branch.delete);
                    //previlage Company
                    localStorage.setItem('RoleaccessPriviledgeCompanyIndex',res?.data?.data.priviledge.company.index);
                    localStorage.setItem('RoleaccessPriviledgeCompanyCreate',res?.data?.data.priviledge.company.create);
                    localStorage.setItem('RoleaccessPriviledgeCompanyUpdate',res?.data?.data.priviledge.company.update);
                    //previlage Departmen
                    localStorage.setItem('RoleaccessPriviledgeDepartmentIndex',res?.data?.data.priviledge.department.index);
                    localStorage.setItem('RoleaccessPriviledgeDepartmentCreate',res?.data?.data.priviledge.department.create);
                    localStorage.setItem('RoleaccessPriviledgeDepartmentUpdate',res?.data?.data.priviledge.department.update);
                    localStorage.setItem('RoleaccessPriviledgeDepartmentDelete',res?.data?.data.priviledge.department.delete);
                    //previlage Division
                    localStorage.setItem('RoleaccessPriviledgeDivisionIndex',res?.data?.data.priviledge.division.index);
                    localStorage.setItem('RoleaccessPriviledgeDivisionCreate',res?.data?.data.priviledge.division.create);
                    localStorage.setItem('RoleaccessPriviledgeDivisionUpdate',res?.data?.data.priviledge.division.update);
                    localStorage.setItem('RoleaccessPriviledgeDivisionDelete',res?.data?.data.priviledge.division.delete);
                    //previlage Employee
                    localStorage.setItem('RoleaccessPriviledgeEmployeeIndex',res?.data?.data.priviledge.employee.index);
                    localStorage.setItem('RoleaccessPriviledgeEmployeeCreate',res?.data?.data.priviledge.employee.create);
                    localStorage.setItem('RoleaccessPriviledgeEmployeeUpdate',res?.data?.data.priviledge.employee.update);
                    localStorage.setItem('RoleaccessPriviledgeEmployeeDownload',res?.data?.data.priviledge.employee.download);
                    //previlage Employee Management
                    localStorage.setItem('RoleaccessPriviledgeEmployeeManagementIndex',res?.data?.data.priviledge.employee_management.index);
                    //previlage Level
                    localStorage.setItem('RoleaccessPriviledgeLevelIndex',res?.data?.data.priviledge.level.index);
                    localStorage.setItem('RoleaccessPriviledgeLevelCreate',res?.data?.data.priviledge.level.create);
                    localStorage.setItem('RoleaccessPriviledgeLevelUpdate',res?.data?.data.priviledge.level.update);
                    localStorage.setItem('RoleaccessPriviledgeLevelDelete',res?.data?.data.priviledge.level.delete);
                    //previlage Position
                    localStorage.setItem('RoleaccessPriviledgePositionIndex',res?.data?.data.priviledge.position.index);
                    localStorage.setItem('RoleaccessPriviledgePositionCreate',res?.data?.data.priviledge.position.create);
                    localStorage.setItem('RoleaccessPriviledgePositionUpdate',res?.data?.data.priviledge.position.update);
                    localStorage.setItem('RoleaccessPriviledgePositionDelete',res?.data?.data.priviledge.position.delete);
                    localStorage.setItem('RoleaccessPriviledgePositionDownload',res?.data?.data.priviledge.position.download);
                    localStorage.setItem('RoleaccessPriviledgePositionViewChart',res?.data?.data.priviledge.position.view);
                    //previlage Role
                    localStorage.setItem('RoleaccessPriviledgeRoleIndex',res?.data?.data.priviledge.role.index);
                    localStorage.setItem('RoleaccessPriviledgeRoleCreate',res?.data?.data.priviledge.role.create);
                    localStorage.setItem('RoleaccessPriviledgeRoleUpdate',res?.data?.data.priviledge.role.update);
                    localStorage.setItem('RoleaccessPriviledgeRoleDelete',res?.data?.data.priviledge.role.update);
                    //previlage User
                    localStorage.setItem('RoleaccessPriviledgeUserIndex',res?.data?.data.priviledge.user.index);
                    localStorage.setItem('RoleaccessPriviledgeUserCreate',res?.data?.data.priviledge.user.create);
                    localStorage.setItem('RoleaccessPriviledgeUserUpdate',res?.data?.data.priviledge.user.update);
                    //previlage Announcement
                    localStorage.setItem('RoleaccessPriviledgeAnnouncementIndex',res?.data?.data.priviledge.announcement.index);
                    localStorage.setItem('RoleaccessPriviledgeAnnouncementCreate',res?.data?.data.priviledge.announcement.create);
                    localStorage.setItem('RoleaccessPriviledgeAnnouncementUpdate',res?.data?.data.priviledge.announcement.update);
                    localStorage.setItem('RoleaccessPriviledgeAnnouncementDelete',res?.data?.data.priviledge.announcement.delete);
                    //previlage Shift
                    localStorage.setItem('RoleaccessPriviledgeShiftIndex',res?.data?.data.priviledge.shift.index);
                    localStorage.setItem('RoleaccessPriviledgeShiftCreate',res?.data?.data.priviledge.shift.create);
                    localStorage.setItem('RoleaccessPriviledgeShiftUpdate',res?.data?.data.priviledge.shift.update);
                    localStorage.setItem('RoleaccessPriviledgeShiftDelete',res?.data?.data.priviledge.shift.delete);
                    //previlage Set Schedule
                    localStorage.setItem('RoleaccessPriviledgeSetScheduleIndex',res?.data?.data.priviledge.set_schedule.index);
                    localStorage.setItem('RoleaccessPriviledgeSetScheduleCreate',res?.data?.data.priviledge.set_schedule.create);
                    localStorage.setItem('RoleaccessPriviledgeSetScheduleUpdate',res?.data?.data.priviledge.set_schedule.update);
                    localStorage.setItem('RoleaccessPriviledgeSetScheduleDelete',res?.data?.data.priviledge.set_schedule.delete);
                    localStorage.setItem('RoleaccessPriviledgeSetScheduleDownload',res?.data?.data.priviledge.set_schedule.download);
                    //previlage Overtime
                    localStorage.setItem('RoleaccessPriviledgeOvertimeIndex',res?.data?.data.priviledge.overtime.index);
                    localStorage.setItem('RoleaccessPriviledgeOvertimeCreate',res?.data?.data.priviledge.overtime.create);
                    localStorage.setItem('RoleaccessPriviledgeOvertimeUpdate',res?.data?.data.priviledge.overtime.update);
                    localStorage.setItem('RoleaccessPriviledgeOvertimeDelete',res?.data?.data.priviledge.overtime.delete);
                    //previlage Overtime Transaction
                    localStorage.setItem('RoleaccessPriviledgeOvertimeTransactionIndex',res?.data?.data.priviledge.overtime_transaction.index);
                    localStorage.setItem('RoleaccessPriviledgeOvertimeTransactionCreate',res?.data?.data.priviledge.overtime_transaction.create);
                    localStorage.setItem('RoleaccessPriviledgeOvertimeTransactionDownload',res?.data?.data.priviledge.overtime_transaction.download);
                    //previlage Leave
                    localStorage.setItem('RoleaccessPriviledgeLeaveIndex',res?.data?.data.priviledge.leave.index);
                    localStorage.setItem('RoleaccessPriviledgeLeaveCreate',res?.data?.data.priviledge.leave.create);
                    localStorage.setItem('RoleaccessPriviledgeLeaveUpdate',res?.data?.data.priviledge.leave.update);
                    localStorage.setItem('RoleaccessPriviledgeLeaveDelete',res?.data?.data.priviledge.leave.delete);
                    //previlage Leave Transaction
                    localStorage.setItem('RoleaccessPriviledgeLeaveTransactionIndex',res?.data?.data.priviledge.leave_transaction.index);
                    localStorage.setItem('RoleaccessPriviledgeLeaveTransactionCreate',res?.data?.data.priviledge.leave_transaction.create);
                    localStorage.setItem('RoleaccessPriviledgeLeaveTransactionUpdate',res?.data?.data.priviledge.leave_transaction.update);
                    localStorage.setItem('RoleaccessPriviledgeLeaveTransactionDownload',res?.data?.data.priviledge.leave_transaction.download);
                    //previlage Holiday
                    localStorage.setItem('RoleaccessPriviledgeHolidayIndex',res?.data?.data.priviledge.holiday.index);
                    localStorage.setItem('RoleaccessPriviledgeHolidayCreate',res?.data?.data.priviledge.holiday.create);
                    localStorage.setItem('RoleaccessPriviledgeHolidayUpdate',res?.data?.data.priviledge.holiday.update);
                    localStorage.setItem('RoleaccessPriviledgeHolidayDelete',res?.data?.data.priviledge.holiday.delete);
                    //previlage Approval Setting
                    localStorage.setItem('RoleaccessPriviledgeApprovalSettingIndex',res?.data?.data.priviledge.approval_setting.index);
                    localStorage.setItem('RoleaccessPriviledgeApprovalSettingCreate',res?.data?.data.priviledge.approval_setting.create);
                    localStorage.setItem('RoleaccessPriviledgeApprovalSettingUpdate',res?.data?.data.priviledge.approval_setting.update);
                    localStorage.setItem('RoleaccessPriviledgeApprovalSettingDelete',res?.data?.data.priviledge.approval_setting.delete);
                    //previlage Approval List
                    localStorage.setItem('RoleaccessPriviledgeApprovalListIndex',res?.data?.data.priviledge.approval_list.index);
                    localStorage.setItem('RoleaccessPriviledgeApprovalListApprove',res?.data?.data.priviledge.approval_list.approve);
                    localStorage.setItem('RoleaccessPriviledgeApprovalListReject',res?.data?.data.priviledge.approval_list.reject);
                    //previlage Time Request
                    localStorage.setItem('RoleaccessPriviledgeTimeRequestIndex',res?.data?.data.priviledge.time_request.index);
                    localStorage.setItem('RoleaccessPriviledgeTimeRequestCreate',res?.data?.data.priviledge.time_request.create);
                    //previlage Loan Setting
                    localStorage.setItem('RoleaccessPriviledgeLoanSettingIndex',res?.data?.data.priviledge.loan_setting.index);
                    localStorage.setItem('RoleaccessPriviledgeLoanSettingCreate',res?.data?.data.priviledge.loan_setting.create);
                    localStorage.setItem('RoleaccessPriviledgeLoanSettingUpdate',res?.data?.data.priviledge.loan_setting.update);
                    //previlage Loan Management
                    localStorage.setItem('RoleaccessPriviledgeLoanIndex',res?.data?.data.priviledge.loan.index);
                    localStorage.setItem('RoleaccessPriviledgeLoanCreate',res?.data?.data.priviledge.loan.create);
                    localStorage.setItem('RoleaccessPriviledgeLoanDownload',res?.data?.data.priviledge.loan.download);
                      //previlage Bpjs Kesehatan
                      localStorage.setItem('RoleaccessPriviledgeBpjsKesehatanIndex',res?.data?.data.priviledge.bpjs_kesehatan.index);
                      localStorage.setItem('RoleaccessPriviledgeBpjsKesehatanCreate',res?.data?.data.priviledge.bpjs_kesehatan.create);
                      localStorage.setItem('RoleaccessPriviledgeBpjsKesehatanUpdate',res?.data?.data.priviledge.bpjs_kesehatan.update);
                      localStorage.setItem('RoleaccessPriviledgeBpjsKesehatanDelete',res?.data?.data.priviledge.bpjs_kesehatan.delete);
                      //previlage Bpjs Ketenagakerjaan
                      localStorage.setItem('RoleaccessPriviledgeBpjsKetenagakerjaanIndex',res?.data?.data.priviledge.bpjs_ketenagakerjaan.index);
                      localStorage.setItem('RoleaccessPriviledgeBpjsKetenagakerjaanCreate',res?.data?.data.priviledge.bpjs_ketenagakerjaan.create);
                      localStorage.setItem('RoleaccessPriviledgeBpjsKetenagakerjaanUpdate',res?.data?.data.priviledge.bpjs_ketenagakerjaan.update);
                      localStorage.setItem('RoleaccessPriviledgeBpjsKetenagakerjaanDelete',res?.data?.data.priviledge.bpjs_ketenagakerjaan.delete);
                    //previlage Insurance
                    localStorage.setItem('RoleaccessPriviledgeInsuranceIndex',res?.data?.data.priviledge.insurance.index);
                    localStorage.setItem('RoleaccessPriviledgeInsuranceCreate',res?.data?.data.priviledge.insurance.create);
                    localStorage.setItem('RoleaccessPriviledgeInsuranceUpdate',res?.data?.data.priviledge.insurance.update);
                    localStorage.setItem('RoleaccessPriviledgeInsuranceDelete',res?.data?.data.priviledge.insurance.delete);
                    //previlage Bpjs Report
                    localStorage.setItem('RoleaccessPriviledgeBpjsReportIndex',res?.data?.data.priviledge.bpjs_report.index);
                    localStorage.setItem('RoleaccessPriviledgeBpjsReportDownload',res?.data?.data.priviledge.bpjs_report.download);
                    //previlage Insurance Report
                    localStorage.setItem('RoleaccessPriviledgeInsuranceReportIndex',res?.data?.data.priviledge.insurance_report.index);
                    localStorage.setItem('RoleaccessPriviledgeInsuranceReportDownload',res?.data?.data.priviledge.insurance_report.download);
                    //previlage Insurance PTKP Status
                    localStorage.setItem('RoleaccessPriviledgePtkpStatusIndex',false);
                    localStorage.setItem('RoleaccessPriviledgePtkpStatusCreate',false);
                    localStorage.setItem('RoleaccessPriviledgePtkpStatusUpdate',false);
                    localStorage.setItem('RoleaccessPriviledgePtkpStatusDelete',false);
                    //previlage Insurance PPH21 RATE
                    localStorage.setItem('RoleaccessPriviledgePph21RateIndex',false);
                    localStorage.setItem('RoleaccessPriviledgePph21RateCreate',false);
                    localStorage.setItem('RoleaccessPriviledgePph21RateUpdate',false);
                    localStorage.setItem('RoleaccessPriviledgePph21RateDelete',false);
                    //previlage Insurance Tax Method
                    localStorage.setItem('RoleaccessPriviledgeTaxMethodIndex',false);
                    localStorage.setItem('RoleaccessPriviledgeTaxMethodCreate',false);
                    localStorage.setItem('RoleaccessPriviledgeTaxMethodUpdate',false);
                    localStorage.setItem('RoleaccessPriviledgeTaxMethodDelete',false);
                     //previlage ReportHistoryFakeGps
                    localStorage.setItem('RoleaccessPriviledgeReportHistoryFakeGpsIndex',res?.data?.data.priviledge.report_history_fake_gps.index);
                    localStorage.setItem('RoleaccessPriviledgeReportHistoryFakeGpsDownload',res?.data?.data.priviledge.report_history_fake_gps.download);
                    navigate("/welcome");
                }else{  
                    message.error({
                        content: "User doesn't have an access",
                    });
                    navigate("/");
                    setTimeout(() => {
                        localStorage.clear();
                    }, 1000);    
                }
                }
           
            } catch (error) {
                console.log(error.response);
                message.error({
                    content: error.response.data.error,
                });
                //setLoading(false)
            }
        },
    });

    const formForgotPassword = useFormik({
        initialValues: {
            email: "",
        },
        enableReinitialize: true,
        validationSchema: "",
        onSubmit: async (values) => {
            setFetching(true)
            try {
                const res = await sendEmail(values);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error || "User Not Found",
                    });
                } else {
                    message.success({
                        content: "Email send successfully"
                    });
                }
                setFetching(false)
            } catch (error) {
                console.log("error >>>", error);
                message.error({
                    content: error?.response?.data?.error,
                });
                setFetching(false)
            }
        },
    });

    return (
        <div id="login-page">
            <div className="bg-gradient">
                <div className="bg-left">
                    <div className="shape"> </div>
                </div>
                <div className="bg-right">
                    <div className="shape"> </div>
                </div>
            </div>
            {forgotPassword ? (
                <ForgotPasswordForm fetching={fetching} form={formForgotPassword} backToLogin={() => setForgotPassword(false)}/>
            ): <div className="login-wrapper">
                <div className="section-image">
                    {/* <img src={logo} className="logo" /> */}
                    <img src={authBg} className="background" />
                </div>
                <div className="section-form">
                    {/* <div className="title-section-login">
                        <div className="title">
                                Welcome to <span>hris.ethos</span>
                            </div>
                            <div className="subtitle ">
                                Welcome back, please login <br /> to your account.
                            </div>
                        </div> */}
                        <div className="text-center">
                            <img src={Logo} className="w-10 h-10 rounded-lg"/>
                            <div>
                                <p className="mb- text-lg font-bold">HRIS.ETHOS</p>
                                <p className="mt-1 text-sm text-gray-400">Welcome back, please login to your account.</p>
                            </div>
                        </div>
                    <div>
                        <LoginForm 
                            form={formLogin}
                            onClick={() => setForgotPassword(true)}
                        />
                         {/* <div className="flex justify-end text-sky-400">
                                <Button type="link" className="title-forgot" onClick={() => setForgotPassword(true)}>
                                    Forgot Password ?
                                 </Button>
                         </div> */}
                    </div>
                    <div>
                        <Row>
                            <Col span={24} >
                                <div className="bg-blue-500 rounded-lg mb-96 md:mb-12">
                                    <Button
                                        type="submit"
                                        className="btn btn-submit w-full"
                                        loading={loading}
                                        disabled={formLogin.isSubmitting}
                                        onClick={formLogin.handleSubmit}
                                    >
                                       <div className="text-white">Login</div> 
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>}
        </div>
    )
}
