import { Button, Col, message, Modal, Pagination, Row, Select, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import "./styles.scss"
import { PlusOutlined, DeleteFilled, EditFilled,StopOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getDivision } from "../../api/division";
import { deletePosition, getPosition } from "../../api/position";
import {decode} from 'html-entities';
import { decryptData } from "../../helper/cryptojs";
export default function Postion() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPagePosition");
	const sessionOffset = sessionStorage.getItem("sessionOffsetPosition");
	const sessionSearch = sessionStorage.getItem("sessionSearchPosition");
	const sessionDivision = sessionStorage.getItem("sessionDivisionPosition");
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const [flag, setFlag] = useState(0)
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
	const [division, setDivision] = useState(sessionDivision ? sessionDivision : "");
	const [listDivision, setListDivision] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const BestpraticeUseEffect = useRef(false)
	const BestpraticeUseEffect1 = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	let defaultlistDivision = [
		{
			value : "",
			title : "All Division"
		},
	]
	const newListDivision = [...defaultlistDivision, ...listDivision]
	const positionList = async () => {
		setLoading(true);
		try {
		  const res = await getPosition(search, limit, offset, page, division, company)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	};
	
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			positionList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPagePosition");
				sessionStorage.removeItem("sessionSearchPosition");
				sessionStorage.removeItem("sessionOffsetPosition");
				sessionStorage.removeItem("sessionDivisionPosition");
				setPage(1)
				setOffset(0)
				setSearch("")
				setDivision("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		  }
	}, [limit, offset, search, division, flag, page, company]);

	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPagePosition", page);
		sessionStorage.setItem("sessionOffsetPosition", offsetSession);
	},[page])

	const divisionList = async () => {
		setLoading(true);
		try {
		  const res = await getDivision("", 100, 0, 1, "", company)
		  const data = res?.data?.data
		  setListDivision(data.map((item) => {
			return {
				value : item.code,
				title : decode(item.division_name)
			}
		}))
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	};
	
	useEffect(() => {
		if (!BestpraticeUseEffect1.current) {
			BestpraticeUseEffect1.current = true;
		  } else {
			divisionList();
		  }
		
		  if(localStorage.getItem("positionSearch") === null) {
			setSearch('');
		  }else{
			setSearch(localStorage.getItem("positionSearch"));
		  }
		  
	}, []);

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            const res = await deletePosition(id);
			if(res.data.success){
				message.success({
					content: "Data successfully deleted",
				});
			}else{
				message.error({
					content: res.data.error,
				});
			}
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
                content: "Request Not Found",
            });
            setLoading(false)
        }
    }

	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			width: "15px",
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/position/detail/${encodeURIComponent("id?" + record.code)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Division',
			dataIndex: 'division_name',
			key: 'division_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/position/detail/${encodeURIComponent("id?" + record.code)}`)}>
						{decode(text)}
					</div>
				)
			}
		},
		{
			title: 'Job Position',
			dataIndex: 'position_name',
			key: 'position_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/position/detail/${encodeURIComponent("id?" + record.code)}`)}>
						{decode(text)}
					</div>
				)
			}
		},
		{
			title: 'Action',
			key: 'action',
			width: "25px",
			render: (_,record,index) => {
				return RoleAccessPositionUpdate && RoleAccessPositionDelete ? (
					<Row justify={"space-between"}>
							<div className="icon-action" onClick={() => showModal(record.code)}>
								<DeleteFilled />
							</div>
						
							<div className="icon-action" onClick={() => navigate(`/position/edit-position/${encodeURIComponent("id?" + record.code)}`)}>
								<EditFilled />
							</div>
					</Row>
				) : (
					<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
					</Row>
				)
			}
		},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/position"
		},
		{
			title: "Position",
			url: "/position"
		},
	];

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		localStorage.setItem("positionSearch", e.target.value);
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchPosition", e.target.value);
	}

	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const RoleAccessPositionCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionCreate'));
	const RoleAccessPositionUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionUpdate'));
	const RoleAccessPositionDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionDelete'));
	const RoleAccessPositionViewChart = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionViewChart'));
    return (
        <>
		    <>
			 <div className="title-section">
				Position
			</div>
			<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<p>Are you sure to delete this data?</p>
			</Modal>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}>
					<Col md={{top:20}} className="mt-5 md:mt-0">
					<Row justify={"end"} style={{gap:15}} md={14}>
							<Select
								placeholder="Select Division"
								value={division}
								onChange={(val) => {
									setDivision(val)
									sessionStorage.setItem("sessionDivisionPosition", val);
									setPage(1)
								}}
								className="select-company w-auto"
								allowClear
							>
								{newListDivision.map((data, index) => {
									return (
										<Select.Option style={{height: "42px"}} key={index} value={data.value}>{data.title}</Select.Option>
									)
								})}
							</Select>
						
							<InputWithIcon
								type="text"
								placeholder="Search Position"
								value={search}
								img={iconSearch}
								className="search-input btn-sh-sec"
								classImg="op-icon"
								onChange={onChangeSearch}
							/>
						{/* <Col span={2}>
						<Button className="btn-sh-p" size="large" loading={loading} type="primary" shape="circle" icon={<FileSearchOutlined />} onClick={handleApiButtonClick} />
						</Col> */}
						{RoleAccessPositionViewChart && 
							<Col>
								<Button style={{maxWidth: "100%", padding: '0 16px'}} onClick={() => navigate('/position/organization/1')} key="submit" type="primary" className="btn btn-primary btn-sh-p">
									View Chart
								</Button>
							</Col>
						}
						{RoleAccessPositionCreate && 
							<Col style={{display: "flex", justifyContent: "flex-end"}}>
								<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/position/add-position')} icon={<PlusOutlined />} size={"large"} />
							</Col>
						}
					</Row>
				</Col>
				</Row>
				<div className="table-wrapper">
				{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	
				</div>
			</div>
			</>
		   
        </>
    )
}
