import axios from "axios";

const token = localStorage.getItem("Token");

 const sso = axios.create({
 baseURL: process.env.REACT_APP_API_URL,
 headers: { Authorization: "Bearer " + token },
});

 const master = axios.create({
  baseURL: process.env.REACT_APP_HRIS_URL,
  headers: { Authorization: "Bearer " + token, "Content-Type": "multipart/form-data"},
});


export { sso ,master };

