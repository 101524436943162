import { Button, Col, Pagination, Row, Select, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, EditFilled, StopOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getUser } from "../../api/login";
import { decryptData } from "../../helper/cryptojs";
import { decode } from "html-entities";

export default function User() {
  const navigate = useNavigate();
  const sessionPage = sessionStorage.getItem("sessionPageUser");
	const sessionOffset = sessionStorage.getItem("sessionOffsetUser");
	const sessionSearch = sessionStorage.getItem("sessionSearchUser");
  const sessionActive = sessionStorage.getItem("sessionActiveUser");
  const [listTable, setListTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0);
  const previousPage = useRef(1);
  const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
  const [active, setActive] = useState(sessionActive ? parseInt(sessionActive) : 1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")): decryptData(localStorage.getItem("DefaultCompanyCode"));

  const userList = async () => {
    setLoading(true);
    try {
      const res = await getUser(search, limit, offset, "", company, active);
      setListTable(res?.data?.data);
      setTotalData(res?.data?.totalRecords);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      userList();
      const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageUser");
				sessionStorage.removeItem("sessionSearchUser");
				sessionStorage.removeItem("sessionOffsetUser");
        sessionStorage.removeItem("sessionActiveUser");
				setPage(1)
				setOffset(0)
				setSearch("")
        setActive(1)
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
    }
  }, [limit, offset, search, page, active]);

  useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageUser", page);
		sessionStorage.setItem("sessionOffsetUser", offsetSession);
	},[page])

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      width: "15px",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/user/detail/${encodeURIComponent("id?" + record.user_id)}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/user/detail/${encodeURIComponent("id?" + record.user_id)}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/user/detail/${encodeURIComponent("id?" + record.user_id)}`
              )
            }>
            {decode(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/user/detail/${encodeURIComponent("id?" + record.user_id)}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/user/detail/${encodeURIComponent("id?" + record.user_id)}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/user/detail/${encodeURIComponent("id?" + record.user_id)}`
              )
            }>
            {text == 1 ? "Active" : "Inactive"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record, index) => {
        return RoleAccessUserUpdate ? (
          <Row justify={"center"}>
            <div
              className="icon-action"
              onClick={() =>
                navigate(
                  `/user/edit-user/${encodeURIComponent(
                    "id?" + record.user_id
                  )}`
                )
              }>
              <EditFilled />
            </div>
          </Row>
        ) : (
          <Row justify={"space-between"}>
            <Tooltip title="No Access">
              <StopOutlined style={{ fontSize: "24px", color: "#ff4d4f" }} />
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const dataFilter = [
    {
      value: 1,
      title: "Active",
    },
    {
      value: 0,
      title: "Inactive",
    },
  ];

  const dataBreadcrumb = [
    {
      title: "Master Data",
      url: "/User",
    },
    {
      title: "User",
      url: "/User",
    },
  ];

  const onChangeTable = (pageNumber, type) => {
    setPage(pageNumber);
    setOffset(pageNumber * limit - 10);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };

  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchUser", e.target.value);
  };

  const RoleAccessUserCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeUserCreate")
  );
  const RoleAccessUserUpdate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeUserUpdate")
  );

  return (
    <>
      <>
        <div className="title-section">User</div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
          <Col span={24}>
            <Breadcrumb items={dataBreadcrumb} />
          </Col>
        </div>
        <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
          <Row
            style={{ marginBottom: "24px", justifyContent: "end" }}
            align={"middle"}>
            <Col md={{ top: 20 }} className="mt-5 md:mt-0">
              <Row justify={"end"} style={{ gap: 15 }} md={14}>
                <Select
                  placeholder="Select"
                  value={active}
                  onChange={(val) => {
                    setActive(val)
                    sessionStorage.setItem("sessionActiveUser", val);
                    setPage(1)
                  }}
                  className="select-company w-24 md:w-36 text-xs ">
                  {dataFilter.map((data, index) => {
                    return (
                      <Select.Option
                        style={{ height: "42px" }}
                        key={index}
                        value={data.value}>
                        {data.title}
                      </Select.Option>
                    );
                  })}
                </Select>
                <InputWithIcon
                  type="text"
                  placeholder="Search User"
                  img={iconSearch}
                  className="search-input btn-sh-sec"
                  classImg="op-icon"
                  value={search}
                  onChange={onChangeSearch}
                />
                {RoleAccessUserCreate && (
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      className="btn-sh-p"
                      type="primary"
                      shape="circle"
                      onClick={() => navigate("/user/add-user")}
                      icon={<PlusOutlined />}
                      size={"large"}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
          <div className="table-wrapper">
          {loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	
          </div>
        </div>
      </>
    </>
  );
}
