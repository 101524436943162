import { Button, Col, Form as FormAntd, Input, Radio, Space, message,Collapse ,TimePicker, Row} from "antd";
import { useEffect, useRef, useState } from "react";
import { InputGroupWithLabel, InputWithLabel, NumberFormat, RadioGroupWithLabel, SelectOptionWithLabel, SelectOptionWithLabelApplies, SelectOptionWithLabelAppliesAllvalue, TextAreaWithLabel } from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { DeleteOutlined, PlusOutlined,SettingOutlined } from "@ant-design/icons";
import { addMOvertime, editMOvertime, getListLevel, getListPosition, getMOvertimeDetail } from "../../api/movertime";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import dayjs from "dayjs";

const FormAddMOvertime = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    let n = location.split("/");
    let result = n[5];
    const decode = decodeURIComponent(result)
    let dec = decode.lastIndexOf('?');
    let id = decode.substring(dec + 1);
    const BestpraticeUseEffect = useRef(false)
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [fetching, setFetching] = useState(false);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const [isYesSelected, setIsYesSelected] = useState(false);
    const [rateType, SetRateType] = useState(false);
    const [option,setOption] = useState([]);
    const [option1,setOption1] = useState([]);
    const [inputRateTypeCustom, setInputsRateTypeCustom] = useState([
        { type: '', hour: '', minute: '', allowance: '', disabled: false }
    ]);
    const [inputRateType, setInputsRateType] = useState([{ hour: '', ratio: '', val_gp: '' }]);
    const [inputs, setInputs] = useState([{ start_minute: '', end_minute: '', val_minute: '' }]);

    const overtimeList = async () => {
        setFetching(true);
        try {
        const res = await getMOvertimeDetail(id);
        const resData = res?.data?.data;
        setDetail(resData);
        if (resData.rounding === 1 && resData.rounding_arr?.length > 0) {
            handleRadioChange(resData.rounding);
            const roundingArr = resData.rounding_arr.map(r => ({
            start_minute: r.start_minute,
            end_minute: r.end_minute,
            val_minute: r.val_minute
            }));
            setInputs(roundingArr);
        }
            const formulaarr = resData.formula_arr.map((data) => ({
            hour: data.hour,
            ratio: data.ratio
            }));
            setInputsRateType(formulaarr);
            const customarr = resData.custom_arr.map((data) => ({
            type: data.type,
            time: data.time,
            allowance: data.allowance
            }));
            setInputsRateTypeCustom(customarr);
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    const positionList= async () => {
        setFetching(true);
        try {
        const res = await getListPosition(company)
        setOption(res.data.data)
        } catch (err) {
            console.log(err);
        }finally {
            setFetching(false);
        }
    }

    const levelList= async () => {
        setFetching(true);
        try {
        const res = await getListLevel(company)
        setOption1(res.data.data)
        } catch (err) {
            console.log(err);
        }finally {
            setFetching(false);
        }
    }
    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else {
            if(result){
                overtimeList();
            }
            positionList();
            levelList();
            }
    }, [flag]);

    const form = useFormik({
        initialValues: {
            name: result ? detail?.name : "",
            companyCode: company,
            description: result ? detail?.description : "",
            compensation_type: result ? detail?.compensation_type : "",
            min_minute: result ? detail?.min_minute : "",
            max_hour: result ? detail?.max_hour : "",
            rounding: result ? detail?.rounding : 0,
            day_type: result ? detail?.day_type : "",
            applies_to: result ? detail?.applies_to : "",
            rate_type: result ? detail?.rate_type : "",
            flat_allowance: result ? detail?.flat_allowance : "",
            level_id: result && detail?.custom_apply_level ? JSON.parse(detail.custom_apply_level) : [],
            position_id: result && detail?.custom_apply_position ? JSON.parse(detail.custom_apply_position) : [],
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            const selectedLevels = form.values.level_id || [];
            const selectedPositions = form.values.position_id || [];
            values['rounding_arr'] = inputs;
            values['formula_arr'] = inputRateType;
            values['custom_arr'] = inputRateTypeCustom;
            values['custom_apply_level'] = selectedLevels;
            values['custom_apply_position'] = selectedPositions;
            setFetching(true)
            try {
                const res = result ? await editMOvertime(values, id) : await addMOvertime(values);
                if (!res?.data?.success) {
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Edit Data Success" : "Add Data Success",
                    });
                    navigate("/overtime")
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });
    const handleInputChange = (index, key, value) => {
        const newInputs = [...inputs];
        newInputs[index][key] = value;
        setInputs(newInputs);
    };

    const handleAddMore = () => {
        if (inputs.length < 5) {
            setInputs([...inputs, { start_minute: '', end_minute: '', val_minute: '' }]);
        }
    };

    const handleRemove = (index) => {
        const newInputs = [...inputs];
        newInputs.splice(index, 1);
        setInputs(newInputs);

    };
    const handleInputChangeTypeRate = (index, key, value) => {
        const newInputs = [...inputRateType];
        newInputs[index][key] = value;
        setInputsRateType(newInputs);
    };

    const handleAddMoreTypeRate = () => {
        if (inputRateType.length < 5) {
            setInputsRateType([...inputRateType, { hour: '', ratio: '', val_gp: '' }]);
        }
    };
    const handleRemoveTypeRate = (index) => {
        const newInputs = [...inputRateType];
        newInputs.splice(index, 1);
        setInputsRateType(newInputs);
    };
    const handleAddMoreTypeRateCustom = () => {
        if (inputRateTypeCustom && inputRateTypeCustom.length < 2) {
            const updatedInputs = [...inputRateTypeCustom];
            const defaultValue = inputRateTypeCustom[0]?.type === 'Before' ? 'After' : 'Before';
            updatedInputs.push({ type: defaultValue, hour: '', minute: '', allowance: '', disabled: true });
            setInputsRateTypeCustom(updatedInputs);
        }
    };
    const handleInputChangeTypeRateCustom = (index, field, value) => {
      if (index < 0 || index >= inputRateTypeCustom.length) {
        return;
      }
      const updatedInputs = [...inputRateTypeCustom];
      updatedInputs[index][field] = value;
      if (index === 0 && value === 'Before') {
        if (updatedInputs[1]) {
          updatedInputs[1].type = 'After';
        }
      } else if (index === 0 && value === 'After') {
        if (updatedInputs[1]) {
          updatedInputs[1].type = 'Before';
        }
      }
      
      setInputsRateTypeCustom(updatedInputs);
    };
    const handleRemoveTypeRateCustom = (index) => {
        const updatedInputs = inputRateTypeCustom.filter((_, i) => i !== index);
        if (updatedInputs.length === 1) {
            updatedInputs[0].disabled = false;
        }
        setInputsRateTypeCustom(updatedInputs);
    };
    
    const handleRadioChange = (e) => {
        setIsYesSelected(e === 1);
    };
    const handleRadioChangeOvertimeRateType = (e) => {
        SetRateType(e === 1);
    };

    const dataBreadcrumb = [
		{
			title: "Master Data - Overtime",
			url: "/overtime"
		},
		{
			title: `${result ?  'Edit Overtime' : 'Add Overtime'}` ,
			url: `${ result ?  `/overtime/edit-overtime/${result}` : '/overtime/add-overtime'}`
		},
	]

    return (
        <>
         <div className="title-section">
                {result ? "Edit Overtime" : "Add Overtime"}
            </div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Overtime Name<span style={{ color: "#FF0000" }}> *</span></div>}
                        placeholder="Overtime Name"
                        value={form?.values?.name}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                </FormAntd.Item>
                <FormAntd.Item>
                    <TextAreaWithLabel
                        name="description"
                        label={"Description"}
                        placeholder="Description"
                        rows={4}
                        onBlur={form.handleBlur}
                        maxLength={200}
                        showCount
                        onChange={form.handleChange}
                        value={form?.values?.description}
                    />

                </FormAntd.Item>
                <FormAntd.Item>
                    <RadioGroupWithLabel
                        name="compensation_type"
                        label={<div>Compensation Type<span style={{ color: "#FF0000" }}> *</span></div>}
                        placeholder="Compensation Type"
                        rows={4}
                        onBlur={form.handleBlur}
                        value={form?.values?.compensation_type}
                        maxLength={500}
                        onChange={form.handleChange}
                    >
                        <Space direction="vertical">
                            <Radio value={'paid'}>Paid Overtime</Radio>
                            <Radio value={'leave'}>Leave Overtime</Radio>
                        </Space>
                    </RadioGroupWithLabel>

                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="min_minute"
                        label={<div>Minimum Overtime (Minutes)<span style={{ color: "#FF0000" }}> *</span></div>}
                        placeholder="Minimum Overtime (Minutes)"
                        value={form?.values?.min_minute}
                        onBlur={form.handleBlur}
                        onChange={ (e) => {
                            const regex = e.target.value;
                            const reg = /^[0-9\b]+$/;
                            if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                form.handleChange(e)
                            }
                        }}
                        maxLength={2}
                        minLength={0}
                    />
                    {form.touched.min_minute && form.errors.min_minute ? (
                        <span className="text-error">{form.errors.min_minute}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="max_hour"
                        label={<div>Maximum Overtime (Hours)<span style={{ color: "#FF0000" }}> *</span></div>}
                        placeholder="Maximum Overtime (Hours)"
                        value={form?.values?.max_hour}
                        onChange={ (e) => {
                            const regex = e.target.value;
                            const reg = /^[0-9\b]+$/;
                            if (regex === '' || reg.test(regex) && parseInt(regex) <= 24) {
                                form.handleChange(e)
                            }
                        }}
                        onBlur={form.handleBlur}
                        maxLength={2}
                        minLength={0}
                    />
                </FormAntd.Item>
                <FormAntd.Item>
                    <RadioGroupWithLabel
                        name="rounding"
                        label={<div>Rounding<span style={{ color: "#FF0000" }}> *</span></div>}
                        placeholder="Rounding"
                        rows={4}
                        onBlur={form.handleBlur}
                        maxLength={500}
                        onChange={(val) => {
                                form.setValues({
                                    ...form.values,
                                    rounding: val.target.value,
                                })
                                handleRadioChange(val.target.value)
                            }
                        }
                        value={form?.values?.rounding}
                    >
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                    </RadioGroupWithLabel>
                </FormAntd.Item>
                {isYesSelected &&
                    <div>
                        <InputGroupWithLabel label={<div>Rounding Setting<span style={{ color: "#FF0000" }}> *</span></div>}>
                            {inputs.map((input, index) => {
                                return (

                                    <div key={index} style={{ flex: 1 }}>
                                        <Input
                                            name={`min_minute${index}`}
                                            style={{ width: 100, marginRight: 10 }}
                                            value={input.start_minute}
                                            onChange={ (e) => {
                                                const regex = e.target.value;
                                                const reg = /^[0-9\b]+$/;
                                                if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                                    handleInputChange(index, 'start_minute', e.target.value)
                                                }
                                            }}
                                            placeholder="0"
                                            maxLength={2}
                                            minLength={0}
                                        />
                                        <span>Minutes</span>
                                        <span style={{ marginLeft: 10 }}>To</span>
                                        <Input
                                            name={`max_minute${index}`}
                                            style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                                            value={input.end_minute}
                                            onChange={ (e) => {
                                                const regex = e.target.value;
                                                const reg = /^[0-9\b]+$/;
                                                if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                                    handleInputChange(index, 'end_minute', e.target.value)
                                                }
                                            }}
                                            placeholder="0"
                                            maxLength={2}
                                            minLength={0}
                                        />
                                        <span>Minutes</span>
                                        <span style={{ marginLeft: 10 }}>=</span>
                                        <Input
                                            name={`val_minute${index}`}
                                            placeholder="0"
                                            style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                                            value={input.val_minute}
                                            onChange={ (e) => {
                                                const regex = e.target.value;
                                                const reg = /^[0-9\b]+$/;
                                                if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                                    handleInputChange(index, 'val_minute', e.target.value)
                                                }
                                            }}
                                            maxLength={2}
                                            minLength={0}
                                        />
                                        <span>Minutes</span>
                                        {index > 0 && (
                                            <DeleteOutlined
                                                size={'large'}
                                                style={{ marginLeft: 10, marginTop: 20, fontSize: 20, color: 'red' }}
                                                onClick={() => handleRemove(index)}
                                            />
                                        )}
                                    </div>
                                )
                            }
                            )}
                            <Button
                                type="dashed"
                                icon={<PlusOutlined />}
                                onClick={handleAddMore}
                                style={{ marginTop: '10px' }}
                            >
                                Add More
                            </Button>
                        </InputGroupWithLabel>
                    </div>
                }
                <FormAntd.Item>
                        <Col span={24}>
                            <SelectOptionWithLabelApplies
                                name="applies_to"
                                label={<div>Applies to<span style={{ color:"#FF0000" }}> *</span></div>}
                                items={[
                                    {
                                    value: "Default",
                                    name: "Default",
                                    },
                                    {
                                    value: "Custom",
                                    name: "Custom",
                                    },
                                ]}
                                value={form?.values?.applies_to ? form?.values?.applies_to : null}
                                onChange={(val) => {
                                    form.setValues({
                                        ...form.values,
                                        applies_to: val,
                                    });
                                }}
                                placeholder="Default by employee setting"
                                onBlur={form.handleBlur}
                            />
                            {form.touched.defaultsetting && form.errors.defaultsetting ? (
                                <span className="text-error">{form.errors.defaultsetting}</span>
                            ) : null}
                            
                        </Col>        
                    </FormAntd.Item>
                {/* Teks yang ditampilkan jika opsi "Custom" dipilih */}
                    {form.values.applies_to === 'Custom' && (
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormAntd.Item>
                            <SelectOptionWithLabelAppliesAllvalue
                            className='w-auto h-auto' 
                            name="level_id"
                            mode="multiple"
                            options={option1.map(item => ({ label: item.name, value: item.level_id }))}
                            value={form?.values?.level_id} 
                            onChange={(val) => {
                                form.setValues({
                                ...form.values,
                                level_id: val,
                                });
                            }}
                            placeholder="Level"
                            onBlur={form.handleBlur}
                            filterOption={(input, option) =>
                                option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            />
                        </FormAntd.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <FormAntd.Item>
                            <SelectOptionWithLabelAppliesAllvalue
                            className='w-auto h-auto' 
                            name="position_id"
                            mode="multiple"
                            options={option.map(item => ({ label: item.name, value: item.position_id }))}
                            value={form?.values?.position_id}
                            onChange={(val) => {
                                form.setValues({
                                ...form.values,
                                position_id: val,
                                });
                            }}
                            placeholder="Position"
                            onBlur={form.handleBlur}
                            filterOption={(input, option) =>
                                option.label
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            />
                        </FormAntd.Item>
                        </Col>
                    </Row>
                    )}

                <div className="mt-10">
                    {form?.values.compensation_type === 'paid' ? (
                <Collapse  expandIcon={() => null}>
                <Collapse.Panel header={
                        <div>
                            <SettingOutlined style={{ marginRight: '8px' }} />
                            Overtime Settings
                        </div>
                    }
                    key="1"
                >
                <>
                    <FormAntd.Item>
                        <Col span={12}>
                            <SelectOptionWithLabel
                                name="day_type"
                                label={<div>Day Type<span style={{color:"#FF0000"}}> *</span></div>}
                                items={[
                                    {
                                        value : "Workday",
                                        name : "Workday",
                                    },
                                    {
                                        value : "Holiday",
                                        name : "Holiday",
                                    }
                                ]}
                                value={form?.values?.day_type ?form?.values?.day_type : null}
                                onChange={(val) =>
                                    form.setValues({
                                    ...form.values,
                                    day_type: val,
                                    })
                                }
                                onBlur={form.handleBlur}
                            />
                            {form.touched.day_type && form.errors.day_type ? (
                                <span className="text-error">{form.errors.day_type}</span>
                            ) : null}
                        </Col>
                    </FormAntd.Item>
                    <FormAntd.Item>
                            <RadioGroupWithLabel
                                name="rate_type"
                                label={<div>Rate Type <span style={{color:"#FF0000"}}> *</span></div>}
                                placeholder="Rate Type"
                                rows={4}
                                onBlur={form.handleBlur}
                                maxLength={500}
                                onChange={(val) => {
                                        form.setValues({
                                            ...form.values,
                                            rate_type: val.target.value,
                                        })
                                        handleRadioChangeOvertimeRateType(val.target.value)
                                    }
                                }
                                value={form?.values?.rate_type}
                                defaultValue={ rateType ? 'flat' : ''} 
                            >
                                <Radio value={'flat'}>Flat Per Day</Radio>
                                <Radio value={'formula'}>Formula</Radio>
                                <Radio value={'custom'}>Custom</Radio>
                            </RadioGroupWithLabel>
                    </FormAntd.Item>
                    {form.values.rate_type === 'flat' && (
                        <Col span={12}>
                            <FormAntd.Item>
                            <NumberFormat
                                name="flat_allowance"
                                type="text"
                                placeholder="Rp"
                                label={<div className="text-bold">Workdays Allowance</div>} 
                                thousandSeparator={true} 
                                allowNegative={false}
                                value={form?.values?.flat_allowance ? form?.values?.flat_allowance  :  null}
                                onChange={form.handleChange}
                                onBlur={form.handleBlur}
                            />
                            {form.touched.flat_allowance && form.errors.flat_allowance ? (
                                <span className="text-error">{form.errors.flat_allowance}</span>
                            ) : null}
                        </FormAntd.Item>
                        </Col>
                    )}
                    {form.values.rate_type === 'formula' && (
                        <FormAntd.Item>
                            <div>
                            <InputGroupWithLabel >
                                {inputRateType.map((input, index) => {
                                    return (
                                        <div key={index} style={{ flex: 1 }}>
                                            <Input
                                                name={`hour${index}`}
                                                style={{ width: 100, marginRight: 10 }}
                                                value={`Hour ${index+1}`}
                                                disabled
                                                onChange={ (e) => {
                                                    const regex = e.target.value;
                                                    const reg = /^[0-9\b]+$/;
                                                    if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                                        handleInputChangeTypeRate(index, 'hour', e.target.value)
                                                    }
                                                }}
                                                allowNegative={false}
                                                placeholder="Hour"
                                                maxLength={2}
                                                minLength={0}
                                                
                                            />
                                        <span>{" = "}</span>
                                            <Input
                                                name={`ratio${index}`}
                                                style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                                                value={input.ratio}
                                                onChange={ (e) => {
                                                    handleInputChangeTypeRate(index, 'ratio', e.target.value);
                                                }}
                                                allowNegative={false}
                                                placeholder="Ratio"
                                                maxLength={4}
                                                minLength={0}
                                                
                                            />
                                            <span>X</span>
                                            
                                            <Input
                                                name={`val_gp${index}`}
                                                placeholder="1/173 X GP"
                                                style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                                                value={input.val_gp}
                                                disabled
                                                onChange={ (e) => {
                                                    const regex = e.target.value;
                                                    const reg = /^[0-9\b]+$/;
                                                    if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                                        handleInputChangeTypeRate(index, 'val_gp', e.target.value)
                                                    }
                                                }}
                                                defaultValue={"1/173 X GP"}
                                            />
                                            {index > 0 && (
                                                <DeleteOutlined
                                                    size={'large'}
                                                    style={{ marginLeft: 10, marginTop: 20, fontSize: 20, color: 'red' }}
                                                    onClick={() => handleRemoveTypeRate(index)}
                                                />
                                            )}
                                        </div>
                                    )
                                }
                                )}
                                <Button
                                    type="dashed"
                                    icon={<PlusOutlined />}
                                    onClick={handleAddMoreTypeRate}
                                    style={{ marginTop: '10px' }}
                                >
                                    Add More
                                </Button>
                            </InputGroupWithLabel>
                        </div>
                        </FormAntd.Item>
                    )}
                    {form.values.rate_type === 'custom' && (
                        <FormAntd.Item>
                            <div>
                                <div>
                                    {inputRateTypeCustom.map((input, index) => {
                                        return (
                                            <div key={index} style={{ flex: 1 }} className="flex flex-row">
                                                <SelectOptionWithLabel
                                                    style={{ width: 200, marginRight: 20 }}
                                                    name={`type${index}`}
                                                    items={[
                                                        { value: "Before", name: "Before" },
                                                        { value: "After", name: "After" }
                                                    ]}
                                                    value={input?.type ? input?.type : null} 
                                                    onChange={(val) => handleInputChangeTypeRateCustom(index, 'type', val)}
                                                    placeholder="Before/After"
                                                    onBlur={form.handleBlur}
                                                    disabled={input.disabled}
                                                />
                                                <TimePicker
                                                        className="w-32 h-10  bg-[#F8FAFB] border-none mt-2"
                                                        value={input.time ? dayjs(input.time, 'HH:mm') : null}
                                                        format="HH:mm"
                                                        onChange={(time, timeString) =>
                                                        handleInputChangeTypeRateCustom(index, 'time', timeString)
                                                        }
                                                        placeholder="HH:mm"
                                                    />

                                                <NumberFormat
                                                    name={`allowance${index}`}
                                                    placeholder="Rp"
                                                    style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                                                    value={input.allowance}
                                                    allowNegative={false}
                                                    thousandSeparator={true}
                                                    onChange={(e) => handleInputChangeTypeRateCustom(index, 'allowance', e.target.value)}
                                                    minLength={0}
                                                />
                                                {index > 0 && (
                                                <DeleteOutlined
                                                    size={'large'}
                                                    style={{ marginLeft: 10, marginTop: 20, fontSize: 20, color: 'red' }}
                                                    onClick={() => handleRemoveTypeRateCustom(index)}
                                                />
                                            )}
                                            </div>
                                        )
                                    }
                                    )}
                                    <Button
                                        type="dashed"
                                        icon={<PlusOutlined />}
                                        onClick={handleAddMoreTypeRateCustom}
                                        style={{ marginTop: '10px' }}
                                    >
                                        Add More
                                    </Button>
                                </div>
                            </div>
                        </FormAntd.Item>
                    )}
                    <div className="mt-5">
                    </div>
                </>
            </Collapse.Panel>
            </Collapse>
                        
                    ):null}
                </div>
            </FormAntd>
         
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{ marginRight: "14px" }} onClick={() => navigate('/overtime')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
        </>
    )
}

export default FormAddMOvertime;