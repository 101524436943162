/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, message, Pagination, Row, Tabs, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
// import "./styles.scss"
import {  useNavigate } from "react-router-dom";
import { getAttendance, getNoAttendance } from "../../api/attendance";
import moment from "moment";
import { PlusOutlined , EditFilled,StopOutlined, SyncOutlined} from "@ant-design/icons";
import { decryptData, encryptData } from "../../helper/cryptojs";
import {decode} from 'html-entities';
import dayjs from "dayjs";
const { TabPane } = Tabs;

const { RangePicker } = DatePicker;
export default function Attendance() {
	const navigate = useNavigate();
	const limitSession = sessionStorage.getItem("sessionPageAttendnace");
	const startDateSession = decryptData(sessionStorage.getItem("sessionStartDateAttendance"));
	const endDateSession = decryptData(sessionStorage.getItem("sessionEndDateAttendance"));
	const offsetSession = sessionStorage.getItem("sessionOffsetAttendance");
	const sessionSearch = sessionStorage.getItem("sessionSearchAttendance");
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(offsetSession ? offsetSession : 0)
	const previousPage = useRef(1);
	const [search, setSearch] = useState(sessionSearch ?? "")
	const [debounceSearch, setDebounceSearch] = useState(sessionSearch ? sessionSearch : "")
	const [startDate, setStartDate] = useState(startDateSession ? startDateSession : null)
	const [endDate, setEndDate] = useState(endDateSession ? endDateSession : null)
	const [startDateno, setStartDateno] = useState(null)
	const [endDateno, setEndDateno] = useState(null)
	const [datenoAttendance, setStartdateNoAttendnace] = useState(null)
	const [listTableNoAttendance, setListTableNoAttendance] = useState([]);
	const [totalDataNoAttendance, setTotalDataNoAttendance] = useState(0)
	const [pageNoAttendance, setPageNoAttendance] = useState(1)
	const [limitNoAttendance, setLimitNoAttendance] = useState(10)
	const [offsetNoAttendance, setOffsetNoAttendance] = useState(0)
	const [searchNoAttendance, setSearchNoAttendance] = useState("")
	const [debounceSearchNoAtt, setDebounceSearchNoAtt] = useState("")
	const [selectedDateRange, setSelectedDateRange] = useState([]);
	const [selectedDateRangeNo, setSelectedDateRangeNo] = useState([]);
	const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const branch = decryptData(sessionStorage.getItem("selectBranch"));
	const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  	const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
	const branchCode = branchSession || branch !== null ? branch  : branchDefault 
	const attendanceList = async () => {
		setLoading(true);
		try {
		  const res = await getAttendance(debounceSearch,startDate,endDate, limit, offset,page,company,branch)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };

	  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
		  BestpraticeUseEffect.current = true;
		} else {
		  attendanceList();
		  const beforeUnloadHandler = () => {
			sessionStorage.removeItem("sessionPageAttendnace");
			sessionStorage.removeItem("sessionOffsetAttendance");
			sessionStorage.removeItem("sessionStartDateAttendance");
			sessionStorage.removeItem("sessionEndDateAttendance");
			sessionStorage.removeItem("sessionSearchAttendance")
			sessionStorage.removeItem('picker_selected');
			setStartDate(null);
			setEndDate(null);
			setPage(1)
			setOffset(0)
			setSearch("")
		  };
		  window.addEventListener("beforeunload", beforeUnloadHandler);
		  return () => {
			window.removeEventListener("beforeunload", beforeUnloadHandler);
		  };
		}
	  }, [startDate, endDate, debounceSearch, limit, offset, page, company]);



	const noAttendanceList = async () => {
		setLoading(true);
		try {
		  const res = await getNoAttendance( debounceSearchNoAtt,startDateno,endDateno, limitNoAttendance,offsetNoAttendance,pageNoAttendance,company,branch)
		  setListTableNoAttendance(res?.data?.data);
		  setTotalDataNoAttendance(res?.data?.recordsTotal)
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
		  BestpraticeUseEffect.current = true;
		} else {
		 noAttendanceList();
		}
	  }, [startDateno,endDateno,debounceSearchNoAtt, limitNoAttendance, offsetNoAttendance, pageNoAttendance, company]);
	  
		useEffect(() => {
			if(search.length > 2 || search.length === 0) {
				const timeoutId = setTimeout(() => {
					setDebounceSearch(search)
				}, 500)
				return () => clearTimeout(timeoutId);
			}
		}, [search])

		useEffect(() => {
			if(searchNoAttendance.length > 2 || searchNoAttendance.length === 0) {
				const timeoutId = setTimeout(() => {
					setDebounceSearchNoAtt(searchNoAttendance)
				}, 500)
				return () => clearTimeout(timeoutId);
			}
		}, [searchNoAttendance])

	  useEffect(() => {
		let offset = 0
		if(page > 1){
			offset = page*limit - 10
		}
		
		setOffset(offset)
		sessionStorage.setItem("sessionPageAttendnace", page);
		sessionStorage.setItem("sessionOffsetAttendance", offset);
	  }, [page]);
		
	useEffect(() => {
		if(pageNoAttendance ===1){
			setOffsetNoAttendance(0)
		}else {
			setOffsetNoAttendance(pageNoAttendance*limit - 10)
		}
	},[pageNoAttendance])

		const calculateDistance = (lat1, lon1, lat2, lon2) => {
			const R = 6371000; 
			const dLat = (Math.PI / 180) * (lat2 - lat1);
			const dLon = (Math.PI / 180) * (lon2 - lon1);
		
			const a =
				Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.cos((Math.PI / 180) * lat1) * Math.cos((Math.PI / 180) * lat2) *
				Math.sin(dLon / 2) * Math.sin(dLon / 2);
		
			const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			const distance = R * c;
		
			return distance;
		}
		
	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text, record,index) => {
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/time-request/detail/${encodeURIComponent("id?" + record.attendance_request_id)}`)}>
					{text}
					</div>
				)
			}
		},
		{
			title: 'Branch',
			dataIndex: 'branch_name',
			key: 'branch_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
						{decode(text)}
					</div>
				)
			}
		},
		{
			title: 'Employee Name',
			dataIndex: 'full_name',
			key: 'full_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
						{decode(text)}
					</div>
				)
			}
		},
		{
			title: 'NIK',
			dataIndex: 'employee_number',
			key: 'employee_number',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Date',
			dataIndex: 'attendance_date',
			key: 'attendance_date',
			sorter: (a, b) => new Date(a.attendance_date) - new Date(b.attendance_date),
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Shift',
			dataIndex: 'name',
			key: 'name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
						{decode(text) || '-'}
					</div>
				)
			}
		},
		{
			title: 'Schedule In',
			dataIndex: 'schedule_in',
			key: 'schedule_in',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
						{text || '--:--'}
					</div>
				)
			}
		},
		{
			title: 'Schedule Out',
			dataIndex: 'schedule_out',
			key: 'schedule_out',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
						{text || '--:--'}
					</div>
				)
			}
		},
		{
			title: 'Check In',
			dataIndex: 'attendance_time_in',
			key: 'attendance_time_in',
			render: (text, record) => {
			// const chekedRadiusIn = record.checked_radius_in
			const checkInLatitude = parseFloat(record.checkin_latitude);
            const checkInLongitude = parseFloat(record.checkin_longitude);
			if (checkInLatitude && checkInLongitude) {
                const defaultLatitude = parseFloat(record.latitude_radius_in);
                const defaultLongitude = parseFloat(record.longitude_radius_in);
                const defaultRadius = parseFloat(record.branch_radius_in);
      
                const distance = calculateDistance(defaultLatitude, defaultLongitude, checkInLatitude, checkInLongitude);
      
                if (distance > defaultRadius) {
                  return (
                    <div className="detail-btn-wp" onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
                      {text ? moment(text, "HH:mm").format("HH:mm")  : '--:--'} <span style={{ color: 'red' }}>i</span>
                    </div>
                  );
                }
              }
			  return (
				<div className="detail-btn-wp" onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
				  {text ? moment(text, "HH:mm").format("HH:mm") : '--:--'} 
				</div>
			  );
			}
		  },
		  
		  {
			title: 'Check Out',
			dataIndex: 'attendance_time_out',
			key: 'attendance_time_out',
			render: (text, record) => {
			//   const checkedRadiusOut = record.checked_radius_out
			  const checkOutLatitude = parseFloat(record.checkout_latitude);
              const checkOutLongitude = parseFloat(record.checkout_longitude);
      
              if (checkOutLatitude && checkOutLongitude) {
                const defaultLatitude = parseFloat(record.latitude_radius_out);
                const defaultLongitude = parseFloat(record.longitude_radius_out);
                const defaultRadius = parseFloat(record.branch_radius_out);
      
                const distance = calculateDistance(defaultLatitude, defaultLongitude, checkOutLatitude, checkOutLongitude);
      
                if (distance > defaultRadius) {
                  return (
                    <div  className="detail-btn-wp" onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
                      {text ? moment(text, "HH:mm").format("HH:mm") : '--:--'} <span style={{ color: 'red' }}>i</span>
                    </div>
                  );
                }
              }
			  return (
				<div className="detail-btn-wp" onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}>
				  {text ? moment(text, "HH:mm").format("HH:mm") : '--:--'} 
				</div>
			  );
			}
		  },
		  
		{
			title: 'Minutes Of Late',
			dataIndex: 'minutes_of_late',
			key: 'minutes_of_late',
			render:(text, record)=>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}> 
						{text && text !== '00:00:00' ? moment(text, "HH:mm:ss").format("HH:mm:ss") : '--:--:--'}
					</div>
				)
			}
		},
		{
			title: 'Work Hour',
			dataIndex: 'work_hour',
			key: 'work_hour',
			render:(text, record)=>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}> 
						{text ?  moment(text, "HH:mm:ss").format("HH:mm:ss") : '--:--:--'}
					</div>
				)
			}
		},
		{
			title: 'Time OFF',
			dataIndex: 'leave_type',
			key: 'leave_type',
			render:(text, record)=>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/attendance/detail/${encodeURIComponent("id?" + record.attendance_id)}`)}> 
						{text ? text : '-'}
					</div>
				)
			}
		},
		{
			title: 'Action',
			key: 'action',
			width: "25px",
			render: (_,record,index) => {
				return RoleAccessAttandanceUpdate ? (
					<Row justify={"space-between"}>
						<div className="icon-action" onClick={() => navigate(`/attendance/edit-attendance/${encodeURIComponent("id?" + record.attendance_id)}`)}>
							<EditFilled />
						</div>
					</Row>
				) : (
					<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
					</Row>
				)
			}
		},
	];

	const columnsNoAttendance = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text}
					</div>
				)
			}
		},
		{
			title: 'Branch',
			dataIndex: 'branch_name',
			key: 'branch_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{decode(text) || '-'}
					</div>
				)
			}
		},
		{
			title: 'Employee Name',
			dataIndex: 'full_name',
			key: 'full_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{decode(text)}
					</div>
				)
			}
		},
		{
			title: 'NIK',
			dataIndex: 'employee_number',
			key: 'employee_number',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text}
					</div>
				)
			}
		},
		{
			title: 'Date',
			dataIndex: 'attendance_date',
			key: 'attendance_date',
			sorter: (a, b) => moment(a.attendance_date).unix() - moment(b.attendance_date).unix(),
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text}
					</div>
				)
			}
		},
		{
			title: 'Shift',
			dataIndex: 'name',
			key: 'name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{decode(text) || '-'}
					</div>
				)
			}
		},
		{
			title: 'Schedule In',
			dataIndex: 'schedule_in',
			key: 'schedule_in',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '--:--'}
					</div>
				)
			}
		},
		{
			title: 'Schedule Out',
			dataIndex: 'schedule_out',
			key: 'schedule_out',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '--:--'}
					</div>
				)
			}
		},
	];
	const dataBreadcrumb = [
		{
			title: "Attendance Management",
			url: "/attendance"
		},
		{
			title: "Attendance",
			url: "/attendance"
		},
	];


	useEffect(() => {
		previousPage.current = page;
	}, [page]);

	useEffect(() => {
		previousPage.current = pageNoAttendance;
	}, [pageNoAttendance]);

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		sessionStorage.setItem("sessionOffsetAttendance", pageNumber*limit- 10);
	};

	const onChangeTableNoAttendance = (pageNumber, type) => {
		setPageNoAttendance(pageNumber)
		setOffsetNoAttendance(pageNumber*limit- 10)
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	const onShowSizeChangeNoAttendance = (_, pageSize) => {
		setLimitNoAttendance(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	function dataTableWithNumberNoAttendance() {
		const showNumber = limitNoAttendance * (pageNoAttendance - 1);
		return listTableNoAttendance?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		setOffset(0)
		sessionStorage.setItem("sessionSearchAttendance", e.target.value);
	}

	const onChangeSearchNoAttendance = (e) => {
		setSearchNoAttendance(e.target.value)
		setLimitNoAttendance(10)
		setPageNoAttendance(1)
	}

		useEffect(() => {
			const startDate = decryptData(sessionStorage.getItem("sessionStartDateAttendance"));
			const endDate = decryptData(sessionStorage.getItem("sessionEndDateAttendance"));
			if (startDate && endDate) {
			setStartDate(startDate);
			setEndDate(endDate);
			setSelectedDateRange([dayjs(startDate),dayjs(endDate)])
			}
		}, []);

	
	
		const handleDateChange = (dates) => {
			if (dates && dates.length === 2) {
			  const start = dates[0].startOf('day').format('YYYY-MM-DD');
			  const end = dates[1].endOf('day').format('YYYY-MM-DD');
			  sessionStorage.setItem("sessionStartDateAttendance", encryptData(start));
			  sessionStorage.setItem("sessionEndDateAttendance", encryptData(end));
			  sessionStorage.setItem('picker_selected', 'true');  
			  setStartDate(start);
			  setEndDate(end);
			  setPage(1)
			 
			} else {
			  sessionStorage.removeItem("sessionStartDateAttendance");
			  sessionStorage.removeItem("sessionEndDateAttendance");
			  sessionStorage.removeItem('picker_selected');
			  setStartDate(null);
			  setEndDate(null);
			  setPage(1)
			
			}
			setSelectedDateRange(dates);
		  };
		const handleDateChangeno = (dates) => {
			if (dates && dates.length === 2) {
			  const start = dates[0].startOf('day').format('YYYY-MM-DD');
			  const end = dates[1].endOf('day').format('YYYY-MM-DD');
			  sessionStorage.setItem('start_dateno', encryptData(start));
			  sessionStorage.setItem('end_dateno', encryptData(end));
			  sessionStorage.setItem('picker_selectedno', 'true');  
			  setStartDateno(start);
			  setEndDateno(end);
			 
			} else {
			  sessionStorage.removeItem('start_dateno');
			  sessionStorage.removeItem('end_dateno');
			  sessionStorage.removeItem('picker_selectedno');
			  setStartDateno(null);
			  setEndDateno(null);
			
			}
			setSelectedDateRangeNo(dates);
		  };
		
		  useEffect(() => {
			const startDateno = decryptData(sessionStorage.getItem("start_dateno"));
			const endDateno = decryptData(sessionStorage.getItem("end_dateno"));
			if (startDateno && endDateno) {
			setStartDateno(startDateno);
			setEndDateno(endDateno);
			setSelectedDateRangeNo([dayjs(startDateno),dayjs(endDateno)])
			}
		}, []);

	const handleDownload = () => {
		let startdate = moment(startDate).format("YYYY-MM-DD");
		let enddate = moment(endDate).format("YYYY-MM-DD");
	  
		if (startdate === "Invalid date" || enddate === "Invalid date") {
		  startdate = ""; // Nilai default
		  enddate = ""; // Nilai default
		}
		const today = moment();
		const selectedStartDate = moment(startdate);
		const diffInDays = today.diff(selectedStartDate, 'days');
	  
		if (diffInDays > 60) {
		  message.error("The date range must be less than 60 days ago");
		  return;
		}
		window.open(`${process.env.REACT_APP_HRIS_URL}attendance-report?startDate=${startdate}&endDate=${enddate}&companyCode=${company}&branchCode=${branchCode}&search=${search}`, '_blank');
	  };
	
	const handleDownloadNoattendance = () => {
		let startdate = dayjs(startDateno);
		let enddate = dayjs(endDateno);
		
		if (!startdate.isValid() || !enddate.isValid()) {
			startdate = null; 
			enddate = null; 
		}
	
		let startDateString = startdate ? startdate.format('YYYY-MM-DD') : "";
		let endDateString = enddate ? enddate.format('YYYY-MM-DD') : "";
	
		window.open(`${process.env.REACT_APP_HRIS_URL}no-attendance-report?startDate=${startDateString}&endDate=${endDateString}&companyCode=${company}&branchCode=${branchCode}`, '_blank');
	};
	
	  
	  const RoleAccessAttandanceDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAttandanceDownload'));
	  const RoleAccessAttandanceCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAttandanceCreate'));
	  const RoleAccessAttandanceUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAttandanceUpdate'));
	  
	  const onChange = (selectedDate) => {
		setStartdateNoAttendnace((selectedDate));
	  };

	const disabledDate = (current) => {
		//disable tanggal 3 bulan ke belakang
		return current && current < dayjs().subtract(3, 'month').startOf('month');
	};
    return (
        <>
            <div className="title-section">
				Attendance
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
			<Tabs defaultActiveKey="1"  className="tab-wrapper" type="card">
				<TabPane tab="Present" key="1">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}  className="mt-4">
					<Col md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
								<RangePicker
									size={"large"}
									onChange={handleDateChange}
									value={selectedDateRange}
									allowClear={true}
									
									format="YYYY-MM-DD"
									disabledDate={disabledDate}
									/>
								<InputWithIcon
									type="text"
									placeholder="Search Attendance"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>
								<Button
									className="btn-sh-p"
									type="primary"
									onClick={() =>
									navigate("/attendance/summary")
									}
									icon={<SyncOutlined />}
									size={"large"}
								>
									Generate Summary Attendance
								</Button>
							{RoleAccessAttandanceDownload && (
								<Button size={"large"}  type="primary"  onClick={handleDownload}>Download</Button>
							)}
							{RoleAccessAttandanceCreate && (
							<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/attendance/add-attendance')} icon={<PlusOutlined />} size={"large"} />
							)}
						</Row>
					</Col>
				
				</Row>
				<div className="table-wrapper">
				{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	
				</div>
				</TabPane>

				<TabPane tab="Absent" key="2">
					<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}  className="mt-4">
						<Col md={{top:20}} className="mt-5 md:mt-0">
							<Row justify={"end"} style={{gap:15}} md={14}>
									{/* <DatePicker size={"large"} allowClear={true} format="YYYY-MM-DD" onChange={onChange} /> */}
									<RangePicker
									size={"large"}
									onChange={handleDateChangeno}
									value={selectedDateRangeNo}
									allowClear={true}
									format="YYYY-MM-DD"
									disabledDate={disabledDate}
									/>
									<InputWithIcon
										type="text"
										placeholder="Search Attendance"
										img={iconSearch}
										className="search-input btn-sh-sec"
										classImg="op-icon"
										onChange={onChangeSearchNoAttendance}
									/>
								{RoleAccessAttandanceDownload && (
									<Button size={"large"}  type="primary" onClick={handleDownloadNoattendance} >Download</Button>
								)}
							</Row>
						</Col>
					
					</Row>
					<div className="table-wrapper">
						{loading ? (
							<>
							<Table id={'my-table'}  columns={columnsNoAttendance} loading={loading}/>
							<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={pageNoAttendance} current={pageNoAttendance} total={totalDataNoAttendance} onChange={onChangeTableNoAttendance} showSizeChanger={false} onShowSizeChange={onShowSizeChangeNoAttendance}/>
							</div>
							</>
						) : (
							<>
							<Table id={'my-table'} dataSource={dataTableWithNumberNoAttendance()}  showSorterTooltip={false}  columns={columnsNoAttendance} loading={loading}/>
							<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={pageNoAttendance} current={pageNoAttendance} total={totalDataNoAttendance} onChange={onChangeTableNoAttendance} showSizeChanger={false} onShowSizeChange={onShowSizeChangeNoAttendance}/>
							</div>
							</>
						)}	
					</div>
				</TabPane>
			</Tabs>
				
			</div>
        </>
    )
}