import { Button, Col, message, Modal, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import "./styles.scss"
import { PlusOutlined, DeleteFilled, EditFilled, StopOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deleteInsurance, getInsurance } from "../../api/insurance";
import { decryptData } from "../../helper/cryptojs";
import { formatCurrency } from "../../helper/formatCurrency"

export default function Insurance() {
    const navigate = useNavigate();
    const sessionPage = sessionStorage.getItem("sessionPageInsurance");
    const sessionOffset = sessionStorage.getItem("sessionOffsetInsurance");
    const sessionSearch = sessionStorage.getItem("sessionSearchInsurance");
    const [loading, setLoading] = useState(false);
    const [listTable, setListTable] = useState([]);
    const [totalData, setTotalData] = useState(0)
    const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
    const [flag, setFlag] = useState(0)
    const previousPage = useRef(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState()
    const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const BestpraticeUseEffect = useRef(false)

    const insuranceList = async () => {
        setLoading(true);
        try {
            const res = await getInsurance(search, limit, offset, page, company)
            setListTable(res?.data?.data);
            setTotalData(res?.data?.recordsTotal)
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else {
            insuranceList();
            const beforeUnloadHandler = () => {
                sessionStorage.removeItem("sessionPageInsurance");
                sessionStorage.removeItem("sessionSearchInsurance");
                sessionStorage.removeItem("sessionOffsetInsurance");
                setPage(1)
                setOffset(0)
                setSearch("")
            };
            window.addEventListener("beforeunload", beforeUnloadHandler);
            return () => {
                window.removeEventListener("beforeunload", beforeUnloadHandler);
            };
        }
    }, [limit, offset, search, page, flag, company]);


    useEffect(() => {
        let offsetSession = 0;
        if (page > 1) {
            offsetSession = page * limit - 10
        }
        setOffset(offsetSession)
        sessionStorage.setItem("sessionPageInsurance", page);
        sessionStorage.setItem("sessionOffsetInsurance", offsetSession);
    }, [page])

    const handleDelete = async (id) => {
        setLoading(true)
        try {
            const res = await deleteInsurance(id);
            message.success({
                content: "Data successfully deleted"
            });
            setLoading(false)
            setFlag((prev) => prev + 1)
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        } catch (error) {
            message.error({
                content: error.response.data.error,
            });
            setLoading(false)
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp" onClick={() => navigate(`/insurance/detail/${encodeURIComponent("id?" + record.id)}`)}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp" onClick={() => navigate(`/insurance/detail/${encodeURIComponent("id?" + record.id)}`)}>
                        {text}
                    </div>
                )
            }
        },
        {
            title: 'Insurance Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp" onClick={() => navigate(`/insurance/detail/${encodeURIComponent("id?" + record.id)}`)}>
                        {text || "-"}
                    </div>
                )
            }
        },
        {
            title: 'Premium Value',
            dataIndex: 'premium_value',
            key: 'premium_value',
            render: (text, record) => {
                return (
                    <div className="detail-btn-wp" onClick={() => navigate(`/insurance/detail/${encodeURIComponent("id?" + record.id)}`)}>
                        {formatCurrency(text) || "-"}
                    </div>
                )
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: "25px",
            render: (_, record, index) => {
                return RoleAccessInsuranceUpdate && RoleAccessInsuranceDelete ? (
                    <Row justify={"space-between"}>
                        <div className="icon-action">
                            <DeleteFilled onClick={() => showModal(record.id)} />
                        </div>

                        <div className="icon-action">
                            <EditFilled onClick={() => navigate(`/insurance/edit-insurance/${encodeURIComponent("id?" + record.id)}`)} />
                        </div>
                    </Row>
                ) : (
                    <Row justify={"space-between"}>
                        <Tooltip title="No Access">
                            <StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
                        </Tooltip>
                    </Row>
                )
            }
        },
    ];
    const dataBreadcrumb = [
        {
            title: "Payroll Management",
            url: "/insurance"
        },
        {
            title: "Insurance",
            url: "/insurance"
        },
    ];

    useEffect(() => {
        previousPage.current = page;
    }, [page]);

    const onChangeTable = (pageNumber, type) => {
        setPage(pageNumber)
        setOffset(pageNumber * limit - 10)
    };

    const onShowSizeChange = (_, pageSize) => {
        setLimit(pageSize)
    };

    function dataTableWithNumber() {
        const showNumber = limit * (page - 1);
        return listTable?.map((data, index) => {
            return {
                ...data,
                key: index + 1,
                no: showNumber + (index + 1),
            };
        });
    }

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
        setLimit(10)
        setPage(1)
        sessionStorage.setItem("sessionSearchInsurance", e.target.value);
    }

    const showModal = (id) => {
        setIsModalOpen(true);
        setDeleteId(id)
    };

    const handleOk = () => {
        setIsModalOpen(false);
        handleDelete(deleteId)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const RoleAccessInsuranceCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeInsuranceCreate'));
    const RoleAccessInsuranceUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeInsuranceUpdate'));
    const RoleAccessInsuranceDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeInsuranceDelete'));

    return (
        <>
            <div className="title-section">
                Insurance
            </div>

            <Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure to delete the data?</p>
            </Modal>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
                <Col span={24}>
                    <Breadcrumb items={dataBreadcrumb} />
                </Col>
            </div>
            <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
                <Row style={{ marginBottom: "24px", justifyContent: 'end' }} align={"middle"} >
                    <Col md={{ top: 20 }} className="mt-5 md:mt-0">
                        <Row justify={"end"} style={{ gap: 15 }} md={14}>
                            <InputWithIcon
                                type="text"
                                placeholder="Search Insurance"
                                img={iconSearch}
                                className="search-input btn-sh-sec"
                                classImg="op-icon"
                                value={search}
                                onChange={onChangeSearch}
                            />
                            {RoleAccessInsuranceCreate && (
                                <Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/insurance/add-insurance')} icon={<PlusOutlined />} size={"large"} />
                            )}
                        </Row>
                    </Col>
                </Row>

                <div className="table-wrapper">
                    {loading ? (
                        <>
                            <Table id={'my-table'} columns={columns} loading={loading} />
                            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                <Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange} />
                            </div>
                        </>
                    ) : (
                        <>
                            <Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
                            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                <Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}