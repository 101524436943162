/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form as FormAntd, message,Modal } from "antd";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  InputWithLabel,
  NumberFormat,
  SelectOptionWithLabelApplies,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import "./styles.scss";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import {
  AddPph21Rate,
  EditPph21Rate,
  getPph21RateDetail,
} from "../../api/pph21rate";
const FormAddPph21Rate = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [fetching, setFetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [detail, setDetail] = useState([]);

  const getDetailPph21Rate = async () => {
    try {
      const res = await getPph21RateDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetailPph21Rate();
    form.resetForm();
  }, []);

  const validationUser = yup.object().shape({
    tax_rate: yup.string().required("Field is required"),
    tax_category: yup.string().required("Field is required"),
    min_value: yup.string().required("Field is required"),
    max_value: yup.string().required("Field is required"),
    rate: yup.string().required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      tax_rate: result ? detail?.tax_rate : "",
      tax_category: result ? detail?.tax_category : "",
      min_value: result ? detail?.min_value : "",
      max_value: result ? detail?.max_value : "",
      rate: result ? detail?.rate : "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = result
          ? await EditPph21Rate(values, id)
          : await AddPph21Rate(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: result
              ? "PPH 21 Rate successfully updated"
              : "PPH 21 Rate successfully saved",
          });
          navigate("/pph21-rate");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "PTKP already exist",
        });
        setFetching(false);
      }
    },
  });


  const showModal = () => {
    if (form.isValid) {
      if (result) {
        setIsModalOpen(true);
      } else {
        form.handleSubmit();
      }
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.handleSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/pph21-rate",
    },
    {
      title: "PPh21",
    },
    {
      title: "PPh 21 Rate",
    },
    {
      title: `${result ? "Edit PPH 21 Rate" : "Add PPH 21 Rate"}`,
    },
  ];

  return (
    <>
      <div className="title-section">
        {result ? "Edit PPH 21 Rate" : "Add PPH 21 Rate"}
      </div>
      <Modal
        title="Edit"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure want to change the data?</p>
      </Modal>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="tax_rate"
                      label={
                        <div>
                          Type of Tax Rate
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "TER",
                          title: "TER",
                        },
                        {
                          value: "Progressive",
                          title: "Progressive",
                        },
                      ]}
                      value={
                        form?.values?.tax_rate ? form?.values?.tax_rate : null
                      }
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          tax_rate: val,
                          tax_category: "",
                        });
                      }}
                      placeholder="Select One"
                      onBlur={form.handleBlur}
                    />
                    <div
                      className={
                        form.touched.tax_rate &&
                        form.errors.tax_rate === "Field is required"
                          ? "mb-[-22px] mt-2"
                          : "mt-0 mb-[-10px]"
                      }
                    >
                      {form.touched.tax_rate && form.errors.tax_rate ? (
                        <span className="text-error">
                          {form.errors.tax_rate}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="tax_category"
                      label={
                        <div>
                          Tax Category{" "}
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={
                        form.values.tax_rate === "TER"
                          ? [
                              {
                                value: "A",
                                title: "A",
                              },
                              {
                                value: "B",
                                title: "B",
                              },
                              {
                                value: "C",
                                title: "C",
                              },
                            ]
                          : form.values.tax_rate === "Progressive"
                          ? [
                              {
                                value: "I",
                                title: "I",
                              },
                              {
                                value: "II",
                                title: "II",
                              },
                              {
                                value: "III",
                                title: "III",
                              },
                              {
                                value: "IV",
                                title: "IV",
                              },
                              {
                                value: "V",
                                title: "V",
                              },
                            ]
                          : null
                      }
                      value={
                        form?.values?.tax_category
                          ? form?.values?.tax_category
                          : null
                      }
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          tax_category: val,
                        });
                      }}
                      placeholder="Select One"
                      onBlur={form.handleBlur}
                    />

                    <div
                      className={
                        form.touched.tax_category &&
                        form.errors.tax_category === "Field is required"
                          ? "mb-0 mt-2"
                          : "mt-3 mb-[12px]"
                      }
                    >
                      {form.touched.tax_category && form.errors.tax_category ? (
                        <span className="text-error">
                          {form.errors.tax_category}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col mt-[-20px]">
              <div className="flex items-center gap-6 md:gap-[90px] mb-1">
                <div className="flex-grow">
                  <NumberFormat
                    label={
                      <div>
                        Min Value<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    allowNegative={false}
                    name="min_value"
                    type="text"
                    placeholder="Rp."
                    thousandSeparator={true}
                    value={form?.values?.min_value}
                    onChange={(e) => {
                      form.handleChange({
                        target: {
                          name: "min_value",
                          value: e.target.value ? parseInt(e.target.value.replace(/\D/g, '')) : ''
                        }
                      });
                    }}
                    onBlur={form.handleBlur}
                    maxLength={13}
                  />
                </div>
              </div>
              <div
                className={
                  form.touched.min_value &&
                  form.errors.min_value === "Field is required"
                    ? "mb-[-22px] mt-[-5px] ml-0"
                    : "mt-3 mb-[-22px]"
                }
              >
                {form.touched.min_value && form.errors.min_value ? (
                  <span className="text-error">{form.errors.min_value}</span>
                ) : null}
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col mt-[20px]">
              <div className="flex items-center gap-6 md:gap-[90px] mb-1">
                <div className="flex-grow">
                  <NumberFormat
                    label={
                      <div>
                        Max Value<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    allowNegative={false}
                    name="max_value"
                    type="text"
                    placeholder="Rp."
                    thousandSeparator={true}
                    value={form?.values?.max_value}
                    onChange={(e) => {
                      form.handleChange({
                        target: {
                          name: "max_value",
                          value: e.target.value ? parseInt(e.target.value.replace(/\D/g, '')) : ''
                        }
                      });
                    }}
                    onBlur={form.handleBlur}
                    maxLength={13}
                  />
                </div>
              </div>
              <div
                className={
                  form.touched.max_value &&
                  form.errors.max_value === "Field is required"
                    ? "mb-[-22px] mt-[-5px] ml-0"
                    : "mt-3 mb-[-22px]"
                }
              >
                {form.touched.max_value && form.errors.max_value ? (
                  <span className="text-error">{form.errors.max_value}</span>
                ) : null}
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col mt-[20px]">
              <div className="flex items-center gap-6 md:gap-[90px] mb-1">
                <div className="flex-grow">
                  <InputWithLabel
                    label={
                      <div>
                        Rate<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    allowNegative={false}
                    name="rate"
                    type="text"
                    placeholder="Rate %"
                    thousandSeparator={true}
                    value={form?.values?.rate}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    maxLength={4}
                    addonAfter="%"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                    }}
                  />
                </div>
              </div>
              <div
                className={
                  form.touched.rate && form.errors.rate === "Field is required"
                    ? "mb-[-22px] mt-[-5px] ml-0"
                    : "mt-3 mb-[-22px]"
                }
              >
                {form.touched.rate && form.errors.rate ? (
                  <span className="text-error">{form.errors.rate}</span>
                ) : null}
              </div>
            </div>
          </FormAntd.Item>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/pph21-rate")}
          >
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={showModal}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddPph21Rate;
