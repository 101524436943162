import { Button } from "antd"
import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import React from "react"

const DownloadPage = ({rootElementId, downloadFileName}) => {
    const downloadFileDocument = () => {
        const input = document.getElementById(rootElementId)
        // html2canvas(input).then((canvas)=>{
        //     const imgData = canvas.toDataURL("image/png")
        //     const pdf = new jsPDF('l', 'mm',[680, 780] )
        //     pdf.addImage(imgData, "JPEG", 0, 0)
        //     pdf.save(`${downloadFileName}`)
        // })
        html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
            orientation: 'landscape',
            });
            const imgProps= pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`${downloadFileName}`);
        });
    }

    return (
        <div style={{display: "flex", justifyContent: "end"}}>
            <Button type="primary" className="btn btn-primary btn-sh-p" onClick={downloadFileDocument}>Download</Button>
        </div>
    )
}

export default DownloadPage