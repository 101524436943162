import React from "react";
import "./styles.scss"

export const BarChart = ({ data }) => {
    return (
        <div className="bar-chart">
            {data &&
                data.map((d) => {
                    return (
                        <div className="bar-data" style={{ background: `${d.color}`, width: `${d.percent}` }}>
                            <p className="percent-text">{d.percent}</p>
                        </div>
                    );
                })}
        </div>
    );
};