import moment from "moment";
import { ClockIcon, DateIcon, LinkIcon } from "../../../assets/icons";

const ScheduleCard = ({ schedule }) => {
  return (
    <>
      <div className="mb-4 mt-8">{schedule.activity} Schedule</div>
      <div className="flex items-center justify-between ">
        <div className="border-card" style={{
          width: "30%"
        }}>
          <div className="flex justify-between">
            {schedule?.date}
            <DateIcon />
          </div>
        </div>
        <div className="border-card" style={{
          width: "30%"
        }}>
          <div className="flex justify-between">
            {schedule.start_time
              ? moment(schedule.start_time, "HH:mm:ss").format("HH:mm")
              : null}
            <ClockIcon />
          </div>
        </div>
        <div className="border-card" style={{
          width: "30%"
        }}>
          <div className="flex justify-between">
            {schedule?.end_time
              ? moment(schedule?.end_time, "HH:mm:ss").format("HH:mm")
              : null}
            <ClockIcon />
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center border-card mt-5">
        <LinkIcon />
        <a href={schedule.url} className="mb-1" target="_blank">
          {schedule.url}
        </a>
      </div>
    </>
  );
};

export default ScheduleCard;
