import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const getPph21Rate = async (
  company,
  page,
  offset,
  limit,
  search
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    draw: page,
    start: offset,
    length: limit,
    search_by: "tax_rate",
    search_value: search?.length > 0 ? search : undefined,
  };
  return master.post("/pph21-rate", payload);
};

const AddPph21Rate = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: data.companyCode,
    tax_rate: data.tax_rate,
    tax_category: data.tax_category,
    min_value: data.min_value,
    max_value: data.max_value,
    rate: data.rate,
    created_by: user,
  };

  return master.post("/pph21-rate-create", payload);
};

const EditPph21Rate = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    m_pph21_rate_status_id: id,
    tax_rate: data.tax_rate,
    tax_category: data.tax_category,
    min_value: data.min_value,
    max_value: data.max_value,
    rate: data.rate,
    modified_by: user,
  };

  return master.post("/pph21-rate-update", payload);
};

const getPph21RateDetail = async (id) => {
  let payload = {
    m_pph21_rate_status_id: id,
  };
  return master.post("/pph21-rate-detail", payload);
};

const DeletePph21Rate = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    m_pph21_rate_status_id: id,
    deleted_by: user,
  };
  return master.post("/pph21-rate-delete", payload);
};

export {
  getPph21Rate,
  DeletePph21Rate,
  AddPph21Rate,
  EditPph21Rate,
  getPph21RateDetail,
};
