import { Button, Checkbox, Form as FormAntd} from "antd";
import React from "react";
import { InputWithLabel } from "../../../component/Form/Input";
import "../styles.scss"

const OnBoardingForm = ({form, handleBack, fetching}) => {
    return (
        <FormAntd
            onFinish={form.handleSubmit}
            className="add-wrapper"
        >
            <FormAntd.Item>
                <Checkbox 
                    name="supportingDocument"
                    onChange={e => {
                        form.handleChange(e);
                    }}
                    checked={form.values?.supportingDocument}
                    className="checkbox-form"
                >
                    Supporting document KTP NPWP KK
                </Checkbox>
            </FormAntd.Item>
            <FormAntd.Item>
                <Checkbox 
                    name="visiMisi"
                    onChange={e => {
                        form.handleChange(e);
                    }}
                    checked={form.values?.visiMisi}
                    className="checkbox-form"
                >
                    Introduction the vision and mission of the company
                </Checkbox>
            </FormAntd.Item>
            <FormAntd.Item>
                <Checkbox 
                    name="organization"
                    onChange={e => {
                        form.handleChange(e);
                    }}
                    checked={form.values?.organization}
                    className="checkbox-form"
                >
                   Introduction the organizational structure of the company
                </Checkbox>
            </FormAntd.Item>
            <FormAntd.Item>
                <Checkbox 
                    name="jobDescription"
                    onChange={e => {
                        form.handleChange(e);
                    }}
                    checked={form.values?.jobDescription}
                    className="checkbox-form"
                >
                    Explaination of the job descriptions
                </Checkbox>
            </FormAntd.Item>
            <FormAntd.Item>
                <Checkbox 
                    name="roleAccess"
                    onChange={e => {
                        form.handleChange(e);
                    }}
                    checked={form.values?.roleAccess}
                    className="checkbox-form"
                >
                    Explaination of role access
                </Checkbox>
            </FormAntd.Item>
            <FormAntd.Item>
                <Checkbox 
                    name="isAssets"
                    onChange={e => {
                        form.handleChange(e);
                    }}
                    checked={form.values?.isAssets}
                    className="checkbox-form"
                >
                    Assets
                </Checkbox>
            </FormAntd.Item>
            {form.values?.isAssets && (
                <FormAntd.Item>
                    <InputWithLabel
                        name="assets"
                        label={""}
                        placeholder="Assets"
                        value={form?.values?.assets}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                </FormAntd.Item>
            )}
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={handleBack}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </FormAntd>
    )
}

export default OnBoardingForm;