import { Table as AntTable } from "antd";
import React from "react";
import "./styles.scss"


const Table = ({ className, ...props }) => {
	return <AntTable className={`table-wrapper ${className}`} pagination={false} scroll={{ x: true }} {...props} />;
};

export default Table;
