import { Button, Col, Form as FormAntd, Input, Row, Select, message,DatePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { InputGroupWithLabel, InputWithLabel, SelectSearchWithLabel, TextAreaWithLabel, TimeWithLabel } from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { DeleteOutlined,CloudUploadOutlined } from "@ant-design/icons";
import './styles.scss'
import moment from "moment";
import { addOvertimeTransaction, editOvertimeTransaction, getListOvertimeTransactionEmployeeNumber, getOvertimeTransactionDetail } from "../../api/overtimeTransaction";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import dayjs from "dayjs";
const FormAddOvertimeTransaction = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    let n = location.split("/");
    let result =  n[5];
    const inputRef = useRef(null);
    const decode = decodeURIComponent(result)
    let dec = decode?.lastIndexOf('?');
    let id = decode?.substring(dec + 1);
    const [flag, setFlag] = useState(0);
    const companyStore = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const [detail, setDetail] = useState([]);
    const { Option } = Select;
    const [fileName, setFileName] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const BestpraticeUseEffect = useRef(false)
	

     const employeeNumberActive = async () => {
        try {
            const res = await getListOvertimeTransactionEmployeeNumber(companyStore)
            const data = res?.data?.data;
            // console.log(data)
            setOptions(data)
          } catch (err) {
            console.log(err);
          }
	  };

      const ApprovalemployeeNumberDetail = async () => {
        try {
            const res = await getOvertimeTransactionDetail(id)
            const data = res?.data?.data;
            setDetail(data)
          } catch (err) {
            console.log(err);
          }finally {
            setFetching(false);
            
          };
	  };


	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            employeeNumberActive();
            ApprovalemployeeNumberDetail();
          }
	},[flag]);

    
      const handleSelectChange = (value) => {
        setSelectedOption(value);
        form.setValues({
            ...form.values,
            EmployeeNumber: value,
        })
        // Mendapatkan nilai full_name terkait dari data API
        const selectedData = options.find(option => option.employee_number === value);
        if (selectedData) {
        form.setFieldValue('EmployeeName',selectedData ? selectedData.full_name : '');
        form.setFieldValue('DepartmentName',selectedData ? selectedData.department_name : '');
        form.setFieldValue('DivisionName',selectedData ? selectedData.division_name : '');
        form.setFieldValue('PositionName',selectedData ? selectedData.position_name : '');
        } else {
        form.setFieldValue('EmployeeName');
        form.setFieldValue('DepartmentName');
        form.setFieldValue('DivisionName');
        form.setFieldValue('PositionName');
        }
    };



   
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 2 * 1024 * 1024) {
          setFileName(file.name);
          form.setValues({
              ...form.values,
              AttechmentUpload: e.currentTarget.files[0],
          })
        }
        else{
          setFileName(null);
          message.error('Please upload a PDF,JPG,PNG file with a maximum size of 2 MB');
        
        }
      };

      const handleFileDelete = () => {
        setFileName(null);
      };
    
   
     const form = useFormik({
        initialValues: {
            EmployeeNumber: result ? detail?.map((data)=> data.employee_number)[0] : "",
            date: result ? detail?.map((data)=> data.date)[0]  : "",
            StartTime : result ? detail?.map((data)=> data.start_time)[0]  : "",
            EndTime : result ? detail?.map((data)=> data.end_time)[0]  : "",
            BreakHour : result ? detail?.map((data)=> data.break_hour)[0]  : "",
            BreakMinute: result ? detail?.map((data)=> data.break_minute)[0]  : "",
            AttechmentUpload : result ? null : null,
            Description: result ? detail?.map((data)=> data.description)[0]  : "",
        },
        enableReinitialize: true,
        // validationSchema: validationUser,
        onSubmit: async(values) => {
            // setFetching(true)
            try {
                const res = result ? await editOvertimeTransaction(values, id) : await addOvertimeTransaction(values);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Edit Data Success" : "Add Data Success",
                    });
                    navigate("/overtime-transaction")
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                    // window.location.reload()

                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });

    const dataBreadcrumb = [
		{
			title: "Attendance Management",
			url: "/overtime-transaction"
		},
		{
			title: "Overtime Transaction",
			url: "/overtime-transaction"
		},
		{
			title: 'Add Overtime Transaction',
			// url: `${ result ?  '/overtime-transaction/edit-overtime-transaction' : '/overtime-transaction/add-overtime-transaction'}`
		},
	]
    return (
      <>
        <div className="title-section">
                {result ? "Edit Overtime Transactions" : "Add Overtime Transactions"}
		</div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >  
            <Col>
                <Row justify={"space-between"}>
                    <Col span={24}>
                        <FormAntd.Item>
                            <SelectSearchWithLabel
                              name="EmployeeNumber"
                              labelname={<div>Employee<span style={{color:"#FF0000"}}> *</span></div>}
                              showSearch
                              allowClear
                              placeholder="Select Employee"
                              optionFilterProp="children"
                              onChange={handleSelectChange}
                              value={selectedOption ? selectedOption : detail?.map((data)=> data.employee_number)[0]}
                              filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {options.map(option => (
                                <Option key={option.employee_number} value={option.approver_id}>{option.employee_option}</Option>
                                ))}
                            </SelectSearchWithLabel>
                        </FormAntd.Item>
                    </Col>

                </Row>

                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="DepartmentName"
                                    label={"Departement"} 
                                    placeholder="Departement"
                                    value={  form.values.DepartmentName ? form.values.DepartmentName : detail?.map((data)=> data.department_name) }
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="DivisionName"
                                    label={"Division"} 
                                    value={  form.values.DivisionName ? form.values.DivisionName : detail?.map((data)=> data.division_name)   }
                                    placeholder="Division"
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="PositionName"
                                    label={"Position"}
                                    value={  form.values.PositionName ? form.values.PositionName : detail?.map((data)=> data.position_name)  } 
                                    placeholder="Position"
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                <Col span={11}>
                <FormAntd.Item>
                        <div className="flex flex-col">
                          <div className="text-slate-500">{"Date"}<span className="text-red-500">*</span></div>
                        <DatePicker
                            name="date"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.date ? dayjs(form.values.date, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('date', formattedDate);
                      
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          </div>
                      </FormAntd.Item>
                    </Col>
                </Row>

                <Row justify={"space-between"}>
                        <Col span={11}>
                            <FormAntd.Item>
                                <TimeWithLabel 
                                name="StartTime"
                                label={<div>Start Time<span style={{color:"#FF0000"}}> *</span></div>}
                                value={form?.values?.StartTime ? moment(form?.values?.StartTime,"HH:mm").format('HH:mm') : null} 
                                onChange={form.handleChange} 
                                />

                            </FormAntd.Item>
                        </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                                <TimeWithLabel 
                                name="EndTime"
                                label={<div>End Time<span style={{color:"#FF0000"}}> *</span></div>}
                                value={form?.values?.EndTime ? moment(form?.values?.EndTime,"HH:mm").format("HH:mm"): null} 
                                onChange={form.handleChange}  
                                />
                             </FormAntd.Item>
                        </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                            <FormAntd.Item>
                            <InputGroupWithLabel label={<div>Break Duration</div>}>
                                <div style={{flex:1}}>
                                    <Input
                                        name="BreakHour"
                                        style={{ width: 100, marginRight: 10 }}
                                        placeholder="0"
                                        onChange={ (e) => {
                                            const regex = e.target.value;
                                            const reg = /^[0-9\b]+$/;
                                            if (regex === '' || reg.test(regex) && parseInt(regex) <= 10) {
                                                form.handleChange(e)
                                            }
                                          }}
                                        value={form.values.BreakHour}
                                    />
                                    <span>Hours</span>
                                
                                    <Input
                                        style={{ width: 100, marginLeft: 10, marginRight: 10 }}
                                        placeholder="0"
                                        name="BreakMinute"
                                        onChange={ (e) => {
                                            const regex = e.target.value;
                                            const reg = /^[0-9\b]+$/;
                                            if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                                form.handleChange(e)
                                            }
                                          }}
                                        value={form.values.BreakMinute}
                                    />
                                    <span>Minutes</span>
                                </div>                       
                            </InputGroupWithLabel>
                            </FormAntd.Item>
                        </Col>
                    <Col span={11}>
                        <FormAntd.Item>
                                <div className="mt-9">
                                        <div>
                                            <label className="file-upload">
                                                <input type="file" name="AttechmentUpload" id="AttechmentUpload" onChange={handleFileUpload} accept=".pdf, .jpg , .png"  ref={inputRef}/>
                                                <CloudUploadOutlined /> Attachment
                                            </label>
                                        </div>
                                        <div>
                                            {fileName && (
                                                <div>
                                                    <p>{fileName}   <Button  shape="circle" onClick={handleFileDelete} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                                                </div>
                                            )}
                                        </div>
                                </div>
                            </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                    <Col span={11}>
                            <FormAntd.Item>
                                <div>
                                    <TextAreaWithLabel 
                                        name="Description"
                                        label={"Description"} 
                                        placeholder="......."
                                        maxLength={200}
                                        showCount
                                        onChange={form.handleChange} 
                                        value={form.values.Description}
                                    />
                                </div>
                               
                            </FormAntd.Item>
                        </Col>
                    
                </Row>

            </Col>
            </FormAntd>

            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/overtime-transaction')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
      </>
    )
}

export default FormAddOvertimeTransaction;