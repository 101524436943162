import { Button, Col, Form as FormAntd, Row, Select, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { DatePicker2WithLabel,  InputWithLabel, TextAreaWithLabel } from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { DeleteOutlined,CloudUploadOutlined } from "@ant-design/icons";
import './styles.scss'
import { getEmployeeNumber } from "../../api/employee";
import moment from "moment";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
const FormAddAnnouncement = () => {
   
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const inputRef = useRef(null);
    const decode = decodeURIComponent(result)
    var dec = decode?.lastIndexOf('?');
    var id = decode?.substring(dec + 1);
    const companyStore = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const [detail, setDetail] = useState([]);
    const { Option } = Select;
    const [fileName, setFileName] = useState(null);
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const BestpraticeUseEffect = useRef(false)
	

     const employeeNumberActive = async () => {
        try {
            const res = await getEmployeeNumber(companyStore)
            const data = res?.data?.data;
            // console.log(data)
            setOptions(data)
          } catch (err) {
            console.log(err);
          }
	  };

    const handleSelectChange = (value) => {
        setSelectedOption(value);
        form.setValues({
            ...form.values,
            EmployeeNumber: value,
        })
        // Mendapatkan nilai full_name terkait dari data API
        const selectedData = options.find(option => option.employee_number === value);
        if (selectedData) {
        form.setFieldValue('EmployeeName',selectedData ? selectedData.full_name : '');
        } else {
        form.setFieldValue('EmployeeName');
        }
    };

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            employeeNumberActive();
            form.resetForm();
          }
	},[]);


   
    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file && file.size <= 2 * 1024 * 1024) {
          setFileName(file.name);
          form.setValues({
              ...form.values,
              document_url: e.currentTarget.files[0],
          })
        }
        else{
          setFileName(null);
          message.error('Please upload a PDF,JPEG,JPG,PNG file with a maximum size of 2 MB');
        
        }
      };

      const handleFileDelete = () => {
        setFileName(null);
      };
    
   
     const form = useFormik({
        initialValues: {
            EmployeeNumber: result ? detail?.employee_number : "",
            EmployeeName: result ? detail?.full_name: "",
            DateFrom: result ? detail?.attendance_date :"",
            document_url :  result ? null : null
        },
        enableReinitialize: true,
        // validationSchema: validationUser,
        onSubmit: async(values) => {
            // setFetching(true)
            try {
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });

    const dataBreadcrumb = [
		{
			title: "Announcement",
			url: "/announcement"
		},
		{
			title: `${result ?  'Edit Announcement' : 'Add Announcement'}` ,
			// url: `${ result ?  '/announcement/edit-announcement' : '/announcement/add-announcement'}`
		},
	]
   
    return (
       <>
        <div className="title-section">
                {result ? "Edit Announcement" : "Add Announcement"}
		</div>
          <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">

            <FormAntd
                onFinish={form.handleSubmit}
            >  
            <Col>
                <Row justify={"space-between"}>
                    <Col span={24}>
                        <FormAntd.Item>
                        <InputWithLabel
                                    name="Title"
                                    label={<div>Title<span style={{color:"#FF0000"}}> *</span></div>} 
                                    placeholder="Title"
                                    value={  form.values.Title ? form.values.Title : ''  }
                                    onChange={form.handleChange}
                                />
                            {form.touched.Title && form.errors.Title ? (
                                <span className="text-error">{form.errors.Title}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                    <Col span={24}>
                            <FormAntd.Item>
                                <div>
                                    <TextAreaWithLabel 
                                        name="Content"
                                        label={<div>Content<span style={{color:"#FF0000"}}> *</span></div>} 
                                        placeholder="Content"
                                        maxLength={500}
                                        showCount
                                    />
                                </div>
                            </FormAntd.Item>
                        </Col>
                                {form.touched.Content && form.errors.Content ? (
                                    <span className="text-error">{form.errors.Content}</span>
                                ) : null}
                </Row>

                <Row justify={"space-between"}>
                <Col span={24}>
                        <FormAntd.Item>
                                <div className="mt-2">
                                        <div>
                                            <label className="file-upload">
                                                <input type="file" name="document_url" id="document_url" onChange={handleFileUpload} accept=".jpeg, .jpg, .png, .pdf"  ref={inputRef}/>
                                                <CloudUploadOutlined /> Attecment
                                            </label>
                                        </div>
                                        <div>
                                            {fileName && (
                                                <div>
                                                    <p>{fileName}   <Button  shape="circle" onClick={handleFileDelete} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                                                </div>
                                            )}
                                        </div>
                                </div>
                            </FormAntd.Item>
                    </Col>
                <Col span={11}>
                        <FormAntd.Item>
                            <DatePicker2WithLabel
                                name="Effectivedate"
                                label={'Effective date'}
                                onBlur={form.handleBlur}  
                                value={form?.values?.Effectivedate ? moment(form?.values?.Effectivedate).format("YYYY-MM-DD") : ""}
                                onChange={e => {
                                    form.handleChange(e);
                                }}
                            />
                            {form.touched.Effectivedate && form.errors.Effectivedate ? (
                                <span className="text-error">{form.errors.Effectivedate}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                <Col span={11}>
                        <FormAntd.Item>
                            <DatePicker2WithLabel
                                name="Expireddate"
                                label={'Expired date'}
                                onBlur={form.handleBlur}  
                                value={form?.values?.Expireddate ? moment(form?.values?.Expireddate).format("YYYY-MM-DD") : ""}
                                onChange={e => {
                                    form.handleChange(e);
                                }}
                            />
                            {form.touched.Expireddate && form.errors.Expireddate ? (
                                <span className="text-error">{form.errors.Expireddate}</span>
                            ) : null}
                        </FormAntd.Item>
                    </Col>
                </Row>
           

            </Col>
            </FormAntd>

            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/announcement')}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
       </>
    )
}

export default FormAddAnnouncement;