import React from "react";

const IconExel = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        width="144px"
        height="144px"
        baseProfile="basic"
      >
        <path
          fill="#a5d6a7"
          d="M41.5,13.5h-11v-1.96c0-1.262-1.155-2.209-2.392-1.962l-15.196,3.039	c-1.403,0.281-2.412,1.512-2.412,2.942V18.5v8v13.941c0,1.43,1.009,2.661,2.412,2.941l15.196,3.039	c1.237,0.248,2.392-0.699,2.392-1.961V43.5h11c2.209,0,4-1.791,4-4v-2v-8v-12C45.5,15.291,43.709,13.5,41.5,13.5z"
        />
        <path
          fill="none"
          stroke="#18193f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="3"
          d="M41.5,33.5v2c0,2.209-1.791,4-4,4h-11"
        />
        <path
          fill="none"
          stroke="#18193f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="3"
          d="M26.5,9.5h11c2.209,0,4,1.791,4,4v12"
        />
        <path
          fill="none"
          stroke="#18193f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="3"
          d="M6.5,22.5v13.941c0,1.43,1.009,2.661,2.412,2.941l15.196,3.039c1.237,0.248,2.392-0.699,2.392-1.961V7.54	c0-1.262-1.155-2.209-2.392-1.962L8.912,8.617C7.509,8.898,6.5,10.129,6.5,11.559V14.5"
        />
        <line
          x1="31.5"
          x2="35.5"
          y1="16.5"
          y2="16.5"
          fill="none"
          stroke="#18193f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="31.5"
          x2="35.5"
          y1="24.5"
          y2="24.5"
          fill="none"
          stroke="#18193f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <line
          x1="31.5"
          x2="35.5"
          y1="32.5"
          y2="32.5"
          fill="none"
          stroke="#18193f"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="3"
        />
        <path
          fill="#18193f"
          d="M19.96,31.158c-0.495,0-0.98-0.246-1.267-0.695l-7-11c-0.444-0.699-0.238-1.626,0.46-2.071	c0.698-0.443,1.626-0.24,2.071,0.46l7,11c0.444,0.699,0.238,1.626-0.46,2.071C20.514,31.083,20.236,31.158,19.96,31.158z"
        />
        <path
          fill="#18193f"
          d="M12.958,31.158c-0.276,0-0.555-0.076-0.805-0.234c-0.698-0.445-0.904-1.372-0.46-2.071l7-11	c0.446-0.7,1.374-0.904,2.071-0.46c0.698,0.445,0.904,1.372,0.46,2.071l-7,11C13.938,30.913,13.453,31.158,12.958,31.158z"
        />
      </svg>
    </div>
  );
};

export default IconExel;
