import { Result } from 'antd';
import { Link } from 'react-router-dom';

const Maintenance = () => (
    <Result
    status="500"
    title="This site is under maintenance"
    subTitle="Sorry, page maintenance is in progress."
    extra={<Link type="primary" to={'/'}>reload page</Link>}
  />
)
 
export default Maintenance;