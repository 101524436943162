import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getPosition = async (search, limit, offset, page, division,company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        divisionCode : division,
        startDate : "",
        endDate : "",
        filterColumn : "name",
        filter : search.length > 0 ? search : undefined,
        start :  offset,
        length :  limit,
        draw : page
    }
    return master.post("/position", payload);
}

export const getPositionDropdown = async (company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
    }
    return master.post("/position-dropdown", payload);
}

export const addPosition = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        name: data.name,
        divisionCode: data.divisionCode,
        parent_id: data.parent_id,
        is_active: "1",
        created_by: user,
        modified_by: user
    }
    return master.post("/position-create", payload);
}

export const getPositionDetail = async (id) => {
    let payload = {
        positionCode: id,
    }
    return master.post("/position-detail", payload);
}

export const deletePosition = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        positionCode: id,
        is_active: "0",
        modified_by: user
    }
    return master.post("/position-delete", payload);
}

export const editPosition = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        positionCode: id,
        name: data.name,
        divisionCode: data.divisionCode,
        parent_id: data.parent_id,
        is_active: "1",
        modified_by: user
    }
    console.log(payload)
    return master.post("/position-update", payload);
}

export const getOrChart = async (companyCode) => {
    let paylaod = {
      companyCode: companyCode,
    }
    return master.post("/organization-chart", paylaod);
  }