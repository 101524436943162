
import { Col, Row,Button, Checkbox, Form as FormAntd} from "antd";
import React from "react";
import { InputWithLabel } from "../../../component/Form/Input";
import { formatCurrency } from "../../../helper/formatCurrency";
import "../styles.scss";

const OnBoardingForm = ({data}) => {
    return (
        <div className="detail-wrapper">
            <Row>
                <Col span={12}>
                    <div className="detail-card">
                        <Checkbox 
                            name="supportingDocument"
                            checked={data.submitted_main_docs==1?true:false}
                            className="checkbox-form">
                            Supporting document KTP NPWP KK
                        </Checkbox>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <Checkbox 
                        checked={data.intro_vision_mission_company==1?true:false}
                        className="checkbox-form">
                        Introduction the vision and mission of the company
                        </Checkbox>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <Checkbox 
                        checked={data.intro_orchart_company == 1 ? true : false}
                        className="checkbox-form">
                        Introduction the organizational structure of the company
                        </Checkbox>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <Checkbox 
                            checked={data.explain_jobdesc==1?true:false}
                            className="checkbox-form">
                            Explaination of the job descriptions
                        </Checkbox>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <Checkbox 
                            name="roleAccess"
                            checked={data.explain_role_access==1?true:false}
                            className="checkbox-form">
                        Explaination of role access
                        </Checkbox>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <Checkbox 
                            name="isAssets"
                            checked={data.assets_given!==null?true:false}
                            className="checkbox-form">
                            Assets
                        </Checkbox>
                        <div className="title">{data.assets_given || "-"}</div>
                        
                    </div>
                </Col>
                
            </Row>
        </div>

    )
}

export default OnBoardingForm;