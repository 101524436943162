import { Button, Col, message, Modal, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, DeleteFilled, EditFilled ,SettingOutlined, StopOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { DeleteApprovalSetting, getApprovalSetting } from "../../api/approvalSetting";
import { decryptData } from "../../helper/cryptojs";
import {decode} from 'html-entities';
export default function ApprovalSetting() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageApprovalSetting");
	const sessionOffset = sessionStorage.getItem("sessionOffsetApprovalSetting");
	const sessionSearch = sessionStorage.getItem("sessionSearchApprovalSetting");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ?? 0)
    const [search, setSearch] = useState(sessionSearch ?? "")
	const [flag, setFlag] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	

	const approvalList = async () => {
		try {
			setLoading(true)
			const res = await getApprovalSetting(search, limit, offset, page,company);
			setListTable(res?.data?.data)
			setTotalData(res?.data?.recordsTotal)
		} catch (error) {
			message.error({
				content: "Request Not Found",
			});
		} finally {
			setLoading(false);
		  }
	}

	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			approvalList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageApprovalSetting");
				sessionStorage.removeItem("sessionSearchApprovalSetting");
				sessionStorage.removeItem("sessionOffsetApprovalSetting");
				setPage(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		  }
	}, [search, limit, offset, page,company]);


	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageApprovalSetting", page);
		sessionStorage.setItem("sessionOffsetApprovalSetting", offsetSession);
	},[page])


	const handleDelete = async (id) => {
		setLoading(true);
		try {
		  const res = await DeleteApprovalSetting(id);
		  message.success({
			content: "Data successfully deleted",
			onClose: () => {
				window.location.reload();
			}
		});
		  setLoading(false);
		  setFlag(prevFlag => prevFlag + 1);
		} catch (error) {
		  message.error('Request Not Found');
		  setLoading(false);
		}
	  };
           	
	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		width: '25px',
        render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'NIK',
		dataIndex: 'employee_number',
		key: 'employee_number',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Employee Name',
		dataIndex: 'employee_name',
		key: 'employee_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{decode(text) || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Approval 1',
		dataIndex: 'approval_1_name',
		key: 'approval_1_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{decode(text) || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Approval 2',
		dataIndex: 'approval_2_name',
		key: 'approval_2_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{decode(text) || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Approval 3',
		dataIndex: 'approval_3_name',
		key: 'approval_3_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{decode(text) || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Approval 4',
		dataIndex: 'approval_4_name',
		key: 'approval_4_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{decode(text) || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Approval 5',
		dataIndex: 'approval_5_name',
		key: 'approval_5_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/approval-setting/detail/${encodeURIComponent("id?" + record.approval_setting_id)}`)}>
					{decode(text) || '-'}
				</div>
			)
		}
	},
	{
		title: 'Action',
		key: 'action',
		width: "50px",
		render: (_, record, index) => {
		  return RoleAccessApprovalSettingUpdate && RoleAccessApprovalSettingDelete ?  (
			<Row justify={"space-between"}>
			  <div className="icon-action">
				<DeleteFilled onClick={() => showModal(record.approval_setting_id)} />
			  </div>
			  <div className="icon-action" onClick={() => {
				navigate(`/approval-setting/edit-approval-setting/${encodeURIComponent("id?" + record.approval_setting_id)}`);
				window.location.reload();
			  }}>
				<EditFilled />
			  </div>
			</Row>
		  ) : (
					<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
					</Row>
		  )
		}
	  },
	  
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/approval-setting"
		},
		{
			title: "Approval Setting",
			url: "/approval-setting"
		},
	];


	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchApprovalSetting", e.target.value);
	}

	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const redirectToAddPage = () => {
		navigate('/approval-setting/add-approval-setting');
		window.location.reload(); // Menambahkan reload setelah mengarahkan pengguna
	  };

	  const RoleAccessApprovalSettingCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalSettingCreate'));
	  const RoleAccessApprovalSettingUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalSettingUpdate'));
	  const RoleAccessApprovalSettingDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalSettingDelete'));
    return (
        <>
		  <>
		  		<div className="title-section">
					Approval Setting
				</div>
				<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
						<p>Are you sure to delete this data?</p>
				</Modal>
				<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
					<Col span={24}>
						<Breadcrumb items={dataBreadcrumb}/>
					</Col>
				</div>
				<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
					<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
							<InputWithIcon
								type="text"
								placeholder="Search Approval Setting"
								img={iconSearch}
								className="search-input btn-sh-sec"
								classImg="op-icon"
								value={search}
								onChange={onChangeSearch}
							/>
						 {RoleAccessApprovalSettingCreate && (
							<>
								<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/approval-setting/default-setting')} icon={<SettingOutlined />} size={"large"} />
								<Button className="btn-sh-p" type="primary" shape="circle" onClick={redirectToAddPage} icon={<PlusOutlined />} size={"large"} />
							</>
						 )}

						</Row>
						</Col>
						</Row>
						<div className="table-wrapper">
							{loading ? (
							<>
								<Table id={'my-table'}  columns={columns}  loading={loading} />
								<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
									<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
								</div>
								</>
								) : (
									<>
									<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
									<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
										<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
									</div>
									</>
							)}	
						</div>
				</div>
					</>
          
        </>
    )
}