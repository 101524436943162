import { Button, Col, DatePicker, message, Pagination, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { PlusOutlined} from "@ant-design/icons";
import { getOvertimeTransaction } from "../../api/overtimeTransaction";
import { decryptData, encryptData } from "../../helper/cryptojs";
import dayjs from "dayjs";
import { formatCurrency } from "../../helper/formatCurrency";
const { RangePicker } = DatePicker;
export default function OvertimeTransaction() {
	const navigate = useNavigate();
	const limitSession = sessionStorage.getItem("sessionPageOvetimetransaction");
	const offsetSession = sessionStorage.getItem("sessionOffsetOvetimetransaction");
	const startDateSession = decryptData(sessionStorage.getItem("sessionStartDateOvertime"));
	const endDateSession = decryptData(sessionStorage.getItem("sessionEndDateOvertime"));
	const sessionSearch = sessionStorage.getItem("sessionSearchOvertimetransaction");
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(limitSession ? limitSession : 1);
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(offsetSession ? offsetSession : 0)
	const previousPage = useRef(1);
	const [search, setSearch] = useState(sessionSearch ?? "")
	const [startDate, setStartDate] = useState(startDateSession ? startDateSession : null)
	const [endDate, setEndDate] = useState(endDateSession ? endDateSession : null)
	const [selectedDateRange, setSelectedDateRange] = useState([]);
	const BestpraticeUseEffect = useRef(false)
	const [isOpen, setIsOpen] = useState(false);
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const branch = decryptData(sessionStorage.getItem("selectBranch"));
	const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
	const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
	const branchCode = branchSession || branch !== null ? branch  : branchDefault   
	const overtimeTransactioneList = async () => {
		setLoading(true);
		try {
		  const res = await getOvertimeTransaction( search,startDate,endDate, limit, offset,page,company,branch)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
		  BestpraticeUseEffect.current = true;
		} else {
		  	overtimeTransactioneList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageOvetimetransaction");
				sessionStorage.removeItem("sessionOffsetOvetimetransaction");
				sessionStorage.removeItem('sessionStartDateOvertime');
				sessionStorage.removeItem('sessionEndDateOvertime');
				sessionStorage.removeItem('picker_selected_overtime');
				sessionStorage.removeItem("sessionSearchOvertimetransaction");
				setStartDate(null);
				setEndDate(null);
				setPage(1);
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		}
	  }, [startDate, endDate, search, limit, offset, page, company,branch]);

	  useEffect(() => {
		sessionStorage.setItem("sessionPageOvetimetransaction", page);
	  }, [page]);

	  useEffect(() => {
		const limitSession = sessionStorage.getItem("sessionPageOvetimetransaction");
		if (limitSession) {
		  setPage(parseInt(limitSession));
		}
	  }, []);

	  useEffect(() => {
		if (limitSession) {
			setPage(parseInt(limitSession));
		}
	  }, []);


	useEffect(() => {
		if(page === 1){
			setOffset(0)
		}else {
			setOffset(page*limit - 10)
		}
		},[page])
	
	
		  
	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record,index) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{index + 1 + (page - 1) * limit}
					</div>
				)
			}
		},
		{
			title: 'Branch',
			dataIndex: 'branch_name',
			key: 'branch_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'NIK',
			dataIndex: 'employee_number',
			key: 'employee_number',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Employee Name',
			dataIndex: 'full_name',
			key: 'full_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{text.replace(/&#039;/g, "'") || "-"}
					</div>
				)
			}
		},
	
		{
			title: 'Request Date',
			dataIndex: 'request_date',
			key: 'request_date',
			sorter: (a, b) => new Date(a.request_date) - new Date(b.request_date),
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Overtime Date',
			dataIndex: 'overtime_date',
			key: 'overtime_date',
			sorter: (a, b) => new Date(a.overtime_date) - new Date(b.overtime_date),
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Compensation',
			dataIndex: 'compensation',
			key: 'compensation',
			render: (text,record) =>{

				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
					{text || "-"}
				  </div>
				)
			}
		},
		{
			title: 'Duration',
			dataIndex: 'duration',
			key: 'duration',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Total Payment',
			dataIndex: 'total_payment',
			key: 'total_payment',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/overtime-transaction/detail/${encodeURIComponent("id?" + record.overtime_id)}`)}>
						{formatCurrency(text ? text : null) || "-"}
					</div>
				)
			}
		},
	
	];
	const dataBreadcrumb = [
		{
			title: "Attendance Manegement",
			url: "/overtime-transaction"
		},
		{
			title: "Overtime Transaction",
			url: "/overtime-transaction"
		},
	];


	useEffect(() => {
		previousPage.current = page;
	}, [page]);

	const onChangeTable = (pageNumber, type) => {
		const offsetSession = pageNumber*limit- 10
		setPage(pageNumber)
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageOvetimetransaction", pageNumber);
		sessionStorage.setItem("sessionOffsetOvetimetransaction", offsetSession);
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});

	  }

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchOvertimetransaction", e.target.value);
	}

	  
	  useEffect(() => {
		const startDate = decryptData(sessionStorage.getItem("sessionStartDateOvertime"));
		const endDate = decryptData(sessionStorage.getItem("sessionEndDateOvertime"));
		if (startDate && endDate) {
		  setStartDate(startDate);
		  setEndDate(endDate);
		  setSelectedDateRange([dayjs(startDate),dayjs(endDate)])
		}
	  }, []);
	  
	
		const handleDateChange = (dates) => {
			if (dates && dates.length === 2) {
			  const start = dates[0].startOf('day').format('YYYY-MM-DD');
			  const end = dates[1].endOf('day').format('YYYY-MM-DD');
			  sessionStorage.setItem('sessionStartDateOvertime', encryptData(start));
			  sessionStorage.setItem('sessionEndDateOvertime', encryptData(end));
			  sessionStorage.setItem('picker_selected_overtime', 'true');
			  setStartDate(start);
			  setEndDate(end);
			  setPage(1)
			 
			} else {
			  sessionStorage.removeItem('sessionStartDateOvertime');
			  sessionStorage.removeItem('sessionEndDateOvertime');
			  sessionStorage.removeItem('picker_selected_overtime');
			  setStartDate(null);
			  setEndDate(null);
			  setPage(1);
			
			}
			setSelectedDateRange(dates);
		  };

const handleDownload = (event) => {
	event.preventDefault(); // Prevents the default form submission behavior
	let startdate = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
	let enddate = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
	if (startdate === "Invalid date" || enddate === "Invalid date") {
		startdate = ""; // Nilai default
		enddate = ""; // Nilai default
	}
	window.open(`${process.env.REACT_APP_HRIS_URL}dash-overtime-report?startDate=${startdate}&endDate=${enddate}&companyCode=${company}&branchCode=${branchCode}&filterColumn=full_name&filter=${search}`);
};

	  const RoleAccessOvertimeTransactionCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeTransactionCreate'));
	  const RoleAccessOvertimeTransactionDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeTransactionDownload'));
    return (
        <>
            <div className="title-section">
				Overtime Transaction
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
								<RangePicker
									size={"large"}
									
									onChange={handleDateChange}
									value={selectedDateRange}
									allowClear={true}
									format="YYYY-MM-DD"
								/>
								<InputWithIcon
									type="text"
									placeholder="Search Employee Name"
									img={iconSearch}
									className="search-input btn-sh-sec w-[240px]"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>
							{RoleAccessOvertimeTransactionDownload && (
								<Button size={"large"}  type="primary" 
									onClick={handleDownload}
								>Download</Button>
							)}
							
							{RoleAccessOvertimeTransactionCreate && (
								<Button className="btn-sh-p" type="primary" shape="circle"  onClick={() => setIsOpen(!isOpen)} icon={<PlusOutlined />} size={"large"} />
							)}

								{isOpen && (
									<div className="absolute  z-10 right-3 mt-12 w-48 bg-white rounded shadow ">
									<div className="px-4 py-3" role="none">
										<p className="text-lg text-gray-900" role="none">
										<Link to={'/overtime-transaction/add-overtime-transaction'}> + Add Overtime</Link>
										</p>
										<p className="text-lg text-gray-900" role="none">
										<Link to={'/overtime-transaction/add-import-overtime-transaction'}> + Import Overtime</Link>
										</p>
									
									</div>
									</div>
								)}
						</Row>
					</Col>
				
				</Row>
				<div className="table-wrapper">
				{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}
				</div>
			</div>
        </>
    )
}