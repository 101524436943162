import { decryptData } from "../helper/cryptojs";
import { master, sso } from "./hris";

export const getApprovalSetting = async (search, limit, offset, page,company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode : companyStore || company !== null ? company : "",
    search_value: search?.length > 0 ? search : undefined,
    search_by : "full_name",
    start:  offset,
    length: limit,
    draw: page,
  };
  return master.post("/approval-setting", payload);
};

export const getApprovalSettingList = async (employeeNumber, multi_company, company_list) => {
  let payload = {
    // companyCode: id,
    employeeNumber : employeeNumber,
    multi_company,
    company_list,
  };
  return master.post("/employee-list-appover", payload, {
    headers :{
      "Content-Type" : "application/json"    
    }
  });
};
export const getApprovalSettingList1 = async (employeeId, multi_company, company_list) => {
  let payload = {
    // companyCode: id,
    employeeId : employeeId,
    multi_company,
    company_list,
  };
  return master.post("/employee-list-appover2 ", payload, {
    headers :{
      "Content-Type" : "application/json"    
    }
  });
};

export const getApprovalSettingDetail = async (id) => {
  let payload = {
    approval_setting_id: id,
  };
  return master.post("/approval-setting-detail", payload);
};

export const getApprovalSettingEmployeeNumber = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
 let payload = {
  companyCode : companyStore || company !== null ? company : "",
 }
// console.log(payload);
 return master.post("/overtime-employee-list-input", payload);
}

export const AddApprovalSetting = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    employeeNumber: data.employeeNumber,
    approval_1:data.approval_1,
    approval_2:data.approval_2,
    approval_3:data.approval_3,
    approval_4:data.approval_4,
    approval_5:data.approval_5,
    created_by : user

  };

  return master.post("/approval-setting-create", payload);
};

export const EditApprovalSetting = async (data,id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    approval_setting_id: id,
    employeeNumber: data.employeeNumber,
    approval_1:data.approval_1,
    approval_2:data.approval_2,
    approval_3:data.approval_3,
    approval_4:data.approval_4,
    approval_5:data.approval_5,
    modified_by : user
  };

  return master.post("/approval-setting-update", payload);
};


export const DeleteApprovalSetting = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
      approval_setting_id: id,
        deleted_by: user
    }
    return master.post("/approval-setting-delete", payload);
}


export const getApprovalSettingRoleList = async (company) => {
  let payload = {
    companyCode : company,
  };
  return sso.post("/getrolelistforinput", payload);
};

export const getApprovalSettingEmployeeRoleList = async (roleId) => {
  let payload = {
    roleId : roleId
  };
  return master.post("/employee-byrole", payload);
};

export const getListDefault = async (company) => {
  let payload = {
    companyCode : company,
  };
  return master.post("/approval-setting-default", payload);
};
export const createDefaultsetting = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode : data.companyCode,
    roleId: data.roleId,
    employeeNumber: data.employeeNumber,
    createdBy: user
  };
  return master.post("/approval-setting-default-create", payload);
};