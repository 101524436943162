import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getSetSchedule = async (company, search, limit, offset, page) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        search_by : "full_name",
        search_value : search.length > 0 ? search : undefined,
        start :offset,
        length :limit,
        draw : page
    }
    
    return master.post("/mshifting-schedule",payload);
}

export const addSetSchedule = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        employeeNumber: data.employeeNumber,
        scheduleData: JSON.stringify(data.scheduleData),
        createdBy: user,
    }

    console.log(payload)
    
    return master.post("/mshifting-schedule-create", payload);
}

export const editSetSchedule = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        employeeNumber: data.employeeNumber,
        scheduleData: JSON.stringify(data.scheduleData),
        modifiedBy: user
    }

    console.log(payload)
    return master.post("/mshifting-schedule-update", payload);
}
export const deleteDatesetSchedule = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        employeeNumber: data.employeeNumber,
        date: data.date,
        deletedBy: user
    }

    console.log(payload)
    return master.post("/mshifting-schedule-delete-date", payload);
}

export const getSetScheduleDetail = async (id) => {
    let payload = {
        employeeNumber: id,
    }
    return master.post("/mshifting-schedule-detail", payload);
}
export const getSetScheduleDetailShow = async (id) => {
    let payload = {
        employeeNumber: id,
    }
    return master.post("/mshifting-schedule-detail-show", payload);
}
export const getListShiftingInputSetSchedule = async (company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
    companyCode : companyStore || company !== null ? company : "",
    }
    return master.post("/mshifting-for-input", payload);
}
export const getListInputNikSetSchedule = async (company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
    companyCode : companyStore || company !== null ? company : "",
    }
    return master.post("/list-employee-for-shift", payload);
}

export const deleteSetSchedule = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        employeeNumber: id,
        deletedBy: user
    }
    return master.post("/mshifting-schedule-delete", payload);
}
export const DownloadSetSchedule = async () => {
    const companyStore = decryptData(localStorage.getItem("DefaultCompanyCode"));
    let payload = {
        companyCode: companyStore,
    }
    return master.get("/mshifting-schedule-download", payload);
}

