import React from "react";
import InputWithIcon, { InputPasswordWithIcon } from "../../component/Form/InputWithIcon";
import userIcon from "../../assets/img/icons/icon-user.svg";
import passwordIcon from "../../assets/img/icons/icon-password.svg";
import { Button, Form as FormAntd} from "antd";

const LoginForm = ({form, onClick}) => {
    const handleKeypress = e => {
      if (e.charCode === 13) {
        form.handleSubmit()
      }
    };
    return (
            <FormAntd onFinish={form.handleSubmit}>
                    <FormAntd.Item className="form-input">
                            <InputWithIcon
                            name="loginIdentifier"
                            placeholder="NIK/Email"
                            value={form.values.loginIdentifier}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            img={userIcon}
                            onKeyPress={handleKeypress}
                            />
                            {form.touched.loginIdentifier && form.errors.loginIdentifier ? (
                            <span className="text-error">{form.errors.loginIdentifier}</span>
                            ) : null}
                    </FormAntd.Item>

                    <FormAntd.Item>
                        <InputPasswordWithIcon
                        name="password"
                        placeholder="******"
                        value={form.values.password}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        img={passwordIcon}
                        onKeyPress={handleKeypress}
                        className="form-input"
                        />
                        {form.touched.password && form.errors.password ? (
                        <span className="text-error">{form.errors.password}</span>
                        ) : null}
                          <div className="flex justify-end text-sky-400">
                                <Button type="link" className="" onClick={onClick} >
                                    Forgot Password ?
                                 </Button>
                         </div>
                    </FormAntd.Item>
                  
            </FormAntd>
    )
}

export default LoginForm
