import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getDetail = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/performance-approval-sequence/detail", payload);
};

export const getApprovalSequenceOptions = async (
  level_id,
  company,
  multi_company,
  company_list
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    level_id,
    multi_company,
    company_list,
  };
  return master.post("/performance-approval-sequence/list-input", payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postApprovalSequenceConfig = async (data, company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    approval_1: data.approval_1,
    approval_2: data.approval_2,
    approval_3: data.approval_3,
    approval_4: data.approval_4,
    approval_5: data.approval_5,
    modified_by: user,
    created_by: user,
    is_multi_company: data.checked,
  };

  return master.post("/performance-approval-sequence/create", payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
