import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";
// import { api_key } from "./login";

export const getEmployeeNearExpired = async (companyCode, limit, offset, page) => {
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  let paylaod = {
    companyCode: companyCode,
    branchCode: branch || branch !== null ? branch  : branchDefault ,
    start: offset,
    length: limit,
    draw: page
  }
  return master.post("/employee-almost-end", paylaod);
}

export const getEmployeeStatus = async (companyCode, startDate, endDate) => {
  let paylaod = {
    companyCode: companyCode
  }
  return master.post("/employee-on-type", paylaod);
}

export const getGenderDiversity = async (companyCode) => {
  let paylaod = {
    companyCode: companyCode,
  }
  return master.post("/employee-gender-diversity", paylaod);
}

export const getEmployeePerBranch = async (companyCode) => {
  let paylaod = {
    companyCode: companyCode,
  }
  return master.post("/employee-per-branch", paylaod);
}

export const getListmountAttendance = async (startDate, endDate,company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    startDate: startDate,
    endDate: endDate,
    filterColumn: "employees.full_name",
    filter: "",
    companyCode: companyStore || company !== null ? company : "",
    start:  0,
    length: 100,
    draw: 1,
  };
  return master.post("/list-mount-attendance", payload);
};
export const getListmountAttendanceGrafik = async (startDate, endDate,company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    startDate: startDate,
    endDate: endDate,
    filterColumn: "employees.full_name",
    filter: "",
    companyCode: companyStore || company !== null ? company : "",
    start:  0,
    length: 100,
    draw: 1,
  };
  return master.post("/list-mount-attendance-grafik", payload);
};



