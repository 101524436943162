import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { getDivisionDetail } from "../../../api/division";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const DivisionDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const departmentList = async () => {
		setLoading(true);
		try {
		  const res = await getDivisionDetail(id)
		  setDetail(res?.data?.data[0]);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            departmentList();
          }
		
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data",
			url: "/division"
		},
		{
			title: "Division",
			url: "/division"
		},
        {
			title: "Division Detail",
			// url: "/division-detail"
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/division')} icon={<ArrowLeftOutlined />} size={"large"} />
				Division Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Department Name</div>
                            <div className="subtitle">{detail?.department_name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Divison Name</div>
                            <div className="subtitle">{detail?.name}</div>
                        </div>
                    </Col>
                    {/* <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Division Code</div>
                            <div className="subtitle">{detail?.division_code || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Department Code</div>
                            <div className="subtitle">{detail?.department_code}</div>
                        </div>
                    </Col> */}
                </Row>
            </div>
        </>
    )
}

export default DivisionDetail