import { Button, Col, message, Modal, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, DeleteFilled, EditFilled ,StopOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deleteRole, getRole } from "../../api/role";
import { decryptData } from "../../helper/cryptojs";

export default function Role() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageRole");
	const sessionOffset = sessionStorage.getItem("sessionOffsetRole");
	const sessionSearch = sessionStorage.getItem("sessionSearchRole");
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const previousPage = useRef(1);
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
	const [flag, setFlag] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));

	const roleList = async () => {
		setLoading(true);
		try {
		  const res = await getRole(company, search, limit, offset)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.totalRecords)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			roleList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageRole");
				sessionStorage.removeItem("sessionSearchRole");
				sessionStorage.removeItem("sessionOffsetRole");
				setPage(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		  }
	}, [limit, offset, search, flag, page]);


	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageRole", page);
		sessionStorage.setItem("sessionOffsetRole", offsetSession);
	},[page])

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            const res = await deleteRole(id);
            message.success({
                content: "Data successfully deleted",
            });
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
                content: error.response.data.error,
            });
            setLoading(false)
        }
    }

	const columns = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			width: "15px",
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/role/detail/${encodeURIComponent("id?" + record.role_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Role Name',
			dataIndex: 'name',
			key: 'name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/role/detail/${encodeURIComponent("id?" + record.role_id)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Action',
			key: 'action',
			width: "50px",
			render: (_,record,index) => {
				return RoleAccessRoleUpdate && RoleAccessRoleDelete ?  (
					<Row justify={"space-between"}>
							<div className="icon-action">
								<DeleteFilled onClick={() => showModal(record.role_id)}/>
							</div>
						
							<div className="icon-action" onClick={() => navigate(`/role/edit-role/${encodeURIComponent("id?" + record.role_id)}`)}>
								<EditFilled />
							</div>
					</Row>
				) : (
					<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
					</Row>
				)
			}
		},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/Role"
		},
		{
			title: "Access Role",
			url: "/Role"
		},
	];

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchRole", e.target.value);
	}

	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const RoleAccessRoleCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeRoleCreate'));
	const RoleAccessRoleUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeRoleUpdate'));
	const RoleAccessRoleDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeRoleDelete'));

    return (
        <>
			<>
			<div className="title-section">
				Access Role
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
				<p>Are you sure to delete this data?</p>
			</Modal>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col  md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
								<InputWithIcon
									type="text"
									placeholder="Search Access Role"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>

							{RoleAccessRoleCreate && (
								<Col style={{display: "flex", justifyContent: "flex-end"}}>
									<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/role/add-role')} icon={<PlusOutlined />} size={"large"} />
								</Col>
							)}
							
						</Row>
					</Col>
				</Row>
				<div className="table-wrapper">
				{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	
				</div>
			</div>
			</>
			
        </>
    )
}