import { Button, Col, Form as FormAntd, Radio, message,Row ,DatePicker,Collapse} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  InputWithLabel,
  RadioGroupWithLabel,
  SelectOptionWithLabel,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AddLeave, AddLeaveBalance, EditLeave, EditLeaveBalance, getGenerateDetailAnnual, getLeaveDetail, getLeaveDropdownGenerate } from "../../api/leave";
import { decryptData, encryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import * as yup from "yup";
import moment from "moment";
import { SettingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import "./styles.scss"

const FormAddLeave = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
  ? decryptData(sessionStorage.getItem("selectCompany"))
  : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const BestpraticeUseEffect = useRef(false);
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState([]);
  const [detailAnnual, setDetailAnnual] = useState([]);
  const [idAnnual, setIdAnnual] = useState([]);
  const [flag, setFlag] = useState(0);
  const [carryOver, SetCarryOver] = useState(false);
  const [carryOverProration, SetCarryOverProration] = useState(false);
  const [dropdown, SetDropdown] = useState([]);
  const valueDataSelect = decryptData(sessionStorage.getItem("dataValue"));
  const valueDataSelectLeave = decryptData(sessionStorage.getItem("dataValueLeaveFirst"));


  const LeaveListDropdown = async () => {
  try {
    const res = await getLeaveDropdownGenerate()
    SetDropdown(res?.data?.data);
  } catch (err) {
    console.log(err);
      }
  };

    useEffect(() => {
      if (!BestpraticeUseEffect.current) {
          BestpraticeUseEffect.current = true;
        }else{
          LeaveListDropdown();
        } 
}, [])

  const LeaveList = async () => {
    setFetching(true);
    try {
      const res = await getLeaveDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setFetching(false);
    }
  };
  const DetailGenerateAnnual = async () => {
    setFetching(true);
    try {
      const res = await getGenerateDetailAnnual(id);
      setDetailAnnual(res?.data?.data);
      setIdAnnual(res?.data?.data?.leave_generate_id);
    } catch (err) {
      console.log(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      if (result && detail?.leave_generate_id !== null) {
        LeaveList();
        DetailGenerateAnnual();
      }else {
        DetailGenerateAnnual();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flag]);

  const validationUser = yup.object().shape({
    name: yup.string().required("Field is required"),
    regulation: yup.string().required("Field is required"),
    leaveType: yup.string().required("Field is required"),
    balanceDeducted: yup.string().required("Field is required"),
    attachment: yup.string().required("Field is required"),
    allowance: yup.string().required("Field is required"),
    ...(detail?.is_annual
      ? {
          leaveBalanceGenerate: yup.string().required("Field is required"),
          effectiveDate: yup.string().required("Field is required"),
          firstEmerge: yup.string().required("Field is required"),
          policyType: yup.string().required("Field is required"),
          isProrate: yup.string().required("Field is required"),
          carryOver: yup.string().required("Field is required"),
          expiredDate: yup.string().required("Field is required"),
        }
      : {}),
      ...(valueDataSelect === 1
        ? {
            carryOverMax: yup.string().required("Field is required"),
            activePeriod: yup.string().required("Field is required"),
          }
        : {}),
      ...(valueDataSelect === 0
        ? {
            expiredDate: yup.string().required("Field is required"),
          }
        : {}),
      ...(valueDataSelectLeave == "first_emerge_status"
        ? {
          firstEmergeRemark: yup.string().required("Field is required"),
          }
        : {}),
});

let isProrateData;

switch (detailAnnual?.is_prorate) {
  case true:
    isProrateData = 1;
    break;
  case false:
    isProrateData = 0;
    break;
  default:
    isProrateData = "";
    break;
}

  const form = useFormik({
    initialValues: {
      companyCode: result ? companyStore : companyStore,
      name: result ? detail?.leave_name : "",
      regulation: result ? detail?.regulation : "",
      leaveType: result ? detail?.leave_type : "",
      leaveBalance: result ? detail?.leave_balance : "",
      balanceDeducted: result
        ? detail?.balance_deducted
        == true ? 1 : 0 : null,
      maxRequest: result ? detail?.max_request : "",
      attachment: result ? detail?.attachment : "",
      allowance: result ? detail?.allowance  == true ? 1 : 0 : null,
      mLeaveId: id,
      leaveBalanceGenerate: result ? detailAnnual?.leave_balance : "",
      effectiveDate: result ? detailAnnual?.effective_date : "",
      policyType: result ? detailAnnual?.policy_type : "",
      firstEmerge: result ? detailAnnual?.first_emerge : "",
      firstEmergeRemark:  result ? detailAnnual?.first_emerge_remark : "",
      isProrate: result ? isProrateData : "",
      carryOver: result ? detailAnnual?.carry_over === true ? 1 : detailAnnual?.carry_over === false ? 0 : null : null,
      carryOverMax: result ? detailAnnual?.carry_over_max : 0,
      hasExpired: result ? detailAnnual?.has_expired  === true ? 1 : 0 : null,
      expiredDate: result ? detailAnnual?.expired_date : "",
      activePeriod: result ? detailAnnual?.active_period : "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,
    onSubmit: async (values) => {
      setFetching(true);
    
      try {
        let res;
    
        if (result) {
          if (detail?.is_annual) {
            const editLeavePromise = EditLeave(values, id, companyStore);
    
            const leaveBalancePromise = detail?.leave_generate_id
              ? EditLeaveBalance(values, idAnnual)
              : AddLeaveBalance(values);
    
            const [editLeaveResult, leaveBalanceResult] = await Promise.all([
              editLeavePromise,
              leaveBalancePromise
            ]);
    
            if (editLeaveResult.data.success && leaveBalanceResult.data.success) {
              handleSuccessMessage();
            } else {
              res = !editLeaveResult.data.success ? editLeaveResult : leaveBalanceResult;
              handleErrorMessage(res);
            }
          } else {
            res = await EditLeave(values, id, companyStore);
            handleSuccessMessage();
          }
        } else {
          res = await AddLeave(values);
    
          if (res?.[1]?.data?.success || res?.data?.success) {
            handleSuccessMessage();
          } else {
            handleErrorMessage(res);
          }
        }
    
        setFetching(false);
      } catch (error) {
        handleErrorMessage();
      }
    }
  });
    
    const handleSuccessMessage = () => {
      if (result) {
        message.success("Leave successfully updated");
      } else {
        message.success("Leave successfully saved");
      }
    
      sessionStorage.removeItem('dataValue');
      navigate("/leave");
      form.resetForm();
      setFlag((prev) => prev + 1);
    }
    
    const handleErrorMessage = (res) => {
      message.error({
        content: res?.[1]?.data?.error || res?.data?.error,
      });
      setFetching(false);
    }
    

  const handleRadioChangeProration = (e) => {
    SetCarryOverProration(e === 1);
};
  const handleRadioChangeOvertimeRateType = (e) => {
    SetCarryOver(e === 1);
};



const items = Array.from({ length: 12 }, (_, index) => {
  const monthIndex = index + 1;
  const date = moment().month(monthIndex - 1).format('YYYY-MM-01');

  return {
    value: date,
    title: moment().month(monthIndex - 1).format('MMMM'),
  };
});


const getDropdownOptions = (data) =>
  data
    ? Object.entries(data).map(([value, title]) => ({
        value,
        title: title === 'Annualy' ? 'Yearly' : title,
      }))
    : [];


  const dataBreadcrumb = [
    {
      title: "Master Data",
      url: "/leave",
    },
    {
      title: "Leave",
      url: "/leave",
    },
    {
      title: `${result ? "Edit Leave" : "Add Leave"}`,
    },
  ];
  

  return (
    <>
      <div className="title-section">{result ? "Edit Leave" : "Add Leave"}</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
     
      <div className="add-wrapper">
     
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <InputWithLabel
              name="name"
              label={
                <div>
                  Leave Name<span style={{ color: "#FF0000" }}> *</span>
                </div>
              }
              placeholder="Leave Name"
              value={form.values.name}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {form.touched.name && form.errors.name ? (
              <span className="text-error">{form.errors.name}
              </span>
            ) : null}
          </FormAntd.Item>
          <FormAntd.Item>
            <SelectOptionWithLabel
              name="regulation"
              label={
                <div>
                  Regulation <span style={{ color: "#FF0000" }}> *</span>
                </div>
              }
              items={[
                {
                  value: "Paid",
                  title: "PAID",
                },
                {
                  value: "Unpaid",
                  title: "UNPAID",
                },
              ]}
              value={form?.values?.regulation ? form?.values?.regulation : null}
              onChange={(val) =>
                form.setValues({
                  ...form.values,
                  regulation: val,
                })
              }
              onBlur={form.handleBlur}
            />
             {form.touched.regulation && form.errors.regulation ? (
              <span className="text-error relative top-2">{form.errors.regulation}
              </span>
            ) : null}
            
          </FormAntd.Item>
          <FormAntd.Item>
            <SelectOptionWithLabel
              name="leave_type"
              label={
                <div>
                  Leave Type <span style={{ color: "#FF0000" }}> *</span>
                </div>
              }
              items={[
                {
                  value: "Workdays",
                  title: "Workdays",
                },
                {
                  value: "Days",
                  title: "Days",
                },
              ]}
              value={form?.values?.leaveType ? form?.values?.leaveType : null}
              onChange={(val) =>
                form.setValues({
                  ...form.values,
                  leaveType: val,
                })
              }
              onBlur={form.handleBlur}
            />
             {form.touched.leaveType && form.errors.leaveType ? (
              <span className="text-error relative top-2">{form.errors.leaveType}
              </span>
            ) : null}
          </FormAntd.Item>
          {detail?.is_annual === true ?  (
         <></>
           ): <FormAntd.Item>
           <InputWithLabel
             name="leaveBalance"
             label={<div>Leave Balance</div>}
             placeholder="Leave Balance"
             value={form?.values?.leaveBalance}
             onChange={(e) => {
               const regex = e.target.value;
               const reg = /^[0-9\b]+$/;
               if (
                regex === "" ||
                (reg.test(regex) &&
                  parseInt(regex) >= 1 &&
                  parseInt(regex) <= 99)
              ) {
                 form.handleChange(e);
               }
             }}
             onBlur={form.handleBlur}
           />
         </FormAntd.Item>}
          <FormAntd.Item>
            <InputWithLabel
              name="maxRequest"
              label={<div>Maximal Request</div>}
              placeholder="Maximal Request"
              value={form?.values?.maxRequest}
              onChange={(e) => {
                const regex = e.target.value;
                const reg = /^[0-9\b]+$/;
                if (
                  regex === "" ||
                  (reg.test(regex) &&
                    parseInt(regex) >= 1 &&
                    parseInt(regex) <= 99)
                ) {
                  form.handleChange(e);
                }
              }}
              onBlur={form.handleBlur}
            />
          </FormAntd.Item>
          <FormAntd.Item>
            <SelectOptionWithLabel
              name="attachment"
              label={
                <div>
                  Attachment <span style={{ color: "#FF0000" }}> *</span>
                </div>
              }
              items={[
                {
                  value: "No",
                  title: "Optional",
                },
                {
                  value: "Yes",
                  title: "Mandatory",
                },
              ]}
              value={form?.values?.attachment ? form?.values?.attachment : null}
              onChange={(val) =>
                form.setValues({
                  ...form.values,
                  attachment: val,
                })
              }
              onBlur={form.handleBlur}
            />
            {form.touched.attachment && form.errors.attachment ? (
              <span className="text-error relative top-2">{form.errors.attachment}
              </span>
            ) : null}
          </FormAntd.Item>
          <FormAntd.Item>
            <SelectOptionWithLabel
              name="Allowance"
              label={
                <div>
                  Allowance <span style={{ color: "#FF0000" }}> *</span>
                </div>
              }
              items={[
                {
                  value: 1,
                  title: "Yes",
                },
                {
                  value: 0,
                  title: "No",
                },
              ]}
            value={form?.values?.allowance}
            onChange={(val) =>
              form.setValues({
                ...form.values,
                allowance: val,
              })
            }
            onBlur={form.handleBlur}
            />
            {form.touched.allowance && form.errors.allowance ? (
              <span className="text-error relative top-2">{form.errors.allowance}
              </span>
            ) : null}
          </FormAntd.Item>
          <FormAntd.Item>
            <RadioGroupWithLabel
              name="balanceDeducted"
              label={
                <div>
                  Leave Balance Deducted
                  <span style={{ color: "#FF0000" }}> *</span>
                </div>
              }
              placeholder="Leave Balance Deducted"
              rows={4}
              onBlur={form.handleBlur}
              value={form?.values?.balanceDeducted}
              maxLength={500}
              onChange={form.handleChange}>
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </RadioGroupWithLabel>
            {form.touched.balanceDeducted && form.errors.balanceDeducted ? (
              <span className="text-error">{form.errors.balanceDeducted}
              </span>
            ) : null}
          </FormAntd.Item>
        </FormAntd>
        
        {detail?.is_annual && (
            <div className="mt-10">
                <Collapse  expandIcon={() => null}>
                <Collapse.Panel header={
                        <div>
                            <SettingOutlined style={{ marginRight: '8px' }} />
                            Annual Leave Setting
                        </div>
                    }
                    key="1"
                >
                <>
            
        <div className="add-wrapper">
        <FormAntd
                onFinish={form.handleSubmit}
            >
        <FormAntd.Item>
        <Row justify={"space-between"}>
        <div className="title-section mt-[-20px]">
				{"Generate Annual Leave"}
			</div>
                <Col span={24}>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="leaveBalanceGenerate"
                            label={<div>Leave Balance
                              <span style={{ color: "#FF0000" }}> *</span>
                            </div>} 
                            placeholder="Leave Balance"
                            value={form.values.leaveBalanceGenerate ? form.values.leaveBalanceGenerate : null  }
                            onChange={ (e) => {
                                const regex = e.target.value;
                                const reg = /^[0-9\b]+$/;
                                if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                    form.handleChange(e)
                                }
                            }}
                            maxLength={2}
                            minLength={0}                                
                        />
                        {form.touched.leaveBalanceGenerate && form.errors.leaveBalanceGenerate ? (
                            <span className="text-error">{form.errors.leaveBalanceGenerate}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
        </Row>
        <Row justify={"space-between"}>
            <Col span={11}>
                    <FormAntd.Item>
                    <SelectOptionWithLabel
                        label={<div>Policy Type
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>}
                        placeholder={'Reset Policy'}
                        items={getDropdownOptions(dropdown.leave_policy_type)}
                        value={form?.values?.policyType || null}
                        onChange={(val) =>
                            form.setValues({
                                ...form.values,
                                policyType: val,
                            })
                        }
                    />
                        {form.touched.policyType && form.errors.policyType ? (
                            <span className="text-error">{form.errors.policyType}</span>
                        ) : null}                               
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                        <FormAntd.Item>
                   <div className="text-slate-500">{"Effective As Of"}<span style={{ color: "#FF0000" }}> *</span></div>
                                <DatePicker
                                name="effectiveDate"
                                format="DD-MM-YYYY"
                                placeholder="dd/mm/yyyy"
                                allowClear={true}
                                value={form.values.effectiveDate ? dayjs(form.values.effectiveDate, 'YYYY-MM-DD') : null}
                                onChange={date => {
                                  const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                                  form.setFieldValue('effectiveDate', formattedDate);
                                }}
                                className="w-full h-[45px] bg-[#f8fafb] border-none mt-2"
                              />
                  {form.touched.effectiveDate && form.errors.effectiveDate ? (
                    <span className="text-error">{form.errors.effectiveDate}</span>
                  ) : null}
                </FormAntd.Item>

                  </Col>
                <Col span={11}>
                    <FormAntd.Item>
                    <SelectOptionWithLabel
                    label={<div>Leave First Emerge
                      <span style={{ color: "#FF0000" }}> *</span>
                    </div>} 
                    placeholder={'Select Option'}
                    items={getDropdownOptions(dropdown.leave_first_emerge)}
                    value={form?.values?.firstEmerge || null}
                   onChange={(val) => {
                     form.setValues({
                       ...form.values,
                       firstEmerge: val,
                       firstEmergeRemark: '',
                     });                   
                     sessionStorage.setItem('dataValueLeaveFirst', encryptData(val));
                   }}
                />
                        {form.touched.firstEmerge && form.errors.firstEmerge ? (
                            <span className="text-error">{form.errors.firstEmerge}</span>
                        ) : null} 
                        

                         {form.values.firstEmerge === "first_emerge_status" ? (
                            <Col span={11}>
                             <SelectOptionWithLabel
                             placeholder={'Permanent / Contract'}
                             items={getDropdownOptions(dropdown.employee_status)}
                             value={ form?.values?.firstEmergeRemark ? form?.values?.firstEmergeRemark : null }
                             onChange={(val) =>
                                 form.setValues({
                                 ...form.values,
                                 firstEmergeRemark: val,
                                 })
                             } 
                            className="mt-4"                                    
                         />
                         {form.touched.firstEmergeRemark && form.errors.firstEmergeRemark ? (
                            <span className="text-error">{form.errors.firstEmergeRemark}</span>
                        ) : null}    
                        </Col>                    
                        ) : null}       
                    </FormAntd.Item>
                    <FormAntd.Item>
                    <div>
                    <RadioGroupWithLabel
                                name="isProrate"
                                label={<div>New Employee Proration
                                  <span style={{ color: "#FF0000" }}> *</span>
                                </div>} 
                                placeholder="Rate Type"
                                rows={4}
                                onBlur={form.handleBlur}
                                maxLength={500}
                                onChange={(val) => {
                                        form.setValues({
                                            ...form.values,
                                            isProrate: val.target.value,
                                        })
                                        handleRadioChangeProration(val.target.value)
                                    }
                                }
                                value={form?.values?.isProrate}
                            >
                        <div>
                        <Radio  value={1}>On First Entitlement</Radio>
                        </div>
                        <div>
                        <Radio value={0}>No Proration</Radio>
                        </div>
                            </RadioGroupWithLabel>
                    </div>
                    {form.touched.isProrate && form.errors.isProrate ? (
                            <span className="text-error">{form.errors.isProrate}</span>
                        ) : null}  
                    </FormAntd.Item>
                </Col>
                      <Col span={11}>
                    <FormAntd.Item>
                        <div>
                            <div className="text-slate-500">{"Expired Leave"}<span style={{ color: "#FF0000" }}> *</span></div>
                            <DatePicker
                            name="expiredDate"
                            format="DD-MM"
                            placeholder="dd/mm"
                            allowClear={true}
                            value={form.values.expiredDate ? dayjs(form.values.expiredDate, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('expiredDate', formattedDate);
                            }}
                            className="w-full h-[45px] bg-[#f8fafb] border-none mt-2"
                          />
                      {form.touched.expiredDate && form.errors.expiredDate ? (
                        <span className="text-error">{form.errors.expiredDate}</span>
                        ) : null} 
                          </div>
                    </FormAntd.Item>
                </Col>
            </Row>
            <FormAntd.Item>
                  <div>
                    <div className="text-lg text-slate-500">Carry Over Balance  <span style={{ color: "#FF0000" }}> *</span></div>
                    <div className="ml-0 text-sm text-slate-400">
                    {'This setting allows employee carry unused leave days from one period to the next'}
                    </div>
                    <RadioGroupWithLabel
                                name="carryOver"
                                placeholder="Rate Type"
                                rows={4}
                                onBlur={form.handleBlur}
                                maxLength={500}
                                onChange={(val) => {
                                        form.setValues({
                                            ...form.values,
                                            carryOver: val.target.value,
                                        })
                                        handleRadioChangeOvertimeRateType(val.target.value);
                                        sessionStorage.setItem('dataValue', encryptData(val.target.value));
                                    }
                                }
                                value={result ? form.values.carryOver : null}
                              
                            >
                                <Radio value={1}>Yes</Radio>
                                <Radio value={0}>No</Radio>
                     </RadioGroupWithLabel>
                    {form.touched.carryOver && form.errors.carryOver ? (
                            <span className="text-error">{form.errors.carryOver}</span>
                        ) : null}  
                    </div>
               
                  
              </FormAntd.Item>
              <div>
              {form.values.carryOver === 1 && (
                    <div className="mt-9">
                        <FormAntd.Item>
                          <Row justify={"space-between"}>
                                <Col span={12}>
                                    <FormAntd.Item>
                                      <div>
                                        <InputWithLabel
                                          className="w-full sm:w-full md:w-[499px] lg:w-[499px] xl:w-[499px]"
                                            name="carryOverMax"
                                            label={<div className="text-slate-500">Maximum Balance To Be Carry Over
                                              <span style={{ color: "#FF0000" }}> *</span>
                                            </div>} 
                                            placeholder="Maximum Balance To Be Carry Over"
                                            value={  form.values.carryOverMax ? form.values.carryOverMax : ''  }
                                            onChange={ (e) => {
                                                const regex = e.target.value;
                                                const reg = /^[0-9\b]+$/;
                                                if (regex === '' || reg.test(regex) && parseInt(regex) <= 60) {
                                                    form.handleChange(e)
                                                }
                                            }}
                                            maxLength={2}
                                            minLength={0}     
                                            addonAfter="Days"                          
                                        />
                                        
                                      {form.touched.carryOverMax && form.errors.carryOverMax ? (
                                      <span className="text-error">{form.errors.carryOverMax}</span>
                                      ) : null} 
                                        </div>
                                    </FormAntd.Item>
                                </Col>
                        </Row>
                          <Row justify={"space-between"}>
                                <Col span={11}>
                                    <div>
                                <FormAntd.Item>
                                <Row justify={"space-between"}>
                                <Col span={11}>
                                <FormAntd.Item>
                                    <div>
                                        <div  className="w-full sm:w-[240px] md:w-[499px] lg:w-[499px] xl:w-[499px]">
                                        <SelectOptionWithLabel
                                            name="activePeriod"
                                           
                                            label={
                                              <div>
                                                Active Period <span style={{ color: "#FF0000" }}> *</span>
                                              </div>
                                            }
                                            items={items}
                                            value={form?.values?.activePeriod ? form?.values?.activePeriod : null}
                                            onChange={(val) =>
                                              form.setValues({
                                                ...form.values,
                                                activePeriod: val,
                                              })
                                            }
                                            onBlur={form.handleBlur}
                                          />
                                        <div className="bg-[#CCE6FF] h-[40px] w-full md:w-[58px] rounded-r-lg relative top-[-32px] right-[-163px] md:right-[-445px] sm:right-[-185px] sm:w-[60px] text-[#94A3B2] " style={{ border:'1px solid #D9D9D9',borderLeft:'none'}}>
                                          <div className="pt-4 ml-2 mt-[-6px] text-black " style={{ position: "absolute" }}>
                                          Month
                                          </div>
                                          </div>
                                         </div>
                                    {form.touched.activePeriod && form.errors.activePeriod ? (
                                    <span className="text-error">{form.errors.activePeriod}</span>
                                    ) : null} 
                                    </div>
                                </FormAntd.Item>
                    </Col>
                        </Row>
                         
                        </FormAntd.Item>    
                    </div>
                                </Col>
                        </Row>
                        </FormAntd.Item>    
                    </div>
                        )}
                </div>
            </FormAntd.Item>
            </FormAntd>
        </div>
        </>
            </Collapse.Panel>
            </Collapse>
                </div>
            )}
        <div className="footer-add">
        <Button
          key="back"
          className="btn btn-sec"
          style={{ marginRight: 14 }}
          onClick={() => {
            navigate("/leave");
            sessionStorage.removeItem('dataValue');
          }}
        >
          Cancel
        </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddLeave;
