import { Button, Col, message, Modal, Pagination, Row, Select, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined,EditFilled,StopOutlined} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { decryptData } from "../../helper/cryptojs";
import {decode} from 'html-entities';

export default function EmployeeTransfer() {
	const navigate = useNavigate();
	const limitSession = sessionStorage.getItem("sessionPageEmployee");
	const activeSession = sessionStorage.getItem("sessionFilterActive");
	const sessionSearch = sessionStorage.getItem("sessionSearchEmployee");
	const sessionOffset = sessionStorage.getItem("sessionOffsetEmployee");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
	const [debounceSearch, setDebounceSearch] = useState(sessionSearch ? sessionSearch : "")
	const [flag, setFlag] = useState(1);
	const [active, setActive] = useState(activeSession ? activeSession : "active")
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState();
	const BestpraticeUseEffect = useRef(false)
	const [isOpen, setIsOpen] = useState(false);
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const branch = decryptData(sessionStorage.getItem("selectBranch"));
	const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  	const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
	const branchCode = branchSession || branch !== null ? branch  : branchDefault
	const employeeList = async () => {
		setLoading(true);
		try {
		//   const res = await getEmployee(debounceSearch, limit, offset, page,company,active)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			employeeList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageEmployee");
				sessionStorage.removeItem("sessionFilterActive");
				sessionStorage.removeItem("sessionSearchEmployee");
				sessionStorage.removeItem("sessionOffsetEmployee");
				setPage(1)
				setActive("active")
				setSearch("")
				setOffset(0)
			  };
			  window.addEventListener("beforeunload", beforeUnloadHandler);
			  return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			  };
		  }
	}, [debounceSearch, limit, offset, page, flag, company, active]);

	useEffect(() => {
		if(search.length > 2 || search.length === 0) {
			const timeoutId = setTimeout(() => {
				setDebounceSearch(search)
			}, 500)
			return () => clearTimeout(timeoutId);
		}
	}, [search])

	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageEmployee", page);
		sessionStorage.setItem("sessionOffsetEmployee", offsetSession);
	},[page])


	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist; 
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text}
				</div>
			)
		}
	},
	{
		title: 'NIK',
		dataIndex: 'employee_number',
		key: 'employee_number',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text}
				</div>
			)
		}
	},
	{
		title: 'Employee Name',
		dataIndex: 'full_name',
		key: 'full_name',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{decode(text)}
				</div>
			)
		}
	},
	{
		title: 'Branch',
		dataIndex: 'branch',
		key: 'branch',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{decode(text) || "-"}
				</div>
			)
		}
	},
	{
		title: 'Created Date',
		dataIndex: 'created_date',
		key: 'created_date',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text || "-"}
				</div>
			)
		}
	},
	{
		title: 'Effective Date',
		dataIndex: 'effective_date',
		key: 'effective_date',
		render: (text,record) =>{
			const isInActiveBacklist = record.in_active_backlist;
			return (
				<div className={`detail-btn-wp ${isInActiveBacklist === true ? 'text-rose-500' : ''}`}  onClick={() => navigate(`/employee/detail/${record.employee_number}`)}>
					{text || "-"}
				</div>
			)
		}
	},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/employee"
		},
		{
			title: "Employee",
			url: "/employee"
		},
		{
			title: "Employee Transfer",
			url: "/employee/employee-transfer"
		},
	]

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchEmployee", e.target.value)
	}

	const dataFilter = [
		{
			value : "active",
			title : "Active"
		},
		{
			value : "inactive",
			title : "Inactive"
		}
	]

	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const handleDownload = () => {
		const filter = active === 'active' ? 1 : 0
		window.open(`${process.env.REACT_APP_HRIS_URL}download-employee-report?companyCode=${company}&branchCode=${branchCode}&filter=${filter}&search=${search}`, '_blank');
	};

	  const RoleAccessEmployeeCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeCreate'));
	  const RoleAccessEmployeeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeUpdate'));
	  const RoleAccessEmployeeDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeDownload'));
    return (
        <>
		<>
		<div className="title-section">
				Employee Transfer
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col md={{top:20}}>
						<Row justify={"end"} style={{gap:12}} md={14}>
								<InputWithIcon
									type="text"
									placeholder="Search Employee Transfer"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>

								<>
								<Button size={"large"}  type="primary" onClick={() => navigate('/employee/employee-transfer/import-employee-transfer')} >Import</Button>
								{RoleAccessEmployeeDownload && (
									<Button size={"large"}  type="primary" onClick={handleDownload} >Download</Button>
								)}
								{RoleAccessEmployeeCreate && (
									<Button className="btn-sh-p" type="primary" shape="circle"  onClick={() => setIsOpen(!isOpen)} icon={<PlusOutlined />} size={"large"} />
								)}
								{isOpen && (
									<div className="absolute  z-10 right-3 mt-12 w-48 bg-white rounded shadow  ">
									<div className="px-4 py-3" role="none">
										<p className="text-lg text-gray-900" role="none">
										<Link to={'/employee/employee-transfer/create-employee-transfer'}> + Create Transfer</Link>
										</p>									
									</div>
									</div>
								)}	
								</>
						</Row>
					</Col>
				
				</Row>

				<div className="table-wrapper">
				{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	

				</div>
			</div>
		</>		
            
        </>
    )
}