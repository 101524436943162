/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, DatePicker, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import { decryptData, encryptData } from "../../helper/cryptojs";
import dayjs from "dayjs";
import "./styles.scss";
import { getListHistoryFakeGpsReport } from "../../api/historyFakeGpsReport";
const { RangePicker } = DatePicker;
export default function HistoryFakeGpsReport() {
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const RoleAccessHistoryFakeGpsReportDownload = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeReportHistoryFakeGpsDownload")
  );
  const limitSession = sessionStorage.getItem(
    "sessionPageHistoryFakeGpsReport"
  );
  const offsetSession = sessionStorage.getItem(
    "sessionOffsetHistoryFakeGpsReport"
  );
  const startDateSession = decryptData(
    sessionStorage.getItem("sessionStarDateHistoryFakeGpsReport")
  );
  const endDateSession = decryptData(
    sessionStorage.getItem("sessionEndDateHistoryFakeGpsReport")
  );
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? limitSession : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [startDate, setStartDate] = useState(
    startDateSession ? startDateSession : "" || ""
  );
  const [endDate, setEndDate] = useState(
    endDateSession ? endDateSession : "" || ""
  );
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);

  const HistoryFakeGpsReport = async () => {
    setLoading(true);
    try {
      const res = await getListHistoryFakeGpsReport(
        company,
        startDate,
        endDate,
        offset,
        limit,
        page
      );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageHistoryFakeGpsReport", page);
  }, [page]);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      HistoryFakeGpsReport();

      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageHistoryFakeGpsReport");
        sessionStorage.removeItem("sessionOffsetHistoryFakeGpsReport");
        sessionStorage.removeItem("sessionDateHistoryFakeGpsReport");
        sessionStorage.removeItem("sessionStarDateHistoryFakeGpsReport");
        sessionStorage.removeItem("sessionEndDateHistoryFakeGpsReport");
        sessionStorage.removeItem("picker_selected_history_fake_gps_report");
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, startDate, endDate, offset, limit, page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem(
      "sessionPageHistoryFakeGpsReport"
    );
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    let offset = 0;
    if (page > 1) {
      offset = page * limit - 10;
    }
    setOffset(offset);
    sessionStorage.setItem("sessionPageHistoryFakeGpsReport", page);
    sessionStorage.setItem("sessionOffsetHistoryFakeGpsReport", offset);
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text) => {
        return <div className="detail-btn-wp ">{text || "-"}</div>;
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Position",
      dataIndex: "position_name",
      key: "position_name",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Detection Status",
      dataIndex: "detection_status",
      key: "detection_status",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },

    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
  ];

  const dataBreadcrumb = [
    {
      title: "Report",
      url: "/fake-gps-history-report",
    },
    {
      title: "History Fake GPS Report",
      url: ``,
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageHistoryFakeGpsReport", pageNumber);
    sessionStorage.setItem("sessionOffsetHistoryFakeGpsReport", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  useEffect(() => {
    const startDate = decryptData(
      sessionStorage.getItem("sessionStarDateHistoryFakeGpsReport")
    );
    const endDate = decryptData(
      sessionStorage.getItem("sessionEndDateHistoryFakeGpsReport")
    );
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
      setSelectedDateRange([dayjs(startDate), dayjs(endDate)]);
    }
  }, []);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const start = dates[0].startOf("day").format("YYYY-MM-DD");
      const end = dates[1].endOf("day").format("YYYY-MM-DD");

      const currentDate = dayjs();
      const maxStartDate = currentDate.subtract(4, "months");
      if (dayjs(start).isBefore(maxStartDate)) {
        message.error(
          "The start date cannot be more than 3 months in the past."
        );
        return;
      }
      sessionStorage.setItem(
        "sessionStarDateHistoryFakeGpsReport",
        encryptData(start)
      );
      sessionStorage.setItem(
        "sessionEndDateHistoryFakeGpsReport",
        encryptData(end)
      );
      sessionStorage.setItem("picker_selected_time_request", "true");
      setStartDate(start);
      setEndDate(end);
      setPage(1);
    } else {
      sessionStorage.removeItem("sessionStarDateHistoryFakeGpsReport");
      sessionStorage.removeItem("sessionEndDateHistoryFakeGpsReport");
      sessionStorage.removeItem("picker_selected_time_request");
      setStartDate("");
      setEndDate("");
      setPage(1);
    }
    setSelectedDateRange(dates);
  };

  const disabledDate = (current) => {
    const currentDate = dayjs();
    const maxStartDate = currentDate.subtract(3, "months").startOf("month");

    return current && current.isBefore(maxStartDate, "day");
  };

  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}download-fake-gps?companyCode=${company}&startDate=${startDate}&endDate=${endDate}`;
  };

  return (
    <>
      <div className="title-section">History Fake GPS Report</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              <RangePicker
                size={"large"}
                onChange={handleDateChange}
                value={selectedDateRange}
                allowClear={true}
                format="YYYY-MM-DD"
                disabledDate={disabledDate}
              />
              {RoleAccessHistoryFakeGpsReportDownload && (
                <Button size={"large"} type="primary" onClick={handleDownload}>
                  Download
                </Button>
              )}
            </Row>
          </Col>
        </Row>
        {loading ? (
          <>
            <div className="table-wrapper-bpjs">
              <Table id={"my-table"} columns={columns} loading={loading} />
            </div>
            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
              <Pagination
                defaultCurrent={page}
                current={page}
                total={totalData}
                onChange={onChangeTable}
                showSizeChanger={false}
                onShowSizeChange={onShowSizeChange}
              />
            </div>
          </>
        ) : (
          <>
            <div className="table-wrapper-bpjs">
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
            </div>
            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
              <Pagination
                defaultCurrent={page}
                current={page}
                total={totalData}
                onChange={onChangeTable}
                onShowSizeChange={onShowSizeChange}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
