import { Button, Row, Col, Form as FormAntd, message, Upload, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SelectOptionWithLabelPeriode } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import { CloudUploadOutlined, FileExcelOutlined } from "@ant-design/icons";
import { uploadPayroll } from "../../api/payslip";
import { getCompanyDetail } from "../../api/company";
import Link from "antd/es/typography/Link";

const { Dragger } = Upload;
const FormAddGeneratePayslip = () => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const [companyDetail, setCompanyDetail] = useState([]);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const companyId = decryptData(sessionStorage.getItem("selectCompanyId"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
  const companyData = async () => {
    const res = await getCompanyDetail(companyId)
    setCompanyDetail(res?.data?.data);
  };
  useEffect(() => {
    companyData()
  }, [])
  const validationUser = yup.object().shape({
    doc_file: yup.string().required("Field is required"),
    month: yup.string().required("Field is required"),
  });
  const form = useFormik({
    initialValues: {
      doc_file: null,
      company_code: company,
      month: "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,
    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = await uploadPayroll(values);
        if (res.data.status !== 400) {
          Modal.success({
            title: "",
            content: (
              <div className="text-center">
                <h2>Generate Payslip Success</h2>
                <p>Please check the results in the table</p>
              </div>
            ),
            onOk: navigate("/generate-payslip"),
          });
          navigate("/generate-payslip");
          form.resetForm();
        } else if (res.data.status !== 200) {
          message.error({
            content: res.data.message ?? "Generate payslip failed",
          });
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Request Not Found",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/generate-payslip",
    },
    {
      title: "Generate Payslip",
      url: "/generate-payslip",
    },
    {
      title: "Execute Generate Payslip",
    },
  ];

  const handleFileUpload = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      // form.setFieldValue("doc_file", info.file);
      if (info.file.size <= 12582912) {
        form.setFieldValue("doc_file", info.file);
      } else {
        console.log("File size exceeds 12MB limit");
        message.error("File size exceeds 12MB limit");
      }
    } if (status === "removed") {
      form.setFieldValue("doc_file", null);
    };
  }

  return (
    <>
      <div className="title-section">Execute Generate Payslip</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <div className="text-center text-stone-500">
            <h1>Generate Payslip</h1>
            <p className="text-gray-400">
              Select relevant documents to Generate Payslip
            </p>
          </div>
          <div>
              <Row align="bottom">
                <Col span={6} className="text-center">
                  <h4>Download Template</h4>
                  <FileExcelOutlined style={{ fontSize: "4em" }} />
                </Col>
                <Col span={18}>
                  <p className="text-gray-400">
                    Generate payroll template
                  </p>
                  <Link href={`${process.env.REACT_APP_HRIS_URL}template-generate-payroll?companyCode=${company}`}>
                    Download the following template and please fill in the data
                  </Link>
                </Col>
              </Row>
          </div>
          <div className="flex justify-end">
            <SelectOptionWithLabelPeriode
              style={{ width: 200 }}
              name="period"
              items={[
                {
                  value: "01",
                  title: "January",
                },
                {
                  value: "02",
                  title: "February",
                },
                {
                  value: "03",
                  title: "March",
                },
                {
                  value: "04",
                  title: "April",
                },
                {
                  value: "05",
                  title: "May",
                },
                {
                  value: "06",
                  title: "June",
                },
                {
                  value: "07",
                  title: "July",
                },
                {
                  value: "08",
                  title: "August",
                },
                {
                  value: "09",
                  title: "September",
                },
                {
                  value: "10",
                  title: "October",
                },
                {
                  value: "11",
                  title: "November",
                },
                {
                  value: "12",
                  title: "December",
                },
              ]}
              value={form?.values?.month ? form?.values?.month : null}
              onChange={(val) =>
                form.setValues({
                  ...form.values,
                  month: val,
                })
              }
              onBlur={form.handleBlur}
              placeholder="Select Periode"
            />
          </div>
          {form.touched.month && form.errors.month ? (
            <div className="flex justify-end mt-3">
              <span className="text-error">{form.errors.month}</span>
            </div>
          ) : null}
          <div className="mt-5">
            <Dragger
              beforeUpload={() => false}
              onChange={handleFileUpload}
              accept=".xlsx, .xls"
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                Select a file or drag and drop here{" "}
              </p>
              <p className="ant-upload-hint">XLSX, file size no more 12MB </p>
            </Dragger>
            {form.touched.doc_file && form.errors.doc_file ? (
              <span className="text-error">{form.errors.doc_file}</span>
            ) : null}
          </div>
        </FormAntd>
        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/generate-payslip")}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddGeneratePayslip;
