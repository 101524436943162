import { Button, Col, Form as FormAntd, Row,DatePicker,Collapse} from "antd";
import { useEffect, useState } from "react";
import  {  InputWithLabel, SelectOptionWithLabel ,NumberFormat, CheckboxOptionWithLabel} from "../../../component/Form/Input";
import dayjs from "dayjs";
import { insuranceInputList } from "../../../api/employee";
import { decryptData } from "../../../helper/cryptojs";
import { SettingOutlined } from "@ant-design/icons";



const PayrollForm = ({form, handleBack, fetching}) => {
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [detail,setDetail] = useState([]);
    const InputListInsurance  = async () => {
        const res = await insuranceInputList(company)
        setDetail(res?.data?.data);
    }

    useEffect(() => {
        InputListInsurance()
    }, [])

const items = Array.from({ length: 11 }, (_, index) => ({
    value: index,
    title: index.toString()
}));

    return (
        <FormAntd
            onFinish={form.handleSubmit}
            className="add-wrapper"
        >
            <FormAntd.Item>
               <NumberFormat
                   name="basicSalary"
                   type="text"
                   placeholder="Basic Salary"
                   label={<div>Basic Salary<span style={{color:"#FF0000"}}> *</span></div>} 
                   allowNegative={false}
                   thousandSeparator={true}
                   value={form?.values?.basicSalary?.replace(/\.00$/, '')}
                   onChange={form.handleChange}
                   onBlur={form.handleBlur}
               />
                {form.touched.basicSalary && form.errors.basicSalary ? (
                    <span className="text-error">{form.errors.basicSalary}</span>
                ) : null}
            </FormAntd.Item>
            <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="salaryType"
                            label={<div>Salary Type<span style={{color:"#FF0000"}}> *</span></div>} 
                            items={[
                                {
                                    value: "monthly",
                                    title: "Monthly"
                                },
                                {
                                    value: "daily",
                                    title: "Daily"
                                },
                            ]}
                            value={form?.values?.salaryType ? form?.values?.salaryType : null}
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                salaryType: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        {form.touched.salaryType && form.errors.salaryType ? (
                            <span className="text-error">{form.errors.salaryType}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="paymentSchedule"
                            label={<div>Payment Schedule<span style={{color:"#FF0000"}}> *</span></div>} 
                            items={[
                                {
                                    value: "monthly",
                                    title: "Monthly"
                                },
                                {
                                    value: "daily",
                                    title: "Daily"
                                },
                            ]}
                            value={form?.values?.paymentSchedule ? form?.values?.paymentSchedule : null}
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                paymentSchedule: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        {form.touched.paymentSchedule && form.errors.paymentSchedule ? (
                            <span className="text-error">{form.errors.paymentSchedule}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
            </Row>
            <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="bankName"
                            label={<div>Bank Name <span style={{color:"#FF0000"}}> *</span></div>} 
                            placeholder="Bank Name"
                            value={form?.values?.bankName}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        {form.touched.bankName && form.errors.bankName ? (
                            <span className="text-error">{form.errors.bankName}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="accountNumber"
                            label={<div>Account Number <span style={{color:"#FF0000"}}> *</span></div>}
                            placeholder="Account Number"
                            value={form?.values?.accountNumber}
                            onChange={ (e) => {
                                const telNo = e.target.value;
                                const re = /^[0-9\b]+$/;
                                if (telNo === '' || re.test(telNo)) {
                                    form.handleChange(e)
                                }
                            }}
                            onBlur={form.handleBlur}
                            maxLength={16}
                        />
                        {form.touched.accountNumber && form.errors.accountNumber ? (
                            <span className="text-error">{form.errors.accountNumber}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
            </Row>
            <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="accountName"
                            label={<div>Account Name <span style={{color:"#FF0000"}}> *</span></div>}
                            placeholder="Account Name"
                            value={form?.values?.accountName}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        {form.touched.accountName && form.errors.accountName ? (
                            <span className="text-error">{form.errors.accountName}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                    <FormAntd.Item>
                    <SelectOptionWithLabel 
                            name="prorateSetting"
                            label={"Prorate Setting"}
                            items={[
                                {
                                    value: "Based on Calendar Day",
                                    title: "Based on Calendar Day"
                                },
                                {
                                    value: "Based on Working Day",
                                    title: "Based on Working Day"
                                },
                            ]}
                            value={form?.values?.prorateSetting ? form?.values?.prorateSetting :null} 
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                prorateSetting: val,
                                })
                            }
                            onBlur={form.handleBlur}
                            
                        />
                        {form.touched.prorateSetting && form.errors.prorateSetting ? (
                            <span className="text-error">{form.errors.prorateSetting}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
            </Row>
            <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="allowedForOvertime"
                            label={<div>Allowed For Overtime<span style={{color:"#FF0000"}}> *</span></div>}
                            items={[
                                {
                                    value: "Yes",
                                    title: "Yes"
                                },
                                {
                                    value: "No",
                                    title: "No"
                                },
                            ]}
                            value={form?.values?.allowedForOvertime ? form?.values?.allowedForOvertime : null} 
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                allowedForOvertime: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                         {form.touched.allowedForOvertime && form.errors.allowedForOvertime ? (
                            <span className="text-error">{form.errors.allowedForOvertime}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="overtimeWorkingDay"
                            label={"Overtime Working Day"}
                            items={[
                                {
                                    value: "Overtime Leave",
                                    title: "Overtime Leave"
                                },
                                {
                                    value: "Paid Overtime",
                                    title: "Paid Overtime"
                                },
                            ]}
                            value={form?.values?.overtimeWorkingDay ? form?.values?.overtimeWorkingDay :null}
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                overtimeWorkingDay: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        {form.touched.overtimeWorkingDay && form.errors.overtimeWorkingDay ? (
                            <span className="text-error">{form.errors.overtimeWorkingDay}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
            </Row>
            <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="overtimeDayOff"
                            label={"Overtime Day Off"}
                            items={[
                                {
                                    value: "Overtime Leave",
                                    title: "Overtime Leave"
                                },
                                {
                                    value: "Paid Overtime",
                                    title: "Paid Overtime"
                                },
                            ]}
                            value={form?.values?.overtimeDayOff ? form?.values?.overtimeDayOff : null}
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                overtimeDayOff: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                         {form.touched.overtimeDayOff && form.errors.overtimeDayOff ? (
                            <span className="text-error">{form.errors.overtimeDayOff}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="overtimeNationalHoliday"
                            label={"Overtime National Holiday   "}
                            items={[
                                {
                                    value: "Overtime Leave",
                                    title: "Overtime Leave"
                                },
                                {
                                    value: "Paid Overtime",
                                    title: "Paid Overtime"
                                },
                            ]}
                            value={form?.values?.overtimeNationalHoliday ? form?.values?.overtimeNationalHoliday : null}
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                overtimeNationalHoliday: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        {form.touched.overtimeNationalHoliday && form.errors.overtimeNationalHoliday ? (
                            <span className="text-error">{form.errors.overtimeNationalHoliday}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
            </Row>

            <div>
                <div className="text-slate-500 text-[16px]">
                BPJS Configuration
                </div>
                <div className="border-solid border-1 w-[140px]"></div>
                <div className="mt-5">
                <Row justify={"space-between"}>
            <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="bpjsKesehatan"
                            label={<div>BPJS Kesehatan<span className="text-red-500">*</span></div>}
                            items={[
                                {
                                    value: 1,
                                    title: "Yes"
                                },
                                {
                                    value: 2,
                                    title: "No"
                                },
                            ]}
                            value={form?.values?.bpjsKesehatan ? form?.values.bpjsKesehatan : null} 
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                bpjsKesehatan: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                          {form.touched.bpjsKesehatan && form.errors.bpjsKesehatan ? (
                            <span className="relative top-[7px] text-error">{form.errors.bpjsKesehatan}</span>
                        ) : null}
                    </FormAntd.Item>
            </Col>
         {form?.values?.bpjsKesehatan === 1 ? (
                 <Col span={11}>
                 <FormAntd.Item>
                     <div className="flex flex-col">
                     <div className="text-slate-500">{"BPJS Kesehatan Date"}</div>
                     <DatePicker
                         name="bpjsKesehatanDate"
                         format="DD-MM-YYYY"
                         placeholder="DD-MM-YYYY"
                         allowClear={true}
                         value={form.values.bpjsKesehatanDate ? dayjs(form.values.bpjsKesehatanDate, 'YYYY-MM-DD') : null}
                         onChange={bpjsKesehatanDate => {
                         const formattedDate = bpjsKesehatanDate ? dayjs(bpjsKesehatanDate).format('YYYY-MM-DD') : null;
                         form.setFieldValue('bpjsKesehatanDate', formattedDate);
                         }}
                         className="w-full h-11 bg-[#F8FAFB] border-none mt-2"
                     />
                     </div>
                     {form.touched.bpjsKesehatanDate && form.errors.bpjsKesehatanDate ? (
                             <span className="relative top-[7px] text-error">{form.errors.bpjsKesehatanDate}</span>
                     ) : null}
                 </FormAntd.Item>
                 </Col>
            ):null}
        
           
            </Row>
                {form?.values?.bpjsKesehatan === 1 ? (
            <Row justify={"space-between"}>
                      <Col span={11}>
                      <FormAntd.Item>
                          <InputWithLabel
                              name="bpjsKesehatanNumber"
                              label={<div>BPJS Kesehatan Number <span className="text-red-500">*</span></div>}
                              placeholder="BPJS Kesehatan Number"
                              value={form?.values?.bpjsKesehatanNumber}
                              onChange={ (e) => {
                                  const telNo = e.target.value;
                                  const re = /^[0-9\b]+$/;
                                  if (telNo === '' || re.test(telNo)) {
                                      form.handleChange(e)
                                  }
                              }}
                              onBlur={form.handleBlur}
                              maxLength={13}
  
                          />
                          {form.touched.bpjsKesehatanNumber && form.errors.bpjsKesehatanNumber ? (
                              <span className="relative top-[7px] text-error">{form.errors.bpjsKesehatanNumber}</span>
                          ) : null}
                      </FormAntd.Item>
                  </Col>
                      <Col span={11}>
                      <FormAntd.Item>
                      <SelectOptionWithLabel 
                              name="bpjsKesehatanCost"
                              items={[
                                  {
                                      value: "Company",
                                      title: "Company"
                                  },
                                  {
                                      value: "Employee",
                                      title: "Employee"
                                  },
                              ]}
                              label={<div>BPJS Kesehatan Cost<span className="text-red-500">*</span></div>}
                              placeholder="BPJS Kesehatan Cost"
                              value={form?.values?.bpjsKesehatanCost || null}
                              onChange={(val) =>
                                  form.setValues({
                                  ...form.values,
                                  bpjsKesehatanCost: val,
                                  })
                              }
                              onBlur={form.handleBlur}
                          />
                             {form.touched.bpjsKesehatanCost && form.errors.bpjsKesehatanCost ? (
                              <span className="relative top-[7px] text-error">{form.errors.bpjsKesehatanCost}</span>
                          ) : null}
                      </FormAntd.Item>
                  </Col>
                </Row>
                ):null} 
            
                {form?.values?.bpjsKesehatan === 2 ? (
            <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="Insurance"
                            label={<div>Insurance<span className="text-red-500">*</span></div>}
                            items={[
                                {
                                    value: 1,
                                    title: "Yes"
                                },
                                {
                                    value: 2,
                                    title: "No"
                                },
                            ]}
                            value={form?.values?.Insurance ? form?.values?.Insurance : null} 
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                Insurance: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                          {form.touched.Insurance && form.errors.Insurance ? (
                            <span className="relative top-[7px] text-error">{form.errors.Insurance}</span>
                        ) : null}


                    </FormAntd.Item>
                </Col>
                </Row>
                ):null}
                {form?.values?.Insurance === 1 && form?.values?.bpjsKesehatan === 2 ? (
                <Row>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="InsuranceCode"
                            label={<div>Insurance Name<span className="text-red-500">*</span></div>}
                            items={detail.map((item) => ({
                                value: item.code,
                                title: item.name
                            }))}
                        
                            
                            value={form?.values?.InsuranceCode ? form.values?.InsuranceCode : null} 
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                InsuranceCode: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        {form.touched.InsuranceCode && form.errors.InsuranceCode ? (
                            <span className="relative top-[7px] text-error">{form.errors.InsuranceCode}</span>
                        ) : null}
                    </FormAntd.Item>
                </Col>
                </Row>
                ):null}
            {form?.values?.Insurance === 1 && form?.values?.bpjsKesehatan === 2 ? (
            <Row justify={"space-between"}>
            <Col span={11}>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="InsuranceNumber"
                            label={<div>Insurance Number<span className="text-red-500">*</span></div>}
                            placeholder="Insurance Number"
                            value={form?.values?.InsuranceNumber}
                            onChange={ (e) => {
                                const telNo = e.target.value;
                                const re = /^[0-9\b]+$/;
                                if (telNo === '' || re.test(telNo)) {
                                    form.handleChange(e)
                                }
                            }}
                            onBlur={form.handleBlur}
                            maxLength={13}
                        />
                        {form.touched.InsuranceNumber && form.errors.InsuranceNumber ? (
                            <span className="relative top-[7px] text-error">{form.errors.InsuranceNumber}</span>
                        ) : null}

                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                    <FormAntd.Item>
                        <div className="flex flex-col">
                        <div className="text-slate-500">{"Insurance Date"}</div>
                        <DatePicker
                            name="InsuranceDate"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            allowClear={true}
                            value={form.values.InsuranceDate ? dayjs(form.values.InsuranceDate, 'YYYY-MM-DD') : null}
                            onChange={InsuranceDate => {
                            const formattedDate = InsuranceDate ? dayjs(InsuranceDate).format('YYYY-MM-DD') : null;
                            form.setFieldValue('InsuranceDate', formattedDate);
                            }}
                            className="w-full h-11 bg-[#F8FAFB] border-none mt-2"
                        />
                        </div>
                        {form.touched.InsuranceDate && form.errors.InsuranceDate ? (
                                <span className="text-error">{form.errors.InsuranceDate}</span>
                        ) : null}
                    </FormAntd.Item>
                    </Col>
            </Row>   
            ):null}  
                {form.values.bpjsKesehatan === 1 ? (
                <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="bpjsKesehatanFamily"
                            label={<div>BPJS Kesehatan Family</div>}
                            items={items}
                            value={form?.values?.bpjsKesehatanFamily ? form?.values?.bpjsKesehatanFamily : null || form.values?.bpjsKesehatanFamily === 0 ? "0" : null} 
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                bpjsKesehatanFamily: val,
                                },
                                console.log('values',val))
                            }
                            onBlur={form.handleBlur}
                        />
                          {form.touched.bpjsKesehatanFamily && form.errors.bpjsKesehatanFamily ? (
                            <span className="text-error">{form.errors.bpjsKesehatanFamily}</span>
                        ) : null}
                    </FormAntd.Item>
            </Col>
            </Row>
            ):null}
                <Row justify={"space-between"}>
                <Col span={11}>
                    <FormAntd.Item>
                        <SelectOptionWithLabel 
                            name="bpjsKetenagakerjaanType"
                            // label={<div>BPJS Ketenagakerjaan Type<span className="text-red-500">*</span></div>}
                            label={<div>BPJS Ketenagakerjaan Type</div>}
                            items={[
                                {
                                    value: "No",
                                    title: "No"
                                },
                                {
                                    value: "BPU",
                                    title: "BPU"
                                },
                                {
                                    value: "PU",
                                    title: "PU"
                                },
                               
                            ]}
                            value={form?.values?.bpjsKetenagakerjaanType ? form?.values?.bpjsKetenagakerjaanType : null} 
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                bpjsKetenagakerjaanType: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                          {form.touched.bpjsKetenagakerjaanType && form.errors.bpjsKetenagakerjaanType ? (
                            <span className="relative top-[7px] text-error">{form.errors.bpjsKetenagakerjaanType}</span>
                        ) : null}
                    </FormAntd.Item>
            </Col>
            {form?.values.bpjsKetenagakerjaanType === "BPU" || form.values.bpjsKetenagakerjaanType === "PU" ?    
            <Col span={11}>
                    <FormAntd.Item>
                        <div className="flex flex-col">
                        <div className="text-slate-500">{"BPJS Ketenagakerjaan Date"}</div>
                        <DatePicker
                            name="bpjsKetenagakerjaanDate"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            allowClear={true}
                            value={form.values.bpjsKetenagakerjaanDate ? dayjs(form.values.bpjsKetenagakerjaanDate, 'YYYY-MM-DD') : null}
                            onChange={bpjsKetenagakerjaanDate => {
                            const formattedDate = bpjsKetenagakerjaanDate ? dayjs(bpjsKetenagakerjaanDate).format('YYYY-MM-DD') : null;
                            form.setFieldValue('bpjsKetenagakerjaanDate', formattedDate);
                            }}
                            className="w-full h-11 bg-[#F8FAFB] border-none mt-2"
                        />
                        </div>
                        {form.touched.bpjsKetenagakerjaanDate && form.errors.bpjsKetenagakerjaanDate ? (
                                <span className="text-error">{form.errors.bpjsKetenagakerjaanDate}</span>
                        ) : null}
                    </FormAntd.Item>
                    </Col>
                    :null}
            </Row>
            {form?.values.bpjsKetenagakerjaanType === "BPU" || form.values.bpjsKetenagakerjaanType === "PU" ?    
            <Row justify={"space-between"}>
            <Col span={11}>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="bpjsKetenagakerjaan"
                            // label={<div>BPJS Ketenagakerjaan Number<span className="text-red-500">*</span></div>}
                            label={<div>BPJS Ketenagakerjaan Number</div>}
                            placeholder="BPJS Ketenagakerjaan Number"
                            value={form?.values?.bpjsKetenagakerjaan}
                            onChange={ (e) => {
                                const telNo = e.target.value;
                                const re = /^[0-9\b]+$/;
                                if (telNo === '' || re.test(telNo)) {
                                    form.handleChange(e)
                                }
                            }}
                            onBlur={form.handleBlur}
                            maxLength={16}
                        />
                        {form.touched.bpjsKetenagakerjaan && form.errors.bpjsKetenagakerjaan ? (
                            <span className="relative top-[7px] text-error">{form.errors.bpjsKetenagakerjaan}</span>
                        ) : null}

                    </FormAntd.Item>
                </Col>
            </Row>
            :null}
         
            {form.values.bpjsKetenagakerjaanType === "PU" ? (
                  <Row justify={"space-between"}>
                  <Col span={11}>
                      <FormAntd.Item>
                          <SelectOptionWithLabel 
                              name="jhtCostName"
                              label={<div>JHT Cost Type<span className="text-red-500">*</span></div>}
                              items={[
                                  {
                                      value: "No",
                                      title: "No"
                                  },
                                  {
                                      value: "Company",
                                      title: "Company"
                                  },
                                  {
                                      value: "Employee",
                                      title: "Employee"
                                  },
                              ]}
                              value={form?.values?.jhtCostName ? form?.values?.jhtCostName  : null} 
                              onChange={(val) =>
                                  form.setValues({
                                  ...form.values,
                                  jhtCostName: val,
                                  })
                              }
                              onBlur={form.handleBlur}
                          />
                           {form.touched.jhtCostName && form.errors.jhtCostName ? (
                              <span className="relative top-[7px] text-error">{form.errors.jhtCostName}</span>
                          ) : null}
                      </FormAntd.Item>
                  </Col>
                  <Col span={11}>
                       <FormAntd.Item>
                           <SelectOptionWithLabel 
                               name="jaminanPensiunCost"
                               label={<div>JP Cost Type<span className="text-red-500">*</span></div>}
                               items={[
                                   {
                                       value: "No",
                                       title: "No"
                                   },
                                   {
                                       value: "Company",
                                       title: "Company"
                                   },
                                   {
                                       value: "Employee",
                                       title: "Employee"
                                   },
                               ]}
                               value={form?.values?.jaminanPensiunCost ? form?.values?.jaminanPensiunCost : null} 
                               onChange={(val) =>
                                   form.setValues({
                                   ...form.values,
                                   jaminanPensiunCost: val,
                                   })
                               }
                               onBlur={form.handleBlur}
                           />
                             {form.touched.jaminanPensiunCost && form.errors.jaminanPensiunCost ? (
                               <span className="relative top-[7px] text-error">{form.errors.jaminanPensiunCost}</span>
                           ) : null}
                       </FormAntd.Item>
               </Col>
                  
              </Row>
            ): null}
          
                </div>
          </div>
            <Row justify={"space-between"}>
                    <Col span={11}>
                            <FormAntd.Item>
                            <CheckboxOptionWithLabel name="componentOvertime"
                                onChange={e => {
                                    form.handleChange(e);
                                }}
                                checked={form.values?.componentOvertime}  label={'Component Overtime'}
                                value={form.values?.componentOvertime}
                             />
                            </FormAntd.Item>
                    </Col>

                </Row>


                <Row justify={"space-between"}> 
                {form.values.componentOvertime ? (
                    <>
                <Col span={11}>
                    <FormAntd.Item>
                        <NumberFormat
                            name="TunjanganJabatan"
                            thousandSeparator={true} 
                            label={"Tunjangan Jabatan"}
                            placeholder="Tunjangan Jabatan"
                            value={form?.values?.TunjanganJabatan}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                <FormAntd.Item>
                        <NumberFormat
                            name="TunjanganKehadiran"
                            thousandSeparator={true} 
                            label={"Tunjangan Kehadiran"}
                            placeholder="Tunjangan Kehadiran"
                            value={form?.values?.TunjanganKehadiran}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                           
                        />
                    </FormAntd.Item>
                </Col>
                            
                <Col span={11}>
                    <FormAntd.Item>
                        <NumberFormat
                            name="TunjanganProfesi"
                            thousandSeparator={true} 
                            label={"Tunjangan Profesi"}
                            placeholder="Tunjangan Profesi"
                            value={form?.values?.TunjanganProfesi}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}

                        />
                    </FormAntd.Item>
                </Col>
                <Col span={11}>
                <FormAntd.Item>
                        <NumberFormat
                            name="TunjanganMakan"
                            thousandSeparator={true} 
                            label={"Tunjangan Makan"}
                            placeholder="Tunjangan Makan"
                            value={form?.values?.TunjanganMakan}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                           
                        />
                    </FormAntd.Item>
                </Col>
                            
                    </>
                        ):null}
                </Row>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={handleBack}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Next
                </Button>
            </div>
        </FormAntd>
    )
}

export default PayrollForm;