import { Button, Col, Form as FormAntd, message, Row,DatePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { DatePicker2WithLabel, InputWithLabel, SelectOptionWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getCompany, getCompanyDetail } from "../../api/company";
import moment from "moment";
import { DeleteOutlined,CloudUploadOutlined } from "@ant-design/icons";
import axios from "axios";
import './styles.scss'
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import dayjs from "dayjs";
const FormAddCompany = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];;
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState();
    const [flag, setFlag] = useState(0);
    const [listCompany, setListCompany] = useState([]);
    const inputRef = useRef(null);
    const [active, setActive] = useState(1)
    const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [fileName, setFileName] = useState(null);
    const [filePayroll, setFilePayroll] = useState(null);
    const inputRefPayroll = useRef(null);
    const BestpraticeUseEffect = useRef(false)
    const BestpraticeUseEffect1 = useRef(false)
    const companyDetail = async () => {
		setFetching(true);
		try {
		  const res = await getCompanyDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if(result){
                companyDetail();
            }
          }
     
	}, [flag]);

    const companyList = async () => {
		setFetching(true);
		try {
		  const res = await getCompany("", -1, 0, 0, active,deCompanyCode)
		  const data = res?.data?.data
		  setListCompany(data.map((item) => {
			return {
                idx : item.company_id,
				value : item.code,
				title : item.name
			}
		}))
		} catch (err) {
			message.error({
				content: err.response.data.error,
			});
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	};
	
	useEffect(() => {
        if (!BestpraticeUseEffect1.current) {
            BestpraticeUseEffect1.current = true;
          } else {
            companyList();
          }

	}, []);

    const validationUser = yup.object().shape({
        companyName: yup.string().required("Field is required"),
        companyLogo: yup.string().required("Document Image is required"),
        companyPhoneNumber: yup.string().min(10, "Min 10 characters").required("Field is required"),
        email: yup.string().email("Must be a valid email").required("Field is required"),
        address: yup.string().required("Field is required"),
        province: yup.string().required("Field is required"),
        city: yup.string().required("Field is required"),
        contactPersonEmail: yup.string().email("Must be a valid email").nullable(),
        contactPersonNumber: yup.string().min(10, "Min 10 characters").nullable(),
        // postalCode: yup.string(),
    });
    
    const form = useFormik({
        initialValues: {
            companyName: result ? detail?.name : null,
            companyPhoneNumber: result ? detail?.phone_number : null,
            email: result ? detail?.email : null,
            companyLogo: result ? false : null,
            address: result ? detail?.address : null,
            postalCode: result? (detail?.postal_code == null ? "" : detail?.postal_code) : "",
            province: result ? detail?.province : null,
            city: result ? detail?.city : null,
            industry: result ? (detail?.industry == 'null' ? "" : detail?.industry) : null,
            companyNpwp: result ? (detail?.company_npwp == 'null' ? "" : detail?.company_npwp) : null,
            companyTaxDate: result ? detail?.company_tax_date : "",
            taxPersonName: result ? (detail?.tax_person_name == 'null' ? "" : detail?.tax_person_name) : null,
            taxPersonNpwp: result ? (detail?.tax_person_npwp == 'null' ? "" : detail?.tax_person_npwp) : null,
            contactPersonName: result ? (detail?.contact_person_name == 'null' ? "" : detail?.contact_person_name) : null,
            contactPersonNumber: result ? (detail?.contact_person_number == 'null' ? "" : detail?.contact_person_number) : null,
            contactPersonEmail: result ? detail?.contact_person_email : "",
            status: result ? detail?.status : null,
            companyTemplatePayroll : result ? false : null
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            if(result){
                setFetching(true)
                var bodyFormData = new FormData();
                bodyFormData.append('company_id', id);
                bodyFormData.append('name', values.companyName);
                bodyFormData.append('description', "add");
                bodyFormData.append('email', values.email);
                bodyFormData.append('address', values.address);
                bodyFormData.append('province', values.province);
                bodyFormData.append('postal_code', values.postalCode);
                bodyFormData.append('city', values.city);
                bodyFormData.append('phone_number', values.companyPhoneNumber);
                bodyFormData.append('industry', values.industry);
                bodyFormData.append('status', values.status);
                bodyFormData.append('company_npwp', values.companyNpwp);
                bodyFormData.append('company_tax_date', values.companyTaxDate);
                bodyFormData.append('tax_person_name', values.taxPersonName);
                bodyFormData.append('tax_person_npwp', values.taxPersonNpwp);
                bodyFormData.append('modified_by', "SYSTEM");
                if(values.companyLogo){
                    bodyFormData.append('company_logo_url', values.companyLogo);
                }
                if(values.companyTemplatePayroll) {
                    bodyFormData.append('company_template_payroll', values.companyTemplatePayroll);
                }
                bodyFormData.append('contact_person_name', values.contactPersonName);
                if(values.contactPersonEmail){
                    bodyFormData.append('contact_person_email', values.contactPersonEmail);
                }
                bodyFormData.append('contact_person_number', values.contactPersonNumber);
                
                await axios({
                    method: "post",
                    url: process.env.REACT_APP_HRIS_URL+"company-update",
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(function (response) {
                    if(!response?.data?.success){
                        message.error({
                            content: response?.data?.error,
                        });
                    } else {
                        message.success({
                            content: result ? "Company successfully updated" : "Company successfully saved",
                        });
                        setTimeout(() => {
                            // window.location.reload();
                        }, 1000);
                        navigate('/company')
                        form.resetForm();
                        setFlag((prev) => prev + 1)
                       
                    }
                    setFetching(false)
                  })
                  .catch(function (response) {
                    message.error({
                        content: "Request Not Found",
                    });
                    setFetching(false)
                  });
            } else {
                setFetching(true)
                var bodyFormData = new FormData();
                bodyFormData.append('name', values.companyName);
                bodyFormData.append('description', "add");
                bodyFormData.append('email', values.email);
                bodyFormData.append('address', values.address);
                bodyFormData.append('province', values.province);
                bodyFormData.append('postal_code', values.postalCode);
                bodyFormData.append('city', values.city);
                bodyFormData.append('phone_number', values.companyPhoneNumber);
                bodyFormData.append('industry', values.industry);
                bodyFormData.append('company_npwp', values.companyNpwp);
                bodyFormData.append('company_tax_date', values.companyTaxDate);
                bodyFormData.append('tax_person_name', values.taxPersonName);
                bodyFormData.append('tax_person_npwp', values.taxPersonNpwp);
                bodyFormData.append('created_by', "SYSTEM");
                bodyFormData.append('company_logo_url', values.companyLogo);
                bodyFormData.append('contact_person_name', values.contactPersonName);
                bodyFormData.append('contact_person_email', values.contactPersonEmail);
                bodyFormData.append('contact_person_number', values.contactPersonNumber);
                if(values.companyTemplatePayroll) {
                    bodyFormData.append('company_template_payroll', values.companyTemplatePayroll);
                }
                
                await axios({
                    method: "post",
                    url: process.env.REACT_APP_HRIS_URL+"company-store",
                    data: bodyFormData,
                    headers: { "Content-Type": "multipart/form-data" },
                }).then(function (response) {
                    if(!response?.data?.success){
                        message.error({
                            content: response?.data?.error,
                        });
                    } else {
                        message.success({
                            content: result ? "Edit Data Success" : "Add Data Success",
                        });
                        // navigate('/company')
                        form.resetForm();
                        setFlag((prev) => prev + 1)
                        window.location.reload()
                    }
                    setFetching(false)
                  })
                  .catch(function (response) {
                    message.error({
                        content: "Request Not Found",
                    });
                    setFetching(false)
                  });
            }
        },
    });



    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (file && file.size <= 2 * 1024 * 1024) {
        setFileName(file.name);
        form.setValues({
            ...form.values,
            companyLogo: e.currentTarget.files[0],
        })
      }
      else{
        setFileName(null);
        let alesrt = message.error('Please upload a JPEG,JPG,PNG file with a maximum size of 2 MB');
        setTimeout(()=>{
            if(alesrt){
                window.location.reload(true)
            }
        },3000)
      
      }
    };

    const handleFilePayroll = (e) => {
        const file = e.target.files[0];
        setFilePayroll(file.name);
        form.setValues({
            ...form.values,
            companyTemplatePayroll: e.currentTarget.files[0],
        })
    };

    const handleFileDelete = () => {
        setFileName(null);
    };

    const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/company"
		},
		{
			title: "Company",
			url: "/company"
		},
		{
			title: `${result ?  'Edit Company' : 'Add Company'}` ,
			// url: `${ result ?  '/company/edit-company' : '/company/add-company'}`
		},
	]
  
    return (
        <>
          <div className="title-section">
				{result ? "Edit Company" : "Add Company"}
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="add-wrapper">
           
          <FormAntd
              onFinish={form.handleSubmit}
          >
              <div className="title-divider">Company Logo <span style={{color:"#FF0000"}}> *</span></div>
              {detail?.company_logo_url && (
                  <div id={id}>
                    <img src={process.env.REACT_APP_HRIS_URL+`company/image/${detail?.company_logo_url}`} height="250px"/>
                </div>
              )}
              <Row>
                  <FormAntd.Item>
                      <div className="flex-container">
                          <div>
                              <label className="file-upload">
                                  <input type="file" onChange={handleFileUpload} accept=".jpeg, .jpg, .png"  ref={inputRef} />
                                  <CloudUploadOutlined /> Uploading Company Image
                              </label>
                          </div>
                          <div>
                              {fileName && (
                                  <div>
                                      <p>{fileName}   <Button  shape="circle" onClick={handleFileDelete} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                                  </div>
                              )}
                          </div>
                      </div>
                      {form.touched.companyLogo && form.errors.companyLogo ? (
                        <span className="text-error">{form.errors.companyLogo}</span>
                        ) : null}
                  </FormAntd.Item>
              </Row>
              <div className="title-divider">Company Info</div>
              <FormAntd.Item>
                  <InputWithLabel
                      name="companyName"
                      label={<div>Company Name<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Company Name"
                      value={form?.values?.companyName}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.companyName && form.errors.companyName ? (
                      <span className="text-error">{form.errors.companyName}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel 
                      name="companyPhoneNumber"
                      label={<div>Company Phone Number<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="08**********"
                      value={form?.values?.companyPhoneNumber}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      onBlur={form.handleBlur}
                      maxLength={13}
                  />
                  {form.touched.companyPhoneNumber && form.errors.companyPhoneNumber ? (
                      <span className="text-error">{form.errors.companyPhoneNumber}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="email"
                      label={<div>Email<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Email"
                      value={form?.values?.email}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.email && form.errors.email ? (
                      <span className="text-error">{form.errors.email}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="address"
                      label={<div>Address<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Address"
                      value={form?.values?.address}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.address && form.errors.address ? (
                      <span className="text-error">{form.errors.address}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="postalCode"
                      label={"Postal Code"}
                      placeholder="Postal Code"
                      value={form?.values?.postalCode}
                      // onChange={form.handleChange}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      onBlur={form.handleBlur}
                      maxLength={6}
                  />
                  {form.touched.postalCode && form.errors.postalCode ? (
                      <span className="text-error">{form.errors.postalCode}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="province"
                      label={<div>Province<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Province"
                      value={form?.values?.province}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.province && form.errors.province ? (
                      <span className="text-error">{form.errors.province}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="city"
                      label={<div>City<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="City"
                      value={form?.values?.city}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.city && form.errors.city ? (
                      <span className="text-error">{form.errors.city}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="industry"
                      label={"Industry"}
                      placeholder="Industry"
                      value={form?.values?.industry}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.industry && form.errors.industry ? (
                      <span className="text-error">{form.errors.industry}</span>
                  ) : null}
              </FormAntd.Item>
              <div className="title-divider">Tax Info</div>
              <FormAntd.Item>
                  <InputWithLabel
                      name="companyNpwp"
                      label={"Company NPWP"}
                      placeholder="Company Npwp"
                      value={form?.values?.companyNpwp}
                      // onChange={form.handleChange}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      maxLength={16}
                      onBlur={form.handleBlur}
                     
                  />
                  {form.touched.companyNpwp && form.errors.companyNpwp ? (
                      <span className="text-error">{form.errors.companyNpwp}</span>
                  ) : null}
              </FormAntd.Item>
                       <FormAntd.Item>
                          <div className="flex flex-col">
                          <div className="text-slate-500">{"Company Tax Date"}</div>
                          <DatePicker
                            name="companyTaxDate"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.companyTaxDate ? dayjs(form.values.companyTaxDate, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('companyTaxDate', formattedDate);
                      
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          {form.touched.companyTaxDate && form.errors.companyTaxDate && (
                            <span className="text-error">{form.errors.companyTaxDate}</span>
                          )}
                        </div>
                      </FormAntd.Item>
                      <FormAntd.Item>
                          <InputWithLabel
                              name="taxPersonName"
                              label={"Tax Person Name"}
                              placeholder="Tax Person Name"
                              value={form?.values?.taxPersonName}
                              onChange={(e) => {
                                  const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                                  if (!regex.test(e.target.value)) {
                                      form.handleChange(e);
                                  }
                              }}
                              onBlur={form.handleBlur}
                          />
                          {form.touched.taxPersonName && form.errors.taxPersonName ? (
                              <span className="text-error">{form.errors.taxPersonName}</span>
                          ) : null}
                      </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="taxPersonNpwp"
                      label={"Tax Person NPWP"}
                      placeholder="Tax Person Npwp"
                      value={form?.values?.taxPersonNpwp}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      maxLength={16}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.taxPersonNpwp && form.errors.taxPersonNpwp ? (
                      <span className="text-error">{form.errors.taxPersonNpwp}</span>
                  ) : null}
              </FormAntd.Item>
              <div className="title-divider">Other Info</div>
              <FormAntd.Item>
                  <InputWithLabel
                      name="contactPersonName"
                      label={"Contact Person Name"}
                      placeholder="Contact Person Name"
                      value={form?.values?.contactPersonName}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.contactPersonName && form.errors.contactPersonName ? (
                      <span className="text-error">{form.errors.contactPersonName}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel 
                      name="contactPersonNumber"
                      label={"Contact Person Number"}
                      placeholder="08**********"
                      value={form?.values?.contactPersonNumber}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      onBlur={form.handleBlur}
                      maxLength={13}
                  />
                  {form.touched.contactPersonNumber && form.errors.contactPersonNumber ? (
                      <span className="text-error">{form.errors.contactPersonNumber}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="contactPersonEmail"
                      label={"Contact Person Email"}
                      placeholder="Contact Person Email"
                      value={form?.values?.contactPersonEmail}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.contactPersonEmail && form.errors.contactPersonEmail ? (
                      <span className="text-error">{form.errors.contactPersonEmail}</span>
                  ) : null}
              </FormAntd.Item>
              <Row>
              {/* <FormAntd.Item>
                      <div className="flex-container">
                          <div>
                              <label className="file-upload">
                                  <input type="file" onChange={handleFilePayroll} accept=".xls, .xlsx"  ref={inputRefPayroll} />
                                  <CloudUploadOutlined /> Uploading Template Payroll
                              </label>
                          </div>
                          <div>
                              {filePayroll && (
                                  <div>
                                      <p>{filePayroll}   <Button  shape="circle" onClick={() => setFilePayroll(null)} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                                  </div>
                              )}
                          </div>
                      </div>
                      {form.touched.companyTemplatePayroll && form.errors.companyTemplatePayroll ? (
                        <span className="text-error">{form.errors.companyTemplatePayroll}</span>
                        ) : null}
                  </FormAntd.Item> */}
                  </Row>
              {result && (
                  <FormAntd.Item>
                      <SelectOptionWithLabel
                          name="status"
                          label={"Status"} 
                          items={[
                              {
                                  value: 1,
                                  title: "Active"
                              },
                              {
                                  value: 0,
                                  title: "Inactive"
                              },
                          ]}
                          value={form?.values?.status}
                          onChange={(val) =>
                              form.setValues({
                              ...form.values,
                              status: val,
                              })
                          }
                          onBlur={form.handleBlur}
                      />
                      {form.touched.status && form.errors.status ? (
                          <span className="text-error">{form.errors.status}</span>
                      ) : null}
                  </FormAntd.Item>
              )}
          </FormAntd>
          <div className="footer-add">
              <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/company')}>
                  Cancel
              </Button>
              <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                  Submit
              </Button>
          </div>
      </div>
        </>
        
      
    )
}

export default FormAddCompany;