/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Table, Tabs, Button } from "antd";
import { useEffect, useState } from "react";
import TabPane from "antd/es/tabs/TabPane";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { formatCurrency } from "../../../../helper/formatCurrency";
import Breadcrumb from "../../../../component/Breadcrumb";
import {
  getManagementDetailLoanId,
  getManagementDetailLoanIdInstallment,
} from "../../../../api/employee";
import "../../styles.scss";
import moment from "moment";

const LoanDetailEmployee = () => {
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const navigate = useNavigate()
  const [detailId, setDetailId] = useState([]);
  const [employeeId, setEmployeeId] = useState([]);
  const [listTable, setListTable] = useState([]);
  const [loading, setLoading] = useState(false);

  const detailLoanManagementList = async () => {
    try {
      const res = await getManagementDetailLoanId(id);
      setDetailId(res?.data?.data);
      setEmployeeId(res?.data?.data?.employee_id)
    } catch (err) {
      console.log(err);
    }
  };
  const detailLoanManagementListInstallment = async () => {
    try {
      const res = await getManagementDetailLoanIdInstallment(id);
      setListTable(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    detailLoanManagementList();
    detailLoanManagementListInstallment();
  }, []);

  const dataBreadcrumb = [
    {
      title: "Master Data",
      url: "/employee-management",
    },
    {
			title: "Employee Management Detail",
      url: `/employee-management/detail/id%3F${employeeId}`
		},
    {
      title: "Loan Detail",
      url: `/employee-management/detail/detail-loan/${result}`,
    },
  ];

  const columnLoan = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Installment Date",
      dataIndex: "installment_date",
      key: "installment_date",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {text ? moment(text).format("DD MMMM YYYY") : "-"}
          </div>
        );
      },
    },
    {
      title: "Installment",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Installment Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Payment Date",
      dataIndex: "paid_date",
      key: "paid_date",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {text ? moment(text).format("DD MMMM YYYY") : "-"}
          </div>
        );
      },
    },
    {
      title: "Remaining Amount",
      dataIndex: "remaining",
      key: "remaining",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {text ? text.charAt(0).toUpperCase() + text.slice(1) : ""}
          </div>
        );
      },
    },
  ];

  function dataTableWithNumber() {
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: index + 1,
      };
    });
  }

  const statusMap = {
    "on process": "On Process",
    paid: "Paid Off",
    active: "Active",
  };

  return (
    <>
      <div className="title-section">
            <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate(`/employee-management/detail/id%3F${employeeId}`)} icon={<ArrowLeftOutlined />} size={"large"} />
                Loan Detail
			</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <Row>
          <Col span={12}>
            <div className="detail-card">
              <div className="title"> Employee Name</div>
              <div className="subtitle">{detailId?.full_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Position</div>
              <div className="subtitle">{detailId?.position_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Department</div>
              <div className="subtitle">{detailId?.department_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Division</div>
              <div className="subtitle">{detailId?.division_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Loan Number</div>
              <div className="subtitle">{detailId?.loan_number || "-"}</div>
            </div>
          </Col>

          <Col span={12}>
            <div className="detail-card">
              <div className="title">Status</div>
              <div className="subtitle">
                {detailId?.active_status
                  ? statusMap[detailId.active_status.toLowerCase()] ||
                    detailId.active_status
                  : "-"}
              </div>
            </div>
          </Col>
          <div className="mt-7 border-dashed border-2 border-sky-500 w-full bg-white p-6 rounded-lg shadow-lg">
            <Tabs defaultActiveKey="1" className="tab-wrapper" type="card">
              <TabPane tab="Loan Detail" key="1">
                <div className="detail-wrapper">
                  <div className="border-dashed border-2 border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg mt-3">
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Loan Type</div>
                          <div className="subtitle">
                            {detailId?.name || "-"}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Loan Date</div>
                          <div className="subtitle">
                            {detailId?.loan_date || "-"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Loan Amount</div>
                          <div className="subtitle">
                            {isNaN(detailId?.loan_amount)
                              ? "-"
                              : formatCurrency(detailId?.loan_amount)}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Disburse Date </div>
                          <div className="subtitle">
                            {detailId?.disburse_date || "-"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Col span={12}>
                      <div className="detail-card">
                        <div className="title">Calculation Reference</div>
                        <div className="title mt-[-10px] text-[#323232] text-[10px] font-bold">
                          {detailId?.cal_ref === "period"
                            ? "Installment Period"
                            : "Installment Amount"}
                        </div>
                        <div className="subtitle">
                          {detailId?.cal_ref == "period" ? (
                            <div className="flex gap-2">
                              <div>{detailId?.tenor || "-"}</div>
                              <div>Months</div>
                            </div>
                          ) : isNaN(detailId?.installment) ? (
                            "-"
                          ) : (
                            formatCurrency(detailId?.installment)
                          )}
                        </div>
                      </div>
                    </Col>

                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">First Installment Custom</div>
                          <div className="subtitle">
                            {isNaN(detailId?.first_installment_custom)
                              ? "-"
                              : formatCurrency(
                                  detailId?.first_installment_custom
                                )}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">
                            {detailId?.cal_ref == "period"
                              ? "Total Installment"
                              : "Total Period"}
                          </div>
                          <div className="subtitle">
                            {detailId?.cal_ref == "period" ? (
                              isNaN(detailId?.installment) ? (
                                "-"
                              ) : (
                                formatCurrency(detailId?.installment)
                              )
                            ) : (
                              <div className="flex gap-2">
                                <div>{detailId?.tenor || "-"}</div>
                                <div>Months</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">First Installment Period</div>
                          <div className="subtitle">
                            {detailId?.first_installment_period === "same"
                              ? "Same period as the loan disbursement date"
                              : "1 Period after the loan disbursement date"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Installments Start Date</div>
                          <div className="subtitle">
                            {detailId?.start_date || "-"}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Estimated End Date</div>
                          <div className="subtitle">
                            {detailId?.end_date || "-"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Description</div>
                          <div className="subtitle">
                            {detailId?.description}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card overflow-x-auto">
                          <div className="title">Attachment</div>
                          <div className="text-sm text-gray-500 flex items-center">
                            <div>
                              <svg
                                className="w-4 h-4 mr-1 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                              </svg>
                            </div>

                            <>
                              {detailId?.attachment_url ? (
                                <Link
                                  to={detailId?.attachment_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="block overflow-x-auto text-gray-500"
                                  style={{
                                    wordWrap: "break-word",
                                    width: "100%",
                                  }}>
                                  {detailId?.attachment_url.replace(
                                    `${process.env.REACT_APP_HRIS_URL}backend/company/image/`,
                                    ""
                                  )}
                                </Link>
                              ) : (
                                <span
                                  key={detailId?.id}
                                  className="text-gray-500">
                                  No File
                                </span>
                              )}
                            </>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Installment" key="2">
                <div className="mt-7 border-dashed border-2  border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg">
                  <div className="text-slate-500 text-[16px]">
                    Installment Detail
                  </div>
                  <div className="border-solid border-1 w-[125px] mb-5"></div>
                  <div className="table-wrapper">
                    {loading ? (
                      <>
                         <Table
                          id={"my-table"}
                          className="table-employee-loan"
                          columns={columnLoan}
                          loading={loading}
                          pagination={true}
                        />
                      </>
                    ) : (
                      <>
                        <Table
                          id={"my-table"}
                          className="table-employee-loan"
                          dataSource={dataTableWithNumber()}
                          columns={columnLoan}
                          loading={loading}
                          pagination={true}
                        />
                      </>
                    )}
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Row>
      </div>
    </>
  );
};

export default LoanDetailEmployee;